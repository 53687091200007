import React, { useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import IconArrowDown from "../../atoms/icons/IconArrowDown";
import IconOn from "../../atoms/icons/IconOn";
import Typography from "../../atoms/Typography";
import SelectOptions from "../SelectOptions/SelectOptions";
import ItemFloatingMenu from "../ItemFloatingMenu";
import Link from "../../molecules/Link";
import IconArrowUp from "../../atoms/icons/IconArrowUp";

import "./textbox.scss";
import IconSort from "../../atoms/icons/IconSort";
import IconSearch from "../../atoms/icons/IconSearch";

interface Props {
  children: any;
  onChange: any;
  options?: any;
  className?: string;
  size?: any;
  disabled?: any;
  onclickDefaultValue?: Function;
}

const Textbox: React.FC<Props> = ({
  children,
  size = "default",
  className,
  onChange,
  disabled,
  options,
  onclickDefaultValue,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const onClickHandler = (option: any): void => {
    //onclickDefaultValue(option);
    setIsVisible(!isVisible);
  };

  return (
    <>
      <div className={`textbox ${disabled ? "textbox__disabled" : ""
        } textbox--${size} ${className ? className : ""}`}
      >
        {disabled ? <div
          className={`textbox-icon ${isVisible ? "textbox--active" : "textbox--inactive"} textbox-icon--${size}`}
        >
          < IconSearch fill="#BCBCBC" />
        </div> : <div
          className={`textbox-icon ${isVisible ? "textbox--active" : "textbox--inactive"} textbox-icon--${size}`}
        >
          < IconSearch />
        </div>}

        <div
          className={`textbox-text`}
        >
          <input 
            className={`textbox-text textbox-text__text`} 
            type="text" 
            onChange={onChange}
            value={children}
            name="buscar" 
            placeholder="Buscar"/>
        </div>

      </div>
    </>
  );
};

export default Textbox;
