import Typography from "../Typography";

import "./modal.scss";

interface Props {
   title: any;
   body: any;
   buttons?: any;
   icon?: any;
   width?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
   isOpen: boolean;
   closeModal: () => void;
}

const Modal: React.FC<Props> = ({
   title,
   body,
   buttons,
   isOpen = false,
   width,
   icon,
   closeModal,
}) => {
   return (
      <div className={`modal modal--${isOpen ? "open" : "close"}`}>
         <section className={`modal__content modal__content--${width}`}>
            <div
               className={`modal__section-title modal__section-title${
                  icon ? "--center" : "--left"
               }`}
            >
               {icon && <div className="modal__container-icon">{icon}</div>}
               {typeof title === "string" ? (
                  <Typography type="h2" variation="mobile" bold>
                     {title}
                  </Typography>
               ) : (
                  title
               )}
            </div>
            <div className="modal__body">{body}</div>
            <div className="modal__buttons">{buttons}</div>
         </section>
      </div>
   );
};

export default Modal;
