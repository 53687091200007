// Constants
import PERMISSIONS from "../../../constants/permission.constants";

// Router
import { Link, useNavigate, useLocation } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { logout } from "../../../slices/authSlice";

// Icons
import IconHome from "../../atoms/icons/IconHome";
import IconGroups from "../../atoms/icons/IconGroups";
import IconRooms from "../../atoms/icons/IconRooms";
import IconStudents from "../../atoms/icons/IconStudents";
import IconGuardians from "../../atoms/icons/IconGuardians";
import IconTeachers from "../../atoms/icons/IconTeachers";
import IconCourses from "../../atoms/icons/IconCourses";
import IconPaths from "../../atoms/icons/IconPaths";
import IconSliders from "../../atoms/icons/IconSliders";
import IconCoupons from "../../atoms/icons/IconCoupons";
import IconAges from "../../atoms/icons/IconAges";
import IconLive from "../../atoms/icons/IconLive";
import IconUpload from "../../atoms/icons/IconUpload";
import IconLogo from "../../atoms/icons/IconLogo";
import UserDropdown from "../UserDropdown";
import IconInvoices from "../../atoms/icons/iconFacturas";
import IconArrowSidebar from "../../atoms/icons/IconArrowSidebar";

import imgUser from "../../../assets/img/imgUser.png";

import "./sidebar.scss";

interface Props {
   isOpen: any;
   setIsOpen: any;
}

const Sidebar: React.FC<Props> = ({ isOpen, setIsOpen }) => {
   const navigate = useNavigate();
   const { pathname } = useLocation();
   const dispatch: AppDispatch = useAppDispatch();
   const user = useSelector((state: RootState) => state.auth.user);

   const handleLogout = () => {
      dispatch(logout());
      navigate("/");
   };

   const navItems = [
      {
         icon: (
            <IconHome
               fill={pathname === "/dashboard" ? "#9C56E0" : "#555555"}
            />
         ),
         title: "Inicio",
         link: "/dashboard",
         permission: PERMISSIONS.menuRoom,
      },
      {
         icon: (
            <IconGroups
               fill={
                  pathname.includes("/dashboard/groups") ? "#9C56E0" : "#555555"
               }
            />
         ),
         title: "Grupos",
         link: "/dashboard/groups",
         permission: PERMISSIONS.menuAbsences,
      },
      {
         icon: (
            <IconRooms
               fill={
                  pathname.includes("/dashboard/rooms") ? "#9C56E0" : "#555555"
               }
            />
         ),
         title: "Salones",
         link: "/dashboard/rooms",
         permission: PERMISSIONS.menuRoom,
      },
      {
         icon: (
            <IconStudents
               fill={
                  pathname.includes("/dashboard/students")
                     ? "#9C56E0"
                     : "#555555"
               }
            />
         ),
         title: "Estudiantes",
         link: "/dashboard/students",
         permission: PERMISSIONS.menuAbsences,
      },
      {
         icon: (
            <IconGuardians
               fill={
                  pathname.includes("/dashboard/guardians")
                     ? "#9C56E0"
                     : "#555555"
               }
            />
         ),
         title: "Tutores",
         link: "/dashboard/guardians",
         permission: PERMISSIONS.menuAbsences,
      },
      {
         icon: (
            <IconInvoices
               fill={
                  pathname.includes("/dashboard/invoices")
                     ? "#9C56E0"
                     : "#555555"
               }
            />
         ),
         title: "Pagos",
         link: "/dashboard/invoices",
         permission: PERMISSIONS.menuAbsences,
      },
      {
         icon: (
            <IconTeachers
               fill={
                  pathname.includes("/dashboard/teachers")
                     ? "#9C56E0"
                     : "#555555"
               }
            />
         ),
         title: "Profesores",
         /* link: "/dashboard/teachers", */
         /* permission: PERMISSIONS.menuAbsences, */
         link: PERMISSIONS.newViewTeachers
            ? "/dashboard/teachers/teacher-availability"
            : "/dashboard/teachers",
         permission: PERMISSIONS.menuAbsences,
      },
      {
         icon: (
            <IconCourses
               fill={
                  pathname.includes("/dashboard/courses")
                     ? "#9C56E0"
                     : "#555555"
               }
            />
         ),
         title: "Cursos",
         link: "/dashboard/courses",
         permission: PERMISSIONS.menuAbsences,
      },
      {
         icon: (
            <IconPaths
               fill={
                  pathname.includes("/dashboard/learning-paths")
                     ? "#9C56E0"
                     : "#555555"
               }
            />
         ),
         title: "Rutas",
         link: "/dashboard/learning-paths",
         permission: PERMISSIONS.menuAbsences,
      },
      {
         icon: (
            <IconSliders
               fill={
                  pathname.includes("/dashboard/sliders")
                     ? "#9C56E0"
                     : "#555555"
               }
            />
         ),
         title: "Sliders",
         link: "/dashboard/sliders",
         permission: PERMISSIONS.menuAbsences,
      },
      {
         icon: (
            <IconCoupons
               fill={
                  pathname.includes("/dashboard/program-coupons")
                     ? "#9C56E0"
                     : "#555555"
               }
            />
         ),
         title: "Cupones",
         link: "/dashboard/program-coupons",
         permission: PERMISSIONS.menuAbsences,
      },
      {
         icon: (
            <IconAges
               fill={
                  pathname.includes("/dashboard/age-groups")
                     ? "#9C56E0"
                     : "#555555"
               }
            />
         ),
         title: "Edades",
         link: "/dashboard/age-groups",
         permission: PERMISSIONS.menuAbsences,
      },
      {
         icon: (
            <IconLive
               fill={
                  pathname.includes("/dashboard/live") ? "#9C56E0" : "#555555"
               }
            />
         ),
         title: "Vivo",
         link: "/dashboard/live",
         permission: PERMISSIONS.menuAbsences,
      },
      /* {
         icon: (
            <IconUpload
               fill={
                  pathname.includes("/dashboard/upload") ? "#9C56E0" : "#555555"
               }
            />
         ),
         title: "Carga Masiva",
         link: "/dashboard/upload",
         permission: PERMISSIONS.menuAbsences,
      }, */
   ];

   return (
      <nav className="navigation">
         <section>
            <div
               className={`navigation__container-logo ${
                  isOpen
                     ? "navigation__container-logo--open"
                     : "navigation__container-logo--close"
               }`}
            >
               <IconLogo
                  className={`navigation__logo ${
                     isOpen
                        ? "navigation__logo--open"
                        : "navigation__logo--close"
                  }`}
               />
               <button
                  className={`navigation__button ${
                     isOpen
                        ? "navigation__button--open"
                        : "navigation__button--close"
                  }`}
                  onClick={() => setIsOpen(!isOpen)}
               >
                  <IconArrowSidebar />
               </button>
            </div>
            <ul className="navigation__content">
               {navItems.map(
                  ({ link, icon, title, permission }, index: any) => {
                     if (
                        permission &&
                        user &&
                        user.permission &&
                        user?.permission[permission]
                     ) {
                        return (
                           <div key={index}>
                              {link === "/dashboard" ? (
                                 <li
                                    className={`navigation__item ${
                                       link === pathname
                                          ? "navigation__item--active"
                                          : "navigation__item--inactive"
                                    } ${
                                       isOpen
                                          ? "navigation__item--open"
                                          : "navigation__item--close"
                                    }`}
                                 >
                                    <Link
                                       to={link}
                                       className={`navigation__link ${
                                          link === pathname
                                             ? "navigation__link--active"
                                             : "navigation__link--inactive"
                                       }`}
                                    >
                                       <div>{icon} </div>
                                       <p
                                          className={`navigation__paragraph ${
                                             isOpen
                                                ? "navigation__paragraph--visible"
                                                : "navigation__paragraph--hidden"
                                          }`}
                                       >
                                          {title}
                                       </p>
                                    </Link>
                                 </li>
                              ) : (
                                 <li
                                    className={`navigation__item ${
                                       pathname.includes(link)
                                          ? "navigation__item--active"
                                          : "navigation__item--inactive"
                                    } ${
                                       isOpen
                                          ? "navigation__item--open"
                                          : "navigation__item--close"
                                    }`}
                                    key={index}
                                 >
                                    <Link
                                       to={link}
                                       className={`navigation__link ${
                                          pathname.includes(link)
                                             ? "navigation__link--active"
                                             : "navigation__link--inactive"
                                       }`}
                                    >
                                       <div>{icon} </div>
                                       <p
                                          className={`navigation__paragraph ${
                                             isOpen
                                                ? "navigation__paragraph--visible"
                                                : "navigation__paragraph--hidden"
                                          }`}
                                       >
                                          {title}
                                       </p>
                                    </Link>
                                 </li>
                              )}
                           </div>
                        );
                     } else {
                        return null;
                     }
                  }
               )}
            </ul>
         </section>
         <div>
            <UserDropdown
               isOpen={isOpen}
               img={imgUser}
               user={user}
               handleLogout={handleLogout}
            />
         </div>
      </nav>
   );
};

export default Sidebar;
