import TagStatus from "../../atoms/TagStatus";

import "./tagStatusGroups.scss";

interface Props {
  status?: "in_progress" | "has_not_started" | "has_ended";
  children: any;
}

const TagStatusGroups: React.FC<Props> = ({ children, status }) => {

  return (
    <TagStatus className={`tag-status-groups tag-status-groups--${status}`}>
      {children === "in_progress"
        ? "Iniciado"
        : children === "has_ended"
        ? "Finalizado"
        :  children === "has_not_started" ? "Por iniciar" : children}
    </TagStatus>
  );
};

export default TagStatusGroups;
