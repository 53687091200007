// Router
import { useParams } from "react-router-dom";

// React
import { useEffect, useState } from "react";

// React Query
import { useMutation, useQuery } from "react-query";

// Moment
import moment from "moment";

// Redux
import { useSelector } from "react-redux";
import { AppDispatch, useAppDispatch } from "../../../../../store/store";
import { RootState } from "../../../../../store/store";

// Hot Toast
import { toast } from "react-hot-toast";

// Interfaces
import { IRoomDetail } from "../../../../../interfaces/room.interface";

// API
import { GET } from "../../../../../services/api/api";

// Material UI
import {
   Typography,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Button,
} from "@material-ui/core";
import { TextField } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { DatePicker } from "@mui/lab";

// Components
import { updateAttendanceDate } from "../../../../../services/rooms";
import TableAttendanceStudent from "../../../../../components/organism/TableAttendanceStudent";

interface Props {
   roomInfo: IRoomDetail;
   refetch: () => {};
}

const AttendanceStudent: React.FC<Props> = ({ roomInfo, refetch }) => {
   const { roomId } = useParams();
   const dispatch: AppDispatch = useAppDispatch();
   const room = useSelector((state: RootState) => state.roomDetail.room);
   const [openModal, setOpenModal] = useState(false);
   const [valueDate, setValueDate] = useState("");
   const [valueDateId, setValueDateId] = useState<number | null>(null);

   const { refetch: refetchDetailRoom } = useQuery(
      ["room", roomId],
      () => GET<IRoomDetail>(`/rooms/${roomId}/`),
      {
         retry: false,
         refetchOnWindowFocus: false,
      }
   );

   const handleUpdateAttendanceDate = async (
      sessionId: number,
      startDate: string
   ) => {
      const date = moment(startDate).format("YYYY-MM-DD");
      try {
         const response = await updateAttendanceDate(sessionId, date);
         if (response?.status === 200) {
            toast.success("La fecha se actualizó correctamente!");
            refetchDetailRoom();
         } else {
            toast.error("No se pudo actualizar");
         }
      } catch (error) {
         console.log("somethings went wrong!", error);
         toast.error(
            "Ha ocurrido un error. Inicie la clase con la cuenta correcta"
         );
      }
   };

   const handleClose = () => {
      setOpenModal(false);
   };

   return (
      <>
         <TableAttendanceStudent
            roomInfo={roomInfo}
            refetch={refetch}
            setOpenModal={setOpenModal}
            setValueDateId={setValueDateId}
            setValueDate={setValueDate}
         />
         <Typography
            style={{
               textAlign: "right",
               fontStyle: "italic",
               fontSize: "14px",
               color: "#555555",
               margin: "14px 19px 0 0",
               fontWeight: 500,
            }}
         >
            La asistencia de los estudiantes se guarda de manera automática
         </Typography>
         <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">Editar Fecha</DialogTitle>
            <DialogContent>
               <br />
               <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                     label="Fecha sesión"
                     inputFormat="yyyy-MM-dd"
                     value={moment(valueDate)
                        .add(1, "days")
                        .format("YYYY-MM-DD")}
                     disableMaskedInput
                     onChange={(newValue) => {
                        if (newValue) {
                           setValueDate(newValue);
                        }
                     }}
                     renderInput={(params) => <TextField {...params} />}
                  />
               </LocalizationProvider>
            </DialogContent>
            <DialogActions>
               <Button color="error" variant="contained" onClick={handleClose}>
                  Cancelar
               </Button>
               <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                     if (valueDate && valueDateId) {
                        handleUpdateAttendanceDate(valueDateId, valueDate);
                     }
                     handleClose();
                  }}
               >
                  Confirmar
               </Button>
            </DialogActions>
         </Dialog>
      </>
   );
};

export default AttendanceStudent;
