//@ts-ignore
import ReactCountryFlag from "react-country-flag";

// React
import { memo } from "react";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";

// Material UI
import {
  DataGrid,
  GridColumns,
  GridValueGetterParams,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Typography } from "@material-ui/core";
import { LinearProgress, Stack } from "@mui/material";

// Components
import Paragraph from "../../../../../components/common/Paragraph/Paragraph";
import CustomLoadingOverlay from "../../../../../components/CustomLoadingOverlay/CustomLoadingOverlay";
import ModalEditStudent from "./ModalEditStudent/ModalEditStudent";

interface Props {}

const Credentials: React.FC<Props> = () => {
  const room = useSelector((state: RootState) => state.roomDetail.room);

  if (!room) return <LinearProgress />;

  return (
    <DataGrid
      autoHeight
      style={{ fontSize: 12 }}
      rows={room.students_records}
      columns={generateColumnsByStudent()}
      pageSize={20}
      rowsPerPageOptions={[20]}
      getRowId={(row) => row.student.id}
      components={{
        LoadingOverlay: CustomLoadingOverlay,
      }}
    />
  );

  function generateColumnsByStudent(): GridColDef[] {
    const columns: GridColumns = [
      {
        field: "actions",
        headerName: "Acciones",
        minWidth: 100,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: "center",
        headerAlign: "center",
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              columnGap={1}
              alignItems="center"
              justifyContent="center"
            >
              <ModalEditStudent
                studentId={params.row.student.id}
                student={params.row.student}
              />
            </Stack>
          );
        },
      },
      {
        field: "full_name",
        headerName: "Alumno",
        minWidth: 350,
        flex: 1,
        editable: false,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.student.full_name;
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack direction="row" spacing={2} alignItems="center">
              <ReactCountryFlag
                countryCode={
                  params.row.student.country ? params.row.student.country : "PE"
                }
              />
              <Typography variant="body2">
                {` ${params.row.student.full_name} (${params.row.student.age} años)`}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 300,
        editable: false,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.student.email;
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Paragraph
              text={params.row.student.email}
              mailable={{ email: params.row.student.email }}
              copyable
            />
          );
        },
      },
      {
        field: "password",
        headerName: "Contraseña Moodle",
        minWidth: 250,
        editable: false,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.student.password;
        },
        renderCell: (params: GridRenderCellParams) => {
          return <Paragraph text={params.row.student.password} copyable hideable />;
        },
      },
      {
        field: "password_google",
        headerName: "Contraseña Google",
        minWidth: 250,
        editable: false,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.student.password_google;
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Paragraph text={params.row.student.password_google} copyable hideable />
          );
        },
      },
    ];
    return columns;
  }
};

export default memo(Credentials);
