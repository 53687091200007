// Axios
import axios, { AxiosResponse } from "axios";

// Helpers
import { getAccessCookie } from "../helpers/cookies.helpers";
import { ITeacherRecord } from "../interfaces/room.interface";

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? ATTENDANCE
export const postCreateUniqueAttendance = async (attendance: any) => {
   try {
      const { data } = await axios.post(
         `${instanceBaseURL}/attendances/`,
         attendance,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const patchAttendanceMutation = async (attendance: any) => {
   try {
      const { data } = await axios.patch(
         `${instanceBaseURL}/attendances/${attendance.id}/`,
         { status: attendance.status },
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const postCreateTeacherAttendance = async (teacherRecords: ITeacherRecord, room: any) => {
   try {
      const { data } = await axios.post(
         `${instanceBaseURL}/rooms/${room?.id}/attendances/teachers/`,
         teacherRecords,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};