// React
import { useEffect, useState } from "react";

// Router
import { useSearchParams } from "react-router-dom";

// React Query
import { useQuery } from "react-query";

// API
import { GET } from "../../services/api/api";

// Utils
import { objectsEqual } from "../../utils/utils";

// Interfaces
import { ILearningPath } from "../../interfaces/learningPaths.interface";

// Material UI
import {
   DataGrid,
   GridColDef,
   GridColumns,
   GridValueGetterParams,
   GridRenderCellParams,
   GridSortModel,
} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { Chip, Stack } from "@mui/material";
import BatteryUnknownIcon from "@mui/icons-material/BatteryUnknown";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CodeIcon from "@mui/icons-material/Code";
import BrushIcon from "@mui/icons-material/Brush";
import PublicIcon from "@mui/icons-material/Public";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";

// Components
import ScreenLoader from "../../components/ScreenLoader/ScreenLoader";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay/CustomLoadingOverlay";
import ModalCreateLearningPath from "./ModalCreateLearningPath/ModalCreateLearningPath";
import ModalEditLearningPath from "./ModalEditLearningPath/ModalEditLearningPath";

interface Props {}

const LearningPaths: React.FC<Props> = () => {
   // Auto Open Modal
   const [params] = useSearchParams();
   const [learningPathId] = useState(
      params.get("id") && parseInt(params.get("id")!)
   );

   const [rows, setRows] = useState<any[]>([]);
   const [sortModel, setSortModel] = useState<GridSortModel>([
      {
         field: "id",
         sort: "desc",
      },
   ]);

   const {
      data: learningPaths,
      error,
      isError,
      isLoading,
      isFetching,
      refetch,
   } = useQuery(
      ["learning-paths-backoffice"],
      () => GET<ILearningPath[]>(`/learning_paths_backoffice/`),
      {
         retry: false,
         refetchOnWindowFocus: false,
      }
   );

   useEffect(() => {
      learningPaths && setRows(learningPaths);
   }, [learningPaths]);

   if (isLoading || !learningPaths) return <ScreenLoader />;
   if (isError) return <div>Error! {JSON.stringify(error)}</div>;

   const currentlySelected = (model: GridSortModel) => {
      !objectsEqual(model, sortModel) && setSortModel(model);
   };

   return (
      <Grid
         container
         rowGap={2}
         style={{ overflowY: "scroll", height: "100%" }}
      >
         {/* Auto Open Modal */}
         {learningPathId && (
            <ModalEditLearningPath
               learningPathId={learningPathId}
               autoOpen={true}
               refetch={refetch}
            />
         )}

         {/* Create New Learning Path */}
         <Grid item xs={12}>
            <ModalCreateLearningPath refetch={refetch} />
         </Grid>

         <DataGrid
            autoHeight
            style={{ fontSize: 12 }}
            loading={isFetching}
            sortModel={sortModel}
            onSortModelChange={(model) => currentlySelected(model)}
            rows={rows}
            columns={generateColumnsByLearningPaths()}
            pageSize={20}
            rowsPerPageOptions={[20]}
            components={{
               LoadingOverlay: CustomLoadingOverlay,
            }}
         />
      </Grid>
   );

   function generateColumnsByLearningPaths(): GridColDef[] {
      const columns: GridColumns = [
         {
            field: "actions",
            headerName: "Acciones",
            width: 85,
            minWidth: 85,
            editable: false,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            renderCell: (params: GridRenderCellParams) => {
               return (
                  <Stack
                     direction="row"
                     justifyContent="center"
                     alignItems="center"
                     spacing={1}
                  >
                     <ModalEditLearningPath
                        learningPathId={params.row.id}
                        refetch={refetch}
                     />
                  </Stack>
               );
            },
         },
         {
            field: "id",
            headerName: "ID",
            width: 100,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.id;
            },
         },
         {
            field: "name",
            headerName: "Nombre",
            minWidth: 150,
            editable: false,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.name;
            },
         },
         {
            field: "is_published",
            headerName: "Estatus",
            minWidth: 130,
            editable: false,
            align: "center",
            headerAlign: "center",
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.is_published;
            },
            renderCell: (params: GridRenderCellParams) => {
               switch (params.row.is_published) {
                  case false:
                     return (
                        <Chip
                           icon={<VpnLockIcon />}
                           label="Privado"
                           variant="outlined"
                           color="warning"
                           sx={{ paddingLeft: "5px" }}
                        />
                     );
                  case true:
                     return (
                        <Chip
                           icon={<PublicIcon />}
                           label="Publico"
                           variant="outlined"
                           color="success"
                           sx={{ paddingLeft: "5px" }}
                        />
                     );
               }
            },
         },
         {
            field: "available_to_purchase",
            headerName: "Accesibilidad",
            minWidth: 135,
            editable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.available_to_purchase;
            },
            renderCell: (params: GridRenderCellParams) => {
               switch (params.row.available_to_purchase) {
                  case false:
                     return (
                        <Chip
                           icon={<CreditCardOffIcon />}
                           label="Privado"
                           variant="outlined"
                           color="warning"
                           sx={{ paddingLeft: "5px" }}
                        />
                     );
                  case true:
                     return (
                        <Chip
                           icon={<CreditCardIcon />}
                           label="Publico"
                           variant="outlined"
                           color="success"
                           sx={{ paddingLeft: "5px" }}
                        />
                     );
               }
            },
         },
         {
            field: "category",
            headerName: "Categoria",
            minWidth: 170,
            editable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.category;
            },
            renderCell: (params: GridRenderCellParams) => {
               switch (params.row.category) {
                  case "design_and_creativity":
                     return (
                        <Chip
                           icon={<BrushIcon />}
                           label="Design & creativity"
                           variant="outlined"
                           color="secondary"
                           size="small"
                           sx={{ paddingLeft: "5px" }}
                        />
                     );
                  case "development":
                     return (
                        <Chip
                           icon={<CodeIcon />}
                           label="Development"
                           variant="outlined"
                           color="success"
                           size="small"
                           sx={{ paddingLeft: "5px" }}
                        />
                     );
                  default:
                     return (
                        <Chip
                           icon={<BatteryUnknownIcon />}
                           label="NONE"
                           variant="filled"
                           color="error"
                        />
                     );
               }
            },
         },
         {
            field: "has_discount",
            headerName: "Descuento",
            minWidth: 150,
            editable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.has_discount;
            },
            renderCell: (params: GridRenderCellParams) => {
               switch (params.row.has_discount) {
                  case false:
                     return (
                        <Chip
                           icon={<NotInterestedIcon />}
                           label="NOT DISCOUNT"
                           variant="outlined"
                           color="error"
                           size="small"
                           sx={{ paddingLeft: "5px" }}
                        />
                     );
                  case true:
                     return (
                        <Chip
                           icon={<LocalOfferIcon />}
                           label="HAS DISCOUNT"
                           variant="outlined"
                           color="success"
                           size="small"
                           sx={{ paddingLeft: "5px" }}
                        />
                     );
               }
            },
         },
      ];

      return columns;
   }
};

export default LearningPaths;
