// React
import { SyntheticEvent, useState } from "react";

// Material UI
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// Components
import AttendanceStudent from "./AttendanceStudent/AttendanceStudent";
import Comments from "./Comments/Comments";
import Credentials from "./Credentials/Credentials";
import { IRoomDetail } from "../../../../interfaces/room.interface";

interface Props {
  refetch: () => {};
  room: IRoomDetail;
}

const Students: React.FC<Props> = ({ refetch, room }) => {
  const [currentPosition, setCurrentPosition] = useState<string>("0");

  const handleChangePosition = (_event: SyntheticEvent, newValue: string) =>
    setCurrentPosition(newValue);

  return (
    <Box sx={{ width: "100%" }}>
      <Tab label="Alumnos" disabled />
      <TabContext value={currentPosition}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangePosition} aria-label="room actions">
            <Tab label="Asistencias" value="0" />
            <Tab label="Comentarios" value="1" />
            <Tab label="Credenciales" value="2" />
          </TabList>
        </Box>

        <TabPanel value="0">
          <AttendanceStudent roomInfo={room} refetch={refetch} />
        </TabPanel>
        <TabPanel value="1">
          <Comments refetch={refetch} />
        </TabPanel>
        <TabPanel value="2">
          <Credentials />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Students;
