import { useState } from "react";
// React Query
import { useMutation, useQuery, useQueryClient, QueryObserver } from "react-query";

// Yup
import * as Yup from "yup";

// Formik
import { Form, Formik } from "formik";

// API
import { GET, PATCH } from "../../services/api/api";

// Hot Toast
import { toast } from "react-hot-toast";

import {
    Alert,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
    Chip
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import UpdateIcon from "@mui/icons-material/Update";


interface Props {
    model_id: number;
    model_type: string;
    name: string;
    min_age: number;
    max_age: number;
    refetch: any;
}


const RangeAge: React.FC<Props> = ({ model_id, model_type, name, min_age, max_age, refetch }) => {

    const updateModelAgeGroup = useMutation((data: any) => PATCH(`/base/group-age/${data.model_type}/${data.model_id}/update/`, data), {
        retry: false,
    });

    const handleUpdateModelAgeGroup = async (values: any) => {
        const data = {
            model_id: model_id,
            model_type: model_type,
            min_age: Number(values.min_age),
            max_age: Number(values.max_age),
        }
        await toast.promise(
            updateModelAgeGroup.mutateAsync(data),
            {
                loading: "Cargando...",
                success: "Rango de edad actualizado",
                error: "Error",
            },
            {
                success: {
                    icon: <UpdateIcon color="primary" />,
                },
            }
        );
        refetch();
    }

    const AgeGroupSchema = Yup.object().shape({
        min_age: Yup.number().required("Edad mínima es requerido"),
        max_age: Yup.number().required("Edad máxima es requerido")
    })

    const initialValues = {
        id: model_id,
        min_age: min_age,
        max_age: max_age
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={AgeGroupSchema}
            onSubmit={async (values) => {
                console.log(values);
            }}
        >
            {({ errors, touched, values, handleChange }) => (
                <Form>
                    <Grid container rowSpacing={2} alignItems="flex-start">
                        <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
                            <Grid item xs={4}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    id="min_age"
                                    label="Min Age"
                                    placeholder="Min Age"
                                    variant="outlined"
                                    type="number"
                                    value={values.min_age}
                                    onChange={handleChange}
                                />
                                {errors.min_age && touched.min_age && (
                                    <Alert severity="error">{errors.min_age}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    id="max_age"
                                    label="Max Age"
                                    placeholder="Max Age"
                                    variant="outlined"
                                    type="number"
                                    value={values.max_age}
                                    onChange={handleChange}
                                />
                                {errors.max_age && touched.max_age && (
                                    <Alert severity="error">{errors.max_age}</Alert>
                                )}
                            </Grid>

                            <Grid item xs={4}>
                                <LoadingButton
                                    fullWidth
                                    loading={updateModelAgeGroup.isLoading}
                                    variant="contained"
                                    onClick={() => handleUpdateModelAgeGroup(values)}
                                    type="button"
                                    startIcon={<UpdateIcon />}
                                >
                                    Save
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default RangeAge;