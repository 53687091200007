import "./defaultLayout.scss";

interface Props {
   children: any;
}

const DefaultLayout: React.FC<Props> = ({ children }) => {
   return (
      <main className="default-layout">
         <div className="default-layout__content">{children}</div>
      </main>
   );
};

export default DefaultLayout;
