// React Query
import { QueryObserver, useQuery, useQueryClient, useMutation } from "react-query";

// API
import { DELETE_MICRO } from "../../services/api/api";

// Utils
import { sortAlphabetically } from "../../utils/utils";

// Interfaces
import { IRule } from "../../interfaces/coupon.interface";
import { ICourse } from "../../interfaces/course.interface";
import { ILearningPath } from "../../interfaces/learningPaths.interface";

// Material UI
import Grid from "@mui/material/Grid";
import { IconButton, ListItem, ListItemText } from "@mui/material";
import { Alert, Autocomplete, Box, LinearProgress, TextField, List } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";


interface Props {
    rules?: IRule[];
    srcData?: any[];
    viewType: "course" | "learning_path";
    refetch: () => {};
}

const ListRule: React.FC<Props> = ({ rules, srcData, viewType, refetch }) => {

    const queryClient = useQueryClient();
    const { refetch: refetchRule } = new QueryObserver(queryClient, {
        queryKey: "coupon-program",
    });


    const deleteRule = useMutation(
        (id: number) => DELETE_MICRO(`/rule/delete/${id}`),
        {
            retry: false,
        }
    );

    const handleDeleteRule = async (id: number) => {
        await deleteRule.mutateAsync(id);

        refetch();
        refetchRule();
    };

    const searcher = (
        ruleId: number,
        modelId: number
    ) => {
        var found_data = srcData?.find((data) => data.id === modelId) || null;
        if (found_data) {
            return (
                <ListItem
                    secondaryAction={
                        <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={() => handleDeleteRule(ruleId)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    }
                    dense
                >
                    <ListItemText id={ruleId.toString()} primary={`[${found_data.id}] ${found_data.name}`} />
                </ListItem>

            );
        }
    }

    return (
        <Grid item container columnSpacing={1} xs={12}>
            <Grid item xs={12}>
                <List sx={{
                    width: "100%",
                    padding: 0,
                    maxHeight: "150px",
                    overflowY: "scroll",
                }}>
                    {rules?.filter((rule) => rule.model_type == viewType).map(({ id, model_id }) => (
                        searcher(id, model_id)
                    ))}
                </List>
            </Grid>
        </Grid>
    );
};

export default ListRule;