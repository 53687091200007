// React
import { useState } from "react";

// Interfaces
import { ITeacher } from "../../../../../interfaces/group.interface";

// Components
import EditRoom from "./EditRoom/EditRoom";
import OpenModal from "../../../../../components/Modal/OpenModal/OpenModal";
import Modal from "../../../../../components/Modal/Modal";

interface Props {
   groupId: number;
   roomId: number;
   roomName: string;
   roomTopic: string;
   roomTeacher: number;
   teachers: ITeacher[];
   refetch?: () => {};
}

const ModalEditRoom: React.FC<Props> = ({
   groupId,
   roomId,
   roomName,
   roomTopic,
   roomTeacher,
   teachers,
   refetch,
}) => {
   const [openModal, setOpenModal] = useState(false);

   const handleOpenModal = () => setOpenModal(true);
   const handleCloseModal = () => setOpenModal(false);

   return (
      <>
         {/* Open Modal */}
         <OpenModal
            option="edit"
            placement="left"
            handleOpenModal={handleOpenModal}
         />

         {/* Modal Edit Room */}
         <Modal
            id={roomId}
            title="Editar Salón"
            fullWidth
            maxWidth="sm"
            openModal={openModal}
            handleCloseModal={handleCloseModal}
         >
            <EditRoom
               groupId={groupId}
               roomId={roomId}
               roomName={roomName}
               roomTopic={roomTopic}
               roomTeacher={roomTeacher}
               teachers={teachers}
               /* refetch={refetch} */
               onClose={handleCloseModal}
            />
         </Modal>
      </>
   );
};

export default ModalEditRoom;
