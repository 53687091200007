//@ts-ignore
import ReactCountryFlag from "react-country-flag";

// Material UI
import { Divider, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';

interface IOnCloseFunction {
  (): void;
}

export interface Props {
  onClick: IOnCloseFunction;
  selected?: boolean;
  studentCountry: string;
  studentFullName: string;
  studentAge: number;
  hasComments: boolean;
}

const StudentListItem: React.FC<Props> = ({
  selected = false,
  studentAge,
  studentCountry,
  studentFullName,
  hasComments = false,
  onClick,
}) => {
  const studentText = ` ${studentFullName} (${studentAge})`;

  return (
    <>
      <ListItem
        button
        selected={selected}
        onClick={onClick}
        sx={{ minHeight: "65px" }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <CircleIcon sx={{ color: hasComments ? "red" : "transparent", fontSize: "0.9rem" }} />
          <ReactCountryFlag countryCode={studentCountry} />
          <ListItemText
            primary={<Typography variant="body2">{studentText}</Typography>}
          />
        </Stack>
      </ListItem>

      <Divider component="li" />
    </>
  );
};

export default StudentListItem;
