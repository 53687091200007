import React from "react";

// Components
import Modal from "../../atoms/Modal";
import IconAlert from "../../atoms/icons/IconAlert";
import Typography from "../../atoms/Typography";

import "./modalMessageAlert.scss";

interface Props {
   title: any;
   isOpenModal: boolean;
   openModal: () => void;
   closeModal: () => void;
   button1?: any;
   button2?: any;
   width?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
   message?: string;
   iconAlert?: any;
}

const ModalMessageAlert: React.FC<Props> = ({
   title,
   isOpenModal,
   openModal,
   closeModal,
   width,
   message,
   button1,
   button2,
   iconAlert,
}) => {
   return (
      <div className="modal-message-alert">
         <Modal
            width={width}
            isOpen={isOpenModal}
            closeModal={closeModal}
            icon={iconAlert ? iconAlert : <IconAlert />}
            title={title}
            body={
               <div className="modal-message-alert__content-body">
                  <Typography type="paragraph2" variation="mobile">
                     {message}
                  </Typography>
               </div>
            }
            buttons={
               <section
                  className={`modal-message-alert__container-buttons modal-message-alert__container-buttons--${
                     button2 ? "justify" : "center"
                  }`}
               >
                  {button1} {button2}
               </section>
            }
         />
      </div>
   );
};

export default ModalMessageAlert;
