import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { IStudent } from "../../interfaces/students.interface";
import { getStudents} from "../../services/students";


export interface IAuthError {
   msg: string;
}

export interface IStudentState {
   students: IStudent[] | null | undefined;
   isLoading: boolean;
   completed: boolean;
   error: IAuthError | null;
   rejected: boolean;
}

const initialState: IStudentState = {
   students: [],
   isLoading: false,
   completed: false,
   error: null,
   rejected: false,
};

export const getAllStudents = createAsyncThunk(
   "students/getAllStudents",
   async (_, { rejectWithValue }: any) => {
      try {
         const data = await getStudents();
         return data;
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const studentsSlice = createSlice({
   name: "students",
   initialState,
   reducers: {
      /* addRoom: (state, action) => {
         console.log("state", state);
         console.log("action", action);
      }, */
   },
   extraReducers: (builder) => {
      /** getAllStudents */
      builder.addCase(getAllStudents.pending, (state, _) => {
         state.students = initialState.students;
         state.isLoading = true;
      });
      builder.addCase(getAllStudents.fulfilled, (state, action) => {
         state.students = action.payload.sort((a: any, b: any) => {
            if (a.id < b.id) return 1;
            if (a.id > b.id) return -1;
            return 0;
         });
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(getAllStudents.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
      /** addStudentToGroup */
      /* builder.addCase(addStudentToGroup.pending, (state, _) => {
         state.isLoading = true;
      });
      builder.addCase(addStudentToGroup.fulfilled, (state, action) => {
         state?.students?.push(action.payload);
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(addStudentToGroup.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      }); */
   },
});

export const selectStateStudents = (state: any) => state.students;
