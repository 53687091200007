import React from 'react'

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#9C56E0",
      contrastText: '#ffffff',
    },
  },
});

const ThemeStyle = ({ children }: any) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default ThemeStyle;