// React Query
import { useMutation } from "react-query";

// API
import { DELETE } from "../../../services/api/api";

// Hot Toast
import { toast } from "react-hot-toast";

// Material UI
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";

interface Props {
  sliderId: number;
  refetch: () => {};
}

const DeleteSlider: React.FC<Props> = ({ sliderId, refetch }) => {
  const deleteSlider = useMutation(() => DELETE(`/sliders/${sliderId}/`), {
    retry: false,
  });

  const handleDeleteGroupStudent = async () => {
    if (!deleteSlider.isSuccess) {
      await toast.promise(
        deleteSlider.mutateAsync(),
        {
          loading: "Cargando...",
          success: "Slider eliminado",
          error: "Error",
        },
        {
          success: {
            icon: <DeleteIcon color="error" />,
          },
        }
      );

      setTimeout(() => refetch(), 1200);
    }
  };

  return (
    <Tooltip title="Eliminar" arrow placement="right">
      <IconButton
        aria-label="delete"
        color={deleteSlider.isSuccess ? "success" : "error"}
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteGroupStudent();
        }}
      >
        {deleteSlider.isSuccess ? <CheckIcon /> : <DeleteIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default DeleteSlider;
