// React
import { useEffect, useMemo, useState } from "react";

//Redux
import { useSelector } from "react-redux";
import { selectStateStudents } from "../../../slices/studentSlice/studentSlice";

// Router
import { Link as LinkRouter } from "react-router-dom";

// Interfaces
import { IStudent } from "../../../interfaces/students.interface";

//Services
import colors from "../../atoms/Colors";

// Components
import Table from "../../molecules/Table";
import Link from "../../molecules/Link";
import ScreenLoader from "../../ScreenLoader/ScreenLoader";
import { selectStateStudentForId } from "../../../slices/studentSlice/studentByIdSlice";
import IconArrow from "../../atoms/icons/IconArrow";

import "./tableStudentRooms.scss";
import Typography from "../../atoms/Typography";


const TableStudentRooms = () => {
   const { students, isLoading } = useSelector(selectStateStudents);
   const { student, isLoadingEditStudent } = useSelector(selectStateStudentForId);
   const [ isNotEmpty, setIsNotEmpty ] = useState(false);

   const columns = useMemo(
      () => [
         {
            Header: "ID",
            accessor: "id",
            disableSortBy: true,
            disableFilters: true,
            widthColumn: 20,
         },
         {
            Header: "Nombre de salón",
            widthColumn: 201,
            accessor: "name",
            disableSortBy: true,
            disableFilters: true,
            hasTooltip: true,
            Cell: ({ cell }: any) => <span>{cell.value}</span>,
         },
         {
            Header: "Acción",
            accessor: "action",
            disableSortBy: true,
            disableFilters: true,
            hasTooltip: true,
            tooltipValue: "inscribir",
            Cell: (props: any) => {
               return (
                  <>
                     <div
                        className="table-student-rooms__button table-student-rooms__button--hover-lineal"
                     >
                        <Link
                           className="table-student-rooms__link-room"
                           to={`/dashboard/rooms/${props.row.values.id}`}
                           iconRight={<IconArrow fill="#000000" />}
                        >
                           Ver salón
                        </Link>
                     </div>
                  </>
               );
            },
         },
      ],
      []
   );

   if (isLoading || !students) return <ScreenLoader />;

   return (
      <>
         {student.rooms_by_student.length > 0 ? 
            <Table data={student.rooms_by_student} columns={columns} /> : 
            <Typography 
               className="table-student-rooms__message"
               type="paragraph" 
               variation="paragraph2"
            >
               El alumno no cuenta con salones disponibles
            </Typography>
         }
         
      </>
   );
};

export default TableStudentRooms;
