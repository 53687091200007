// React Route
import { Link } from "react-router-dom";

// Interfaces
import { IRoom, ITeacher } from "../../../../interfaces/group.interface";

// Material UI
import { Grid, Stack } from "@mui/material";
import {
   DataGrid,
   GridColumns,
   GridValueGetterParams,
   GridColDef,
   GridRenderCellParams,
} from "@mui/x-data-grid";
import { IconButton } from "@material-ui/core";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

// Hooks
import { useModal } from "../../../../hooks/useModal";

// Components
import Paragraph from "../../../../components/common/Paragraph/Paragraph";
import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay/CustomLoadingOverlay";
import ModalEditRoom from "./ModalEditRoom/ModalEditRoom";
import DeleteRoom from "./DeleteRoom/DeleteRoom";
import OpenModal from "../../../../components/Modal/OpenModal/OpenModal";
import ModalCreateRoom from "../../../../pages/Groups/ModalCreateRoom/ModalCreateRoom";

interface Props {
   rooms: IRoom[];
   teachers: ITeacher[];
   groupId: number;
   refetch?: () => {};
   isFetching: boolean;
}

const Rooms: React.FC<Props> = ({
   rooms,
   teachers,
   groupId,
   refetch,
   isFetching,
}) => {
   const {
      openModal: openModalCreateRoom,
      closeModal: closeModalCreateRoom,
      isOpenModal: isOpenModalCreateRoom,
   } = useModal();

   return (
      <Grid container rowGap={2}>
         <Grid item xs={12}>
            {/* Button create New Room */}
            <OpenModal
               option="create"
               title="Crear nuevo salón"
               handleOpenModal={openModalCreateRoom}
            />
            <ModalCreateRoom
               teachers={teachers}
               groupId={groupId}
               isOpenModal={isOpenModalCreateRoom}
               openModal={closeModalCreateRoom}
               closeModal={closeModalCreateRoom}
            />
         </Grid>

         {/* Rooms */}
         <Grid item xs={12}>
            <DataGrid
               autoHeight
               style={{ fontSize: 12 }}
               loading={isFetching}
               rows={rooms}
               columns={generateColumnsByRoom()}
               pageSize={20}
               rowsPerPageOptions={[20]}
               checkboxSelection={false}
               disableSelectionOnClick
               components={{
                  LoadingOverlay: CustomLoadingOverlay,
               }}
            />
         </Grid>
      </Grid>
   );

   function generateColumnsByRoom(): GridColDef[] {
      const columns: GridColumns = [
         {
            field: "actions",
            headerName: "Acciones",
            minWidth: 100,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            editable: false,
            renderCell: (params: GridRenderCellParams) => {
               return (
                  <Stack
                     direction="row"
                     columnGap={1}
                     alignItems="center"
                     justifyContent="center"
                  >
                     <ModalEditRoom
                        groupId={groupId}
                        roomId={params.row.id}
                        roomName={params.row.name}
                        roomTopic={params.row.topic}
                        roomTeacher={params.row.teacher.id}
                        teachers={teachers}
                        /* refetch={refetch} */
                     />
                     <DeleteRoom
                        roomId={params.row.id}
                        groupId={groupId}
                        /* refetch={refetch} */
                     />
                  </Stack>
               );
            },
         },
         {
            field: "id",
            headerName: "ID",
            width: 100,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.id;
            },
         },
         {
            field: "name",
            headerName: "Nombre del salón",
            minWidth: 600,
            flex: 1,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.name;
            },
            renderCell: (params: GridRenderCellParams) => {
               return (
                  <Stack
                     sx={{ width: "100%" }}
                     direction="row"
                     columnGap={2}
                     alignItems="center"
                     justifyContent="space-between"
                  >
                     <span>{params.row.name}</span>
                     <Link
                        to={`/dashboard/rooms/${params.row.id}`}
                        style={{ textDecoration: "none" }}
                     >
                        <IconButton color="primary">
                           <RemoveRedEyeIcon />
                        </IconButton>
                     </Link>
                  </Stack>
               );
            },
         },
         {
            field: "topic",
            headerName: "Asunto",
            minWidth: 200,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.topic;
            },
         },
         {
            field: "teacher",
            headerName: "Profesor",
            minWidth: 200,
            editable: false,
            headerAlign: "center",
            disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.teacher.full_name;
            },
         },
         {
            field: "zoom_teacher_url",
            headerName: "Enlace zoom del profesor",
            minWidth: 200,
            sortable: false,
            editable: false,
            renderCell: (params: GridValueGetterParams) => {
               return (
                  <Stack direction="row" spacing={1}>
                     <Paragraph
                        text={params.row.zoom_teacher_url}
                        copyable
                        hideText
                     />
                     <a
                        href={params.row.zoom_teacher_url}
                        target="_blank"
                        rel="noreferrer"
                     >
                        {params.row.zoom_teacher_url}
                     </a>
                  </Stack>
               );
            },
         },
         {
            field: "zoom_url",
            headerName: "Enlace zoom del estudiante",
            minWidth: 210,
            sortable: false,
            editable: false,
            renderCell: (params: GridValueGetterParams) => {
               return (
                  <Stack direction="row" spacing={1}>
                     <Paragraph text={params.row.zoom_url} copyable hideText />
                     <a
                        href={params.row.zoom_url}
                        target="_blank"
                        rel="noreferrer"
                     >
                        {params.row.zoom_url}
                     </a>
                  </Stack>
               );
            },
         },
         {
            field: "licensed_user_email",
            headerName: "Licencia",
            minWidth: 250,
            editable: false,
            disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.licensed_user_email;
            },
         },
         {
            field: "licensed_user_password",
            headerName: "Contraseña",
            minWidth: 150,
            editable: false,
            renderCell: (params: GridValueGetterParams) => {
               return (
                  <Paragraph
                     text={params.row.licensed_user_password}
                     copyable
                     hideable
                  />
               );
            },
         },
         {
            field: "zoom_id",
            headerName: "ID meet",
            minWidth: 200,
            editable: false,
            renderCell: (params: GridValueGetterParams) => {
               return <Paragraph text={params.row.zoom_id} copyable />;
            },
         },
         {
            field: "password",
            headerName: "Contraseña meet",
            minWidth: 180,
            editable: false,
            renderCell: (params: GridValueGetterParams) => {
               return (
                  <Paragraph text={params.row.password} copyable hideable />
               );
            },
         },
      ];

      return columns;
   }
};

export default Rooms;
