// Etc
import { format } from "date-fns";

// Material UI
import {
  Box,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";

// Components
import LetterAvatar from "../../../../../../components/common/LetterAvatar/LetterAvatar";
import Chip from "../../../../../../components/atoms/Chip";

interface Props {
  commentFromFullName: string;
  commentBody: string;
  commentCreatedAt: string;
  commentSubject: string;
  isReaded: boolean;
}

const CommentListItem: React.FC<Props> = ({
  commentBody,
  commentCreatedAt,
  commentFromFullName,
  commentSubject,
  isReaded,
}) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <LetterAvatar name={commentFromFullName} />
      </ListItemAvatar>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom component="div">
            <Stack direction="row" spacing={2} alignContent="center">
              <Box>{commentFromFullName}</Box>
              <Chip
                label={`${
                  commentSubject === "behavior"
                    ? "Comportamiento"
                    : commentSubject === "credentials"
                    ? "Credenciales"
                    : commentSubject === "general"
                    ? "General"
                    : commentSubject === "sac"
                    ? "Comentarios SAC"
                    : ""
                }`}
                color={`${
                  commentSubject === "behavior"
                    ? "success"
                    : commentSubject === "credentials"
                    ? "warning"
                    : commentSubject === "general"
                    ? "info"
                    : "primary"
                }`}
                size="small"
                variant="filled"
              />
            </Stack>
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            component="div"
            style={{ whiteSpace: "pre-line" }}
          >
            {commentBody}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ListItemText
            secondary={
              <Typography variant="caption" color="darkgray">
                {format(new Date(commentCreatedAt), "PP - p")}
              </Typography>
            }
          ></ListItemText>
          {/* <ListItemText
            secondary={
              <Typography variant="caption" color="darkgray">
                {isReaded ? (
                  <>
                    Leido por {commentFromFullName} <DoneAllIcon sx={{ color: "green" }} />
                  </>
                ) : (
                  <>
                    Leido por {commentFromFullName} <DoneAllIcon sx={{ color: "red" }} />
                  </>
                )}
              </Typography>
            }
          ></ListItemText> */}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default CommentListItem;
