// React
import { useEffect, useState } from "react";

// React Query
import { useQuery } from "react-query";

// API
import { GET } from "../../services/api/api";

// Utils
import { objectsEqual, searchClearner } from "../../utils/utils";

// Interfaces
import { ITeacher } from "../../interfaces/teacher.interface";

// Material UI
import {
   DataGrid,
   GridColDef,
   GridColumns,
   GridValueGetterParams,
   GridRenderCellParams,
   GridSortModel,
} from "@mui/x-data-grid";
import { Stack } from "@material-ui/core";
import { Grid } from "@mui/material";

// Components
import ScreenLoader from "../../components/ScreenLoader/ScreenLoader";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay/CustomLoadingOverlay";
import CustomQuickSearchToolbar from "../../components/CustomQuickSearchToolbar/CustomQuickSearchToolbar";
import ModalEditTeacher from "./ModalEditTeacher/ModalEditTeacher";
import ModalCreateTeacher from "./ModalCreateTeacher/ModalCreateTeacher";

interface Props {}

const Teachers: React.FC<Props> = () => {
   const [searchText, setSearchText] = useState<string>("");
   const [rows, setRows] = useState<ITeacher[]>([]);
   const [sortModel, setSortModel] = useState<GridSortModel>([
      {
         field: "id",
         sort: "desc",
      },
   ]);

   const {
      data: teachers,
      error,
      isError,
      isLoading,
      isFetching,
      refetch,
   } = useQuery(
      ["teachers"],
      () => GET<ITeacher[]>(`/teachers-backoffice/?no-page`),
      {
         retry: false,
         refetchOnWindowFocus: false,
      }
   );

   useEffect(() => {
      teachers && setRows(teachers);
   }, [teachers]);

   if (isLoading || !teachers) return <ScreenLoader />;
   if (isError) return <div>Error! {JSON.stringify(error)}</div>;

   const currentlySelected = (model: GridSortModel) => {
      !objectsEqual(model, sortModel) && setSortModel(model);
   };

   const requestSearch = (searchValue: string) => {
      setSearchText(searchValue);

      const filteredRows = teachers.filter((teacher: ITeacher) =>
         searchClearner(`${teacher.first_name} ${teacher.last_name}`).includes(
            searchClearner(searchValue)
         )
      );

      setRows(filteredRows);
   };

   return (
      <Grid
         container
         rowGap={2}
         style={{ overflowY: "scroll", height: "100%" }}
      >
         {/* Create Teacher */}
         <Grid item xs={12}>
            <ModalCreateTeacher refetch={refetch} />
         </Grid>

         {/* Teachers */}
         <Grid item xs={12}>
            <DataGrid
               autoHeight
               style={{ fontSize: 12 }}
               loading={isFetching}
               sortModel={sortModel}
               onSortModelChange={(model) => currentlySelected(model)}
               rows={rows}
               columns={generateColumnsByStudents()}
               pageSize={20}
               rowsPerPageOptions={[20]}
               components={{
                  Toolbar: CustomQuickSearchToolbar,
                  LoadingOverlay: CustomLoadingOverlay,
               }}
               componentsProps={{
                  toolbar: {
                     value: searchText,
                     onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                        requestSearch(event.target.value),
                     clearSearch: () => requestSearch(""),
                  },
               }}
            />
         </Grid>
      </Grid>
   );

   function generateColumnsByStudents(): GridColDef[] {
      const columns: GridColumns = [
         {
            field: "actions",
            headerName: "Acciones",
            minWidth: 100,
            editable: false,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            renderCell: (params: GridRenderCellParams) => {
               return (
                  <Stack
                     direction="row"
                     justifyContent="center"
                     alignItems="center"
                     spacing={1}
                  >
                     <ModalEditTeacher
                        teacherId={params.row.id}
                        refetch={refetch}
                     />
                  </Stack>
               );
            },
         },
         {
            field: "id",
            headerName: "ID",
            width: 100,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.id;
            },
         },
         {
            field: "username",
            headerName: "Usuario",
            minWidth: 250,
            flex: 1,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.username;
            },
         },
         {
            field: "first_name",
            headerName: "Nombre",
            minWidth: 130,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.first_name;
            },
         },
         {
            field: "last_name",
            headerName: "Apellido",
            minWidth: 140,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.last_name;
            },
         },
         {
            field: "email",
            headerName: "Correo",
            minWidth: 300,
            flex: 1,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.email;
            },
         },
      ];

      return columns;
   }
};

export default Teachers;
