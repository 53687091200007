// React
import { memo, useState } from "react";

// Moment
import moment from "moment";
import "moment/locale/es";

// Utils
import { objectsEqual } from "../../../../../utils/utils";

// Interfaces
import { Invoice } from "../../../../../interfaces/guardian.interface";

// Material UI
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridValueGetterParams,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { Chip, Stack } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import VerifiedIcon from "@mui/icons-material/Verified";

// Components
import CustomLoadingOverlay from "../../../../../components/CustomLoadingOverlay/CustomLoadingOverlay";
import ModalViewEnrolments from "./ModalViewEnrolments/ModalViewEnrolments";
import ModalEditInvoice from "./ModalEditInvoice/ModalEditInvoice";

interface Props {
  invoices: Invoice[];
  guardianId: number;
  isFetching: boolean;
  //refetch: () => {};
}

const DataGridInvoices: React.FC<Props> = ({ invoices, guardianId, isFetching }) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "id",
      sort: "desc",
    },
  ]);

  function currentlySelected(model: GridSortModel) {
    if (!objectsEqual(model, sortModel)) setSortModel(model);
  }

  return (
    <DataGrid
      autoHeight
      style={{ fontSize: 12 }}
      loading={isFetching}
      sortModel={sortModel}
      onSortModelChange={(model) => currentlySelected(model)}
      rows={invoices}
      columns={generateColumnsByInvoices()}
      pageSize={20}
      rowsPerPageOptions={[20]}
      components={{
        LoadingOverlay: CustomLoadingOverlay,
      }}
    />
  );

  function generateColumnsByInvoices(): GridColDef[] {
    const columns: GridColumns = [
      {
        field: "actions",
        headerName: "Acciones",
        minWidth: 100,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: "center",
        headerAlign: "center",
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              columnGap={1}
              alignItems="center"
              justifyContent="center"
            >
              <ModalViewEnrolments
                enrolments={params.row.enrolments}
                guardianId = {guardianId}
                //refetch={refetch}
              />
              <ModalEditInvoice
                guardianId = {guardianId}
                invoiceId={params.row.id}
                invoice={params.row}
                //refetch={refetch}
              />
            </Stack>
          );
        },
      },
      {
        field: "id",
        headerName: "ID",
        width: 100,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.id;
        },
      },
      {
        field: "learning_path_name",
        headerName: "Nombre de ruta",
        minWidth: 200,
        flex: 1,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.learning_path && params.row.learning_path.name;
        },
      },
      {
        field: "payment_gateway",
        headerName: "Metodo de pago",
        minWidth: 150,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.payment_gateway;
        },
      },
      {
        field: "amount",
        headerName: "Monto",
        minWidth: 100,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.amount;
        },
      },
      {
        field: "currency",
        headerName: "Divisa",
        minWidth: 100,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.currency;
        },
      },
      {
        field: "is_verified",
        headerName: "Pago verificado",
        minWidth: 150,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.is_verified;
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <>
              {params.row.is_verified ? (
                <Chip
                  icon={<VerifiedIcon />}
                  label="Verificado"
                  variant="outlined"
                  color="success"
                />
              ) : (
                <Chip
                  icon={<HelpIcon />}
                  label="Sin confirmar"
                  variant="outlined"
                  color="warning"
                />
              )}
            </>
          );
        },
      },
      {
        field: "source",
        headerName: "Fuente de pago",
        minWidth: 150,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          if (params.row.source == 'payment_web') {
            return 'WEB'
          } else if (params.row.source == 'payment_inscription') {
            return 'Inscripción Interna'
          } else if (params.row.source == 'backoffice_form') {
            return 'BO'
          } else if (params.row.source == 'batch_load') {
            return 'Carga Lote'
          } else {
            return params.row.source;
          }
        },
      },
      {
        field: "created_at",
        headerName: "Fecha creación",
        minWidth: 150,
        disableColumnMenu: true,
        editable: false,
        type: "dateTime",
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.created_at && moment(params.row.created_at);
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            params.row.created_at &&
            moment(params.row.created_at).format("YYYY MMM D")
          );
        },
      },
      {
        field: "invoiced_at",
        headerName: "Fecha facturación",
        minWidth: 180,
        disableColumnMenu: true,
        editable: false,
        type: "dateTime",
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.invoiced_at && moment(params.row.invoiced_at);
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            params.row.invoiced_at &&
            moment(params.row.invoiced_at).format("YYYY MMM D")
          );
        },
      },
      {
        field: "payment_at",
        headerName: "Fecha pago",
        minWidth: 180,
        disableColumnMenu: true,
        editable: false,
        type: "dateTime",
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.payment_at && moment(params.row.payment_at);
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            params.row.payment_at &&
            moment(params.row.payment_at).format("YYYY MMM D")
          );
        },
      },
    ];

    return columns;
  }
};

export default memo(DataGridInvoices);
