// Router
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Views
import TeacherAttendances from "../views/TeacherAttendances/TeacherAttendances";
import DashboardLayout from "../components/templates/DashboardLayout";
import StudentAbsences from "../views/StudentAbsences/StudentAbsences";
import GroupRooms from "../views/Groups/GroupRooms/GroupRooms";
import RoomDetail from "../views/Rooms/RoomDetail/RoomDetail";
import Dashboard from "../views/Dashboard/Dashboard";
import Guardians from "../views/Guardians/Guardians";
import Students from "../views/Students/Students";
import Groups from "../views/Groups/Groups";
import SignIn from "../views/SignIn/Login";
import Rooms from "../views/Rooms/Rooms";
import Sliders from "../views/Sliders/Sliders";
import Courses from "../views/Courses/Courses";
import LearningPaths from "../views/LearningPaths/LearningPaths";
import Live from "../views/Live/Live";
import Teachers from "../views/Teachers/Teachers";
import ProgramCoupons from "../views/ProgramCoupons/ProgramCoupons";
import AgeGroups from "../views/AgeGroups/AgeGroups";
import Invoices from "../views/Invoices/Invoices";
import TeacherAvailability from "../pages/Teachers/TeacherAvailability";
import TeacherProfile from "../pages/Profile/TeacherProfile";

interface Props {
   isAuthenticated: boolean;
}

const Router: React.FC<Props> = ({ isAuthenticated }) => {
   return (
      <BrowserRouter>
         <Routes>
            {isAuthenticated ? (
               <>
                  <Route path="/dashboard" element={<DashboardLayout />}>
                     <Route index element={<Dashboard />} />
                     <Route path="groups">
                        <Route index element={<Groups />} />
                        <Route path=":groupId/rooms" element={<GroupRooms />} />
                     </Route>
                     <Route path="rooms">
                        <Route index element={<Rooms />} />
                        <Route path=":roomId" element={<RoomDetail />} />
                     </Route>
                     <Route path="students" element={<Students />} />
                     <Route path="guardians" element={<Guardians />} />
                     <Route path="invoices" element={<Invoices />} />

                     <Route path="teachers">
                        <Route index element={<Teachers />} />
                        <Route path="teacher-availability">
                           <Route index element={<TeacherAvailability />} />
                           <Route
                              path="teacher-profile/:teacherId"
                              element={<TeacherProfile />}
                           />
                        </Route>
                     </Route>

                     <Route path="courses" element={<Courses />} />
                     <Route path="learning-paths" element={<LearningPaths />} />
                     <Route path="sliders" element={<Sliders />} />
                     <Route
                        path="program-coupons"
                        element={<ProgramCoupons />}
                     />
                     <Route path="age-groups" element={<AgeGroups />} />
                     <Route path="live" element={<Live />} />

                     <Route
                        path="student-absences"
                        element={<StudentAbsences />}
                     />
                     <Route
                        path="teacher-attendances"
                        element={<TeacherAttendances />}
                     />
                  </Route>

                  <Route path="*" element={<Navigate to="/dashboard" />} />
               </>
            ) : (
               <>
                  <Route path="/" element={<SignIn />} />

                  <Route path="*" element={<Navigate to="/" />} />
               </>
            )}
         </Routes>
      </BrowserRouter>
   );
};

export default Router;
