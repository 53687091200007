import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import EditInvoice from "./EditInvoice/EditInvoice";
import OpenModal from "../../../components/Modal/OpenModal/OpenModal";
import Modal from "../../../components/Modal/Modal";

// Helpers
import { addParams, deleteParams } from "../../../helpers/router.helpers";

interface InvoiceServerless {
    id: number;
    username: string;
    payment_at: Date | null;
    invoiced_at: Date | null;
    created_at: Date | null;
    coupon_code: string;
    coupon_discount: string;
    transaction_id: string;
    payment_gateway:
    | "niubiz"
    | "stripe"
    | "dlocal";
    source:
    | "backoffice_form"
    | "django_form"
    | "enrollment_form_campaigns"
    | "enrollment_form_courses_paths"
    | "manual"
    | "payment_gateway"
    | "payment_web"
    | "payment_inscription"
    | "batch_load'";
    is_verified: boolean,
    currency:
    | "USD"
    | "PEN"
    | "MXN"
    | "COP";
    amount: number
}

interface Props {
    invoiceId: number;
    invoice: any | InvoiceServerless;
    refetch: () => {};
    autoOpen?: boolean;
}

const ModalEditInvoice: React.FC<Props> = ({
    invoiceId,
    invoice,
    refetch,
    autoOpen = false,
}) => {
    const [params, setParams] = useSearchParams();
    const [openModal, setOpenModal] = useState(autoOpen);

    const handleOpenModal = () => {
        addParams(params, setParams, "id", invoiceId.toString());
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        deleteParams(params, setParams, "id");
        setOpenModal(false);
    };

    return (
        <>
            {!autoOpen && (
                <OpenModal
                    option="edit"
                    placement="left"
                    handleOpenModal={handleOpenModal}
                />
            )}
            <Modal
                id={invoiceId}
                title="Editar Pago"
                copyUrl
                fullWidth
                maxWidth="md"
                openModal={openModal}
                handleCloseModal={handleCloseModal}
            >
                <EditInvoice
                    invoiceId={invoiceId}
                    invoice={invoice}
                    refetch={refetch}
                    onClose={handleCloseModal}
                />
            </Modal>
        </>
    );
};

export default ModalEditInvoice;