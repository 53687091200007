import React, { useRef, useState } from "react";

import IconArrowDown from "../../atoms/icons/IconArrowDown";
import Typography from "../../atoms/Typography";

import "./collapseWrapper.scss";

interface Props {
   title: string;
   body: any;
   isOpen: boolean;
   setIsOpen: any;
}

const CollapseWrapper: React.FC<Props> = ({
   title,
   body,
   isOpen,
   setIsOpen,
}) => {
   const content = useRef<any>();
   const [height, setHeight] = useState("0px");
   const [rotate, setRotate] = useState("down");

   const toggleCollapse = () => {
      setIsOpen(!isOpen);
      setHeight(isOpen ? "0px" : `${content.current.scrollHeight}px`);
      setRotate(isOpen ? "down" : "up");
   };

   return (
      <div className={`collapse-wrapper ${isOpen}`}>
         <section className="collapse-wrapper__header">
            <Typography type="paragraph1" variation="mobile" bold>
               {title}
            </Typography>
            <div onClick={toggleCollapse}>
               <IconArrowDown className={`collapse-wrapper__icon-${rotate}`} />
            </div>
         </section>
         <br />
         <div
            ref={content}
            style={{
               maxHeight: height !== "0px" ? height : "0px",
               paddingTop: isOpen ? "20px" : "0px",
            }}
            className="collapse-wrapper__body"
         >
            {body}
         </div>
      </div>
   );
};

export default CollapseWrapper;
