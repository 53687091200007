// React
import { useState } from "react";

// Interfaces
import { IStudent } from "../../../../../interfaces/group.interface";

// Components
import AddStudentToGroup from "./AddStudentToGroup/AddStudentToGroup";
import OpenModal from "../../../../../components/Modal/OpenModal/OpenModal";
import Modal from "../../../../../components/Modal/Modal";

interface Props {
   complement_students: IStudent[];
   groupId: number;
   refetch?: () => {};
}

const ModalAddStudentToGroup: React.FC<Props> = ({
   complement_students,
   groupId,
   refetch,
}) => {
   const [openModal, setOpenModal] = useState(false);

   const handleOpenModal = () => setOpenModal(true);
   const handleCloseModal = () => setOpenModal(false);

   return (
      <>
         {/* Open Modal */}
         <OpenModal
            option="create"
            title="Añadir estudiante al grupo"
            handleOpenModal={handleOpenModal}
         />

         {/* Modal Add Student To Group */}
         <Modal
            title="Añadir estudiante al grupo"
            fullWidth
            maxWidth="sm"
            openModal={openModal}
            handleCloseModal={handleCloseModal}
         >
            <AddStudentToGroup
               complement_students={complement_students}
               groupId={groupId}
               /* refetch={refetch} */
               onClose={handleCloseModal}
            />
         </Modal>
      </>
   );
};

export default ModalAddStudentToGroup;
