// React
import { useState } from "react";

// Utils
import { searchClearner } from "../../../../../../utils/utils";

// Interfaces
import { IStudent } from "../../../../../../interfaces/group.interface";

//Redux
import { AppDispatch, useAppDispatch } from "../../../../../../store/store";
import { listRoomsByGroupId } from "../../../../../../slices/roomSlice/roomsByGroupIdSlice";
import { addStudentToGroup } from "../../../../../../slices/groupSlice/groupsSlice";

// Material UI
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddBoxIcon from "@mui/icons-material/AddBox";
import GroupAddIcon from "@mui/icons-material/GroupAdd";


interface Props {
   complement_students: IStudent[];
   groupId: number;
   refetch?: () => {};
   onClose: () => void;
}

const AddStudentToGroup: React.FC<Props> = ({
   complement_students,
   groupId,
   refetch,
   onClose,
}) => {
   const dispatch: AppDispatch = useAppDispatch();
   const [students, setStudents] = useState<IStudent[]>(complement_students);
   const [selectedStudentId, setSelectedStudentId] = useState<number>(0);
   const [isLoadingStudentToGroup, setIsLoadingStudentToGroup] = useState(false);

   const handleListItemClick = (index: number) => setSelectedStudentId(index);

   const onChangeInputSearch = (searchedVal: string) => {
      const filteredStudents = complement_students.filter((student: IStudent) =>
         searchClearner(student.full_name).includes(searchClearner(searchedVal))
      );

      setStudents(filteredStudents);
   };

   const handleAddStudentToGroup = async () => {
      //await addStudentToGroup();
      await dispatch(addStudentToGroup({groupId, selectedStudentId}));
      try {
      } catch (error) {
         console.log(error);
      } finally {
         await dispatch(listRoomsByGroupId(Number(groupId)));
         onClose();
      }
   };

   return (
      <Grid container rowGap={2}>
         {/* Searcher */}
         <Grid item container xs={12}>
            <TextField
               autoFocus
               fullWidth
               placeholder="Buscar estudiantes"
               id="search"
               label="Buscar estudiantes"
               variant="outlined"
               onChange={(event) => onChangeInputSearch(event.target.value)}
            />
         </Grid>

         {/* List */}
         <Grid item xs={12}>
            <List
               sx={{ width: "100%", maxHeight: 320, overflow: "auto" }}
               component="nav"
            >
               {students.map((student: IStudent) => (
                  <ListItemButton
                     sx={{
                        width: "100%",
                        border: "1px solid #E0E0E0",
                        borderRadius: "6px",
                        marginBottom: "5px",
                     }}
                     key={student.id}
                     selected={selectedStudentId === student.id}
                     onClick={() => handleListItemClick(student.id)}
                  >
                     <ListItemText primary={student.full_name} />
                     {selectedStudentId === student.id && (
                        <AddBoxIcon fontSize="medium" color="primary" />
                     )}
                  </ListItemButton>
               ))}
            </List>
         </Grid>

         {/* Button */}
         <Grid item xs={12}>
            <LoadingButton
               fullWidth
               disabled={!(selectedStudentId > 0)}
               loading={
                  isLoadingStudentToGroup /* addStudentToGroup.isLoading */
               }
               loadingPosition="start"
               startIcon={<GroupAddIcon />}
               color="primary"
               variant="contained"
               onClick={() => handleAddStudentToGroup()}
            >
               Agregar estudiante
            </LoadingButton>
         </Grid>
      </Grid>
   );
};

export default AddStudentToGroup;
