// React
import { useEffect, useState } from "react";

// Redux
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../store/store";

// Components
import GroupsLayout from "../../components/templates/GroupsLayout";
import GlobalSearch from "../../components/organism/GlobalSearch";
import TableGroups from "../../components/organism/TableGroups";
import ButtonFilled from "../../components/molecules/ButtonFilled";
import {
   getAllLearnings,
   selectStateLearnings,
} from "../../slices/learningsSlice/learningsSlice";

function Groups() {
   const dispatch: AppDispatch = useAppDispatch();
   const user = useSelector((state: RootState) => state.auth.user);
   const { learnings, isLoading } = useSelector(selectStateLearnings);

   const [message, setMessage] = useState("");
   const [isSearch, setIsSearch] = useState(false);
   const [openModalCreate, setOpenModalCreate] = useState(false);

   const [selectOption1, setSelectOption1] = useState<any>("");
   const [selectOption2, setSelectOption2] = useState<any>("");
   const [selectOption3, setSelectOption3] = useState<any>("");
   const [selectOption4, setSelectOption4] = useState<any>("");

   useEffect(() => {
      dispatch(getAllLearnings());
   }, []);

   return (
      <>
         {/* List groups */}
         <GroupsLayout
            buttonAdd={
               <ButtonFilled
                  fullWidth
                  onClick={() => {
                     setOpenModalCreate(true);
                  }}
                  className={"groups-layout__button-add-group"}
               >
                  {"+ Crear nuevo grupo"}
               </ButtonFilled>
            }
            globalSearch={
               <GlobalSearch
                  message={message}
                  setMessage={setMessage}
                  isSearch={isSearch}
                  setIsSearch={setIsSearch}
                  selectOption1={selectOption1}
                  setSelectOption1={setSelectOption1}
                  selectOption2={selectOption2}
                  setSelectOption2={setSelectOption2}
                  selectOption3={selectOption3}
                  setSelectOption3={setSelectOption3}
                  selectOption4={selectOption4}
                  setSelectOption4={setSelectOption4}
                  data={learnings}
               />
            }
            table={
               <TableGroups
                  openModalCreate={openModalCreate}
                  setOpenModalCreate={setOpenModalCreate}
                  message={message}
                  isSearch={isSearch}
                  selectOption1={selectOption1}
                  selectOption2={selectOption2}
                  selectOption3={selectOption3}
                  selectOption4={selectOption4}
               />
            }
         />
      </>
   );
}

export default Groups;
