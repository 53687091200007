import React from "react";
import { Iprops } from "./types";
import { LoginWrapper, ImgSection, ContenSection } from "./styles";

function SignInLayout({ children }: Iprops) {
  return (
    <LoginWrapper>
      <ImgSection></ImgSection>
      <ContenSection>{children}</ContenSection>
    </LoginWrapper>
  );
}

export default SignInLayout;
