// React
import { useEffect, useState } from "react";

// Utils
import { searchClearner } from "../../../../utils/utils";

// Interfaces
import {
  IEnrollStudent,
  IEnrolments,
  IGroup,
  PurchaseTypeConst,
} from "../../../../interfaces/enrolment.interface";

//Redux
import { selectStateEnrolments, updateEnrollStudent } from "../../../../slices/enrolmentSlice/enrolmentsSlice";
import { useSelector } from "react-redux";
import { AppDispatch, useAppDispatch } from "../../../../store/store";
import { getAllStudents } from "../../../../slices/studentSlice/studentSlice";


// Material UI
import {
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

// Components
import ListGroup from "./ListGroup/ListGroup";


interface Props {
  studentId: number;
  refetch: () => {};
  onClose: () => void;
}

const EnrollStudent: React.FC<Props> = ({ studentId, refetch, onClose }) => {
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<IGroup[]>([]);

  const [purchaseType, setPurchaseType] = useState<PurchaseTypeConst>();
  const [variation, setVariation] = useState<number | null>(null);
  const [campaign, setCampaign] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [groupIds, setGroupIds] = useState<number[]>([]);
  const [isLoadingEnrolStudent, setIsLoadingEnrolStudent] = useState(false);

  

  const dispatch: AppDispatch = useAppDispatch();
  const {enrolments, isLoadingEnrolments} = useSelector(selectStateEnrolments);
  

  const funcEnrollStudent = async (enrollStudent: IEnrollStudent) => {
    try {
      await dispatch(updateEnrollStudent(enrollStudent));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setGroupIds([]);
    setVariation(null);
    setCampaign(null);
    setSearchText("");
  }, [purchaseType]);

  useEffect(() => {
    purchaseType &&
      enrolments &&
      currentGroups(purchaseType, enrolments, campaign, variation);
  }, [purchaseType, enrolments, campaign, variation]);

  useEffect(() => {
    setGroupIds([]);
    setSearchText("");
  }, [campaign]);

  useEffect(() => {
    setGroupIds([]);
    setSearchText("");
  }, [variation]);

  if (isLoadingEnrolments || !enrolments) return <LinearProgress />;
  /* if (isError) return <div>Error! {JSON.stringify(error)}</div>; */

  const handlePurchaseType = (purchase_type: PurchaseTypeConst) => (): void =>
    setPurchaseType(purchase_type);
  const handleVariation = (variation: number) => (): void => setVariation(variation);
  const handleCampaign = (campaign: number) => (): void => setCampaign(campaign);

  const currentGroups = (
    purchaseType: PurchaseTypeConst,
    enrolments: IEnrolments,
    campaign: number | null,
    variation: number | null
  ): void => {
    let filteredGroups: IGroup[] = [];

    switch (purchaseType) {
      case "campaign":
        filteredGroups = enrolments.groups["campaign"].filter(
          (group: IGroup) => campaign === group["campaign"]
        );
        break;
      case "variation":
        filteredGroups = enrolments.groups["variation"].filter(
          (group: IGroup) => variation === group["course"]
        );
        break;
      default:
        filteredGroups = enrolments.groups[purchaseType!];
        break;
    }

    setFilteredGroups(filteredGroups);
    setGroups(filteredGroups);
  };

  const onChangeInputSearch = (searchedVal: string): void => {
    setSearchText(searchedVal);

    const filteredGroups: IGroup[] = groups.filter((group: IGroup) =>
      searchClearner(group.name).includes(searchClearner(searchedVal))
    );

    setFilteredGroups(filteredGroups);
  };

  const handleGroup = (groupId: number) => (): void => {
    const currentIndex = groupIds.indexOf(groupId);
    const newGroupIds = [...groupIds];

    currentIndex === -1
      ? newGroupIds.push(groupId)
      : newGroupIds.splice(currentIndex, 1);

    if (purchaseType === "campaign") {
      // Limit length of an array to TWO
      setGroupIds([...newGroupIds].splice(0, 2));
    } else {
      // Limit length of an array to ONE
      setGroupIds([...newGroupIds].splice(0, 1));
    }
  };

  const handleEnrollStudent =
    (
      purchase_type: PurchaseTypeConst,
      groupIds: number[],
      studentId: number,
      variation: number | null
    ) =>
    async () => {
      const data: IEnrollStudent = {
        purchase_type: purchase_type,
        variation: variation,
        groups: groupIds,
        student: studentId,
      };

      await funcEnrollStudent(data);
      await dispatch(getAllStudents());
      onClose();
    };
  console.log("enrollments: ",enrolments);
  return (
    <Grid container rowGap={2}>
      {/* Type_of_purchase */}
      <Grid item container xs={12}>
        <FormControl fullWidth>
          <InputLabel id="type_of_purchase">Tipo de compra</InputLabel>
          <Select
            autoFocus
            labelId="type_of_purchase"
            id="type_of_purchase"
            label="Tipo de compra"
            placeholder="Tipo de compra"
            value={purchaseType || ""}
          >
            {enrolments.purchase_types.map((purchase_type:any, idx:any) => (
              <MenuItem
                key={idx}
                value={purchase_type.value}
                onClick={handlePurchaseType(purchase_type.value)}
              >
                {purchase_type.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Variation */}
      {purchaseType === "variation" && (
        <Grid item container xs={12}>
          <FormControl fullWidth>
            <InputLabel id="variation">Variación</InputLabel>
            <Select
              labelId="variation"
              id="variation"
              label="Variación"
              value={variation || ""}
            >
              {enrolments.variations.map((variation:any, idx:any) => (
                <MenuItem
                  key={idx}
                  value={variation.course}
                  onClick={handleVariation(variation.course)}
                >
                  {variation.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* Campaign */}
      {purchaseType === "campaign" && (
        <Grid item container xs={12}>
          <FormControl fullWidth>
            <InputLabel id="campaign">Campaña</InputLabel>
            <Select
              labelId="campaign"
              id="campaign"
              label="Campaña"
              value={campaign || ""}
            >
              {enrolments.campaigns.map((campaign:any, idx:any) => (
                <MenuItem
                  key={idx}
                  value={campaign.id}
                  onClick={handleCampaign(campaign.id)}
                >
                  {campaign.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* Searcher */}
      {purchaseType && (
        <Grid item container xs={12}>
          <TextField
            fullWidth
            id="search"
            label="Buscar grupo"
            variant="outlined"
            value={searchText}
            disabled={!purchaseType}
            onChange={(event) => onChangeInputSearch(event.target.value)}
          />
        </Grid>
      )}

      {/* List Groups */}
      {purchaseType && (
        <Grid item xs={12}>
          <ListGroup
            groups={filteredGroups}
            groupIds={groupIds}
            handleGroup={handleGroup}
          />
        </Grid>
      )}

      {/* Button */}
      <Grid item xs={12}>
        <LoadingButton
          fullWidth
          disabled={!groupIds}
          loading={isLoadingEnrolStudent /* enrollStudent.isLoading */}
          loadingPosition="start"
          startIcon={<GroupAddIcon />}
          color="primary"
          variant="contained"
          onClick={handleEnrollStudent(
            purchaseType!,
            groupIds!,
            studentId,
            variation
          )}
        >
          Matrícular Estudiante
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default EnrollStudent;
