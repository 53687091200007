// React
import { useEffect, useMemo, useState } from "react";

// Interfaces
import { IGuardian } from "../../../interfaces/guardian.interface";

// Helpers
import { addParams, deleteParams } from "../../../helpers/router.helpers";

// Hot Toast
import { toast } from "react-hot-toast";

// Utils
import { countries } from "../../../utils/countries";

// Router
import { useSearchParams, useLocation } from "react-router-dom";

//Services

// Redux
import { useSelector } from "react-redux";
import { selectStateGuardians } from "../../../slices/guardianSlice/guardianSlice";
import { AppDispatch, useAppDispatch } from "../../../store/store";
import { getGuardianById } from "../../../slices/guardianSlice/guardianByIdSlice";
import { getGuardianDetails } from "../../../slices/guardianSlice/guardianInvoicesSlice";

import Table from "../../molecules/Table";
import FloatingMenu from "../../molecules/FloatingMenu";
import ScreenLoader from "../../ScreenLoader/ScreenLoader";
import IconEdit from "../../atoms/icons/IconAges";
import GuardianDetails from "../../../views/Guardians/ModalGuardianDetails/GuardianDetails/GuardianDetails";
import Modal from "../../../components/Modal/Modal";
import EditGuardian from "../../../views/Guardians/ModalEditGuardian/EditGuardian/EditGuardian";
import ItemFloatingMenu from "../../molecules/ItemFloatingMenu";
import { setGenericPassword } from "../../../services/account";

import "./tableGuardian.scss";

const TableGuardian = () => {
   const dispatch: AppDispatch = useAppDispatch();
   let location = useLocation();
   const [params, setParams] = useSearchParams();
   const [idGuardian, setIdGuardian] = useState<number | null>(null);
   const [openModalDetails, setOpenModalDetails] = useState(false);
   const [openModalEdit, setOpenModalEdit] = useState(false);

   const { guardians, isLoading } = useSelector(selectStateGuardians);

   useEffect(() => {
      if (idGuardian) {
         dispatch(getGuardianById(idGuardian));
         dispatch(getGuardianDetails(idGuardian));
      }
   }, [dispatch, idGuardian]);

   useEffect(() => {
      if (parseInt(location.search.split("?id=")[1])) {
         setIdGuardian(parseInt(location.search.split("?id=")[1]));
         setOpenModalDetails(true);
      }
   }, [location.search]);

   const handleSetGenericPassword = async (sessionId: number) => {
      try {
         const response = await setGenericPassword(sessionId);
         if (response?.status === 200) {
            toast.success("Se reestablecio la contraseña correctamente!");
         } else {
            toast.error("No se pudo actualizar la contraseña");
         }
      } catch (error) {
         toast.error("Ha ocurrido un error.");
      }
   };

   const columns = useMemo(
      () => [
         {
            Header: "ID",
            accessor: "id",
            widthColumn: 65,
         },
         {
            Header: "Nombre de tutor",
            accessor: "full_name",
            classColumn: "tb-guardian-tutor",
            widthColumn: 240,
            hasTooltip: true,
         },
         {
            Header: "Correo",
            accessor: "username",
            classColumn: "tb-guardian-username",
            hasTooltip: true,
            widthColumn: 240,
            Cell: ({ cell }: any) => <>{cell.value}</>,
         },
         {
            Header: "Teléfono",
            accessor: "phone_number",
            hasSort: true,
            disableSortBy: true,
            disableFilters: true,
            minWidthColumn: 140,
            Cell: ({ cell }: any) => <>{cell.value}</>,
         },
         {
            Header: "País",
            accessor: "country",
            minWidthColumn: 160,
            Cell: ({ cell }: any) => {
               let country;
               if (cell.value) {
                  country = countries.find(
                     (country) => country.code === cell.value
                  );
               }
               return <>{country ? country.label : cell.label}</>;
            },
            disableFilters: true,
         },
         {
            Header: "DNI",
            accessor: "doc_number",
            minWidthColumn: 134,
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ cell }: any) => <>{cell.value}</>,
         },
         {
            //hideColumn: true,
            Header: "",
            accessor: "action",
            actionWidth: 156,
            hasTooltip: true,
            tooltipValue: "Editar",
            Cell: (props: any) => {
               return (
                  <>
                     <div
                        className="table-guardian__link table-guardian__link--hover-lineal"
                        onClick={() => {
                           addParams(
                              params,
                              setParams,
                              "id",
                              props.row.values.id.toString()
                           );
                           setIdGuardian(props.row.values.id);
                           setOpenModalDetails(true);
                        }}
                     >
                        Ver más
                     </div>

                     {props.row.values.id ? (
                        <div
                           className="table-guardian__container-tooltip"
                           onClick={() => {
                              setOpenModalEdit(true);
                              setIdGuardian(props.row.values.id);
                           }}
                        >
                           <IconEdit
                              className={`table-guardian__button table-guardian__button--enabled`}
                           />
                           <section className="table-guardian__tooltip">
                              {props.column.tooltipValue}
                           </section>
                        </div>
                     ) : (
                        <div className="table-guardian__container-tooltip">
                           <IconEdit
                              className={`table-guardian__button table-guardian__button--disabled`}
                              fill="#bcbcbc"
                           />
                           <section className="table-guardian__tooltip">
                              {props.column.tooltipValue}
                           </section>
                        </div>
                     )}
                     <FloatingMenu
                        items={[
                           <ItemFloatingMenu
                              onClick={() => {
                                 addParams(
                                    params,
                                    setParams,
                                    "id",
                                    props.row.values.id.toString()
                                 );
                                 setIdGuardian(props.row.values.id);
                                 setOpenModalDetails(true);
                              }}
                           >
                              Ver más
                           </ItemFloatingMenu>,
                           <ItemFloatingMenu
                              onClick={() => {
                                 setOpenModalEdit(true);
                                 setIdGuardian(props.row.values.id);
                              }}
                           >
                              Editar
                           </ItemFloatingMenu>,
                           <ItemFloatingMenu
                              onClick={() => {
                                 if (props.row.values.id) {
                                    toast.success("URL copiado...");
                                    navigator.clipboard.writeText(
                                       `https://backoffice.crackthecode.la/dashboard/guardians?id=${props.row.values.id}`
                                    );
                                 } else toast.error("No se piso copiar la URL");
                              }}
                           >
                              Copiar URL
                           </ItemFloatingMenu>,
                           <ItemFloatingMenu
                              onClick={() => {
                                 handleSetGenericPassword(props.row.values.id);
                              }}
                           >
                              Reestablecer Contraseña
                           </ItemFloatingMenu>,

                           ,
                        ]}
                     />
                  </>
               );
            },
            headerPagination: true,
            disableSortBy: true,
            disableFilters: true,
         },
      ],
      []
   );

   if (isLoading) return <ScreenLoader />;

   return (
      <>
         <Table data={guardians} columns={columns} />
         {idGuardian ? (
            <>
               {openModalDetails && (
                  <Modal
                     id={idGuardian}
                     title="Tutor / Madre / Padre"
                     fullWidth
                     maxWidth="xl"
                     openModal={openModalDetails}
                     handleCloseModal={() => {
                        setOpenModalDetails(false);
                        deleteParams(params, setParams, "id");
                     }}
                  >
                     <GuardianDetails guardianId={idGuardian} />
                  </Modal>
               )}
               <Modal
                  id={idGuardian}
                  title="Editar Tutor / Madre / Padre"
                  fullWidth
                  maxWidth="md"
                  openModal={openModalEdit}
                  handleCloseModal={() => setOpenModalEdit(false)}
               >
                  <EditGuardian
                     guardianId={idGuardian}
                     onClose={() => setOpenModalEdit(false)}
                  />
               </Modal>
            </>
         ) : (
            ""
         )}
      </>
   );
};

export default TableGuardian;
