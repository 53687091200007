// React
import { useState } from "react";

// Material UI
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  label: string;
  placeholder: string;
  values: string[];
  enabled: boolean;
  setFieldValue: any;
  to: string;
}

const ToDo: React.FC<Props> = ({
  label,
  placeholder,
  values,
  enabled,
  setFieldValue,
  to,
}) => {
  const [tasks, setTasks] = useState<string[]>(
    values && values.length > 0 ? values : []
  );
  const [task, setTask] = useState<string>("");

  const handleAddTask = (task: string) => {
    task.length > 0 && task ? setTasks([...tasks, task]) : setTasks([...tasks]);
    setTask("");

    setFieldValue(to, [...tasks, task]);
  };

  const handleDeleteTask = (deleteTask: string) => {
    const filteredArray = tasks.filter((task) => task !== deleteTask);
    setTasks(filteredArray);

    setFieldValue(to, filteredArray);
  };

  return (
    <Grid container rowSpacing={1} alignItems="flex-start">
      {/* Header */}
      <Grid item xs={12}>
        <Divider textAlign="left">
          <Chip label={label} />
        </Divider>
      </Grid>

      {/* Main */}
      <Grid item xs={12}>
        <List
          sx={{
            width: "100%",
            padding: 0,
            maxHeight: "150px",
            overflowY: "scroll",
          }}
        >
          {tasks.map((task: string, idx) => {
            const labelId = `checkbox-list-label-${task}`;

            return (
              <ListItem
                disabled={enabled}
                key={idx}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => handleDeleteTask(task)}
                    disabled={enabled}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
                dense
              >
                <ListItemText id={labelId} primary={task} />
              </ListItem>
            );
          })}
        </List>
      </Grid>

      {/* Btn Add ToDo */}
      <Grid item xs={12}>
        <Stack direction="row" spacing={1.5}>
          <TextField
            fullWidth
            id="outlined-basic"
            label={placeholder}
            placeholder={placeholder}
            value={task}
            onChange={(e) => setTask(e.target.value)}
            variant="outlined"
            disabled={enabled}
            size="small"
            type="text"
            onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
          />
          <LoadingButton
            sx={{ width: "50%" }}
            variant="contained"
            onClick={() => handleAddTask(task)}
            disabled={enabled}
            type="button"
            startIcon={<AddCircleIcon />}
          >
            Agregar
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ToDo;
