// React
import { useEffect } from "react";

import colors from "../../atoms/Colors";

// Material UI
import {
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   FormControl,
   MenuItem,
   Paper,
   Select,
   SelectChangeEvent,
} from "@material-ui/core";

import { IAttendance } from "../../../interfaces/room.interface";
import AttendanceIndicator from "../../common/AttendanceIndicator/AttendanceIndicator";

import "./tableAttendanceTeacher.scss";

interface Props {
   room: any;
   setCurrentTeacherRecords: any;
   currentTeacherRecords: any;
   refetch?: () => {};
}

const TableAttendanceStudent: React.FC<Props> = ({
   room,
   setCurrentTeacherRecords,
   currentTeacherRecords,
}) => {
   const handleCheckAttendance = (
      event: SelectChangeEvent<number>,
      index_session: number
   ) => {
      const teacherRecordsAux = JSON.parse(
         JSON.stringify(currentTeacherRecords)
      );
      teacherRecordsAux.attendances[index_session].status = event.target.value;
      teacherRecordsAux.attendances[index_session].was_edited = true;

      setCurrentTeacherRecords(teacherRecordsAux);
   };

   useEffect(() => {
      setCurrentTeacherRecords(room?.teacher_records);

      // eslint-disable-next-line
   }, [room]);

   return (
      <>
         <TableContainer
            component={Paper}
            sx={{
               boxShadow: "none",
               "&::-webkit-scrollbar": {
                  height: 12,
               },
               "&::-webkit-scrollbar-track": {
                  backgroundColor: colors.gris02,
               },
               "&::-webkit-scrollbar-thumb": {
                  backgroundColor: colors.gris03,
                  borderRadius: 2,
               },
            }}
         >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
               {/* Header */}
               <TableHead>
                  <TableRow>
                     <TableCell
                        component="th"
                        scope="row"
                        sx={{
                           fontFamily: "Sofia Pro",
                           fontStyle: "normal",
                           fontWeight: 700,
                           fontSize: 12,
                           borderBottom: "none",
                           minWidth: 250,
                           maxWidth: 250,
                           width: 250,
                        }}
                     >
                        Profesor
                     </TableCell>
                     {[...Array(Number(room?.number_of_sessions) || 0)].map(
                        (_, index) => (
                           <TableCell
                              key={index}
                              component="th"
                              scope="row"
                              sx={{
                                 fontFamily: "Sofia Pro",
                                 fontStyle: "normal",
                                 fontWeight: 700,
                                 fontSize: 12,
                                 textAlign: "center",
                                 borderBottom: "none",
                                 p: "0px 4px",
                              }}
                           >
                              Sesión {index + 1}
                           </TableCell>
                        )
                     )}
                  </TableRow>
               </TableHead>

               {/* Body */}
               <TableBody>
                  {room && (
                     <TableRow>
                        {/* Student */}
                        <TableCell
                           component="th"
                           scope="row"
                           sx={{
                              fontFamily: "Sofia Pro",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: 14,
                              borderBottom: "none",
                           }}
                        >
                           {room.teacher.full_name}
                        </TableCell>

                        {/* Attendances */}
                        {currentTeacherRecords &&
                           currentTeacherRecords.attendances.map(
                              (attendance: IAttendance, index: number) => (
                                 <TableCell
                                    component="th"
                                    scope="row"
                                    key={index}
                                    sx={{
                                       borderBottom: "none",
                                       p: "0px 4px",
                                       textAlign: "center",
                                    }}
                                 >
                                    <FormControl
                                       sx={{ m: 1 }}
                                       variant="standard"
                                    >
                                       <Select
                                          labelId="demo-customized-select-label"
                                          id="demo-customized-select"
                                          value={attendance.status}
                                          defaultValue={0}
                                          onChange={(
                                             event: SelectChangeEvent<number>
                                          ) =>
                                             handleCheckAttendance(event, index)
                                          }
                                          className={`table-attendance-teacher table-attendance-teacher__select 
                                    ${
                                       attendance.status == 0
                                          ? "table-attendance-teacher__disable"
                                          : attendance.status == 1
                                          ? "table-attendance-teacher__attended"
                                          : attendance.status == 2
                                          ? "table-attendance-teacher__delay"
                                          : attendance.status == 3
                                          ? "table-attendance-teacher__absence"
                                          : attendance.status == 4
                                          ? "table-attendance-teacher__justified"
                                          : "table-attendance-teacher__disable"
                                    }
                                 `}
                                          sx={{
                                             ".MuiSelect-icon ": {
                                                fill:
                                                   attendance.status == 0
                                                      ? colors.gris03
                                                      : attendance.status == 1
                                                      ? colors.statusAttendedText
                                                      : attendance.status == 2
                                                      ? colors.statusDelayText
                                                      : attendance.status == 3
                                                      ? colors.statusAbsenceText
                                                      : attendance.status == 4
                                                      ? colors.statusJustifiedText
                                                      : colors.gris03,
                                             },
                                          }}
                                       >
                                          <MenuItem
                                             value={0}
                                             style={{ display: "none" }}
                                          >
                                             <em>-</em>
                                          </MenuItem>
                                          <MenuItem
                                             value={1}
                                             sx={{ minWidth: 105 }}
                                          >
                                             <AttendanceIndicator
                                                message="A"
                                                status="attended"
                                             />
                                          </MenuItem>
                                          <hr />
                                          <MenuItem value={2}>
                                             <AttendanceIndicator
                                                message="T"
                                                status="delay"
                                             />
                                          </MenuItem>
                                          <hr />
                                          <MenuItem value={3}>
                                             <AttendanceIndicator
                                                message="F"
                                                status="absence"
                                             />
                                          </MenuItem>
                                       </Select>
                                    </FormControl>
                                 </TableCell>
                              )
                           )}
                     </TableRow>
                  )}
               </TableBody>
            </Table>
         </TableContainer>
      </>
   );
};

export default TableAttendanceStudent;
