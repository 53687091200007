import { LoadingButton, LocalizationProvider, DateTimePicker } from "@mui/lab";
import * as Yup from "yup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Alert, Grid, TextField, Stack } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import { Formik, Form } from "formik";
import { useState } from "react"
import parse from "date-fns/parse";
import moment from "moment";
import toast from "react-hot-toast";
import bgLocale from 'date-fns/locale/bg';
import AdapterDateFns from "@mui/lab/AdapterDateFns";

interface Props {
    idParent: number,
    nameCustom: string,
    dateLimit?: Date,
    submitAction: any,
    refetch: any
}

const CustomDatePicker: React.FC<Props> = ({
    idParent = -1,
    nameCustom,
    dateLimit,
    submitAction,
    refetch
}) => {
    const [datePickerEnable, setDatePickerEnable] = useState(true);

    const handleClickEnable = () => {
        setDatePickerEnable(false);
    };

    const handleClickDisable = () => {
        setDatePickerEnable(true);
    };

    const initialValues = {
        id: idParent,
        dateLimit: dateLimit && moment(dateLimit),
    };

    const CustomDateSchema = Yup.object().shape({
        dateLimit: Yup.date()
            .transform(function (value, originalValue) {
                if (this.isType(value)) {
                    return value;
                }
                const result = parse(originalValue, "dd/MM/yyyy HH:mm", new Date());
                return result;
            })
            .typeError("please enter valid date")
            .required()
    });

    return (
        <Formik initialValues={initialValues} validationSchema={CustomDateSchema} onSubmit={async (values) => {
            if (datePickerEnable) {
                handleClickEnable();
            } else {
                const data = {
                    id: values.id,
                    date_limit: values.dateLimit && moment(values.dateLimit).format("YYYY-MM-DDTHH:mm")
                }
                await toast.promise(
                    submitAction.mutateAsync(data!),
                    {
                        loading: "Cargando...",
                        success: "Fecha límite guardado",
                        error: "Error",
                    },
                    {
                        success: {
                            icon: <UpdateIcon color="primary" />,
                        },
                    }
                );
                refetch();
                handleClickDisable();
            }
        }} >
            {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1.5} justifyContent="space-between">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={bgLocale}>
                                <DateTimePicker
                                    disabled={datePickerEnable}
                                    label={nameCustom}
                                    inputFormat="dd/MM/yyyy (p)"
                                    value={values.dateLimit}
                                    onChange={(value) => setFieldValue("dateLimit", value)}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                            {datePickerEnable ?
                                <LoadingButton
                                    sx={{ minWidth: 150 }}
                                    variant="contained"
                                    type="submit"
                                    startIcon={<AddCircleIcon />}>Edit</LoadingButton>
                                :
                                <LoadingButton
                                    sx={{ minWidth: 150 }}
                                    variant="contained"
                                    type="submit"
                                    startIcon={<AddCircleIcon />}>Save</LoadingButton>
                            }
                        </Stack>
                        {errors.dateLimit && touched.dateLimit && (
                            <Alert severity="error">{errors.dateLimit}</Alert>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik >
    )
}

export default CustomDatePicker;