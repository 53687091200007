// React
import { useEffect, useMemo, useState } from "react";

//Redux
import { useSelector } from "react-redux";
import { selectStateStudents } from "../../../slices/studentSlice/studentSlice";

// Router
import { Link as LinkRouter, useLocation } from "react-router-dom";

// Interfaces
import { IStudent } from "../../../interfaces/students.interface";

//Services

// Components
import Table from "../../molecules/Table";
import Link from "../../molecules/Link";
import IconEnroll from "../../atoms/icons/IconEnroll";
import ItemFloatingMenu from "../../molecules/ItemFloatingMenu";
import FloatingMenu from "../../molecules/FloatingMenu";
import ScreenLoader from "../../ScreenLoader/ScreenLoader";
import DetailsStudent from "../../../views/Students/ModalDetailsStudent/DetailsStudent/DetailsStudent";
import Modal from "../../Modal/Modal";
import EnrollStudent from "../../../views/Students/ModalEnrollStudent/EnrollStudent/EnrollStudent";
import EditStudent from "../../../views/Students/ModalEditStudent/EditStudent/EditStudent";

import { AppDispatch, useAppDispatch } from "../../../store/store";
import { getStudentById } from "../../../slices/studentSlice/studentByIdSlice";

import "./tableStudent.scss";

const TableStudent = () => {
   const { students, isLoading } = useSelector(selectStateStudents);
   const dispatch: AppDispatch = useAppDispatch();
   let location = useLocation();
   const [idStudent, setIdStudent] = useState<number | null>(null);
   const [openModalDetails, setOpenModalDetails] = useState(false);
   const [openModalEdit, setOpenModalEdit] = useState(false);
   const [openModalEnroll, setOpenModalEnroll] = useState(false);

   useEffect(() => {
      if (idStudent) {
         dispatch(getStudentById(idStudent));
      }
   }, [dispatch, idStudent]);

   useEffect(() => {
      if (parseInt(location.search.split("?id=")[1])) {
         setIdStudent(parseInt(location.search.split("?id=")[1]));
         setOpenModalDetails(true);
      }
   }, [location.search]);

   const columns = useMemo(
      () => [
         {
            Header: "ID",
            accessor: "id",
            widthColumn: 100,
         },
         {
            Header: "Nombre",
            widthColumn: 201,
            accessor: "full_name",
            classColumn: "name-student",
            hasTooltip: true,
            Cell: ({ cell }: any) => <span>{cell.value}</span>,
         },
         {
            Header: "Edad",
            accessor: "age",
            align: "center",
            //minWidthColumn: 120,
            minWidthColumn: 122,
            disableFilters: true,
            Cell: ({ cell }: any) => <span>{cell.value}</span>,
         },
         {
            Header: "Tutor",
            accessor: "guardian.full_name",
            widthColumn: 187,
            classColumn: "name-tutor",
            hasTooltip: true,
            Cell: ({ cell }: any) => <span>{cell.value}</span>,
         },
         {
            Header: "Tutor",
            accessor: "guardian.id",
            hideColumn: true,
            Cell: ({ cell }: any) => <span>{cell.value}</span>,
         },
         {
            Header: "Correo de tutor",
            widthColumn: 209,
            accessor: "guardian.email",
            classColumn: "name-email",
            hasTooltip: true,
            Cell: ({ cell }: any) => <span>{cell.value}</span>,
         },
         {
            Header: "DNI",
            minWidthColumn: 129,
            accessor: "guardian.doc_number",
            classColumn: "name-dni",
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ cell }: any) => <span>{cell.value}</span>,
         },
         {
            Header: "",
            accessor: "action",
            actionWidth: 156,
            headerPagination: true,
            disableSortBy: true,
            disableFilters: true,
            hasTooltip: true,
            tooltipValue: "inscribir",
            Cell: (props: any) => {
               return (
                  <>
                     <div
                        className="table-student__button table-student__button--hover-lineal"
                        onClick={() => {
                           setOpenModalDetails(true);
                           setIdStudent(props.row.values.id);
                           dispatch(getStudentById(props.row.values.id));
                        }}
                     >
                        Ver más
                     </div>
                     {props.row.values.id ? (
                        <div
                           className="table-student__container-tooltip"
                           onClick={() => {
                              setOpenModalEnroll(true);
                              setIdStudent(props.row.values.id);
                           }}
                        >
                           <IconEnroll
                              className={`table-student__button table-student__button--enabled`}
                           />
                           <section className="table-student__tooltip">
                              {props.column.tooltipValue}
                           </section>
                        </div>
                     ) : (
                        <div>
                           <IconEnroll
                              className={`table-student__button table-student__button--disabled`}
                              fill="#bcbcbc"
                           />
                        </div>
                     )}
                     <FloatingMenu
                        items={[
                           <ItemFloatingMenu
                              onClick={() => {
                                 setOpenModalDetails(true);
                                 setIdStudent(props.row.values.id);
                                 dispatch(getStudentById(props.row.values.id));
                              }}
                           >
                              Ver Estudiante
                           </ItemFloatingMenu>,
                           <ItemFloatingMenu
                              onClick={() => {
                                 setOpenModalEnroll(true);
                                 setIdStudent(props.row.values.id);
                              }}
                           >
                              Inscribir
                           </ItemFloatingMenu>,
                           <ItemFloatingMenu
                              onClick={() => {
                                 setOpenModalEdit(true);
                                 setIdStudent(props.row.values.id);
                                 dispatch(getStudentById(props.row.values.id));
                              }}
                           >
                              Editar
                           </ItemFloatingMenu>,
                           <ItemFloatingMenu disabled>
                              Mover de salón
                           </ItemFloatingMenu>,
                           <ItemFloatingMenu disabled>
                              Quitar de salón
                           </ItemFloatingMenu>,
                           <ItemFloatingMenu
                              disabled={
                                 props.row.original.guardian ? false : true
                              }
                           >
                              {props.row.original.guardian ? (
                                 <LinkRouter
                                    to={`/dashboard/guardians?id=${props.row.original.guardian.id}`}
                                 >
                                    Ver Tutor
                                 </LinkRouter>
                              ) : (
                                 <>Ver Tutor</>
                              )}
                           </ItemFloatingMenu>,
                           ,
                        ]}
                     />
                  </>
               );
            },
         },
      ],
      []
   );

   if (isLoading || !students) return <ScreenLoader />;

   return (
      <>
         <Table data={students} columns={columns} />
         {idStudent ? (
            <>
               <Modal
                  id={idStudent}
                  title="Mas Información"
                  fullWidth
                  copyUrl
                  maxWidth="md"
                  openModal={openModalDetails}
                  handleCloseModal={() => setOpenModalDetails(false)}
               >
                  <DetailsStudent studentId={idStudent} />
               </Modal>
               <Modal
                  id={idStudent}
                  title="Inscribir Estudiante"
                  fullWidth
                  maxWidth="md"
                  openModal={openModalEnroll}
                  handleCloseModal={() => setOpenModalEnroll(false)}
               >
                  <EnrollStudent
                     studentId={idStudent}
                     refetch={students /* listStudents */}
                     onClose={() => setOpenModalEnroll(false)}
                  />
               </Modal>
               <Modal
                  id={idStudent}
                  title="Editar Estudiante"
                  fullWidth
                  maxWidth="md"
                  openModal={openModalEdit}
                  handleCloseModal={() => setOpenModalEdit(false)}
               >
                  <EditStudent
                     studentId={idStudent}
                     //refetch={students /* listStudents */}
                     onClose={() => setOpenModalEdit(false)}
                  />
               </Modal>
            </>
         ) : (
            ""
         )}
      </>
   );
};

export default TableStudent;
