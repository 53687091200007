// Interfaces
import { IDiscount } from "../../../../../interfaces/coupon.interface";

// Material UI
import { Grid } from "@mui/material";

// Component
import CreateDiscount from '../../../../../components/Discounts/Discounts';

interface Props {
    discounts?: IDiscount[];
    couponProgramId: number;
    refetch: () => {};
}

const Coupons: React.FC<Props> = ({
    discounts,
    couponProgramId,
}) => {
    return (
        <Grid container rowSpacing={2} alignItems="flex-start">
            <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
                <Grid item xs={12}>
                    <CreateDiscount
                        discounts={discounts}
                        couponProgramId={couponProgramId} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Coupons;