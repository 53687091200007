// Router
import { useNavigate } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../store/store";
import { signIn, getCurrentUser } from "./../../slices/authSlice";

// Formik
import { ErrorMessage, Field, Form, Formik } from "formik";

// Yup
import * as Yup from "yup";

// Etc
import ctcLogo from "../../assets/ctclogo.png";

// Styles
import {
   Wrapper,
   Title,
   Image,
   LoginFormTile,
   CustomTextField,
   CustomButton,
   CustomAlert,
} from "./styled";

const SignInSchema = Yup.object().shape({
   email: Yup.string().email("Invalid email").required("Required"),
   password: Yup.string().required("Required"),
});

interface Props {}

const SignInForm: React.FC<Props> = () => {
   let navigate = useNavigate();
   const dispatch: AppDispatch = useAppDispatch();
   const error = useSelector((state: RootState) => state.auth.error);

   async function handleSubmit(
      email: string,
      password: string,
      setSubmitting: (isSubmitting: boolean) => void
   ) {
      const result = await dispatch(signIn({ email, password }));
      if (signIn.fulfilled.match(result)) {
         const resultUser = await dispatch(getCurrentUser());
         if (getCurrentUser.fulfilled.match(resultUser)) {
            navigate("/");
         }
      }
      setSubmitting(false);
   }

   return (
      <Wrapper>
         <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={SignInSchema}
            onSubmit={(values, { setSubmitting }) => {
               const { email, password } = values;
               handleSubmit(email, password, setSubmitting);
            }}
         >
            {({ isSubmitting }) => (
               <Form>
                  <LoginFormTile>
                     <Image src={ctcLogo} alt="CTC Logo" />
                     <Title>Bienvenido de nuevo</Title>
                     <Field
                        as={CustomTextField}
                        label="Email"
                        name="email"
                        type="email"
                        variant="outlined"
                     />
                     <ErrorMessage
                        name="email"
                        render={() => (
                           <CustomAlert severity="error">
                              Este campo es requerido
                           </CustomAlert>
                        )}
                     />
                     <Field
                        autoComplete="current-password"
                        id="outlined-password-input"
                        label="Contraseña"
                        name="password"
                        type="password"
                        variant="outlined"
                        as={CustomTextField}
                     />
                     <ErrorMessage
                        name="password"
                        render={() => (
                           <CustomAlert severity="error">
                              Este campo es requerido
                           </CustomAlert>
                        )}
                     />
                     {error && (
                        <CustomAlert severity="error">{error.msg}</CustomAlert>
                     )}
                     <CustomButton
                        color="primary"
                        type="submit"
                        variant="contained"
                        disableElevation
                        disabled={isSubmitting}
                     >
                        {isSubmitting ? "Cargando..." : "Ingresar"}
                     </CustomButton>
                  </LoginFormTile>
               </Form>
            )}
         </Formik>
      </Wrapper>
   );
};

export default SignInForm;
