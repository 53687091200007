// Redux
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// Slices
import { authSlice } from "./../slices/authSlice";
import { roomDetailSlice } from "../slices/roomDetailSlice/roomDetailSlice";
import { guardiansSlice } from "../slices/guardianSlice/guardianSlice";
import { guardiansByIdSlice } from "../slices/guardianSlice/guardianByIdSlice";
import { studentsSlice } from "../slices/studentSlice/studentSlice";
import { roomsSlice } from "../slices/roomSlice/roomsSlice";
import { guardiansInvoicesSlice } from "../slices/guardianSlice/guardianInvoicesSlice";
import { studentsByIdSlice } from "../slices/studentSlice/studentByIdSlice";
import { groupsSlice } from "../slices/groupSlice/groupsSlice";
import { groupByIdSlice } from "../slices/groupSlice/groupByIdSlice";
import { individualCoursesSlice } from "../slices/courseSlice/individualCoursesSlice";
import { coursesSlice } from "../slices/courseSlice/coursesSlice";
import { roomsByGroupIdSlice } from "../slices/roomSlice/roomsByGroupIdSlice";
import { enrolmentsSlice } from "../slices/enrolmentSlice/enrolmentsSlice";
import { learningsSlice } from "../slices/learningsSlice/learningsSlice";

export const store = configureStore({
   reducer: {
      auth: authSlice.reducer,
      roomDetail: roomDetailSlice.reducer,
      rooms: roomsSlice.reducer,
      groups: groupsSlice.reducer,
      groupById: groupByIdSlice.reducer,
      individualCourses: individualCoursesSlice.reducer,
      courses: coursesSlice.reducer,
      roomsByGroupId: roomsByGroupIdSlice.reducer,
      students: studentsSlice.reducer,
      studentsById: studentsByIdSlice.reducer,
      enrolments: enrolmentsSlice.reducer,
      guardians: guardiansSlice.reducer,
      guardiansById: guardiansByIdSlice.reducer,
      guardiansInvoices: guardiansInvoicesSlice.reducer,
      learnings: learningsSlice.reducer,
   },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
