import React from "react";

interface Props {
  fill?: string;
  className?: string;
}

const IconPaths: React.FC<Props> = ({ className, fill = "#555555" }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9 16.6152V16.6156C20.9014 17.3412 20.6378 18.0423 20.1587 18.5872C19.6795 19.1321 19.0179 19.4832 18.2981 19.5746C17.5783 19.6661 16.8499 19.4914 16.2498 19.0836C15.6496 18.6757 15.2191 18.0627 15.0392 17.3598L15.0199 17.2846H14.9423H5.61538C4.41783 17.2846 3.26932 16.8089 2.42253 15.9621C1.57573 15.1153 1.1 13.9668 1.1 12.7692C1.1 11.5717 1.57573 10.4232 2.42253 9.57637C3.26932 8.72957 4.41783 8.25385 5.61538 8.25385H14.8461C15.4847 8.25385 16.0971 8.00018 16.5486 7.54865C17.0002 7.09712 17.2538 6.48471 17.2538 5.84615C17.2538 5.20759 17.0002 4.59519 16.5486 4.14366C16.0971 3.69213 15.4847 3.43846 14.8461 3.43846H5.61538C5.43789 3.43846 5.26767 3.36795 5.14217 3.24245C5.01666 3.11694 4.94615 2.94672 4.94615 2.76923C4.94615 2.59174 5.01666 2.42152 5.14217 2.29601C5.26767 2.17051 5.43789 2.1 5.61538 2.1H14.8461C15.8397 2.1 16.7925 2.49468 17.4951 3.19722C18.1976 3.89976 18.5923 4.85261 18.5923 5.84615C18.5923 6.83969 18.1976 7.79254 17.4951 8.49508C16.7925 9.19762 15.8397 9.59231 14.8461 9.59231H5.61538C4.77281 9.59231 3.96475 9.92702 3.36896 10.5228C2.77317 11.1186 2.43846 11.9267 2.43846 12.7692C2.43846 13.6118 2.77317 14.4199 3.36896 15.0157C3.96475 15.6114 4.77281 15.9462 5.61538 15.9462H14.9423H15.0199L15.0392 15.871C15.2191 15.168 15.6496 14.5551 16.2498 14.1472C16.8499 13.7393 17.5783 13.5647 18.2981 13.6561C19.0179 13.7475 19.6795 14.0987 20.1587 14.6436C20.6378 15.1885 20.9014 15.8896 20.9 16.6152Z"
        fill={fill}
        stroke="white"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default IconPaths;
