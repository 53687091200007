// Hot Toast
import { toast } from "react-hot-toast";

// Redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Axios
import { AxiosError } from "axios";

// Interfaces
import {
   IGroupDetail,
   IGroupDetailPag,
} from "../../interfaces/group.interface";

// Services
import {
   getGroupById,
   getGroups,
   postCreateGroup,
} from "../../services/groups";
import { removeStudentOfGroup } from "../../services/students";

export interface IAuthError {
   msg: string;
}

export interface IGroupState {
   group: IGroupDetail | null;
   isLoading: boolean;
   isCompleted: boolean;
   error: IAuthError | null;
   rejected: boolean;
}

const initialState: IGroupState = {
   group: null,
   isLoading: false,
   isCompleted: false,
   error: null,
   rejected: false,
};

export const listGroupById = createAsyncThunk(
   "rooms/listGroupById",
   async (groupId: number, { getState, rejectWithValue }: any) => {
      try {
         const response = await getGroupById(groupId);
         return response?.data;
      } catch (err) {
         if (err instanceof Error) {
            console.log(err.message);
         }
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const deleteGroupStudent = createAsyncThunk(
   "rooms/deleteGroupStudent",
   async (
      {groupId, studentId}: any,
      { getState, rejectWithValue, dispatch, ...others }: any
   ) => {
      try {
         const response = await removeStudentOfGroup(groupId, studentId);
         if (response?.status === 204) {
            toast.success("Estudiante eliminado del grupo");
            return response;
         }
      } catch (err) {
         toast.error("Ha ocurrido un error al eliminar del grupo.");
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const groupByIdSlice = createSlice({
   name: "groupDetail",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      /** listGroupById */
      builder.addCase(listGroupById.pending, (state, _) => {
         state.group = initialState.group;
         state.isLoading = true;
      });
      builder.addCase(listGroupById.fulfilled, (state, action) => {
         state.group = action.payload;
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(listGroupById.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
      /** deleteGroupStudent */
      builder.addCase(deleteGroupStudent.pending, (state, _) => {
         state.isLoading = true;
      });
      builder.addCase(deleteGroupStudent.fulfilled, (state, action) => {
         if (!action.payload) {
            console.log(
               "Delete could not complete, dont exist ID Student",
               action.payload
            );
            return;
         }
         /* const idStudentDeleted = action.payload;
         const rooms = state.rooms.filter((room) => room.id !== idStudentDeleted);
         state.rooms = rooms; */
         state.isLoading = false;
         state.isCompleted = true;
         state.error = null;
      });
      builder.addCase(deleteGroupStudent.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
      
      
      
      
   },
});

export const selectStateGroupById = (state: any) => state.groupById;
