import colors from "../../atoms/Colors";

import "./itemFloatingMenu.scss";

interface Props {
   children: any;
   onClick?: any;
   disabled?: boolean;
   dividerItems?: boolean;
}

const ItemFloatingMenu: React.FC<Props> = ({
   children,
   onClick,
   disabled = false,
   dividerItems = false,
}) => {
   return (
      <li
         className={`item-floating-menu item-floating-menu--${
            disabled ? "disabled" : "active"
         }`}
         onClick={onClick}
         style={{
            borderBottom: dividerItems ? `1px solid ${colors.gris02}` : "none",
         }}
      >
         {children}
      </li>
   );
};

export default ItemFloatingMenu;
