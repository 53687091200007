// React
import { useState } from "react";

// Redux
import { useSelector } from "react-redux";
import { AppDispatch, useAppDispatch } from "../../../../../store/store";
import { RootState } from "../../../../../store/store";

// Hot Toast
import { toast } from "react-hot-toast";

// Interfaces
import { ITeacherRecord } from "../../../../../interfaces/room.interface";

// Material UI
import { Box } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveRoundedIcon from "@material-ui/icons/SaveRounded";

// Components
import { postCreateTeacherAttendance } from "../../../../../services/attendance";
import TableAttendanceTeacher from "../../../../../components/organism/TableAttendanceTeacher";

function AttendanceTeacher() {
   const dispatch: AppDispatch = useAppDispatch();
   const room = useSelector((state: RootState) => state.roomDetail.room);

   const [currentTeacherRecords, setCurrentTeacherRecords] =
      useState<ITeacherRecord>();

   const [isLoadingCreateAttendance, setIsLoadingCreateAttendance] =
      useState(false);

   const createAttendance = async (teacherRecords: ITeacherRecord) => {
      try {
         setIsLoadingCreateAttendance(true);
         const data = await postCreateTeacherAttendance(teacherRecords, room);
         if (data) {
            toast.success("Asistencia actualizada correctamente!");
         } else {
            toast.error("Ha ocurrido un error al actualizar la asistencia.");
         }
         setIsLoadingCreateAttendance(false);
      } catch (error) {
         setIsLoadingCreateAttendance(false);
         console.log("somethings went wrong!", error);
         toast.error("Ha ocurrido un error al actualizar la asistencia.");
      }
   };

   const handleSubmitAttendance = async () => {
      await createAttendance(currentTeacherRecords!);
   };

   return (
      <>
         <TableAttendanceTeacher
            room={room}
            currentTeacherRecords={currentTeacherRecords}
            setCurrentTeacherRecords={setCurrentTeacherRecords}
         ></TableAttendanceTeacher>
         {/* Save */}
         <Box
            display="flex"
            alignContent="flex-end"
            justifyContent="flex-end"
            marginTop={2}
            marginBottom={7}
         >
            <LoadingButton
               loading={
                  isLoadingCreateAttendance /* createAttendance.isLoading */
               }
               variant="contained"
               type="submit"
               onClick={handleSubmitAttendance}
               loadingPosition="start"
               startIcon={<SaveRoundedIcon />}
            >
               {
                  /* createAttendance.isLoading */ isLoadingCreateAttendance
                     ? "Guardando..."
                     : "Guardar"
               }
            </LoadingButton>
         </Box>
      </>
   );
}

export default AttendanceTeacher;
