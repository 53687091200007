// React
import { memo, useEffect, useState } from "react";

// Custom Hooks
import { useFocus } from "../../../../../../../hooks/useFocus";

// Redux
import { updateStudent as updateStudentRedux } from "../../../../../../../slices/roomDetailSlice/roomDetailSlice";
import { AppDispatch, useAppDispatch } from "../../../../../../../store/store";

// React Query
import { useMutation } from "react-query";

// Yup
import * as Yup from "yup";

// Formik
import { Formik, Form } from "formik";

// API
import { PATCH } from "../../../../../../../services/api/api";

// Hot Toast
import { toast } from "react-hot-toast";

// Interfaces
import { IStudent } from "../../../../../../../interfaces/room.interface";

// Material UI
import UpdateIcon from "@mui/icons-material/Update";
import { Alert, Divider, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { patchUpdateStudent } from "../../../../../../../services/students";

interface Props {
  studentId: number;
  student: IStudent;
  onClose: () => void;
}

interface EmailAndPassword {
  email: string;
  password: string;
  password_google: string;
}

const UpdateCurrentUserDataSchema = Yup.object().shape({
  email: Yup.string().email("Email invalido").required("Este campo es requerido"),
  password: Yup.string().required("Este campo es requerido"),
});

const EditStudent: React.FC<Props> = ({ studentId, student, onClose }) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [input1Ref, setInput1Focus] = useFocus();
  const [isLoadingUpdateStudent, setIsLoadingUpdateStudent] = useState(false);

  const updateStudent = async ({ email, password, password_google }: EmailAndPassword) => {
    try {
      setIsLoadingUpdateStudent(true);
      const data = await patchUpdateStudent({ email, password, password_google }, studentId);
      if (data) {
        toast.success("Estudiante editado correctamente!");
      } else {
        toast.error(
          "Ha ocurrido un error al editar el estudiante."
        );
      }
      setIsLoadingUpdateStudent(false);
    } catch (error) {
      setIsLoadingUpdateStudent(false);
      console.log("somethings went wrong!", error);
      toast.error(
        "Ha ocurrido un error al editar el estudiante."
      );
    }
  };

  /* const updateStudent = useMutation(
    ({ email, password, password_google }: EmailAndPassword) =>
      PATCH(`/students/${studentId}/`, { email, password, password_google })
  ); */

  const initialValues = {
    email: student.email,
    password: student.password,
    password_google: student.password_google,
  };

  useEffect(() => {
    setInput1Focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UpdateCurrentUserDataSchema}
      onSubmit={async (values) => {
        await updateStudent(values);
        /* await toast.promise(
          //updateStudent.mutateAsync(values).then((res) => {
            //dispatch(updateStudentRedux(res.data));
          //}),
          updateStudent(values),
          {
            loading: "Cargando...",
            success: "Datos actualizados",
            error: "Error",
          },
          {
            success: {
              icon: <UpdateIcon color="primary" />,
            },
          }
        ); */

        onClose();
      }}
    >
      {({ errors, touched, values, handleChange }) => (
        <Form>
          <Grid container columnSpacing={0} rowSpacing={2} alignItems="flex-start">
            {/* Student */}
            <Grid item container columnSpacing={0} rowSpacing={3} xs={12}>
              {/* Email */}
              <Grid item xs={12}>
                <TextField
                  inputRef={input1Ref}
                  fullWidth
                  id="email"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && touched.email && (
                  <Alert severity="error">{errors.email}</Alert>
                )}
              </Grid>

              {/* Password */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="password"
                  label="Contraseña Moodle"
                  variant="outlined"
                  value={values.password}
                  onChange={handleChange}
                />
                {errors.password && touched.password && (
                  <Alert severity="error">{errors.password}</Alert>
                )}
              </Grid>

              {/* Google Password */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="password_google"
                  label="Contraseña Google"
                  variant="outlined"
                  value={values.password_google}
                  onChange={handleChange}
                />
                {errors.password_google && touched.password_google && (
                  <Alert severity="error">{errors.password_google}</Alert>
                )}
              </Grid>
            </Grid>

            {/* Edit */}
            <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={isLoadingUpdateStudent /* updateStudent.isLoading */}
                  variant="contained"
                  type="submit"
                  startIcon={<UpdateIcon />}
                >
                  Editar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default memo(EditStudent);
