
import { useState } from "react";
import OpenModal from "../../components/Modal/OpenModal/OpenModal";
import { useSearchParams } from "react-router-dom";
import { addParams, deleteParams } from "../../helpers/router.helpers";
import Modal from "../../components/Modal/Modal";
import EditAgeGroup from './EditAgeGroup';

interface Props {
    ageGroupId: number;
    ageGroupName: string;
    ageGroupMin: number;
    ageGroupMax: number;
    autoOpen?: boolean;
}

const ModalEdit: React.FC<Props> = ({ ageGroupId, ageGroupName, ageGroupMin, ageGroupMax, autoOpen = false }) => {

    const [params, setParams] = useSearchParams();
    const [openModal, setOpenModal] = useState(autoOpen);

    const handleOpenModal = () => {
        addParams(params, setParams, "id", ageGroupId.toString());
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        deleteParams(params, setParams, "id");
        setOpenModal(false);
    };

    return (
        <>
            {!autoOpen && (
                <OpenModal
                    option="edit"
                    placement="left"
                    handleOpenModal={handleOpenModal}
                />
            )}

            <Modal
                id={ageGroupId}
                title="Editar Rango de edad"
                copyUrl
                fullWidth
                maxWidth="md"
                openModal={openModal}
                handleCloseModal={handleCloseModal}
            >
                <EditAgeGroup
                    ageGroupId={ageGroupId}
                    ageGroupName={ageGroupName}
                    ageGroupMin={ageGroupMin}
                    ageGroupMax={ageGroupMax}
                    onClose={handleCloseModal}
                />
            </Modal>
        </>
    );
}

export default ModalEdit;