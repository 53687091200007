// React
import { useState } from "react";

// Material UI
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  label: string;
  values: Task[];
  enabled: boolean;
  setFieldValue: any;
  to: string;
}

interface Task {
  title: string;
  description: string;
}

const SuperToDo: React.FC<Props> = ({
  label,
  values,
  enabled,
  setFieldValue,
  to,
}) => {
  const [tasks, setTasks] = useState<Task[]>(
    values && values.length > 0 ? values : []
  );
  const [task, setTask] = useState<Task>({
    title: "",
    description: "",
  });

  const handleAddTask = (task: Task) => {
    task.title.length > 0 &&
      task.description.length > 0 &&
      task &&
      setTasks([...tasks, task]);
    setTask({ title: "", description: "" });

    setFieldValue(to, [...tasks, task]);
  };

  const handleDeleteTask = (deleteTask: Task) => {
    const filteredArray = tasks.filter((task) => task.title !== deleteTask.title);
    setTasks(filteredArray);

    setFieldValue(to, filteredArray);
  };

  return (
    <Grid container rowSpacing={1} alignItems="flex-start">
      {/* Header */}
      <Grid item xs={12}>
        <Divider textAlign="left">
          <Chip label={label} />
        </Divider>
      </Grid>

      {/* Main */}
      <Grid item xs={12}>
        <List
          sx={{
            width: "100%",
            padding: 0,
            maxHeight: "150px",
            overflowY: "scroll",
          }}
        >
          {tasks.map((task: Task, idx) => {
            const labelId = `checkbox-list-label-${task.title}`;

            return (
              <ListItem
                disabled={enabled}
                key={idx}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => handleDeleteTask(task)}
                    disabled={enabled}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
                dense
              >
                <ListItemText
                  id={labelId}
                  primary={task.title}
                  secondary={task.description}
                />
              </ListItem>
            );
          })}
        </List>
      </Grid>

      {/* Btn Add ToDo */}
      <Grid item xs={12}>
        <Stack direction="row" spacing={1.5} justifyContent="space-between">
          <Stack
            direction="column"
            spacing={1.5}
            sx={{ minWidth: "65%", maxWidth: "65%" }}
          >
            <TextField
              fullWidth
              id="title"
              label="Add title"
              placeholder="Add title"
              value={task.title}
              onChange={(e) =>
                setTask({
                  ...task,
                  title: e.target.value,
                })
              }
              variant="outlined"
              disabled={enabled}
              size="small"
              type="text"
              onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
            />

            <TextareaAutosize
              id="description"
              aria-label="minimum height"
              placeholder="Add description"
              minRows={8.3}
              value={task.description}
              onChange={(e) =>
                setTask({
                  ...task,
                  description: e.target.value,
                })
              }
              disabled={enabled}
              style={{ width: "99%", minWidth: "99%", maxWidth: "99%" }}
              onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
            />
          </Stack>

          <LoadingButton
            sx={{ width: "50%" }}
            variant="contained"
            onClick={() => handleAddTask(task)}
            disabled={enabled}
            startIcon={<AddCircleIcon />}
          >
            Agregar
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SuperToDo;
