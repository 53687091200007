import React from "react";

import "./tooltip.scss";

interface Props {
   children: any;
   title: string;
   left?: string;
   right?: string;
   top?: string;
   bottom?: string;
}

const Tooltip: React.FC<Props> = ({
   children,
   title,
   left,
   right,
   top = "-2rem",
   bottom,
}) => {
   return (
      <div className="tooltip">
         <div
            className="tooltip__title"
            style={{ left: left, top: top, bottom: bottom, right: right }}
         >
            {title}
         </div>
         {children}
      </div>
   );
};

export default Tooltip;
