import React from "react";
import colors from "../Colors";

interface Props {
   fill?: string;
   className?: string;
}

const IconAlert: React.FC<Props> = ({
   className,
   fill = colors.colorAlertRed,
}) => {
   return (
      <svg
         className={className}
         width="47"
         height="48"
         viewBox="0 0 47 48"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M23.5 26.3337C24.1611 26.3337 24.7157 26.1097 25.1637 25.6617C25.6101 25.2152 25.8333 24.6614 25.8333 24.0003V14.6087C25.8333 13.9475 25.6101 13.4031 25.1637 12.9753C24.7157 12.5475 24.1611 12.3337 23.5 12.3337C22.8389 12.3337 22.2851 12.5569 21.8387 13.0033C21.3907 13.4513 21.1667 14.0059 21.1667 14.667V24.0587C21.1667 24.7198 21.3907 25.2642 21.8387 25.692C22.2851 26.1198 22.8389 26.3337 23.5 26.3337ZM23.5 35.667C24.1611 35.667 24.7157 35.443 25.1637 34.995C25.6101 34.5485 25.8333 33.9948 25.8333 33.3337C25.8333 32.6726 25.6101 32.118 25.1637 31.67C24.7157 31.2235 24.1611 31.0003 23.5 31.0003C22.8389 31.0003 22.2851 31.2235 21.8387 31.67C21.3907 32.118 21.1667 32.6726 21.1667 33.3337C21.1667 33.9948 21.3907 34.5485 21.8387 34.995C22.2851 35.443 22.8389 35.667 23.5 35.667ZM23.5 47.3337C20.2722 47.3337 17.2389 46.7208 14.4 45.495C11.5611 44.2708 9.09167 42.6087 6.99167 40.5087C4.89167 38.4087 3.22956 35.9392 2.00533 33.1003C0.779557 30.2614 0.166668 27.2281 0.166668 24.0003C0.166668 20.7725 0.779557 17.7392 2.00533 14.9003C3.22956 12.0614 4.89167 9.59199 6.99167 7.49199C9.09167 5.39199 11.5611 3.7291 14.4 2.50333C17.2389 1.2791 20.2722 0.666992 23.5 0.666992C26.7278 0.666992 29.7611 1.2791 32.6 2.50333C35.4389 3.7291 37.9083 5.39199 40.0083 7.49199C42.1083 9.59199 43.7704 12.0614 44.9947 14.9003C46.2204 17.7392 46.8333 20.7725 46.8333 24.0003C46.8333 27.2281 46.2204 30.2614 44.9947 33.1003C43.7704 35.9392 42.1083 38.4087 40.0083 40.5087C37.9083 42.6087 35.4389 44.2708 32.6 45.495C29.7611 46.7208 26.7278 47.3337 23.5 47.3337Z"
            fill={fill}
         />
      </svg>
   );
};

export default IconAlert;
