// Axios
import axios, { AxiosResponse } from "axios";

// Helpers
import { getAccessCookie } from "../helpers/cookies.helpers";
import { ICreateRoom, IGroupRooms } from "../interfaces/group.interface";
import { IRoom } from "../interfaces/room.interface";

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? ROOMS
export const getRooms = async (userId: number) => {
   const { data } = await axios.get<IRoom[]>(
      `${instanceBaseURL}/teachers/${userId}/rooms/?no-page`,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return data;
};

export const getRoomsByGroupId = async (groupId: any) => {
   try {
      const response = await axios.get<IGroupRooms>(
         `${instanceBaseURL}/groups/${groupId}/rooms`,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return response;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const updateZoomLink = async (zoom_meeting_id: string) => {
   try {
      const { data } = await axios.patch(
         `${instanceBaseURL}/meeting/${zoom_meeting_id}/refresh-start/`,
         { zoom_meeting_id },
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};
//? mostrar respuesta 404 no hay licencia http://localhost:3000/dashboard/groups/1719/rooms
export const removeRoom = async (roomId: any) => {
   try {
      const response = await axios.delete(
         `${instanceBaseURL}/rooms/delete/${roomId}/`,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return response;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const postCreateRoom = async <T>(
   newRoom: ICreateRoom
): Promise<AxiosResponse<T>> => {
   const response: AxiosResponse = await axios.post<T>(
      `${instanceBaseURL}/rooms/`,
      newRoom,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return response;
};

export const postCreateRoomWithManualLinkZoom = async (
   newRoom: ICreateRoom
) => {
   const response = await axios.post(
      `${instanceBaseURL}/rooms/manual/`,
      newRoom,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return response;
};

export const patchEditRoom = async (roomId: any, room: any) => {
   try {
      const { data } = await axios.patch(
         `${instanceBaseURL}/rooms/${roomId}/`,
         room,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const updateAttendanceDate = async (
   sessionId: number,
   start_date: string
) => {
   try {
      const response = await axios.put(
         `${instanceBaseURL}/room-sessions/${sessionId}/`,
         { start_date },
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return response;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};
