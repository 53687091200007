// Axios
import axios, { AxiosResponse } from "axios";

// Helpers
import { getAccessCookie } from "../helpers/cookies.helpers";
import { IGuardian, IGuardianDetails, UpdateInvoice } from "../interfaces/guardian.interface";

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? GUARDIANS
export const getGuardians = async () => {
   const { data } = await axios.get<IGuardian[]>(
      `${instanceBaseURL}/users/?no-page`,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return data;
};

export const getGuardianForId = async (guardianId:any) => {
   const { data } = await axios.get<IGuardian>(
      `${instanceBaseURL}/users/${guardianId}/`,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return data;
};

export const getGuardianInvoices = async (guardianId: number) => {
   const { data } = await axios.get<IGuardianDetails>(
      `${instanceBaseURL}/users/${guardianId}/students/invoices/`,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return data;
};

export const patchEditGuardian = async (guardian: any, guardianId: any) => {
   try {
      const { data } = await axios.patch(
         `${instanceBaseURL}/users/${guardianId}/`,
         guardian,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const patchEditGuardianInvoice = async (invoiceId: any, invoice: UpdateInvoice) => {
   try {
      const { data } = await axios.patch(
         `${instanceBaseURL}/invoices/${invoiceId}/`,
         invoice,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};
