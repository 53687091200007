// React Query
import { useMutation } from "react-query";

// Yup
import * as Yup from "yup";

// Formik
import { Formik, Form } from "formik";

// API
import { POST } from "../../../../services/api/api";

// Hot Toast
import { toast } from "react-hot-toast";

// Material UI
import Grid from "@mui/material/Grid";
import { LoadingButton } from "@mui/lab";
import { Alert, Divider, TextField } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const SignupSchema = Yup.object().shape({
  first_name: Yup.string(),
  last_name: Yup.string(),
  username: Yup.string(),
  phone_number: Yup.string().required("Teléfono requerido"),
  email: Yup.string()
    .email("Email invalido!")
    .min(2, "Muy corto!")
    .required("Email requerido"),
  password: Yup.string().min(2, "Muy corto!").required("Contraseña requerido"),
  TyC_accepted: Yup.boolean()
    .required("Debe de aceptar los términos y condiciones")
    .oneOf([true], "Debe de aceptar los términos y condiciones"),
});

interface Props {
  refetch: () => {};
  onClose: () => void;
}

const CreateTeacher: React.FC<Props> = ({ refetch, onClose }) => {
  const createTeacher = useMutation(
    (teacher: any) => POST(`/account/register-teacher/`, teacher),
    {
      retry: false,
    }
  );

  const initialValues = {
    first_name: "",
    last_name: "",
    username: "",
    phone_number: "",
    email: "",
    password: "",
    TyC_accepted: true,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={async (values) => {
        const data = {
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.phone_number,

          username: values.email,
          email: values.email,

          password: values.password,
          TyC_accepted: values.TyC_accepted,
        };

        await toast.promise(
          createTeacher.mutateAsync(data!),
          {
            loading: "Cargando...",
            success: `${data.first_name} Creado`,
            error: "Error",
          },
          {
            success: {
              icon: <AddCircleIcon color="primary" />,
            },
          }
        );

        refetch();
        onClose();
      }}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Grid container rowGap={2}>
            {/* Details */}
            <Grid item container columnSpacing={2} rowGap={3} xs={12}>
              {/* First_name */}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  id="first_name"
                  label="Nombre"
                  variant="outlined"
                  value={values.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && touched.first_name && (
                  <Alert severity="error">{errors.first_name}</Alert>
                )}
              </Grid>

              {/* Last_name */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="last_name"
                  label="Apellido"
                  variant="outlined"
                  value={values.last_name}
                  onChange={handleChange}
                />
                {errors.last_name && touched.last_name && (
                  <Alert severity="error">{errors.last_name}</Alert>
                )}
              </Grid>

              {/* Email */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Correo"
                  variant="outlined"
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && touched.email && (
                  <Alert severity="error">{errors.email}</Alert>
                )}
              </Grid>

              {/* Password */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="password"
                  label="Contraseña"
                  variant="outlined"
                  value={values.password}
                  onChange={handleChange}
                />
                {errors.password && touched.password && (
                  <Alert severity="error">{errors.password}</Alert>
                )}
              </Grid>

              {/* Phone_number */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="phone_number"
                  label="Teléfono"
                  variant="outlined"
                  value={values.phone_number}
                  onChange={handleChange}
                />
                {errors.phone_number && touched.phone_number && (
                  <Alert severity="error">{errors.phone_number}</Alert>
                )}
              </Grid>
            </Grid>

            {/* Create */}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={createTeacher.isLoading}
                  variant="contained"
                  type="submit"
                  startIcon={<AddCircleIcon />}
                >
                  Crear
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateTeacher;
