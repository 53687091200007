// Axios
import axios, { AxiosResponse } from "axios";

// Helpers
import { getAccessCookie } from "../helpers/cookies.helpers";
import { IEnrollStudent, IEnrolments } from "../interfaces/enrolment.interface";
import { EmailAndPassword, IStudent } from "../interfaces/students.interface";

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? ENROLMENTS
export const getEnrolments = async () => {
   const { data } = await axios.get<IEnrolments>(
      `${instanceBaseURL}/enrolments/form/`,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return data;
};

export const postEnrollStudent = async (enrollStudent: IEnrollStudent) => {
   try {
      const response = await axios.post(
         `${instanceBaseURL}/enrolments/form/`,
         enrollStudent,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return response;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const patchCancelEnrolment = async (enrollmentId: any) => {
   try {
      const { data } = await axios.patch(
         `${instanceBaseURL}/enrolments/${enrollmentId}/`,
         { state: "cancel" },
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};
