import React from "react";
import { QueryObserver, useQueryClient, useMutation } from "react-query";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import toast from "react-hot-toast";
import UpdateIcon from "@mui/icons-material/Update";
import { LoadingButton } from "@mui/lab";
import { Alert, TextField, Grid } from "@mui/material";
import { PATCH } from "../../services/api/api";

interface Props {
    ageGroupId: number;
    ageGroupName: string;
    ageGroupMin: number;
    ageGroupMax: number;
    onClose: () => void;
}

const EditAgeGroup: React.FC<Props> = ({ ageGroupId, ageGroupName, ageGroupMin, ageGroupMax, onClose }) => {

    const queryClient = useQueryClient();
    const { refetch: refetchGroupAges } = new QueryObserver(queryClient, {
        queryKey: "group-ages",
    });

    const updateAgeGroup = useMutation((data: any) => PATCH(`/base/group-age/${data.id}/`, data), {
        retry: false,
    });

    const AgeGroupSchema = Yup.object().shape({
        name: Yup.string().required("Nombre es requerido"),
        min_age: Yup.number().required("Edad mínima es requerido"),
        max_age: Yup.number().required("Edad máxima es requerido")
    })

    const initialValues = {
        id: ageGroupId,
        name: ageGroupName,
        min_age: ageGroupMin,
        max_age: ageGroupMax
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={AgeGroupSchema}
            onSubmit={async (values, { resetForm }) => {
                const data = {
                    id: values.id,
                    name: values.name,
                    min_age: Number(values.min_age),
                    max_age: Number(values.max_age),
                }
                await toast.promise(
                    updateAgeGroup.mutateAsync(data),
                    {
                        loading: "Cargando...",
                        success: "Rango de edad actualizado",
                        error: "Error",
                    },
                    {
                        success: {
                            icon: <UpdateIcon color="primary" />,
                        },
                    }
                );
                refetchGroupAges();
                resetForm();
                onClose();
            }}
        >
            {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form>
                    <Grid container rowSpacing={2} alignItems="flex-start">
                        <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
                            <Grid item xs={4}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    placeholder="Name"
                                    variant="outlined"
                                    type="text"
                                    value={values.name}
                                    onChange={handleChange}
                                />
                                {errors.name && touched.name && (
                                    <Alert severity="error">{errors.name}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    id="min_age"
                                    label="Min Age"
                                    placeholder="Min Age"
                                    variant="outlined"
                                    type="number"
                                    value={values.min_age}
                                    onChange={handleChange}
                                />
                                {errors.min_age && touched.min_age && (
                                    <Alert severity="error">{errors.min_age}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    id="max_age"
                                    label="Max Age"
                                    placeholder="Max Age"
                                    variant="outlined"
                                    type="number"
                                    value={values.max_age}
                                    onChange={handleChange}
                                />
                                {errors.max_age && touched.max_age && (
                                    <Alert severity="error">{errors.max_age}</Alert>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <LoadingButton
                                    fullWidth
                                    loading={updateAgeGroup.isLoading}
                                    variant="contained"
                                    type="submit"
                                    startIcon={<UpdateIcon />}
                                >
                                    Save
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>

                </Form>
            )
            }
        </Formik>
    )
}

export default EditAgeGroup;