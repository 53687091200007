// React
import { useEffect, useState } from "react";

// Redux
import { useSelector } from "react-redux";
import { addComment } from "../../../../../slices/roomDetailSlice/roomDetailSlice";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../../store/store";

// Interfaces
import { INewComment } from "./interfaces";
import {
  IStudentRecord,
  IComment,
} from "../../../../../interfaces/room.interface";

// React Query
import { useMutation } from "react-query";

// API
import { POST, PATCH } from "../../../../../services/api/api";

// Generics
import { dict } from "../../../../../types/generics/generics";

// Formik
import { ErrorMessage, Field, Form, Formik } from "formik";

// Yup
import * as Yup from "yup";

// Material UI
import {
  Box,
  Stack,
  Grid,
  Divider,
  TextField,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Typography,
  FormControl,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import TouchAppRoundedIcon from "@material-ui/icons/TouchAppRounded";
import InboxRoundedIcon from "@material-ui/icons/InboxRounded";

// Styles
import { CardStudentsComments, ListComments, ListStudents } from "./styles";

// Components
import { AlertSnackbar } from "../../../../../components/common/AlertSnackbar/AlertSnackbar";
import CommentListItem from "./CommentListItem/CommentListItem";
import StudentListItem from "./StudentListItem/StudentListItem";
import { patchCommentStateMutation, postCommentMutation } from "../../../../../services/comments";
import toast from "react-hot-toast";

interface Props {
  refetch: () => {};
}

const CommentSchema = Yup.object().shape({
  body: Yup.string().max(500).required("Required"),
});

const EmptyAdvice = ({ message }: { message: string }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <Box>
        <Typography variant="h6" gutterBottom component="div">
          {message}
        </Typography>
      </Box>
      <InboxRoundedIcon fontSize="large" color="disabled" />
    </Box>
  );
};

const SelectAdvice = ({ message }: { message: string }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100%"
    >
      <Box>
        <Typography variant="h6" gutterBottom component="div">
          {message}
        </Typography>
      </Box>
      <TouchAppRoundedIcon fontSize="large" color="disabled" />
    </Box>
  );
};

const Comments: React.FC<Props> = ({ refetch }) => {
  const dispatch: AppDispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const room = useSelector((state: RootState) => state.roomDetail.room);

  const [studentsRecords, setStudentRecords] = useState<IStudentRecord[]>([]);
  const [studentSelected, setStudentSelected] =
    useState<IStudentRecord | null>();
  const [stateUpdated, setStateUpdated] = useState(0);

  /* const [isLoadingCommentMutation, setIsLoadingCommentMutation] = useState(false);
  const [isLoadingCommentStateMutation, setIsLoadingCommentStateMutation] = useState(false);

  const commentMutation = async (comment: dict) => {
    try {
      setIsLoadingCommentMutation(true);
      const { data } = await postCommentMutation(comment);
      if (data) {
        toast.success("Comentario agregado correctamente!");
      }
      setIsLoadingCommentMutation(false);
    } catch (error) {
      setIsLoadingCommentMutation(false);
      console.log("somethings went wrong!", error);
      toast.error(
        "Ha ocurrido un error al comentar."
      );
    }
  };

  const commentStateMutation = async (state: dict) => {
    try {
      setIsLoadingCommentStateMutation(true);
      const { data } = await patchCommentStateMutation(studentSelected, state);
      if (data) {
        toast.success("Comentario actualizado correctamente!");
      }
      setIsLoadingCommentStateMutation(false);
    } catch (error) {
      setIsLoadingCommentStateMutation(false);
      console.log("somethings went wrong!", error);
      toast.error(
        "Ha ocurrido un error al actualizar la asistencia."
      );
    }
  }; */

  const commentMutation = useMutation((comment: dict) =>
    POST(`/comments/`, comment)
  );

  const commentStateMutation = useMutation((state: dict) =>
    PATCH(
      `/comments/${
        studentSelected?.comments[studentSelected?.comments?.length - 1].id
      }/`,
      state
    )
  );

  useEffect(() => {
    setStudentRecords(room ? room?.students_records : []);
    const studentIndex: number = room
      ? room?.students_records?.findIndex(
          (record) => record.student.id === studentSelected?.student.id
        )
      : -1;

    if (studentIndex !== -1) {
      setStudentSelected(room?.students_records[studentIndex]);
    }

    // eslint-disable-next-line
  }, [room]);

  const handleSendComment = async (values: dict) => {
    values.to_student = studentSelected?.student.id || 0;
    try {
      const result = await commentMutation.mutateAsync(values);
      //const result = await commentMutation(values);

      const newComment: IComment = {
        body: result.data.body,
        created_at: result.data.created_at,
        from_user: {
          id: user?.id || 0,
          full_name: `${user?.first_name} ${user?.last_name}`,
        },
        id: result.data.id,
        is_public: result.data.is_public,
        subject: result.data.subject,
        to_student: result.data.to_student,
        read_by_teacher: false,
      };

      dispatch(addComment(newComment));

      values.body = "";
      values.subject = "general";
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpdateStateComment = async () => {
    try {
      const result = await commentStateMutation.mutateAsync({
        read_by_teacher: true,
      });
      /* const result = await commentStateMutation({
        read_by_teacher: true,
      }); */
      setStateUpdated((prev) => prev + 1);
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CardStudentsComments container>
      {/* Students list */}
      <Grid item xs={3}>
        <ListStudents>
          {studentsRecords.length > 0 && (
            <>
              {studentsRecords
                ?.filter(
                  (studentRecords: any) =>
                    studentRecords?.comments[
                      studentRecords?.comments?.length - 1
                    ]?.read_by_teacher === false
                )
                .sort((a: any, b: any) =>
                  a.student?.full_name?.toLowerCase() >
                  b.student?.full_name?.toLowerCase()
                    ? 1
                    : -1
                )
                .map((studentRecords) => (
                  <StudentListItem
                    hasComments={
                      studentRecords?.comments[
                        studentRecords?.comments?.length - 1
                      ]?.read_by_teacher === false
                        ? true
                        : false
                    }
                    key={studentRecords.student.id}
                    studentAge={studentRecords.student.age}
                    studentCountry={
                      studentRecords.student.country
                        ? studentRecords.student.country
                        : "PE"
                    }
                    studentFullName={studentRecords.student.full_name}
                    selected={
                      studentRecords.student.id === studentSelected?.student.id
                    }
                    onClick={() => setStudentSelected(studentRecords)}
                  />
                ))}
            </>
          )}
          <>
            {studentsRecords
              ?.filter(
                (studentRecords: any) =>
                  studentRecords?.comments[studentRecords?.comments?.length - 1]
                    ?.read_by_teacher === true
              )
              .sort((a: any, b: any) =>
                a.student?.full_name?.toLowerCase() >
                b.student?.full_name?.toLowerCase()
                  ? 1
                  : -1
              )
              .map((studentRecords) => (
                <StudentListItem
                  hasComments={
                    studentRecords?.comments[
                      studentRecords?.comments?.length - 1
                    ]?.read_by_teacher === true
                      ? false
                      : true
                  }
                  key={studentRecords.student.id}
                  studentAge={studentRecords.student.age}
                  studentCountry={
                    studentRecords.student.country
                      ? studentRecords.student.country
                      : "PE"
                  }
                  studentFullName={studentRecords.student.full_name}
                  selected={
                    studentRecords.student.id === studentSelected?.student.id
                  }
                  onClick={() => setStudentSelected(studentRecords)}
                />
              ))}
          </>
          <>
            {studentsRecords
              ?.filter(
                (studentRecords: any) => studentRecords?.comments?.length <= 0
              )
              .sort((a: any, b: any) =>
                a.student?.full_name?.toLowerCase() >
                b.student?.full_name?.toLowerCase()
                  ? 1
                  : -1
              )
              ?.map((studentRecords) => (
                <StudentListItem
                  hasComments={studentRecords?.comments?.length <= 0 && false}
                  key={studentRecords?.student.id}
                  studentAge={studentRecords?.student.age}
                  studentCountry={
                    studentRecords?.student.country
                      ? studentRecords?.student.country
                      : "PE"
                  }
                  studentFullName={studentRecords?.student.full_name}
                  selected={
                    studentRecords?.student.id === studentSelected?.student.id
                  }
                  onClick={() => setStudentSelected(studentRecords)}
                />
              ))}
          </>
        </ListStudents>
      </Grid>

      {/* Comments list */}
      <Grid item xs={9} display="flex" flexDirection="column">
        <Box
          flexGrow={1}
          position="relative"
          overflow="auto"
          width="100%"
          style={{
            maxHeight: "330px",
            height: "330px",
            overflow: "hidden",
          }}
        >
          <Box
            position="relative"
            overflow="auto"
            width="100%"
            style={{
              height:
                user?.permission?.name === "Teacher"
                  ? "calc(100% - 45px)"
                  : "100%",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {studentSelected ? (
              studentSelected.comments.length ? (
                <>
                  <Box>
                    <ListComments>
                      {studentSelected.comments.map((comment, idx) => (
                        <CommentListItem
                          key={idx}
                          commentBody={comment.body}
                          commentCreatedAt={comment.created_at}
                          commentFromFullName={comment.from_user.full_name}
                          commentSubject={comment.subject}
                          isReaded={comment?.read_by_teacher}
                        />
                      ))}
                    </ListComments>
                  </Box>
                </>
              ) : (
                <EmptyAdvice message="El estudiante aún no tiene comentarios" />
              )
            ) : (
              <SelectAdvice message="Seleccione a un estudiante para ver sus comentarios" />
            )}
          </Box>
          {user?.permission?.name === "Teacher" && studentSelected && (
            <Box
              position="relative"
              overflow="auto"
              width="100%"
              style={{
                minHeight: "45px",
                background: "#F6F6F6",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              {studentSelected?.comments[studentSelected?.comments?.length - 1]
                ?.read_by_teacher === false ? (
                <Button
                  style={{
                    color: "#313131",
                    marginRight: "8px",
                    borderRadius: "10px",
                    padding: "3px 16px",
                  }}
                  variant="text"
                  onClick={handleUpdateStateComment}
                  startIcon={<CheckCircleRoundedIcon />}
                >
                  Marcar como leído
                </Button>
              ) : (
                <Typography
                  style={{
                    fontStyle: "italic",
                    color: "#555555",
                    marginRight: "14px",
                  }}
                >
                  Todos los comentarios leídos.
                </Typography>
              )}
            </Box>
          )}
        </Box>

        <Box flexGrow={1} position="relative" overflow="auto" width="100%">
          {studentSelected && (
            <>
              <Divider />
              <Grid container style={{ padding: "20px" }}>
                {/* Form */}
                <Formik
                  initialValues={
                    {
                      body: "",
                      from_user: user?.id || 0,
                      is_public: false,
                      room: room?.id || 0,
                      subject: "general",
                      to_student: 0,
                    } as INewComment
                  }
                  validationSchema={CommentSchema}
                  onSubmit={handleSendComment}
                >
                  <Form style={{ width: "100%" }}>
                    <Grid container>
                      <Grid item xs={9} gap={5}>
                        <Box marginBottom={1}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Asunto
                            </InputLabel>
                            <Field
                              as={Select}
                              labelId="demo-simple-select-label"
                              id="subject"
                              name="subject"
                              label="Asunto"
                            >
                              <MenuItem value="general" selected>
                                General
                              </MenuItem>
                              <MenuItem value="behavior">
                                Comportamiento
                              </MenuItem>
                              <MenuItem value="credentials">
                                Credenciales
                              </MenuItem>
                              <MenuItem value="sac">Comentarios SAC</MenuItem>
                            </Field>
                          </FormControl>
                        </Box>
                        <Field
                          id="body-input"
                          label="Comentario"
                          name="body"
                          variant="outlined"
                          as={TextField}
                          multiline
                          rows={3}
                          fullWidth
                        />
                        <ErrorMessage
                          name="body"
                          // render={() => <>Este campo es requerido</>}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disableElevation
                            disabled={commentMutation.isLoading}
                            startIcon={<SendIcon />}
                          >
                            {commentMutation.isLoading
                              ? "Cargando..."
                              : "Comentar"}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                </Formik>

                {/* Alerts */}
                {!commentMutation.data || (
                  <AlertSnackbar
                    message="Comentario creado"
                    severity="success"
                  />
                )}
                {!commentMutation.error || (
                  <AlertSnackbar
                    message="Error al crear comentario"
                    severity="error"
                  />
                )}
              </Grid>
            </>
          )}
        </Box>
      </Grid>
    </CardStudentsComments>
  );
};

export default Comments;
