import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { GET_SERVERLESS, GET } from "../../services/api/api";
import ModalEditInvoice from "./ModalEditInvoice/ModalEditInvoice";

// Moment
import moment from "moment";
import "moment/locale/es";

// Material UI
import {
   DataGrid,
   GridColDef,
   GridColumns,
   GridValueGetterParams,
   GridRenderCellParams,
   GridSortModel,
} from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { Chip, Stack } from "@mui/material";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay/CustomLoadingOverlay";
import ScreenLoader from "../../components/ScreenLoader/ScreenLoader";
import CustomQuickSearchToolbar from "../../components/CustomQuickSearchToolbar/CustomQuickSearchToolbar";
import { objectsEqual, searchClearner } from "../../utils/utils";
import HelpIcon from "@mui/icons-material/Help";
import VerifiedIcon from "@mui/icons-material/Verified";

interface InvoiceServerless {
   id: number;
   username: string;
   payment_at: Date | null;
   invoiced_at: Date | null;
   created_at: Date | null;
   coupon_code: string;
   coupon_discount: string;
   transaction_id: string;
   payment_gateway: "niubiz" | "stripe" | "dlocal";
   source:
      | "backoffice_form"
      | "django_form"
      | "enrollment_form_campaigns"
      | "enrollment_form_courses_paths"
      | "manual"
      | "payment_gateway"
      | "payment_web"
      | "payment_inscription";
   is_verified: boolean;
   currency: "USD" | "PEN" | "MXN" | "COP";
   amount: number;
}
interface Props {}

const Invoices: React.FC<Props> = () => {
   const [params] = useSearchParams();
   const [couponProgramId] = useState(
      params.get("id") && parseInt(params.get("id")!)
   );

   const [searchText, setSearchText] = useState<string>("");
   const [rows, setRows] = useState<InvoiceServerless[]>([]);
   const [sortModel, setSortModel] = useState<GridSortModel>([
      {
         field: "id",
         sort: "desc",
      },
   ]);

   const {
      data: invoices,
      error,
      isError,
      isLoading,
      isFetching,
      refetch,
   } = useQuery(
      ["invoices-backoffice"],
      () => GET_SERVERLESS<InvoiceServerless[]>(`/invoice-all`),
      {
         retry: false,
         refetchOnWindowFocus: false,
      }
   );
   useEffect(() => {
      invoices && setRows(invoices);
   }, [invoices]);

   if (isLoading || !invoices) return <ScreenLoader />;
   if (isError) return <div>Error! {JSON.stringify(error)}</div>;

   const currentlySelected = (model: GridSortModel) => {
      !objectsEqual(model, sortModel) && setSortModel(model);
   };

   const requestSearch = (searchValue: string) => {
      setSearchText(searchValue);
      const filteredRows = invoices.filter((invoice: InvoiceServerless) =>
         searchClearner(`${invoice.username}`).includes(
            searchClearner(searchValue)
         )
      );
      setRows(filteredRows);
   };

   return (
      <Grid
         container
         rowGap={2}
         style={{ overflowY: "scroll", height: "100%" }}
      >
         <Grid item xs={12}>
            <DataGrid
               autoHeight
               style={{ fontSize: 12 }}
               loading={isFetching}
               sortModel={sortModel}
               onSortModelChange={(model) => currentlySelected(model)}
               rows={rows}
               columns={generateColumnsByInvoices()}
               pageSize={20}
               rowsPerPageOptions={[20]}
               components={{
                  Toolbar: CustomQuickSearchToolbar,
                  LoadingOverlay: CustomLoadingOverlay,
               }}
               componentsProps={{
                  toolbar: {
                     value: searchText,
                     onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                        requestSearch(event.target.value),
                     clearSearch: () => requestSearch(""),
                  },
               }}
            />
         </Grid>
      </Grid>
   );

   function generateColumnsByInvoices(): GridColDef[] {
      const columns: GridColumns = [
         {
            field: "edit",
            headerName: "Edit",
            width: 85,
            minWidth: 85,
            editable: false,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            renderCell: (params: GridRenderCellParams) => {
               return (
                  <Stack
                     direction="row"
                     justifyContent="center"
                     alignItems="center"
                     spacing={1}
                  >
                     <ModalEditInvoice
                        invoiceId={params.row.id}
                        invoice={params.row}
                        refetch={refetch}
                     />
                  </Stack>
               );
            },
         },
         {
            field: "id",
            headerName: "ID",
            width: 100,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.id;
            },
         },
         {
            field: "username",
            headerName: "cliente",
            minWidth: 150,
            editable: false,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.username;
            },
         },
         {
            field: "invoiced_at",
            headerName: "fecha de facturación",
            minWidth: 100,
            editable: false,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.invoiced_at && moment(params.row.invoiced_at);
            },
            renderCell: (params: GridRenderCellParams) => {
               return (
                  params.row.invoiced_at &&
                  moment(params.row.invoiced_at).format("YYYY MMM D")
               );
            },
         },
         {
            field: "payment_gateway",
            headerName: "Método de pago",
            minWidth: 100,
            editable: false,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.payment_gateway;
            },
         },
         {
            field: "coupon_code",
            headerName: "Cupón",
            minWidth: 100,
            editable: false,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.coupon_code;
            },
         },
         {
            field: "source",
            headerName: "Fuente de pago",
            minWidth: 100,
            editable: false,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
               if (params.row.source == "payment_web") {
                  return "WEB";
               } else if (params.row.source == "payment_inscription") {
                  return "Inscripción Interna";
               } else if (params.row.source == "backoffice_form") {
                  return "BO";
               } else if (params.row.source == "batch_load") {
                  return "Carga Lote";
               } else {
                  return params.row.source;
               }
            },
         },
         {
            field: "is_verified",
            headerName: "Pago verificado",
            minWidth: 100,
            editable: false,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.is_verified;
            },
            renderCell: (params: GridRenderCellParams) => {
               return (
                  <>
                     {params.row.is_verified ? (
                        <Chip
                           icon={<VerifiedIcon />}
                           label="Verificado"
                           variant="outlined"
                           color="success"
                        />
                     ) : (
                        <Chip
                           icon={<HelpIcon />}
                           label="Sin confirmar"
                           variant="outlined"
                           color="warning"
                        />
                     )}
                  </>
               );
            },
         },
         {
            field: "currency",
            headerName: "Divisa",
            minWidth: 50,
            editable: false,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.currency;
            },
         },
         {
            field: "amount",
            headerName: "Monto",
            minWidth: 50,
            editable: false,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.amount;
            },
         },
      ];
      return columns;
   }
};

export default Invoices;
