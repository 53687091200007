import { useState } from "react";

import IconDots from "../../atoms/icons/IconDots";

import "./floatingMenu.scss";

interface Props {
   items: any;
}

const FloatingMenu: React.FC<Props> = ({ items }) => {
   const [isVisibleMenu, setIsVisibleMenu] = useState(false);

   return (
      <section>
         <div
            className={`floating-menu ${
               isVisibleMenu ? "floating-menu--visible" : ""
            }`}
            onClick={() => setIsVisibleMenu(true)}
         >
            <IconDots className="floating-menu__icon-dots" />
         </div>
         <div className="floating-menu__container-items">
            {isVisibleMenu ? (
               <>
                  <ul className="floating-menu__content">
                     {items.map((item: any, index: number) => (
                        <div key={index}>{item}</div>
                     ))}
                  </ul>
                  <div
                     className="floating-menu__container"
                     onClick={() => setIsVisibleMenu(false)}
                  ></div>
               </>
            ) : (
               ""
            )}
         </div>
      </section>
   );
};

export default FloatingMenu;
