import moment from "moment";

export const inputHourRange = [
   "09:00:00",
   "09:30:00",
   "10:00:00",
   "10:30:00",
   "11:00:00",
   "11:30:00",
   "12:00:00",
   "12:30:00",
   "13:00:00",
   "13:30:00",
   "14:00:00",
   "14:30:00",
   "15:00:00",
   "15:30:00",
   "16:00:00",
   "16:30:00",
   "17:00:00",
   "17:30:00",
   "18:00:00",
   "18:30:00",
   "19:00:00",
   "19:30:00",
   "20:00:00",
];

export const hourFormatter = (hour: string) => {
   return moment(`2000/01/01 ${hour}`).format("HH:mm");
};

export const hourFormatterSecond = (hour: string) => {
   return moment(`2000/01/01 ${hour}`).format("HH:mm:ss");
};

// GET error in validation
// default validation hour range
export const defHourRange = (hour: string) => {
   let hourSelected = moment(`2000/01/01 ${hour}`).format("HH:mm");

   const result =
      hourSelected < moment(`2000/01/01 09:00`).format("HH:mm") ||
      hourSelected > moment(`2000/01/01 20:00`).format("HH:mm");
   return result;
};

// validation range between 2 hours (validation for hourFrom)
export const hourFromRangeBetween = (
   hourSelected: string,
   hourFrom: string,
   hourTo: string
) => {
   let selectedTimeFormatted = moment(`2000/01/01 ${hourSelected}`).format(
      "HH:mm"
   );
   let hourFromFormatted = moment(`2000/01/01 ${hourFrom}`).format("HH:mm");
   let hourToFormatted = moment(`2000/01/01 ${hourTo}`).format("HH:mm");

   const result =
      selectedTimeFormatted >= hourFromFormatted &&
      selectedTimeFormatted < hourToFormatted;
   return result;
};

// validation range between 2 hours (validation for hourTo)
export const hourToRangeBetween = (
   hourSelected: string,
   hourFrom: string,
   hourTo: string
) => {
   let selectedTimeFormatted = moment(`2000/01/01 ${hourSelected}`).format(
      "HH:mm"
   );
   let hourFromFormatted = moment(`2000/01/01 ${hourFrom}`).format("HH:mm");
   let hourToFormatted = moment(`2000/01/01 ${hourTo}`).format("HH:mm");

   const result =
      selectedTimeFormatted > hourFromFormatted &&
      selectedTimeFormatted <= hourToFormatted;
   return result;
};

// validation valHourTo  -> selectedHourTo <= hourFromFormatted --> error
export const valHourTo = (selectedHourTo: string, hourFrom: string) => {
   let hourFromFormatted = moment(`2000/01/01 ${hourFrom}`).format("HH:mm");
   let selectedHourToFormatted = moment(`2000/01/01 ${selectedHourTo}`).format(
      "HH:mm"
   );

   const result = selectedHourToFormatted <= hourFromFormatted;
   return result;
};

// validation valOtherRangesTime  -> if first range is into the actual range --> error
export const valOtherRangesTime = (
   hourFrom: string,
   hourTo: string,
   hourFrom1: string,
   selectedHourTo1: string
) => {
   let hourFromFormatted = moment(`2000/01/01 ${hourFrom}`).format("HH:mm");
   let hourToFormatted = moment(`2000/01/01 ${hourTo}`).format("HH:mm");
   let hourFromFormatted1 = moment(`2000/01/01 ${hourFrom1}`).format("HH:mm");
   let selectedHourToFormatted1 = moment(
      `2000/01/01 ${selectedHourTo1}`
   ).format("HH:mm");

   const result =
      hourFromFormatted1 < hourFromFormatted &&
      selectedHourToFormatted1 >= hourToFormatted;
   return result;
};
