import Box from "@mui/material/Box";
import Loader from "react-loader-spinner";
import Typography from "@mui/material/Typography";

interface Props {
   fullWidth?: boolean;
}

const ScreenLoader: React.FC<Props> = ({ fullWidth = true }) => {
   if (fullWidth) {
      return (
         <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            height="100vh"
            justifyContent="center"
         >
            <Loader
               type="RevolvingDot"
               color="#9C56E0"
               height={100}
               width={100}
            />
            <Typography variant="h6">Cargando...</Typography>
         </Box>
      );
   } else {
      return (
         <div
            style={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               width: "100%",
            }}
         >
            <Loader
               type="RevolvingDot"
               color="#9C56E0"
               height={100}
               width={100}
            />
            <Typography variant="h6">Cargando...</Typography>
         </div>
      );
   }
};

export default ScreenLoader;
