// React
import { memo, useEffect } from "react";

//@ts-ignore
// ReactCountryFlag
import ReactCountryFlag from "react-country-flag";

// Material UI
import { Stack, Typography } from "@material-ui/core";
import { Divider, LinearProgress } from "@mui/material";
import Grid from "@mui/material/Grid";

//Redux
import { useSelector } from "react-redux";
import {
   getGuardianDetails,
   selectStateGuardianDetails,
} from "../../../../slices/guardianSlice/guardianInvoicesSlice";
import { AppDispatch, useAppDispatch } from "../../../../store/store";

// Components
import DataGridStudents from "./DataGridStudents/DataGridStudents";
import DataGridInvoices from "./DataGridInvoices/DataGridInvoices";

interface Props {
   guardianId: number;
}

const GuardianDetails: React.FC<Props> = ({ guardianId }) => {
   const dispatch: AppDispatch = useAppDispatch();

   const { guardian, isLoadingGuardianDetails } = useSelector(
      selectStateGuardianDetails
   );

   useEffect(() => {
      if (guardianId) {
         dispatch(getGuardianDetails(guardianId));
      }
   }, [guardianId]);

   if (isLoadingGuardianDetails || !guardian) return <LinearProgress />;

   return (
      <Grid container rowGap={2}>
         {/* Details */}
         <Grid item xs={12}>
            <Divider textAlign="left">Detalles</Divider>
         </Grid>
         <Grid item container rowGap={1.5} xs={12}>
            {/* Name */}
            <Grid item xs={5}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     Nombre:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     {guardian.first_name} {guardian.last_name}
                  </Typography>
               </Stack>
            </Grid>

            {/* Document Type */}
            <Grid item xs={5}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     DNI:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     ({guardian.doc_type}) {guardian.doc_number}
                  </Typography>
               </Stack>
            </Grid>

            {/* Email address */}
            <Grid item xs={5}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     Correo:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     {guardian.email}
                  </Typography>
               </Stack>
            </Grid>

            {/* Phone Number */}
            <Grid item xs={5}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     Teléfono:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     {guardian.phone_number}
                  </Typography>
               </Stack>
            </Grid>

            {/* Country */}
            <Grid item xs={2}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     País:
                  </Typography>
                  <Stack
                     direction="row"
                     columnGap={2}
                     alignItems="center"
                     justifyContent="space-between"
                  >
                     <ReactCountryFlag countryCode={guardian.country} />
                     <span>{guardian.country && `(${guardian.country})`}</span>
                  </Stack>
               </Stack>
            </Grid>
         </Grid>

         {/* Students */}
         <Grid item xs={12}>
            <Divider textAlign="left">Hij@s</Divider>
         </Grid>
         <Grid item container rowGap={2} xs={12}>
            <Grid item xs={12}>
               <DataGridStudents
                  students={guardian.students}
                  isFetching={isLoadingGuardianDetails /* isFetchingGuardian */}
               />
            </Grid>
         </Grid>

         {/* Invoices */}
         <Grid item xs={12}>
            <Divider textAlign="left">Facturas</Divider>
         </Grid>
         <Grid item container rowGap={2} xs={12}>
            <DataGridInvoices
               invoices={guardian.invoices}
               guardianId={guardian.id}
               isFetching={isLoadingGuardianDetails}
            />
         </Grid>
      </Grid>
   );
};

export default memo(GuardianDetails);
