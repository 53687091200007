// React
import { useState } from "react";

// Components
import CreateCourse from "./CreateCourse/CreateCourse";
import OpenModal from "../../../components/Modal/OpenModal/OpenModal";
import Modal from "../../../components/Modal/Modal";

interface Props {
  refetch: () => {};
}

const ModalCreateCourse: React.FC<Props> = ({ refetch }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      {/* Open Modal */}
      <OpenModal
        option="create"
        title="Crear nuevo curso"
        handleOpenModal={handleOpenModal}
      />

      {/* Modal Create Course */}
      <Modal
        title="Crear Curso"
        fullWidth
        maxWidth="lg"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      >
        <CreateCourse refetch={refetch} onClose={handleCloseModal} />
      </Modal>
    </>
  );
};

export default ModalCreateCourse;
