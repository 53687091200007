import React from "react";

interface Props {
  fill?: string;
  className?: string;
}


const IconGroups: React.FC<Props> = ({ className, fill = "#555555" }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4357 18.6847L14.8341 13.8685C14.7489 13.1864 14.4174 12.5588 13.9019 12.104C13.3864 11.6491 12.7226 11.3982 12.0351 11.3984H9.9635C9.27635 11.3986 8.61292 11.6497 8.09785 12.1046C7.58277 12.5594 7.25152 13.1867 7.16631 13.8685L6.56382 18.6847C6.53076 18.9493 6.55437 19.2179 6.63308 19.4726C6.71179 19.7274 6.8438 19.9625 7.02035 20.1624C7.1969 20.3622 7.41394 20.5222 7.65707 20.6317C7.9002 20.7413 8.16384 20.7978 8.4305 20.7976H13.57C13.8365 20.7977 14.1001 20.741 14.3431 20.6314C14.5861 20.5219 14.803 20.3618 14.9795 20.162C15.1559 19.9622 15.2879 19.7271 15.3665 19.4724C15.4452 19.2177 15.4687 18.9492 15.4357 18.6847V18.6847Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0004 7.6395C12.5577 7.6395 13.8202 6.37705 13.8202 4.81975C13.8202 3.26244 12.5577 2 11.0004 2C9.44311 2 8.18066 3.26244 8.18066 4.81975C8.18066 6.37705 9.44311 7.6395 11.0004 7.6395Z"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M3.48091 10.4628C4.51911 10.4628 5.36074 9.62116 5.36074 8.58296C5.36074 7.54475 4.51911 6.70312 3.48091 6.70312C2.4427 6.70312 1.60107 7.54475 1.60107 8.58296C1.60107 9.62116 2.4427 10.4628 3.48091 10.4628Z"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M18.5195 10.4628C19.5577 10.4628 20.3993 9.62116 20.3993 8.58296C20.3993 7.54475 19.5577 6.70312 18.5195 6.70312C17.4813 6.70312 16.6396 7.54475 16.6396 8.58296C16.6396 9.62116 17.4813 10.4628 18.5195 10.4628Z"
        stroke={fill}
        strokeWidth="1.5"
      />
      <path
        d="M3.48067 13.2813H3.19306C2.74806 13.2812 2.31748 13.439 1.97791 13.7266C1.63834 14.0142 1.4118 14.4129 1.3386 14.8518L1.02561 16.7317C0.980692 17.001 0.994988 17.2769 1.0675 17.5401C1.14001 17.8034 1.269 18.0477 1.4455 18.256C1.62199 18.4643 1.84175 18.6317 2.08949 18.7465C2.33724 18.8613 2.60702 18.9208 2.88006 18.9207H6.30042M18.5193 13.2813H18.8069C19.2519 13.2812 19.6825 13.439 20.0221 13.7266C20.3617 14.0142 20.5882 14.4129 20.6614 14.8518L20.9744 16.7317C21.0193 17.001 21.005 17.2769 20.9325 17.5401C20.86 17.8034 20.731 18.0477 20.5545 18.256C20.378 18.4643 20.1582 18.6317 19.9105 18.7465C19.6628 18.8613 19.393 18.9208 19.1199 18.9207H15.6996"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconGroups;
