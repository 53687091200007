// Interfaces
import { ICoupon } from "../../../../../interfaces/coupon.interface";

// Material UI
import { Grid } from "@mui/material";

import ListCoupon from '../../../../../components/Coupons/ListCoupon';
import CreateCoupon from '../../../../../components/Coupons/CreateCoupon/CreateCoupon';

interface Props {
    coupons?: ICoupon[];
    couponProgramId: number;
    refetch: () => {};
}

const Coupons: React.FC<Props> = ({
    coupons,
    couponProgramId,
    refetch,
}) => {
    return (
        <Grid container rowSpacing={2} alignItems="flex-start">
            <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
                <Grid item xs={12}>
                    <CreateCoupon
                        couponProgramId={couponProgramId}
                    />
                    <ListCoupon
                        coupons={coupons}
                        couponProgramId={couponProgramId}
                        refetch={refetch}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Coupons;