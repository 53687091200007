// React
import { useState } from "react";

// Interfaces
import { IRoom, IStudentRoom } from "../../../../../interfaces/group.interface";

//Redux
import { addStudentToRoom, listRoomsByGroupId, selectStateRoomsByGroupId } from "../../../../../slices/roomSlice/roomsByGroupIdSlice";
import { AppDispatch, useAppDispatch } from "../../../../../store/store";
import { useSelector } from "react-redux";

// Material UI
import { CircularProgress, MenuItem, Select, Stack } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

interface Props {
   studentId: number;
   groupId: number;
   rooms: IRoom[];
   room: IStudentRoom;
   refetch?: () => {};
}

const UpdateStudentRoom: React.FC<Props> = ({
   studentId,
   groupId,
   rooms,
   room,
   refetch,
}) => {
   const dispatch: AppDispatch = useAppDispatch();
   const [currentRoomId, setCurrentRoomId] = useState<number | string>(room.id);
   const [isLoadingStudentRoom, setIsLoadingStudentRoom] = useState(false);
   const { isLoading } = useSelector(selectStateRoomsByGroupId);

   const handleUpdateStudentRoom = async (roomId: number | string) => {
      setCurrentRoomId(roomId);
      await dispatch(addStudentToRoom({roomId, studentId}));
      await dispatch(listRoomsByGroupId(Number(groupId)));
   };

   return (
      <Stack
         sx={{ width: "100%" }}
         direction="row"
         spacing={2}
         alignItems="center"
         justifyContent="space-between"
      >
         <Select
            fullWidth
            labelId="room-label"
            id="room"
            label="Salón"
            value={currentRoomId}
            onChange={(value) => handleUpdateStudentRoom(value.target.value)}
         >
            <MenuItem value={0} disabled>
               <em>Ningún Salón</em>
            </MenuItem>
            {rooms.map((room: IRoom) => (
               <MenuItem key={room.id} value={room.id}>
                  {room.name}
               </MenuItem>
            ))}
         </Select>
         {
            isLoadingStudentRoom/* updateStudentRoom.isSuccess  isLoadingStudentRoom */ && (
               <CheckBoxIcon fontSize="large" color="success" />
            )
         }
         {
            isLoadingStudentRoom /* updateStudentRoom.isLoading  isLoadingStudentRoom */ && (
               <CircularProgress />
            )
         }
      </Stack>
   );
};

export default UpdateStudentRoom;
