import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { IEnrollStudent, IEnrolments } from "../../interfaces/enrolment.interface";
import { getEnrolments, patchCancelEnrolment, postEnrollStudent } from "../../services/enrolments";


export interface IAuthError {
   msg: string;
}

export interface IEnrolmentState {
    enrolments: IEnrolments[];
    isLoadingEnrolments: boolean;
    isLoadingEnrolStudent: boolean;
    isLoadingCancelEnrolment: boolean;
    isCompleted: boolean;
    error: IAuthError | null;
    rejected: boolean;
}

const initialState: IEnrolmentState = {
    enrolments: [],
    isLoadingEnrolments: false,
    isLoadingEnrolStudent: false,
    isLoadingCancelEnrolment: false,
    isCompleted: false,
    error: null,
    rejected: false,
};

export const getAllEnrolments = createAsyncThunk(
   "enrolments/getAllEnrolments",
   async (_, { rejectWithValue }: any) => {
      try {
         const data = await getEnrolments();
         return data;
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const updateEnrollStudent = createAsyncThunk(
    "enrolments/updateEnrollStudent",
    async (enrollStudent: IEnrollStudent, { rejectWithValue }: any) => {
       try {
          const response = await postEnrollStudent(enrollStudent);
          if (response?.status === 200) {
            toast.success("Estudiante inscrito correctamente!");
            console.log("data-enrol: ", response);
          } else {
            toast.error(
              "Ha ocurrido un error al inscribir el estudiante."
            );
            console.log("data-error: ", response);
          } 
          return response?.data;
       } catch (err) {
          let error: AxiosError = err as AxiosError;
          return rejectWithValue(error.response?.data);
       }
    }
 );

 export const cancelEnrollStudent = createAsyncThunk(
    "enrolments/cancelEnrollStudent",
    async (enrollmentId: any, { rejectWithValue }: any) => {
       try {
          const data = await patchCancelEnrolment(enrollmentId);
          if (data) {
            toast.success("Inscripción cancelada correctamente!");
          } else {
            toast.error(
                "Ha ocurrido un error al cancelar la inscripción."
            );
          } 
          return data;
       } catch (err) {
          let error: AxiosError = err as AxiosError;
          return rejectWithValue(error.response?.data);
       }
    }
 );

export const enrolmentsSlice = createSlice({
   name: "enrolments",
   initialState,
   reducers: {
      /* addRoom: (state, action) => {
         console.log("state", state);
         console.log("action", action);
      }, */
   },
   extraReducers: (builder) => {
      /** getAllEnrolments */
      builder.addCase(getAllEnrolments.pending, (state, _) => {
         state.enrolments = initialState.enrolments;
         state.isLoadingEnrolments = true;
      });
      builder.addCase(getAllEnrolments.fulfilled, (state, action) => {
         state.enrolments = action.payload;
         state.isLoadingEnrolments = false;
         state.error = null;
      });
      builder.addCase(getAllEnrolments.rejected, (state, _) => {
         state.isLoadingEnrolments = initialState.isLoadingEnrolments;
         state.rejected = true;
      });
      /** enrollStudent */
      /* builder.addCase(updateEnrollStudent.pending, (state, _) => {
        state.enrolments = initialState.enrolments;
        state.isLoadingEnrolStudent = true;
     });
     builder.addCase(updateEnrollStudent.fulfilled, (state, action) => {
        //console.log("action update: ", action.payload);
        state.enrolments = action.payload;
        state.isLoadingEnrolStudent = false;
        state.error = null;
     });
     builder.addCase(updateEnrollStudent.rejected, (state, _) => {
        state.isLoadingEnrolStudent = initialState.isLoadingEnrolStudent;
        state.rejected = true;
     }); */
     /** cancelEnrollStudent */
     builder.addCase(cancelEnrollStudent.pending, (state, _) => {
        state.enrolments = initialState.enrolments;
        state.isLoadingCancelEnrolment = true;
     });
     builder.addCase(cancelEnrollStudent.fulfilled, (state, action) => {
        console.log("action delete: ", action.payload);
        //state.enrolments = action.payload;
        state.isLoadingCancelEnrolment = false;
        state.error = null;
     });
     builder.addCase(cancelEnrollStudent.rejected, (state, _) => {
        state.isLoadingCancelEnrolment = initialState.isLoadingCancelEnrolment;
        state.rejected = true;
     });
   },
});

export const selectStateEnrolments = (state: any) => state.enrolments;
