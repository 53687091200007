// React
import React, { SyntheticEvent, useState } from "react";

// Interfaces
import { currencies } from "../../../interfaces/currency.interface";

// Material UI
import { Box } from "@material-ui/core";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

// Components
import Currency from "./Currency/Currency";

interface Props {
  learningPathId: number;
  currencies: currencies[];
  refetch: () => {};
}

const Currencies: React.FC<Props> = ({ learningPathId, currencies, refetch }) => {
  const [currentPosition, setCurrentPosition] = useState<string>("0");

  const handleChangePosition = (_event: SyntheticEvent, newValue: string) =>
    setCurrentPosition(newValue);

  return (
    <TabContext value={currentPosition}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <TabList onChange={handleChangePosition} aria-label="room actions">
          {currencies.map((currency, idx) => (
            <Tab label={currency} value={idx.toString()} />
          ))}
        </TabList>
      </Box>

      {currencies.map((currency, idx) => (
        <TabPanel
          value={idx.toString()}
          sx={{ p: 0, width: "100%", marginTop: "20px" }}
        >
          <Currency
            learningPathId={learningPathId}
            currency={currency}
            refetch={refetch}
          />
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default Currencies;
