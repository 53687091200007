// Material UI
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

// Components
import { confirmDialog } from "../../../../../components/ConfirmDialog/ConfirmDialog";

// Constants
import PERMISSIONS from "../../../../../constants/permission.constants";

// Redux
import {
   AppDispatch,
   RootState,
   useAppDispatch,
} from "../../../../../store/store";
import { useSelector } from "react-redux";
import {
   deleteRoomById,
   getAllRooms,
} from "../../../../../slices/roomSlice/roomsSlice";
import { listRoomsByGroupId } from "../../../../../slices/roomSlice/roomsByGroupIdSlice";

interface Props {
   roomId: number;
   groupId: number;
   refetch?: () => {};
}

const DeleteRoom: React.FC<Props> = ({ roomId, groupId }) => {
   const dispatch: AppDispatch = useAppDispatch();
   const user = useSelector((state: RootState) => state.auth.user);

   const handleDeleteRoom = async () => {
      try {
         await dispatch(deleteRoomById(roomId));
      } catch (error) {
         console.log(error);
      } finally {
         if (user) {
            const userId = user?.permission[PERMISSIONS.roomListAll]
               ? 0
               : user?.id;
            await dispatch(getAllRooms(userId));
         }
         dispatch(listRoomsByGroupId(Number(groupId)));
      }
   };

   return (
      <Tooltip title="Eliminar salón" arrow placement="right">
         <IconButton
            aria-label="Delete"
            color="error"
            size="small"
            onClick={(e) => {
               e.stopPropagation();
               confirmDialog(
                  `¿Realmente quieres eliminar el salon con ID:${roomId}?`,
                  () => handleDeleteRoom()
               );
            }}
         >
            <DeleteIcon />
         </IconButton>
      </Tooltip>
   );
};

export default DeleteRoom;
