import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link as LinkRouter } from "react-router-dom";
import IconArrowDown from "../../atoms/icons/IconArrowDown";
import IconOn from "../../atoms/icons/IconOn";
import Typography from "../../atoms/Typography";
import SelectOptions from "../SelectOptions/SelectOptions";
import ItemFloatingMenu from "../ItemFloatingMenu";
import Link from "../Link";
import IconArrowUp from "../../atoms/icons/IconArrowUp";

import "./select.scss";

interface Props {
   children: any;
   isOpen: any;
   onClickOutside?: any;
   setIsOpen: any;
   options?: any;
   className?: string;
   size?: any;
   disabled?: any;
   defaultValue?: any;
   onclickDefaultValue: Function;
}

const Select: React.FC<Props> = ({
   children,
   isOpen,
   onClickOutside,
   setIsOpen,
   size = "default",
   className,
   disabled,
   options,
   onclickDefaultValue,
}) => {
   const ref = useRef<HTMLInputElement>(null);

   const dataSort = Array.isArray(options) ? [...options] : options;

   const onClickHandler = (option: any): void => {
      onclickDefaultValue(option);
      setIsOpen(!isOpen);
   };

   useEffect(() => {
      const handleClickOutside = (event: any) => {
         if (ref.current && !ref.current.contains(event.target)) {
            onClickOutside && onClickOutside();
         }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
         document.removeEventListener("click", handleClickOutside, true);
      };
   }, [onClickOutside]);

   return (
      <>
         <div
            className={`select ${disabled ? "select__disabled" : ""} ${
               className ? className : ""
            }`}
         >
            <div className={`select__text`}>
               <Typography
                  type="paragraph2"
                  variation="mobile"
                  className={`select__text--ellipsis ${
                     disabled ? "select__text-disabled" : ""
                  } `}
               >
                  {children}
               </Typography>
            </div>
            {disabled ? (
               <div
                  className={`select__icon ${
                     isOpen ? "select--active" : "select--inactive"
                  } select__icon--${size} ${
                     disabled ? "select__icon--disabled" : ""
                  }`}
               >
                  <IconArrowDown fill="#BCBCBC" />
               </div>
            ) : (
               <div
                  onClick={() => setIsOpen(!isOpen)}
                  className={`select__icon ${
                     isOpen ? "select--active" : "select--inactive"
                  } select__icon--${size}`}
               >
                  {isOpen ? <IconArrowUp /> : <IconArrowDown />}
               </div>
            )}
         </div>
         {isOpen && (
            <div ref={ref}>
               <SelectOptions
                  isScroll={dataSort?.length > 8 ? true : false}
                  size={size}
                  options={dataSort
                     ?.sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                     .map((option: any) => (
                        <ItemFloatingMenu
                           key={option?.id}
                           onClick={() => onClickHandler(option)}
                        >
                           {option?.name}
                        </ItemFloatingMenu>
                     ))}
               />
            </div>
         )}
      </>
   );
};

export default Select;
