// Components
import Currencies from "../../../../../components/Courses/Currencies/Currencies";

interface Props {
  refetch: () => {};
  courseId: number;
}

const Prices: React.FC<Props> = ({ refetch, courseId }) => {
  return (
    <Currencies
      courseId={courseId}
      currencies={["PEN", "USD", "MXN", "COP"]}
      refetch={refetch}
    />
  );
};

export default Prices;
