// Material UI
import { Dialog, DialogContent, useMediaQuery, useTheme } from "@mui/material";
import CustomDialogTitle from "../CustomDialogTitle/CustomDialogTitle";

interface Props {
  id?: number;
  copyUrl?: boolean;
  title: string;
  fullWidth?: boolean;
  maxWidth: "xs" | "sm" | "md" | "lg" | "xl";
  openModal: boolean;
  handleCloseModal: () => void;
}

const Modal: React.FC<Props> = ({
  id,
  copyUrl = false,
  title,
  fullWidth,
  maxWidth = "md",
  openModal,
  handleCloseModal,
  children,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openModal}
        onClose={handleCloseModal}
      >
        <CustomDialogTitle
          onClose={handleCloseModal}
          options={{ id: id, copyUrl: copyUrl }}
        >
          {title}
        </CustomDialogTitle>
        <DialogContent dividers>{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default Modal;
