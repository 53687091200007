import React from "react";

import "./fileInput.scss";

interface Props {
   children: any;
   id: string;
   disabled?: boolean;
}

const FileInput: React.FC<Props> = ({ id, children, disabled = false }) => {
   return (
      <div className="file-input">
         <label htmlFor={id}>{children}</label>
         <input
            className="file-input__upload"
            id={id}
            type="file"
            disabled={disabled}
         />
      </div>
   );
};

export default FileInput;
