// Hot Toast
import { toast } from "react-hot-toast";

// Redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Axios
import { AxiosError } from "axios";

// Interfaces
import {
   IGroupDetail,
   IGroupDetailPag,
} from "../../interfaces/group.interface";

// Services
import {
   getGroups,
   patchEditGroup,
   postCreateGroup,
} from "../../services/groups";
import { postAddStudentToGroup } from "../../services/students";

export interface IAuthError {
   msg: string;
}

export interface IGroupState {
   groups: IGroupDetailPag[];
   isLoading: boolean;
   isCompleted: boolean;
   error: IAuthError | null;
   rejected: boolean;
}

const initialState: IGroupState = {
   groups: [],
   isLoading: false,
   isCompleted: false,
   error: null,
   rejected: false,
};

export const getAllGroups = createAsyncThunk(
   "rooms/getAllGroups",
   async (
      {
         selectOption1,
         selectOption2,
         selectOption3,
         selectOption4,
         isSearch,
         message,
      }: {
         selectOption1?: any;
         selectOption2?: any;
         selectOption3?: any;
         selectOption4?: any;
         isSearch?: boolean;
         message?: string;
      } = {},
      { getState, rejectWithValue }: any
   ) => {
      try {
         /* const { auth: user } = getState() as RootState; */
         const response = await getGroups(
            selectOption1,
            selectOption2,
            selectOption3,
            selectOption4,
            isSearch,
            message
         );
         return response.data;
      } catch (err) {
         if (err instanceof Error) {
            console.log(err.message);
         }
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const addGroup = createAsyncThunk(
   "rooms/addGroup",
   async (
      newGroup: IGroupDetail,
      { getState, rejectWithValue, dispatch, ...others }: any
   ) => {
      try {
         const response = await postCreateGroup(newGroup);
         if (response?.status === 201) {
            toast.success("Grupo creado correctamente!");
         } else {
            toast.error("Ha ocurrido un error al crear el grupo.");
         }
         return response;
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const updateGroup = createAsyncThunk(
   "rooms/updateGroup",
   async (
      { groupId, group }: any,
      { getState, rejectWithValue, dispatch, ...others }: any
   ) => {
      try {
         const data = await patchEditGroup(groupId, group);
         if (data) {
            toast.success("Grupo editado correctamente!");
         } else {
            toast.error("Ha ocurrido un error al editar el grupo.");
         }
         return data;
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const addStudentToGroup = createAsyncThunk(
   "students/addStudentToGroup",
   async (
      {groupId, selectedStudentId}: any,
      { getState, rejectWithValue, dispatch, ...others }: any
   ) => {
      try {
         const response = await postAddStudentToGroup(groupId, selectedStudentId);
         if (response) {
            toast.success("Estudiante agregado al grupo!");
         } else {
            toast.error("Ha ocurrido un error al agregar al estudiante al grupo.");
         }
         return response;
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);


export const groupsSlice = createSlice({
   name: "groups",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      /** getAllGroups */
      builder.addCase(getAllGroups.pending, (state, _) => {
         state.groups = initialState.groups;
         state.isLoading = true;
      });
      builder.addCase(getAllGroups.fulfilled, (state, action) => {
         state.groups = action.payload;
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(getAllGroups.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
      /** addGroup */
      builder.addCase(addGroup.pending, (state, _) => {
         state.isLoading = true;
      });
      builder.addCase(addGroup.fulfilled, (state, action) => {
         state?.groups?.push(action.payload);
         state.isLoading = false;
         state.isCompleted = true;
         state.error = null;
      });
      builder.addCase(addGroup.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
      builder.addCase(updateGroup.pending, (state, _) => {
         state.isLoading = true;
      });
      builder.addCase(updateGroup.fulfilled, (state, action) => {
         if (!action.payload) {
            console.log(
               "Update could not complete, dont exist ID Room",
               action.payload
            );
            return;
         }
         const payload = action.payload;
         const groups = state.groups.filter((group) => group.id !== payload.id);
         state.groups = [...groups, action.payload];
         state.isLoading = false;
         state.isCompleted = true;
         state.error = null;
      });
      builder.addCase(updateGroup.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
      
      /** addStudentToGroup */
      builder.addCase(addStudentToGroup.pending, (state, _) => {
         state.isLoading = true;
      });
      builder.addCase(addStudentToGroup.fulfilled, (state, action) => {
         //state?.students?.push(action.payload);
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(addStudentToGroup.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
   },
});

export const selectStateGroups = (state: any) => state.groups;
