import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { IGuardian } from "../../interfaces/guardian.interface";
import { getGuardianForId, patchEditGuardian } from "../../services/guardians";
// Hot Toast
import { toast } from "react-hot-toast";
import { IStudent } from "../../interfaces/students.interface";
import { getStudentForId, patchEditStudent } from "../../services/students";


export interface IAuthError {
   msg: string;
}

export interface IStudentByIdState {
   students:IStudent[] ;
   student: IStudent | {};
   isLoadingEditStudent: boolean;
   isLoadingUpdateStudent: boolean;
   isCompleted: boolean;
   error: IAuthError | null;
   rejected: boolean;
}

const initialState: IStudentByIdState = {
   students: [],
   student: {},
   isLoadingEditStudent: false,
   isLoadingUpdateStudent: false,
   isCompleted: false,
   error: null,
   rejected: false,
};

export const getStudentById = createAsyncThunk(
   "studentsById/getStudentById",
   async (studentId:number, { rejectWithValue }: any) => {
      try {
         /* const { auth: user } = getState() as RootState; */
         const data = await getStudentForId(studentId);
         return data;
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const updateStudentById = createAsyncThunk(
   "studentsById/updateStudentById",
   async ( 
      { studentId, student }: any, 
      { getState, rejectWithValue, dispatch, ...others }: any 
   ) => {
      try {
         const response = await patchEditStudent(student, studentId);
         if (response) {
            toast.success("Estudiante editado correctamente!");
         } else {
            toast.error("Ha ocurrido un error al editar el estudiante.");
         }
         console.log("response data", response);
         console.log("student data", student);
         //return {id:response.id, ...student};
         return response;
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const studentsByIdSlice = createSlice({
   name: "studentsById",
   initialState,
   reducers: {
      /* addRoom: (state, action) => {
         console.log("state", state);
         console.log("action", action);
      }, */
   },
   extraReducers: (builder) => {
      /** getStudentById */
      builder.addCase(getStudentById.pending, (state, _) => {
         state.student = initialState.student;
         state.isLoadingEditStudent = true;
      });
      builder.addCase(getStudentById.fulfilled, (state, action) => {
         state.student = action.payload;
         state.isLoadingEditStudent = false;
         state.error = null;
      });
      builder.addCase(getStudentById.rejected, (state, _) => {
         /* state.rooms = initialState.rooms; */
         state.isLoadingEditStudent = initialState.isLoadingEditStudent;
         state.rejected = true;
      });
      /** updateStudentById */
      builder.addCase(updateStudentById.pending, (state, _) => {
         state.isLoadingUpdateStudent = true;
      });
      builder.addCase(updateStudentById.fulfilled, (state, action) => {
         if (!action.payload) {
            console.log(
               "Update could not complete, dont exist ID Student",
               action.payload
            );
            return;
         }
         /* const payload = action.payload;
         console.log("payload", payload);
         //const studentState = {...state.students} as IStudent;
         const studentFiltered = state.students.filter((student:any) => student.id !== payload.id );
         console.log("studentFiltered", studentFiltered);
         const studentUpdated = state.students.find((student:any) => {
            console.log("state.students", state.students);
            if(student.id === payload.id){
               console.log("student data", student);
               return {
                  ...student,
                  birthdate:payload.birthdate,
                  device_experience:payload.device_experience,
                  email:payload.email,
                  first_name:payload.first_name,
                  gender:payload.gender,
                  guardian:payload.guardian,
                  icon_id:payload.icon_id,
                  last_name:payload.last_name,
                  login_challenge_mdl:payload.login_challenge_mdl,
                  operative_system:payload.operative_system,
                  password:payload.password,
                  password_google:payload.password_google,
                  school_name:payload.school_name,
                  user_mdl_id:payload.user_mdl_id
               }
            }
         });
         console.log("studentUpdated: ",studentUpdated);
         const newStudentState = {...studentFiltered, studentUpdated}
         console.log("newStudentState: ",newStudentState);
         state.student = newStudentState; */
         console.log("payload", action.payload);
         state.student = action.payload;
         state.isLoadingUpdateStudent = false;
         state.isCompleted = true;
         state.error = null;
      });
      builder.addCase(updateStudentById.rejected, (state, _) => {
         state.isLoadingUpdateStudent = initialState.isLoadingUpdateStudent;
         state.rejected = true;
      });
   },
});

export const selectStateStudentForId = (state: any) => state.studentsById;
