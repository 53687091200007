import React from "react";
import { Link as RouterLink } from "react-router-dom";

import "./link.scss";

interface Props {
   children: any;
   to: string;
   iconLeft?: any;
   iconRight?: any;
   hoverColor?: boolean;
   size?: string;
   className?: string;
   disabled?: boolean;
}

const Link: React.FC<Props> = ({
   children,
   to,
   iconLeft,
   iconRight,
   hoverColor,
   size,
   disabled,
   className,
}) => {
   return (
      <>
         <RouterLink
            className={`link ${
               hoverColor ? "link--hover-color" : "link--hover-nocolor"
            } ${size === "small" ? "link--small-size" : "link--default-size"} ${
               disabled ? "link--disabled" : "link--enabled"
            } ${className}`}
            to={to}
         >
            {iconLeft} {children} {iconRight}
         </RouterLink>
      </>
   );
};

export default Link;
