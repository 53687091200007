import React, { useState } from "react";

// Yup
import * as Yup from "yup";

// Formik
import { Formik, Form } from "formik";

import colors from "../../../components/atoms/Colors";

// Components
import Modal from "../../../components/atoms/Modal";
import Typography from "../../../components/atoms/Typography";
import Input from "../../../components/molecules/Input";
import IconDiscord from "../../../components/atoms/icons/IconDiscord";
import ModalMessageAlert from "../../../components/molecules/ModalMessageAlert";
import IconWhatsapp from "../../../components/atoms/icons/IconWhatsapp";
import SelectFunctional from "../../../components/molecules/SelectFunctional";
import CollapseWrapper from "../../../components/molecules/CollapseWrapper/CollapseWrapper";
import ScreenLoader from "../../../components/ScreenLoader/ScreenLoader";
import ButtonFilled from "../../../components/molecules/ButtonFilled";
import ButtonOutline from "../../../components/molecules/ButtonOutline";
import ButtonLineal from "../../../components/molecules/ButtonLineal";

// Interface
import { ICreateRoom, ITeacher } from "../../../interfaces/group.interface";
import { ITeacherProfile } from "../../../interfaces/teacher.interface";

// Utils
import { clearString } from "../../../utils/utils";

// Redux
import {
   addRoom,
   addRoomManualLinkZoom,
   getAllRooms,
   selectStateRooms,
} from "../../../slices/roomSlice/roomsSlice";
import {
   listRoomsByGroupId,
   selectStateRoomsByGroupId,
} from "../../../slices/roomSlice/roomsByGroupIdSlice";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";

// Constants
import PERMISSIONS from "../../../constants/permission.constants";

// Hooks
import { useModal } from "../../../hooks/useModal";

import "./modalCreateRoom.scss";

interface Props {
   teachers: ITeacher[];
   groupId: number;
   isOpenModal: boolean;
   openModal: () => void;
   closeModal: () => void;
}

const CreateRoomSchema = Yup.object().shape({
   name: Yup.string().min(2, "Muy corto!").required("Requerido"),
   topic: Yup.string().min(2, "Muy corto!").required("Requerido"),
   teacher: Yup.number(),
});

const CreateRoomWithManualLinkZoom = Yup.object().shape({
   name: Yup.string().min(2, "Muy corto!").required("Requerido"),
   topic: Yup.string().min(2, "Muy corto!").required("Requerido"),
   teacher: Yup.number(),

   zoom_join_url: Yup.string()
      .url("URL invalido!")
      .min(10, "Muy corto!")
      .required("Requerido"),
   zoom_license: Yup.string()
      .email("Email invalido!")
      .min(2, "Muy corto!")
      .required("Requerido"),
   zoom_password: Yup.string().min(2, "Muy corto!").required("Requerido"),
   meet_id: Yup.string().min(2, "Muy corto!").required("Requerido"),
   meet_password: Yup.string().min(2, "Muy corto!").required("Requerido"),
});

const ModalCreateRoom: React.FC<Props> = ({
   teachers,
   groupId,
   isOpenModal,
   openModal,
   closeModal,
}) => {
   const dispatch: AppDispatch = useAppDispatch();
   const user = useSelector((state: RootState) => state.auth.user);
   const { error: errorCreateRoom } = useSelector(selectStateRooms);
   const {
      openModal: openModalAlertCreateRoom,
      closeModal: closeModalAlertCreateRoom,
      isOpenModal: isOpenModalAlertCreateRoom,
   } = useModal();
   const { rooms, isLoading } = useSelector(selectStateRoomsByGroupId);
   const [isOpenSelect, setIsOpenSelect] = useState(false);
   const [isOpenCollapse, setIsOpenCollapse] = useState(false);
   const [selectOption1, setSelectOption1] = useState<any>("");
   const [teacherProfile, setTeacherProfile] = useState<ITeacherProfile>();
   const [alertSelectFunctional, setAlertSelectFunctional] = useState(false);
   const [isLoadingCreateRoom, setIsLoadingCreateRoom] = useState(false);
   const [teacherName, setTeacherName] = useState("");

   const initialValues: ICreateRoom = {
      name: "",
      topic: "",
      teacher: -1,
      group: groupId,

      zoom_join_url: "",
      zoom_license: "",
      zoom_password: "",

      meet_id: "",
      meet_password: "",
   };

   const optionSelection1 = (option: any): void => {
      setSelectOption1(option);
      setTeacherName(option.full_name);
   };

   if (isLoading || !rooms) return <ScreenLoader />;

   return (
      <div>
         <Formik
            initialValues={initialValues}
            validationSchema={
               isOpenCollapse ? CreateRoomWithManualLinkZoom : CreateRoomSchema
            }
            onSubmit={async (values, { resetForm }) => {
               try {
                  if (selectOption1.id >= 0 && !alertSelectFunctional) {
                     if (isOpenCollapse) {
                        setIsLoadingCreateRoom(true);
                        const data = {
                           name: values.name.trim(),
                           topic: values.topic.trim(),
                           teacher:
                              selectOption1.id &&
                              teacherName &&
                              selectOption1.id,
                           group: values.group,

                           zoom_join_url: values.zoom_join_url!,
                           zoom_license: values.zoom_license!,
                           zoom_password: values.zoom_password!.trim(),

                           meet_id: clearString(values.meet_id!),
                           meet_password: values.meet_password!.trim(),
                        };
                        const response = await dispatch(
                           addRoomManualLinkZoom(data)
                        );
                        if (
                           response.type ===
                           "rooms/addRoomManualLinkZoom/rejected"
                        ) {
                           setIsLoadingCreateRoom(false);
                           openModalAlertCreateRoom();
                        } else {
                           resetForm();
                           setSelectOption1(null);
                           closeModal();
                           setIsLoadingCreateRoom(false);
                           await dispatch(listRoomsByGroupId(Number(groupId)));
                           await dispatch(
                              getAllRooms(
                                 Number(
                                    user?.permission[PERMISSIONS.roomListAll]
                                       ? 0
                                       : user?.id
                                 )
                              )
                           );
                        }
                     } else {
                        setIsLoadingCreateRoom(true);
                        const onlyNecessaryData = {
                           name: values.name.trim(),
                           topic: values.topic.trim(),
                           teacher:
                              selectOption1.id &&
                              teacherName &&
                              selectOption1.id,
                           group: values.group,
                        };
                        const response = await dispatch(
                           addRoom(onlyNecessaryData)
                        );
                        if (response.type === "rooms/addRoom/rejected") {
                           setIsLoadingCreateRoom(false);
                           openModalAlertCreateRoom();
                        } else {
                           resetForm();
                           setSelectOption1(null);
                           closeModal();
                           setIsLoadingCreateRoom(false);
                           await dispatch(listRoomsByGroupId(Number(groupId)));
                           await dispatch(
                              getAllRooms(
                                 Number(
                                    user?.permission[PERMISSIONS.roomListAll]
                                       ? 0
                                       : user?.id
                                 )
                              )
                           );
                        }
                     }
                  }
               } catch (error) {
                  setIsLoadingCreateRoom(false);
                  resetForm();
                  setSelectOption1(null);
               }
            }}
         >
            {({ errors, touched, values, handleChange }) => (
               <Form>
                  <Modal
                     width="xl"
                     isOpen={isOpenModal}
                     closeModal={closeModal}
                     title="Crear Salón"
                     body={
                        <div className="modal-create-room__content-body">
                           <div className="modal-create-room__room-information">
                              <Typography
                                 bold
                                 type="paragraph2"
                                 variation="mobile"
                              >
                                 Nombre
                              </Typography>
                              <Input
                                 placeholder="Nombre de salón"
                                 name="name"
                                 onChange={handleChange}
                                 value={values.name}
                                 required
                                 error={
                                    errors.name && touched.name
                                       ? errors.name
                                       : ""
                                 }
                              />
                              <Typography
                                 bold
                                 type="paragraph2"
                                 variation="mobile"
                              >
                                 Asunto
                              </Typography>
                              <Input
                                 placeholder="Asunto de salón"
                                 name="topic"
                                 onChange={handleChange}
                                 value={values.topic}
                                 required
                                 error={
                                    errors.topic && touched.topic
                                       ? errors.topic
                                       : ""
                                 }
                              />
                              <Typography
                                 bold
                                 type="paragraph2"
                                 variation="mobile"
                              >
                                 Profesor
                              </Typography>
                              <div className="modal-create-room__container-select">
                                 <SelectFunctional
                                    onClickOutside={() =>
                                       setIsOpenSelect(false)
                                    }
                                    setIsOpen={setIsOpenSelect}
                                    isOpen={isOpenSelect}
                                    size="default"
                                    onclickDefaultValue={optionSelection1}
                                    identifierSort="full_name"
                                    options={teachers}
                                    disabled={!teachers}
                                    alert={alertSelectFunctional}
                                    setAlert={setAlertSelectFunctional}
                                    teacherProfile={teacherProfile}
                                    setTeacherProfile={setTeacherProfile}
                                    teacherName={teacherName}
                                    setTeacherName={setTeacherName}
                                 />
                              </div>
                              <Typography
                                 bold
                                 type="paragraph2"
                                 variation="mobile"
                              >
                                 Contactar
                              </Typography>
                              <section className="modal-create-room__section-contact">
                                 <ButtonLineal
                                    className="modal-create-room__button-contact"
                                    size="small"
                                    external
                                    underline={false}
                                    disabled={
                                       teacherProfile && teacherProfile.discord
                                          ? false
                                          : true
                                    }
                                    to={`https://discord.com/users/${teacherProfile?.discord}`}
                                 >
                                    <section className="modal-create-room__container-discord">
                                       <IconDiscord
                                          fill={
                                             teacherProfile &&
                                             teacherProfile.discord
                                                ? colors.discord
                                                : colors.gris03
                                          }
                                       />
                                       <Typography
                                          type="paragraph2"
                                          variation="mobile"
                                          bold
                                          color={
                                             teacherProfile &&
                                             teacherProfile.discord
                                                ? colors.discord
                                                : colors.gris03
                                          }
                                       >
                                          Discord
                                       </Typography>
                                    </section>
                                 </ButtonLineal>
                                 <ButtonLineal
                                    className="modal-create-room__button-contact"
                                    size="small"
                                    external
                                    underline={false}
                                    disabled={
                                       teacherProfile &&
                                       teacherProfile.phone_number
                                          ? false
                                          : true
                                    }
                                    to={`https://api.whatsapp.com/send/?phone=${teacherProfile?.phone_number}`}
                                 >
                                    <section className="modal-create-room__container-whatsapp">
                                       <IconWhatsapp
                                          fill={
                                             teacherProfile &&
                                             teacherProfile.phone_number
                                                ? colors.whatsapp
                                                : colors.gris03
                                          }
                                       />
                                       <Typography
                                          type="paragraph2"
                                          variation="mobile"
                                          bold
                                          color={
                                             teacherProfile &&
                                             teacherProfile.phone_number
                                                ? colors.whatsapp
                                                : colors.gris03
                                          }
                                       >
                                          WhatsApp
                                       </Typography>
                                    </section>
                                 </ButtonLineal>
                              </section>
                           </div>

                           <div className="modal-create-room__zoom-information">
                              <CollapseWrapper
                                 isOpen={isOpenCollapse}
                                 setIsOpen={setIsOpenCollapse}
                                 title="Asignar enlace de Zoom"
                                 body={
                                    <div className="modal-create-room__content-zoom-information">
                                       <Typography
                                          bold
                                          type="paragraph2"
                                          variation="mobile"
                                       >
                                          Enlace
                                       </Typography>
                                       <Input
                                          placeholder="Enlace de Zoom del estudiante"
                                          name="zoom_join_url"
                                          value={values.zoom_join_url}
                                          onChange={handleChange}
                                          error={
                                             errors.zoom_join_url &&
                                             touched.zoom_join_url
                                                ? errors.zoom_join_url
                                                : ""
                                          }
                                       />
                                       <Typography
                                          bold
                                          type="paragraph2"
                                          variation="mobile"
                                       >
                                          Licencia
                                       </Typography>
                                       <Input
                                          placeholder="Licencia de Zoom"
                                          name="zoom_license"
                                          value={values.zoom_license}
                                          onChange={handleChange}
                                          error={
                                             errors.zoom_license &&
                                             touched.zoom_license
                                                ? errors.zoom_license
                                                : ""
                                          }
                                       />
                                       <Typography
                                          bold
                                          type="paragraph2"
                                          variation="mobile"
                                       >
                                          Contraseña
                                       </Typography>
                                       <Input
                                          placeholder="Contraseña de Zoom"
                                          name="zoom_password"
                                          value={values.zoom_password}
                                          onChange={handleChange}
                                          error={
                                             errors.zoom_password &&
                                             touched.zoom_password
                                                ? errors.zoom_password
                                                : ""
                                          }
                                       />
                                       <Typography
                                          bold
                                          type="paragraph2"
                                          variation="mobile"
                                       >
                                          ID meet
                                       </Typography>
                                       <Input
                                          placeholder="ID meet"
                                          name="meet_id"
                                          value={values.meet_id}
                                          onChange={handleChange}
                                          error={
                                             errors.meet_id && touched.meet_id
                                                ? errors.meet_id
                                                : ""
                                          }
                                       />
                                       <Typography
                                          bold
                                          type="paragraph2"
                                          variation="mobile"
                                       >
                                          Contraseña
                                       </Typography>
                                       <Input
                                          placeholder="Contraseña de meet"
                                          name="meet_password"
                                          value={values.meet_password}
                                          onChange={handleChange}
                                          error={
                                             errors.meet_password &&
                                             touched.meet_password
                                                ? errors.meet_password
                                                : ""
                                          }
                                       />
                                    </div>
                                 }
                              />
                           </div>
                           <section className="modal-create-room__container-buttons">
                              <ButtonOutline
                                 type="reset"
                                 size="default"
                                 disabled={isLoadingCreateRoom}
                                 onClick={() => {
                                    closeModal();
                                    setSelectOption1(null);
                                    setTeacherName("");
                                 }}
                              >
                                 Cancelar
                              </ButtonOutline>
                              <ButtonFilled
                                 type="submit"
                                 size="default"
                                 disabled={isLoadingCreateRoom}
                                 onClick={
                                    !selectOption1?.id ||
                                    !teacherName ||
                                    teacherName.length < 8
                                       ? () => setAlertSelectFunctional(true)
                                       : () => setAlertSelectFunctional(false)
                                 }
                              >
                                 Crear
                              </ButtonFilled>
                           </section>
                        </div>
                     }
                  />
               </Form>
            )}
         </Formik>
         {errorCreateRoom && (
            <ModalMessageAlert
               width="xs"
               title={
                  <Typography bold type="paragraph1" variation="desktop">
                     Algo salió mal...
                  </Typography>
               }
               message="Parece que has añadido un valor incorrecto o que ya expiró. Revisa y corrije el espacio marcado en rojo para continuar."
               isOpenModal={isOpenModalAlertCreateRoom}
               openModal={openModalAlertCreateRoom}
               closeModal={closeModalAlertCreateRoom}
               button1={
                  <ButtonFilled
                     size="default"
                     onClick={closeModalAlertCreateRoom}
                  >
                     Aceptar
                  </ButtonFilled>
               }
            />
         )}
      </div>
   );
};

export default ModalCreateRoom;
