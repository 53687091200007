// Moment
import moment from "moment";

// Interfaces
import { IRoomDetail } from "../interfaces/room.interface";

export const objectsEqual = (o1: any[], o2: any[]): boolean =>
  typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p: any) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

export const sortAlphabetically = (array: any[], field: string): any[] => {
  const arrayForSort = [...array];

  if (array.length === 0) return [];
  return arrayForSort.sort((a: any, b: any) => a[field].localeCompare(b[field]));
};

export const getCurrentRooms = (rooms: IRoomDetail[], lastMonth: number) => {
  const currentRooms = rooms.filter((room) => {
    const end_date = moment(room.group.end_date);
    const today = moment();

    if (room.status === "has_ended" && today.diff(end_date, "months") >= lastMonth) {
      return false;
    } else {
      return true;
    }
  });

  return currentRooms;
};

export const escapeRegExp = (value: string): string => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export const clearString = (string: string): string => {
  return string
    .trim()
    .split("")
    .filter((char: string) => char !== " " && char)
    .join("");
};

export const searchClearner = (search: string) => {
  if (search) {
    return search
      .toLowerCase()
      .replace(/\s+/g, " ")
      .trim()
      .replace("á", "a")
      .replace("é", "e")
      .replace("í", "i")
      .replace("ó", "o")
      .replace("ú", "u")
      .replace("ñ", "n")

      .replace("Á", "A")
      .replace("É", "E")
      .replace("Í", "I")
      .replace("Ó", "O")
      .replace("Ú", "U")
      .replace("Ñ", "N");
  } else {
    return "";
  }
};
