// React Query
import { useMutation, useQuery, useQueryClient, QueryObserver } from "react-query";

// Yup
import * as Yup from "yup";

// Formik
import { Form, Formik } from "formik";

// API
import { GET, PATCH } from "../../../../../services/api/api";

// Hot Toast
import { toast } from "react-hot-toast";

// Interfaces
import { ILearningPath } from "../../../../../interfaces/learningPaths.interface";

// Material UI
import {
  Alert,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Chip
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// Components
import ToDo from "../../../../../components/ToDo/ToDo";
import SuperToDo from "../../../../../components/SuperToDo/SuperToDo";
import RangeAge from "../../../../../components/RangeAge/RangeAge";

const TechnicalDataSchema = Yup.object().shape({
  order: Yup.number().min(0, "Muy pequeño!"),
  age_range: Yup.string(),
  frequency: Yup.number().min(0, "Muy pequeño!"),
  duration: Yup.number().min(0, "Muy pequeño!").max(100, "Mucho texto!"),
  available_places: Yup.number().min(0, "Muy pequeño!"),
  main_color: Yup.string(),
  secondary_color: Yup.string(),
  stripe_product_id: Yup.string(),

  gradient_colors: Yup.array().required().min(1),
  category: Yup.string(),

  technical_requirements: Yup.array().required().min(1),
  benefits: Yup.array(),
});

interface Props {
  refetch: () => {};
  learningPathId: number;
  isTechnicalDataSent: boolean;
  setIsTechnicalDataSent: any;
}

const TechnicalData: React.FC<Props> = ({
  refetch,
  learningPathId,
  isTechnicalDataSent,
  setIsTechnicalDataSent,
}) => {
  const { data: learningPath, remove } = useQuery(
    ["learningPath-technical-data", learningPathId],
    () => GET<ILearningPath>(`/learning_paths_backoffice/${learningPathId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!learningPathId && isTechnicalDataSent,
    }
  );

  const updateCourseTechnicalData = useMutation(
    (technicalData: any) =>
      PATCH(`/learning_paths_backoffice/${learningPathId}/`, technicalData),
    {
      retry: false,
    }
  );

  const {
    data: groupAgesLearningPath,
    error: errorAgesLearningPath,
    isError: isErrorAgesLearningPath,
    isLoading: isLoadingAgesLearningPath,
  } = useQuery(
    ["group-ages-learning-path", learningPathId],
    () => GET<{ model_id: number, model_type: string, min_age: number, max_age: number }>(`/base/group-age/learning_path/${learningPathId}`),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();
  const { refetch: refetchAgeGroup } = new QueryObserver(queryClient, {
    queryKey: ["group-ages-learning-path", learningPathId],
  });

  if (!!learningPathId && isTechnicalDataSent && !learningPath)
    return <LinearProgress />;

  if (isLoadingAgesLearningPath || !groupAgesLearningPath) return <LinearProgress />;
  if (isErrorAgesLearningPath) return <div>Error! {JSON.stringify(errorAgesLearningPath)}</div>;

  let initialValues: any = {
    order: 0,
    age_range: "none",
    frequency: 0,
    duration: 0,
    available_places: 0,
    main_color: "#000000",
    secondary_color: "#000000",
    stripe_product_id: "",

    gradient_colors: [],
    category: "none",

    technical_requirements: [],
    benefits: [],
  };

  if (isTechnicalDataSent && learningPath) {
    initialValues = {
      order: learningPath.order,
      age_range: learningPath.age_range,
      frequency: learningPath.frequency,
      duration: learningPath.duration,
      available_places: learningPath.available_places,
      main_color: learningPath.main_color,
      secondary_color: learningPath.secondary_color,
      stripe_product_id: learningPath.stripe_product_id,

      gradient_colors: learningPath.gradient_colors,
      category: learningPath.category,

      technical_requirements: learningPath.technical_requirements,
      benefits: learningPath.benefits,
    };
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={TechnicalDataSchema}
      onSubmit={async (values) => {
        await toast.promise(
          updateCourseTechnicalData
            .mutateAsync(values)
            .then(() => setIsTechnicalDataSent(true)),
          {
            loading: "Cargando...",
            success: "Datos Técnicos creado",
            error: "Error",
          },
          {
            success: {
              icon: <AddCircleIcon color="primary" />,
            },
          }
        );

        remove();
        refetch();
      }}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Grid container rowSpacing={2} alignItems="flex-start">
            {/* Main */}
            <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
              {/* Order */}
              <Grid item xs={2}>
                <TextField
                  autoFocus
                  fullWidth
                  id="order"
                  label="Order"
                  placeholder="Order in list"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.order}
                  onChange={handleChange}
                />
                {errors.order && touched.order && (
                  <Alert severity="error">{errors.order}</Alert>
                )}
              </Grid>

              {/* Frecuency */}
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  id="frequency"
                  label="Frecuency"
                  placeholder="Frecuency"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.frequency}
                  onChange={handleChange}
                />
                {errors.frequency && touched.frequency && (
                  <Alert severity="error">{errors.frequency}</Alert>
                )}
              </Grid>

              {/* Duration */}
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  id="duration"
                  label="Duration"
                  placeholder="Duration"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.duration}
                  onChange={handleChange}
                />
                {errors.duration && touched.duration && (
                  <Alert severity="error">{errors.duration}</Alert>
                )}
              </Grid>

              {/* Age_range */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="age_range">Age range</InputLabel>
                  <Select
                    labelId="age_range"
                    id="age_range"
                    label="age_range"
                    value={values.age_range}
                    disabled={isTechnicalDataSent}
                    onChange={(value) =>
                      setFieldValue("age_range", value.target.value)
                    }
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="5_to_7">5 to 7</MenuItem>
                    <MenuItem value="8_plus">8 plus</MenuItem>
                    <MenuItem value="9_plus">9 plus</MenuItem>
                    <MenuItem value="10_plus">10 plus</MenuItem>
                    <MenuItem value="12_plus">12 plus</MenuItem>
                  </Select>
                  {errors.age_range && touched.age_range && (
                    <Alert severity="error">{errors.age_range}</Alert>
                  )}
                </FormControl>
              </Grid>

              {/* Category */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="category">Category</InputLabel>
                  <Select
                    labelId="category"
                    id="category"
                    label="category"
                    value={values.category}
                    disabled={isTechnicalDataSent}
                    onChange={(value) =>
                      setFieldValue("category", value.target.value)
                    }
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="design_and_creativity">
                      Design and creativity
                    </MenuItem>
                    <MenuItem value="development">Development</MenuItem>
                  </Select>
                  {errors.category && touched.category && (
                    <Alert severity="error">{errors.category}</Alert>
                  )}
                </FormControl>
              </Grid>

              {/* Available_places */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="available_places"
                  label="Available places"
                  placeholder="Available places"
                  variant="outlined"
                  type="number"
                  disabled={isTechnicalDataSent}
                  value={values.available_places}
                  onChange={handleChange}
                />
                {errors.available_places && touched.available_places && (
                  <Alert severity="error">{errors.available_places}</Alert>
                )}
              </Grid>

              {/* Main_color */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="main_color"
                  label="Main color"
                  placeholder="Main color"
                  variant="outlined"
                  type="color"
                  disabled={isTechnicalDataSent}
                  value={values.main_color}
                  onChange={handleChange}
                />
                {errors.main_color && touched.main_color && (
                  <Alert severity="error">{errors.main_color}</Alert>
                )}
              </Grid>

              {/* Secondary_color */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="secondary_color"
                  label="Secondary color"
                  placeholder="Secondary color"
                  variant="outlined"
                  type="color"
                  disabled={isTechnicalDataSent}
                  value={values.secondary_color}
                  onChange={handleChange}
                />
                {errors.secondary_color && touched.secondary_color && (
                  <Alert severity="error">{errors.secondary_color}</Alert>
                )}
              </Grid>

              {/* Product_id_strip */}
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="stripe_product_id"
                  label="Product id stripe"
                  placeholder="Product id stripe"
                  variant="outlined"
                  type="string"
                  disabled={isTechnicalDataSent}
                  value={values.stripe_product_id}
                  onChange={handleChange}
                />
                {errors.stripe_product_id && touched.stripe_product_id && (
                  <Alert severity="error">{errors.stripe_product_id}</Alert>
                )}
              </Grid>

              {/* Age Range */}
              <Grid item xs={12}>
                <Divider textAlign="left">
                  <Chip label='Age Range' />
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <RangeAge
                  model_id={groupAgesLearningPath.model_id}
                  model_type={groupAgesLearningPath.model_type}
                  name={groupAgesLearningPath.model_type}
                  min_age={groupAgesLearningPath.min_age}
                  max_age={groupAgesLearningPath.max_age}
                  refetch={refetchAgeGroup}
                />
              </Grid>

              {/* Technical_requirements */}
              <Grid item xs={6}>
                <ToDo
                  label="Technical requirements"
                  placeholder="Add technical requirements"
                  values={values.technical_requirements}
                  enabled={!!learningPathId && isTechnicalDataSent}
                  setFieldValue={setFieldValue}
                  to="technical_requirements"
                />
                {errors.technical_requirements && touched.technical_requirements && (
                  <Alert severity="error">{errors.technical_requirements}</Alert>
                )}
              </Grid>

              {/* Gradient_colors */}
              <Grid item xs={6}>
                <ToDo
                  label="Gradient colors"
                  placeholder="Gradient colors"
                  values={values.gradient_colors}
                  enabled={!!learningPathId && isTechnicalDataSent}
                  setFieldValue={setFieldValue}
                  to="gradient_colors"
                />
                {errors.gradient_colors && touched.gradient_colors && (
                  <Alert severity="error">{errors.gradient_colors}</Alert>
                )}
              </Grid>

              {/* Benefits */}
              <Grid item xs={12}>
                <SuperToDo
                  label="Benefits"
                  values={values.benefits}
                  enabled={!!learningPathId && isTechnicalDataSent}
                  setFieldValue={setFieldValue}
                  to="benefits"
                />
              </Grid>
            </Grid>

            {/* Save */}
            <Grid item container rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={updateCourseTechnicalData.isLoading}
                  variant="contained"
                  type="submit"
                  disabled={isTechnicalDataSent}
                  startIcon={<AddCircleIcon />}
                >
                  Crear
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default TechnicalData;
