// React Query
import { useMutation, useQuery } from "react-query";

// Yup
import * as Yup from "yup";

// Formik
import { Form, Formik } from "formik";

// API
import { GET, PATCH } from "../../../../services/api/api";

// Hot Toast
import { toast } from "react-hot-toast";

// Interfaces
import { ILearningPath } from "../../../../interfaces/learningPaths.interface";
import { currencies } from "../../../../interfaces/currency.interface";

// Utils
import {
  initialValueByCurrency,
  submitByCurrency,
} from "../../../../utils/currency";

// Material UI
import {
  Alert,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import UpdateIcon from "@mui/icons-material/Update";

const PricesSchema = Yup.object().shape({
  available_to_purchase: Yup.boolean(),

  // Standar amount
  amount: Yup.number().min(0, "Muy pequeño!"),
  // Per month
  amount_per_month: Yup.number().min(0, "Muy pequeño!"),

  // Discount ------------------------------------------
  has_discount: Yup.boolean(),
  discount_percentage: Yup.number().min(0, "Muy pequeño!"),

  // Standar amount + discount
  amount_after_discount: Yup.number().min(0, "Muy pequeño!"),
  // Per month + discount
  amount_after_discount_per_month: Yup.number().min(0, "Muy pequeño!"),

  amount_full_course_by_course: Yup.number().min(0, "Muy pequeño!"),
});

interface Props {
  learningPathId: number;
  currency: currencies;
  refetch: () => {};
}

const Currency: React.FC<Props> = ({ learningPathId, currency, refetch }) => {
  const {
    data: prices,
    error: errorPrices,
    isError: isErrorPrices,
    isLoading: isLoadingPrices,
    remove,
  } = useQuery(
    ["learningPath-PRICES", learningPathId],
    // () => GET<any>(`/learning_paths_backoffice/${pricesId}/prices/${currency}`),
    () => GET<ILearningPath>(`/learning_paths_backoffice/${learningPathId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const editPrices = useMutation(
    (prices: any) => PATCH(`/learning_paths_backoffice/${learningPathId}/`, prices),
    {
      retry: false,
    }
  );

  if (isLoadingPrices || !prices) return <LinearProgress />;
  if (isErrorPrices) return <div>Error! {JSON.stringify(errorPrices)}</div>;

  return (
    <Formik
      initialValues={initialValueByCurrency(currency, prices)}
      validationSchema={PricesSchema}
      onSubmit={async (values) => {
        await toast.promise(
          editPrices.mutateAsync(submitByCurrency(currency, values)),
          {
            loading: "Cargando...",
            success: "Precios editado",
            error: "Error",
          },
          {
            success: {
              icon: <UpdateIcon color="primary" />,
            },
          }
        );

        remove();
        refetch();
      }}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Grid container rowSpacing={2} alignItems="flex-start">
            {/* Main */}
            <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
              {/* Amount */}
              <Grid item container columnSpacing={1} rowSpacing={2} xs={5}>
                {/* Available_to_purchase */}
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Switch
                      checked={values.available_to_purchase}
                      color="success"
                      onChange={(e) =>
                        setFieldValue("available_to_purchase", e.target.checked)
                      }
                    />
                    {values.available_to_purchase ? (
                      <Chip
                        icon={<VisibilityIcon />}
                        color="success"
                        label="Available to purchase"
                      />
                    ) : (
                      <Chip
                        icon={<VisibilityOffIcon />}
                        color="warning"
                        label="Not available to purchase"
                      />
                    )}
                  </Stack>
                </Grid>

                {/* Alert */}
                <Grid item xs={12}>
                  {values.available_to_purchase ? (
                    <Alert variant="standard" severity="success">
                      Available to purchase
                    </Alert>
                  ) : (
                    <Alert variant="standard" severity="warning">
                      NOT Available to purchase
                    </Alert>
                  )}
                </Grid>

                {/* Amount */}
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    fullWidth
                    id="amount"
                    label="Amount"
                    placeholder="Amount"
                    variant="outlined"
                    type="number"
                    value={values.amount}
                    onChange={handleChange}
                  />
                  {errors.amount && touched.amount && (
                    <Alert severity="error">{errors.amount}</Alert>
                  )}
                </Grid>

                {/* Amount_per_month */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="amount_per_month"
                    label="Amount PER MONTH"
                    placeholder="Amount PER MONTH"
                    variant="outlined"
                    type="number"
                    value={values.amount_per_month}
                    onChange={handleChange}
                  />
                  {errors.amount_per_month && touched.amount_per_month && (
                    <Alert severity="error">{errors.amount_per_month}</Alert>
                  )}
                </Grid>
              </Grid>

              {/* Discount */}
              <Grid item container columnSpacing={1} rowSpacing={2} xs={7}>
                {/* Has_discount */}
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Switch
                      checked={values.has_discount}
                      color="success"
                      onChange={(e) =>
                        setFieldValue("has_discount", e.target.checked)
                      }
                    />
                    {values.has_discount ? (
                      <Chip
                        icon={<VisibilityIcon />}
                        color="success"
                        label="Has Discount"
                      />
                    ) : (
                      <Chip
                        icon={<VisibilityOffIcon />}
                        color="warning"
                        label="Has NOT Discount"
                      />
                    )}
                  </Stack>
                </Grid>

                {/* Discount_percentage */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="discount_percentage"
                    label="Discount percentage"
                    placeholder="Discount percentage"
                    variant="outlined"
                    type="number"
                    disabled={!values.has_discount}
                    value={values.discount_percentage}
                    onChange={handleChange}
                  />
                  {errors.discount_percentage && touched.discount_percentage && (
                    <Alert severity="error">{errors.discount_percentage}</Alert>
                  )}
                </Grid>

                {/* Amount_after_discount */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="amount_after_discount"
                    label="Amount AFTER DISCOUNT"
                    placeholder="Amount AFTER DISCOUNT"
                    variant="outlined"
                    type="number"
                    disabled={!values.has_discount}
                    value={values.amount_after_discount}
                    onChange={handleChange}
                  />
                  {errors.amount_after_discount && touched.amount_after_discount && (
                    <Alert severity="error">{errors.amount_after_discount}</Alert>
                  )}
                </Grid>

                {/* Amount_after_discount_per_month */}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="amount_after_discount_per_month"
                    label="Amount AFTER DISCOUNT per month"
                    placeholder="Amount AFTER DISCOUNT per month"
                    variant="outlined"
                    type="number"
                    disabled={!values.has_discount}
                    value={values.amount_after_discount_per_month}
                    onChange={handleChange}
                  />
                  {errors.amount_after_discount_per_month &&
                    touched.amount_after_discount_per_month && (
                      <Alert severity="error">
                        {errors.amount_after_discount_per_month}
                      </Alert>
                    )}
                </Grid>
              </Grid>

              {/* Amount_full_course_by_course */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="amount_full_course_by_course"
                  label="Amount full course by course"
                  placeholder="Amount full course by course"
                  variant="outlined"
                  type="number"
                  value={values.amount_full_course_by_course}
                  onChange={handleChange}
                />
                {errors.amount_full_course_by_course &&
                  touched.amount_full_course_by_course && (
                    <Alert severity="error">
                      {errors.amount_full_course_by_course}
                    </Alert>
                  )}
              </Grid>
            </Grid>

            {/* Save */}
            <Grid item container rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={editPrices.isLoading}
                  variant="contained"
                  type="submit"
                  startIcon={<UpdateIcon />}
                >
                  Editar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default Currency;
