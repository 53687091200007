// Axios
import axios, { AxiosError, AxiosResponse } from "axios";

// Helpers
import { getAccessCookie } from "../helpers/cookies.helpers";
import {
   ITeacherAvailability,
   ITeacherProfile,
} from "../interfaces/teacher.interface";

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? TEACHERS
export const getTeachers = async () => {
   try {
      const response = await axios.get<ITeacherAvailability[]>(
         `${instanceBaseURL}/teachers-backoffice/?no-page`,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return response.data;
   } catch (err) {
      let error: AxiosError = err as AxiosError;
      return error.response?.data.detail;
   }
};

export const getTeachersByAvailability = async () => {
   try {
      const response = await axios.get<ITeacherAvailability[]>(
         `${instanceBaseURL}/teacher-profile/`,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return response.data;
   } catch (err) {
      let error: AxiosError = err as AxiosError;
      return error.response?.data.detail;
   }
};

export const getTeacherProfileById = async (teacherId: number) => {
   const response = await axios.get<ITeacherProfile>(
      `${instanceBaseURL}/teacher-profile-edit/${teacherId}/`,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return response.data;
};

export const putTeacherProfile = async (
   teacherId: number,
   dataProfile: any
) => {
   try {
      const response = await axios.put(
         `${instanceBaseURL}/teacher-profile-edit/${teacherId}/`,
         dataProfile,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return response;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};
