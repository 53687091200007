// Material UI
import { Button, IconButton, Tooltip } from "@mui/material";

// Icons
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import UpdateIcon from "@mui/icons-material/Update";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

interface Props {
  title?: string;
  option: "more-info" | "create" | "edit" | "duplicate" | "enroll";
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  handleOpenModal: () => void;
}

const OpenModal: React.FC<Props> = ({
  title,
  option,
  placement,
  handleOpenModal,
}) => {
  switch (option) {
    case "more-info":
      return (
        <Tooltip title={title || "Mas Información"} arrow placement={placement}>
          <IconButton
            aria-label="more-info"
            color="primary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenModal();
            }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </Tooltip>
      );
    case "edit":
      return (
        <Tooltip title={title || "Editar"} arrow placement={placement}>
          <IconButton
            aria-label="edit"
            color="secondary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenModal();
            }}
          >
            <UpdateIcon />
          </IconButton>
        </Tooltip>
      );
    case "create":
      return (
        <Button
          fullWidth
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            handleOpenModal();
          }}
          startIcon={<AddCircleIcon />}
        >
          {title || "Crear"}
        </Button>
      );
    case "duplicate":
      return (
        <Tooltip title={title || "Duplicar"} arrow placement={placement}>
          <IconButton
            aria-label="duplicate"
            color="secondary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenModal();
            }}
          >
            <CopyAllIcon />
          </IconButton>
        </Tooltip>
      );
    case "enroll":
      return (
        <Tooltip title={title || "Inscribir"} arrow placement={placement}>
          <IconButton
            aria-label="enroll"
            color="primary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenModal();
            }}
          >
            <CreateNewFolderIcon />
          </IconButton>
        </Tooltip>
      );
    default:
      return <>Modal</>;
  }
};

export default OpenModal;
