// Material UI
import { Avatar, Typography, useTheme } from "@material-ui/core";
import colors from "../../atoms/Colors";

// Interfaces
interface AttendanceIndicatorProps {
   message: string;
   status: "attended" | "delay" | "absence" | "justified";
}

const AttendanceIndicator = ({ message, status }: AttendanceIndicatorProps) => {
   const theme = useTheme();

   return (
      <Avatar
         variant="square"
         sx={{
            bgcolor: "transparent",
            color:
               status === "attended"
                  ? colors.statusAttendedText
                  : status === "delay"
                  ? colors.statusDelayText
                  : status === "absence"
                  ? colors.statusAbsenceText
                  : status === "justified"
                  ? colors.statusJustifiedText
                  : colors.gris03,
            height: 24,
         }}
      >
         <Typography
            variant="body2"
            sx={{
               fontFamily: "Sofia Pro",
               fontStyle: "normal",
               fontWeight: 400,
               fontSize: 14,
            }}
         >
            {message}
         </Typography>
      </Avatar>
   );
};

export default AttendanceIndicator;
