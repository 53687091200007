// React Query
import { useQuery } from "react-query";

// API
import { GET } from "../../../../../services/api/api";

// Interfaces
import { ILearningPath } from "../../../../../interfaces/learningPaths.interface";

// Material UI
import { Grid, LinearProgress } from "@mui/material";

// Components
import LearningPathFaqs from "../../../../../components/LearningPaths/PathFaqs/PathFaqs";

interface Props {
  refetch: () => {};
  learningPathId: number;
}

const PathFaqs: React.FC<Props> = ({ refetch, learningPathId }) => {
  const {
    data: learningPath,
    error: errorLearningPath,
    isError: isErrorLearningPath,
    isLoading: isLoadingLearningPath,
    refetch: refetchLearningPath,
  } = useQuery(
    ["learningPath-pathFaqs", learningPathId],
    () => GET<ILearningPath>(`/learning_paths_backoffice/${learningPathId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoadingLearningPath || !learningPath) return <LinearProgress />;
  if (isErrorLearningPath)
    return <div>Error! {JSON.stringify(errorLearningPath)}</div>;

  return (
    <Grid container rowSpacing={2} alignItems="flex-start">
      {/* Main */}
      <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
        <LearningPathFaqs
          faqs={learningPath.faqs}
          learningPathId={learningPathId}
          refetch={refetch}
          refetchLearningPath={refetchLearningPath}
        />
      </Grid>
    </Grid>
  );
};

export default PathFaqs;
