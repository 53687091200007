// React Query
import { QueryObserver, useQuery, useQueryClient } from "react-query";

// API
import { GET } from "../../../services/api/api";

// Utils
import { sortAlphabetically } from "../../../utils/utils";

// Interfaces
import { IRecommendation } from "../../../interfaces/recommendation.interface";

// Material UI
import { LinearProgress, List } from "@mui/material";
import Grid from "@mui/material/Grid";

// Components
import CustomListItem from "./CustomListItem/CustomListItem";

interface Props {
  originId: number;
  originType: "course" | "learning_path";
  recommendedType: "course" | "learning_path";
  refetch: () => {};
}

const ListRecommendation: React.FC<Props> = ({
  originId,
  originType,
  recommendedType,
  refetch,
}) => {
  // Query Client
  const queryClient = useQueryClient();
  const { refetch: refetchRecommendation } = new QueryObserver(queryClient, {
    queryKey: `recommended-${originType}`,
  });

  const { data, error, isError, isLoading } = useQuery(
    [`recommended-${originType}`],
    () => GET<IRecommendation>(`/recommended/backoffice/${originType}/${originId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading || !data) return <LinearProgress />;
  if (isError) return <div>Error! {JSON.stringify(error)}</div>;

  const list = (recommendedType: "course" | "learning_path") => {
    switch (recommendedType) {
      case "course":
        return sortAlphabetically(data.recommended_courses, "model_name").map(
          ({ id, model_name }) => (
            <CustomListItem
              key={id}
              id={id}
              name={model_name}
              refetch={refetch}
              refetchRecommendation={refetchRecommendation}
            />
          )
        );

      case "learning_path":
        return sortAlphabetically(data.recommended_learning_paths, "model_name").map(
          ({ id, model_name }) => (
            <CustomListItem
              key={id}
              id={id}
              name={model_name}
              refetch={refetch}
              refetchRecommendation={refetchRecommendation}
            />
          )
        );
    }
  };

  return (
    <Grid item container columnSpacing={1} xs={12}>
      {/* Main */}
      <Grid item xs={12}>
        <List
          sx={{
            width: "100%",
            padding: 0,
            maxHeight: "150px",
            overflowY: "scroll",
          }}
        >
          {list(recommendedType)}
        </List>
      </Grid>
    </Grid>
  );
};

export default ListRecommendation;
