// React
import { useState } from "react";

// Interfaces
import { ICouponProgramForm, ICouponProgram } from "../../../interfaces/coupon.interface";

// Components
import CreateCoupon from "./CreateCoupon/CreateCoupon";
import OpenModal from "../../../components/Modal/OpenModal/OpenModal";
import Modal from "../../../components/Modal/Modal";

interface Props {
    refetch: () => {};
}

const ModalCreateCoupon: React.FC<Props> = ({ refetch }) => {
    const [openModal, setOpenModal] = useState(false);
    const [currentCouponProgram, setCurrentProgram] = useState<ICouponProgram>({ id: 0, name: '', description: '', all_course: false, all_path: false });

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setCurrentProgram({ id: 0, name: '', description: '', all_course: false, all_path: false });
        setOpenModal(false)
    };

    return (
        <>
            {/* Open Modal */}
            <OpenModal
                option="create"
                title="Crear nuevo Programa de Cupón"
                handleOpenModal={handleOpenModal}
            />

            {/* Modal Create Learning Path */}
            <Modal
                title={currentCouponProgram.id ? "Editar Programa de Cupón" : "Crear Programa de Cupón"}
                fullWidth
                maxWidth="md"
                openModal={openModal}
                handleCloseModal={handleCloseModal}
            >
                <CreateCoupon
                    currentCouponProgram={currentCouponProgram}
                    setCurrentProgram={setCurrentProgram}
                    refetch={refetch}
                    onClose={handleCloseModal} />
            </Modal>
        </>
    );
};

export default ModalCreateCoupon;