//Redux
import { listRoomsByGroupId } from "../../../../../slices/roomSlice/roomsByGroupIdSlice";
import { AppDispatch, useAppDispatch } from "../../../../../store/store";
import { deleteGroupStudent} from "../../../../../slices/groupSlice/groupByIdSlice";

// Material UI
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { confirmDialog } from "../../../../../components/ConfirmDialog/ConfirmDialog";

interface Props {
   groupId: number;
   studentId: number;
   refetch?: () => {};
}

const DeleteGroupStudent: React.FC<Props> = ({
   groupId,
   studentId,
   refetch,
}) => {
   const dispatch: AppDispatch = useAppDispatch();   

   const handleDeleteGroupStudent = async () => {
      try {
         await dispatch(deleteGroupStudent({groupId, studentId}));
      } catch (error) {
         console.log("error: ", error)
      } finally {
         await dispatch(listRoomsByGroupId(Number(groupId)));
      }

   };

   return (
      <Tooltip title="Eliminar del grupo" arrow placement="left">
         <IconButton
            aria-label="Delete"
            color="error"
            size="small"
            onClick={(e) => {
               e.stopPropagation();
               confirmDialog(
                  `¿Realmente quieres eliminar al estudiante (ID:${studentId}) del grupo (ID:${groupId})?`,
                  () =>  handleDeleteGroupStudent() 
               );
            }}
         >
            <DeleteIcon />
         </IconButton>
      </Tooltip>
   );
};

export default DeleteGroupStudent;
