import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { IGuardian } from "../../interfaces/guardian.interface";
import { getGuardians } from "../../services/guardians";

export interface IAuthError {
   msg: string;
}

export interface IGuardianState {
   guardians: IGuardian[];
   guardian: IGuardian | null;
   isLoading: boolean;
   completed: boolean;
   error: IAuthError | null;
   rejected: boolean;
}

const initialState: IGuardianState = {
   guardians: [],
   guardian: null,
   isLoading: false,
   completed: false,
   error: null,
   rejected: false,
};

export const getAllGuardians = createAsyncThunk(
   "guardians/getAllGuardians",
   async (_, { rejectWithValue }: any) => {
      try {
         /* const { auth: user } = getState() as RootState; */
         const data = await getGuardians();
         return data;
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         console.log("error getGuardians: ", error.response?.data);
         return rejectWithValue(error.response?.data);
      }
   }
);

export const guardiansSlice = createSlice({
   name: "guardians",
   initialState,
   reducers: {
      /* addRoom: (state, action) => {
         console.log("state", state);
         console.log("action", action);
      }, */
   },
   extraReducers: (builder) => {
      /** getAllGuardians */
      builder.addCase(getAllGuardians.pending, (state, _) => {
         state.guardians = initialState.guardians;
         state.isLoading = true;
      });
      builder.addCase(getAllGuardians.fulfilled, (state, action) => {
         state.guardians = action.payload.sort((a: any, b: any) => {
            if (a.id < b.id) return 1;
            if (a.id > b.id) return -1;
            return 0;
         });
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(getAllGuardians.rejected, (state, _) => {
         /* state.rooms = initialState.rooms; */
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
   },
});

export const selectStateGuardians = (state: any) => state.guardians;
