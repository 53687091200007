// Interfaces
import { IGroup } from "../../../../../interfaces/enrolment.interface";

// Material UI
import { List } from "@mui/material";

// Components
import ListGroupItem from "./ListGroupItem/ListGroupItem";

interface Props {
  groups: IGroup[];
  groupIds: number[];
  handleGroup: (groupId: number) => () => void;
}

const ListGroup: React.FC<Props> = ({ groups, groupIds, handleGroup }) => {
  return (
    <List
      sx={{ width: "100%", maxHeight: 320, overflow: "auto", padding: 0 }}
      component="nav"
    >
      {groups.map((group) => (
        <ListGroupItem
          key={group.id}
          group={group}
          groupIds={groupIds}
          handleGroup={handleGroup}
        />
      ))}
    </List>
  );
};

export default ListGroup;
