//import React from "react";
import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";

// React router dom
import { useParams, useNavigate } from "react-router-dom";

// Yup
import * as Yup from "yup";

// Hot Toast
import { toast } from "react-hot-toast";

// Interface
import { ITeacherProfile } from "../../interfaces/teacher.interface";

// Formik
import { Formik, Form } from "formik";

// Hooks
import { useModal } from "../../hooks/useModal";

// Services
import {
   getTeacherProfileById,
   putTeacherProfile,
} from "../../services/teachers";

// Components
import colors from "../../components/atoms/Colors";
import Tooltip from "../../components/atoms/Tooltip";
import ModalMessageAlert from "../../components/molecules/ModalMessageAlert";
import IconAlert from "../../components/atoms/icons/IconAlert";
import Typography from "../../components/atoms/Typography";
import ButtonFilled from "../../components/molecules/ButtonFilled";
import ButtonOutline from "../../components/molecules/ButtonOutline";
import FileInput from "../../components/molecules/FileInput";
import Input from "../../components/molecules/Input";
import ScheduleAvailability from "../../components/organism/ScheduleAvailability";
import TagCourses from "../../components/molecules/TagCourses";
import imgProfile from "../../assets/img/imgProfile.png";
import DefaultLayout from "../../components/templates/DefaultLayout";
import ScreenLoader from "../../components/ScreenLoader/ScreenLoader";

import "./teacherProfile.scss";

// Schema Profile
const TeacherProfileSchema = Yup.object().shape({
   first_name: Yup.string()
      .min(3, "Muy pequeño!")
      .max(500, "Mucho texto!")
      .required("Por favor llena este campo"),

   last_name: Yup.string()
      .min(3, "Muy pequeño!")
      .max(500, "Mucho texto!")
      .required("Por favor llena este campo"),

   modality_CTC: Yup.string()
      .min(3, "Muy pequeño!")
      .max(500, "Mucho texto!")
      .required("Por favor llena este campo"),

   email: Yup.string()
      .email("Email invalido")
      .required("Por favor llena este campo"),

   phone_number: Yup.string()
      .min(6, "Muy pequeño!")
      .max(500, "Mucho texto!")
      .required("Por favor llena este campo"),

   discord: Yup.string()
      .min(6, "Muy pequeño!")
      .max(500, "Mucho texto!")
      .required("Por favor llena este campo"),
});

const TeacherProfile = () => {
   const { teacherId } = useParams();
   let navigate = useNavigate();
   const {
      openModal: openModalAlertUpdProfile,
      closeModal: closeModalAlertUpdProfile,
      isOpenModal: isOpenModalAlertUpdProfile,
   } = useModal();
   const {
      openModal: openModalAlertCancelProfile,
      closeModal: closeModalAlertCancelProfile,
      isOpenModal: isOpenModalAlertCancelProfile,
   } = useModal();
   const [teacherProfile, setTeacherProfile] = useState<ITeacherProfile>();
   const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

   const [isActive1, setIsActive1] = useState(false);
   const [schedule1, setSchedule1] = useState([]);
   const [isError1, setIsError1] = useState(false);

   const [isActive2, setIsActive2] = useState(false);
   const [schedule2, setSchedule2] = useState([]);
   const [isError2, setIsError2] = useState(false);

   const [isActive3, setIsActive3] = useState(false);
   const [schedule3, setSchedule3] = useState([]);
   const [isError3, setIsError3] = useState(false);

   const [isActive4, setIsActive4] = useState(false);
   const [schedule4, setSchedule4] = useState([]);
   const [isError4, setIsError4] = useState(false);

   const [isActive5, setIsActive5] = useState(false);
   const [schedule5, setSchedule5] = useState([]);
   const [isError5, setIsError5] = useState(false);

   const [isActive6, setIsActive6] = useState(false);
   const [schedule6, setSchedule6] = useState([]);
   const [isError6, setIsError6] = useState(false);

   const [hasChanged, setHasChanged] = useState(false);
   const [initialSchedule, setInitialSchedule] = useState({
      schedule1: [],
      schedule2: [],
      schedule3: [],
      schedule4: [],
      schedule5: [],
      schedule6: [],
   });

   useEffect(() => {
      teacherProfileById();
      setInitialSchedule({
         schedule1: schedule1,
         schedule2: schedule2,
         schedule3: schedule3,
         schedule4: schedule4,
         schedule5: schedule5,
         schedule6: schedule6,
      });
   }, []);

   const teacherProfileById = async () => {
      const response = await getTeacherProfileById(Number(teacherId));
      if (response) setTeacherProfile(response);
   };

   const updateTeacherProfile = async (teacherId: number, data: any) => {
      if (
         (isActive1 && isError1) ||
         (isActive2 && isError2) ||
         (isActive3 && isError3) ||
         (isActive4 && isError4) ||
         (isActive5 && isError5) ||
         (isActive6 && isError6)
      ) {
         openModalAlertUpdProfile();
      } else {
         try {
            setIsLoadingUpdate(true);
            const response = await putTeacherProfile(teacherId, data);
            if (response?.status === 200) {
               toast.success("Se actualizó correctamente la información!");
               setHasChanged(false);
            } else {
               openModalAlertUpdProfile();
            }

            setIsLoadingUpdate(false);
         } catch (err) {
            setIsLoadingUpdate(false);
            let error: AxiosError = err as AxiosError;
            toast.error(error.response?.data);
            return error.response?.data.detail;
         }
      }
   };

   const handleClickCancel = () => {
      if (
         hasChanged ||
         initialSchedule.schedule1 !== schedule1 ||
         initialSchedule.schedule2 !== schedule2 ||
         initialSchedule.schedule3 !== schedule3 ||
         initialSchedule.schedule4 !== schedule4 ||
         initialSchedule.schedule5 !== schedule5 ||
         initialSchedule.schedule6 !== schedule6
      ) {
         openModalAlertCancelProfile();
      } else {
         navigate("/dashboard/teachers/teacher-availability");
      }
   };

   if (!teacherProfile) return <ScreenLoader />;

   return (
      <>
         <DefaultLayout>
            <Formik
               initialValues={{
                  first_name: teacherProfile?.first_name,
                  last_name: teacherProfile?.last_name,

                  modality_CTC: teacherProfile?.modality_CTC,

                  email: teacherProfile?.email,
                  phone_number: teacherProfile?.phone_number,
                  discord: teacherProfile?.discord,
               }}
               validationSchema={TeacherProfileSchema}
               onSubmit={async (values, { resetForm }) => {
                  const data = {
                     first_name: values.first_name,
                     last_name: values.last_name,
                     modality_CTC: values.modality_CTC,
                     email: values.email,
                     phone_number: values.phone_number,
                     discord: values.discord,

                     teacher_schedules: {
                        day_available: [
                           ...schedule1,
                           ...schedule2,
                           ...schedule3,
                           ...schedule4,
                           ...schedule5,
                           ...schedule6,
                        ],
                     },
                  };

                  await updateTeacherProfile(Number(teacherId), data);
                  resetForm();
                  teacherProfileById();
               }}
            >
               {({ errors, touched, values, handleChange, setFieldValue }) => (
                  <Form>
                     <div className="teacher-profile">
                        <section className="teacher-profile__container-information">
                           <div className="teacher-profile__details">
                              <Typography type="h2" variation="mobile" bold>
                                 Perfil de profesor
                              </Typography>
                              <section className="teacher-profile__box">
                                 <img
                                    className="teacher-profile__image-teacher"
                                    src={imgProfile}
                                    alt="Imagen perfil profesor | CTC"
                                 />
                                 <div>
                                    <Typography
                                       type="paragraph2"
                                       variation="mobile"
                                       bold
                                    >
                                       {`${teacherProfile?.first_name} ${teacherProfile?.last_name}`}
                                    </Typography>
                                    <Typography
                                       type="small-12"
                                       variation="mobile"
                                       className="teacher-profile__teacher-id"
                                       color={colors.gris03}
                                    >
                                       ID: {teacherProfile?.id}
                                    </Typography>
                                    <Typography
                                       type="small-12"
                                       variation="mobile"
                                       className="teacher-profile__teacher-id-moodle"
                                       color={colors.gris03}
                                    >
                                       ID moodle: {teacherProfile?.user_mdl_id}
                                    </Typography>
                                    <FileInput id="file-input" disabled>
                                       <Typography
                                          type="small-12"
                                          variation="mobile"
                                          color={colors.gris03}
                                          underline
                                       >
                                          Cambiar foto de perfil
                                       </Typography>
                                    </FileInput>
                                 </div>
                              </section>
                           </div>
                           <div className="teacher-profile__container-form">
                              <Typography
                                 type="paragraph2"
                                 variation="mobile"
                                 bold
                                 className="teacher-profile__typography"
                              >
                                 Nombres
                              </Typography>
                              <Input
                                 placeholder="Nombres"
                                 name="first_name"
                                 value={values?.first_name}
                                 onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                 ) => {
                                    handleChange(e);
                                    setHasChanged(true);
                                 }}
                                 error={errors.first_name}
                              />
                              <Typography
                                 type="paragraph2"
                                 variation="mobile"
                                 bold
                                 className="teacher-profile__typography"
                              >
                                 Apellidos
                              </Typography>
                              <Input
                                 placeholder="Apellidos"
                                 name="last_name"
                                 value={values?.last_name}
                                 onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                 ) => {
                                    handleChange(e);
                                    setHasChanged(true);
                                 }}
                                 error={errors.last_name}
                              />
                              <Typography
                                 type="paragraph2"
                                 variation="mobile"
                                 bold
                                 className="teacher-profile__typography"
                              >
                                 Modalidad
                              </Typography>
                              <Input
                                 placeholder="Locador"
                                 name="modality_CTC"
                                 value={values?.modality_CTC}
                                 onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                 ) => {
                                    handleChange(e);
                                    setHasChanged(true);
                                 }}
                                 error={errors.modality_CTC}
                              />
                              <Typography
                                 type="paragraph2"
                                 variation="mobile"
                                 bold
                                 className="teacher-profile__typography"
                              >
                                 Correo
                              </Typography>
                              <Input
                                 placeholder="Correo"
                                 name="email"
                                 value={values?.email}
                                 onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                 ) => {
                                    handleChange(e);
                                    setHasChanged(true);
                                 }}
                                 error={errors.email}
                              />
                              <Typography
                                 type="paragraph2"
                                 variation="mobile"
                                 bold
                                 className="teacher-profile__typography"
                              >
                                 Teléfono
                              </Typography>
                              <Input
                                 placeholder="Pega aquí tu número de teléfono"
                                 name="phone_number"
                                 value={values?.phone_number}
                                 onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                 ) => {
                                    handleChange(e);
                                    setHasChanged(true);
                                 }}
                                 error={errors.phone_number}
                              />
                              <section className="teacher-profile__container-availability-text">
                                 <Typography
                                    type="paragraph2"
                                    variation="mobile"
                                    bold
                                    className="teacher-profile__typography"
                                 >
                                    Disponibilidad
                                 </Typography>
                                 <Typography
                                    type="small-12"
                                    variation="mobile"
                                    color={colors.gris03}
                                 >
                                    Elige tus días y horas disponibles. Hora de
                                    Perú.
                                 </Typography>
                              </section>
                              <section className="teacher-profile__container-availability">
                                 <ScheduleAvailability
                                    isActive={isActive1}
                                    setIsActive={setIsActive1}
                                    schedule={schedule1}
                                    setSchedule={setSchedule1}
                                    setIsError={setIsError1}
                                    defaultValues={teacherProfile.teacher_schedules.day_available
                                       .filter(
                                          (schedule: any) =>
                                             schedule.day === "monday"
                                       )
                                       .map((schedule: any) => schedule)}
                                 >
                                    Lunes
                                 </ScheduleAvailability>
                                 <ScheduleAvailability
                                    isActive={isActive2}
                                    setIsActive={setIsActive2}
                                    schedule={schedule2}
                                    setSchedule={setSchedule2}
                                    setIsError={setIsError2}
                                    defaultValues={teacherProfile.teacher_schedules.day_available
                                       .filter(
                                          (schedule: any) =>
                                             schedule.day === "monday"
                                       )
                                       .map((schedule: any) => schedule)}
                                 >
                                    Martes
                                 </ScheduleAvailability>
                                 <ScheduleAvailability
                                    isActive={isActive3}
                                    setIsActive={setIsActive3}
                                    schedule={schedule3}
                                    setSchedule={setSchedule3}
                                    setIsError={setIsError3}
                                    defaultValues={teacherProfile.teacher_schedules.day_available
                                       .filter(
                                          (schedule: any) =>
                                             schedule.day === "tuesday"
                                       )
                                       .map((schedule: any) => schedule)}
                                 >
                                    Miercoles
                                 </ScheduleAvailability>
                                 <ScheduleAvailability
                                    isActive={isActive4}
                                    setIsActive={setIsActive4}
                                    schedule={schedule4}
                                    setSchedule={setSchedule4}
                                    setIsError={setIsError4}
                                    defaultValues={teacherProfile.teacher_schedules.day_available
                                       .filter(
                                          (schedule: any) =>
                                             schedule.day === "thursday"
                                       )
                                       .map((schedule: any) => schedule)}
                                 >
                                    Jueves
                                 </ScheduleAvailability>
                                 <ScheduleAvailability
                                    isActive={isActive5}
                                    setIsActive={setIsActive5}
                                    schedule={schedule5}
                                    setSchedule={setSchedule5}
                                    setIsError={setIsError5}
                                    defaultValues={teacherProfile.teacher_schedules.day_available
                                       .filter(
                                          (schedule: any) =>
                                             schedule.day === "friday"
                                       )
                                       .map((schedule: any) => schedule)}
                                 >
                                    Viernes
                                 </ScheduleAvailability>
                                 <ScheduleAvailability
                                    isActive={isActive6}
                                    setIsActive={setIsActive6}
                                    schedule={schedule6}
                                    setSchedule={setSchedule6}
                                    setIsError={setIsError6}
                                    defaultValues={teacherProfile.teacher_schedules.day_available
                                       .filter(
                                          (schedule: any) =>
                                             schedule.day === "saturday"
                                       )
                                       .map((schedule: any) => schedule)}
                                 >
                                    Sabado
                                 </ScheduleAvailability>
                              </section>
                              <Typography
                                 type="paragraph2"
                                 variation="mobile"
                                 bold
                                 className="teacher-profile__typography"
                              >
                                 Cursos actuales
                              </Typography>
                              <section className="teacher-profile__container-courses">
                                 {teacherProfile &&
                                 teacherProfile?.current_courses?.courses
                                    .length > 0 ? (
                                    teacherProfile?.current_courses.courses.map(
                                       (course, idx) => (
                                          <Tooltip
                                             key={idx}
                                             title={course.group__name}
                                             left="1.5rem"
                                          >
                                             <TagCourses className="teacher-profile__tag">
                                                <Typography
                                                   type="paragraph2"
                                                   variation="mobile"
                                                   bold
                                                >
                                                   {course.group__course__name}
                                                </Typography>
                                             </TagCourses>
                                          </Tooltip>
                                       )
                                    )
                                 ) : (
                                    <Typography
                                       type="paragraph2"
                                       variation="mobile"
                                    >
                                       No se han asignado cursos
                                    </Typography>
                                 )}
                              </section>
                              <Typography
                                 type="paragraph2"
                                 variation="mobile"
                                 bold
                                 className="teacher-profile__typography"
                              >
                                 Discord
                              </Typography>
                              <Input
                                 placeholder="Pega aquí tu nombre de usuario. Ej: Jaime Maraví#6211"
                                 name="discord"
                                 value={values?.discord}
                                 onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                 ) => {
                                    handleChange(e);
                                    setHasChanged(true);
                                 }}
                                 error={errors.discord}
                              />
                           </div>
                           <section className="teacher-profile__container-buttons">
                              <ButtonOutline
                                 size="small"
                                 onClick={() => handleClickCancel()}
                              >
                                 Cancelar
                              </ButtonOutline>
                              <ButtonFilled type="submit" size="small">
                                 Guardar
                              </ButtonFilled>
                           </section>
                        </section>
                     </div>
                  </Form>
               )}
            </Formik>
         </DefaultLayout>

         <ModalMessageAlert
            width="xs"
            title={
               <Typography bold type="paragraph1" variation="desktop">
                  Algo salió mal...
               </Typography>
            }
            message="Parece que has añadido un valor incorrecto o que ya expiró. Revisa y corrije el espacio marcado en rojo para continuar."
            isOpenModal={isOpenModalAlertUpdProfile}
            openModal={openModalAlertUpdProfile}
            closeModal={closeModalAlertUpdProfile}
            button1={
               <ButtonFilled size="default" onClick={closeModalAlertUpdProfile}>
                  Aceptar
               </ButtonFilled>
            }
         />

         <ModalMessageAlert
            width="sm"
            iconAlert={<IconAlert fill={colors.primary} />}
            title={
               <Typography bold type="paragraph1" variation="desktop">
                  No olvides guardar los cambios...
               </Typography>
            }
            isOpenModal={isOpenModalAlertCancelProfile}
            openModal={openModalAlertCancelProfile}
            closeModal={closeModalAlertCancelProfile}
            button1={
               <ButtonOutline
                  size="small"
                  width="11rem"
                  disabled={isLoadingUpdate}
                  onClick={() =>
                     navigate("/dashboard/teachers/teacher-availability")
                  }
               >
                  Salir de página
               </ButtonOutline>
            }
            button2={
               <ButtonFilled
                  size="small"
                  width="11.5rem"
                  disabled={isLoadingUpdate}
                  onClick={closeModalAlertCancelProfile}
               >
                  Quiero quedarme
               </ButtonFilled>
            }
         />
      </>
   );
};

export default TeacherProfile;
