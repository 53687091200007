// React
import React, { useEffect, useState } from "react";
import axios from "axios";

// React Query
import { useMutation } from "react-query";

// Router
import { Link as LinkRouter } from "react-router-dom";
// API
import { PATCH } from "../../../services/api/api";

// React router dom
import { useNavigate } from "react-router-dom";

// Hot Toast
import { toast } from "react-hot-toast";

// Utils
import { clearString } from "../../../utils/utils";

// Helpers
import { getAccessCookie } from "../../../helpers/cookies.helpers";

// Constants
import PERMISSIONS from "../../../constants/permission.constants";

import ButtonFilled from "../../molecules/ButtonFilled/ButtonFilled";
import ButtonOutline from "../../molecules/ButtonOutline";
import Typography from "../../atoms/Typography";
import IconZoom from "../../atoms/icons/IconZoom";
import IconWiki from "../../atoms/icons/IconWiki";
import IconClock from "../../atoms/icons/IconClock";
import IconArrowCollapse from "../../atoms/icons/IconArrowCollapse";
import FloatingMenu from "../../molecules/FloatingMenu";
import ItemFloatingMenu from "../../molecules/ItemFloatingMenu";

import "./nextClass.scss";

interface Zoom {
   zoom_id: string;
   url_alumno: string;
}
interface Group {
   id: number;
   name: string;
}
interface Teacher {
   id: number;
   full_name: string;
}
interface NextClass {
   room_id: number;
   group: Group;
   teacher: Teacher;
   zoom_data: Zoom;
   course_wiki_url: string;
}

interface Props {
   user: any;
   children?: any;
}

const NextClass: React.FC<Props> = ({ user }) => {
   const navigate = useNavigate();
   const [isOpen, setIsOpen] = useState(false);
   const [nextClass, setNextClass] = useState<NextClass | null>(null);

   useEffect(() => {
      if (user.permission.name === "Teacher") {
         setIsOpen(true);
         getNextClass();
      }
   }, [user]);

   const getNextClass = async () => {
      try {
         const { data } = await axios.get<any>(
            `${process.env.REACT_APP_CTC_BACKEND_URL}/teachers/${
               user!.id
            }/rooms/next_session`,
            {
               headers: { Authorization: `Bearer ${getAccessCookie()}` },
            }
         );
         if (!data.group.id) {
            setIsOpen(false);
         }
         setNextClass(data);
      } catch (error) {
         console.log("somethings went wrong!", error);
      }
   };

   const refreshMeetingLink = useMutation(
      (zoom_meeting_id: string) =>
         PATCH(`/meeting/${zoom_meeting_id}/refresh-start/`, {
            zoom_meeting_id,
         }),
      { retry: false }
   );

   const handleRefreshMeetingLink = async (zoomId: any) => {
      await toast.promise(
         refreshMeetingLink
            .mutateAsync(clearString(zoomId))
            .then(({ data: { start_url } }: any) => {
               setTimeout(() => {
                  window.open(start_url, "_blank");
               }, 1000);
            }),
         {
            loading: "Cargando...",
            success: "Vamos a Zoom!",
            error: `Ha ocurrido un error. Inicie la clase con la cuenta correcta` /*  <${room.licensed_user_email}> */,
         }
      );
   };

   return (
      <>
         <div
            className={`next-class next-class__${
               user.permission.name === "Teacher" && isOpen
                  ? "height-open"
                  : "height-close"
            }`}
         >
            <section className="next-class__section-title">
               <div className="next-class__title">
                  <IconClock />
                  <Typography type="h3" variation="mobile" bold>
                     Tu próxima clase
                  </Typography>
               </div>
               {user.permission.name === "Teacher" && nextClass?.group.id ? (
                  <div
                     className={`next-class__title `}
                     onClick={() => setIsOpen(!isOpen)}
                  >
                     <IconArrowCollapse
                        className={`next-class__icon-collapse next-class__icon-collapse--${
                           isOpen ? "open" : "close"
                        }`}
                     />
                  </div>
               ) : (
                  <div className="next-class__title">
                     <IconArrowCollapse className="next-class__icon-collapse next-class__icon-collapse--close next-class__icon-collapse--disabled" />
                  </div>
               )}
            </section>
            <hr />
            <div className="next-class__detail-next-class">
               <section className="next-class__detail">
                  <div className="next-class__group">
                     <Typography type="paragraph1" variation="mobile" bold>
                        Grupo
                     </Typography>
                     <Typography type="paragraph1" variation="mobile">
                        {nextClass ? nextClass.group.name : ""}
                     </Typography>
                  </div>
                  <div className="next-class__teacher">
                     <Typography type="paragraph1" variation="mobile" bold>
                        Profesor
                     </Typography>
                     <Typography type="paragraph1" variation="mobile">
                        {nextClass ? nextClass.teacher.full_name : ""}
                     </Typography>
                  </div>
               </section>
               <section className="next-class__actions">
                  <ButtonFilled
                     size="small"
                     iconLeft={<IconZoom />}
                     onClick={
                        nextClass && nextClass.zoom_data.zoom_id
                           ? () =>
                                handleRefreshMeetingLink(
                                   nextClass.zoom_data.zoom_id
                                )
                           : ""
                     }
                  >
                     Ir a Zoom
                  </ButtonFilled>
                  <ButtonOutline
                     size="small"
                     onClick={
                        nextClass
                           ? () =>
                                navigate(
                                   `/dashboard/rooms/${nextClass.room_id}`
                                )
                           : ""
                     }
                  >
                     Ver Salón
                  </ButtonOutline>
                  {nextClass && nextClass.course_wiki_url ? (
                     <div className="next-class__container-tooltip">
                        <LinkRouter to={nextClass.course_wiki_url}>
                           <IconWiki className="next-class__icon-wiki" />
                        </LinkRouter>
                        <section className="next-class__tooltip next-class__tooltip--wiki">
                           Ir a Wiki
                        </section>
                     </div>
                  ) : (
                     <div className="next-class__container-tooltip">
                        <IconWiki
                           className="next-class__icon-wiki next-class__icon-wiki--disabled"
                           fill="#bcbcbc"
                        />
                        <section className="next-class__tooltip next-class__tooltip--wiki">
                           Ir a Wiki
                        </section>
                     </div>
                  )}
                  <FloatingMenu
                     items={[
                        <>
                           {nextClass && nextClass.zoom_data ? (
                              <ItemFloatingMenu
                                 onClick={() => {
                                    if (nextClass.zoom_data.url_alumno) {
                                       toast.success("URL de Zoom copiado...");
                                       navigator.clipboard.writeText(
                                          nextClass.zoom_data.url_alumno
                                       );
                                    } else toast.error("No existe URL de Zoom");
                                 }}
                              >
                                 Copiar URL de Zoom
                              </ItemFloatingMenu>
                           ) : (
                              <ItemFloatingMenu disabled>
                                 Copiar URL de Zoom
                              </ItemFloatingMenu>
                           )}
                        </>,
                        <>
                           {user?.permission[PERMISSIONS.roomListAll] &&
                           nextClass &&
                           nextClass.group ? (
                              <ItemFloatingMenu>
                                 <LinkRouter
                                    to={`/dashboard/groups/${nextClass.group.id}`}
                                 >
                                    Ver Grupo
                                 </LinkRouter>
                              </ItemFloatingMenu>
                           ) : (
                              <ItemFloatingMenu disabled>
                                 Ver Grupo
                              </ItemFloatingMenu>
                           )}
                        </>,
                     ]}
                  />
               </section>
            </div>
         </div>
      </>
   );
};

export default NextClass;
