import { useEffect, useState, useCallback, useMemo } from "react";
import { GET } from "../../services/api/api";
import { Chip, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import ViewAgenda from "@mui/icons-material/ViewAgenda";
import { IconButton } from "@material-ui/core";
import { useQuery } from "react-query";
import { IGroupAge } from "../../interfaces/groupAges.interface";
import ScreenLoader from "../../components/ScreenLoader/ScreenLoader";
import ModalEdit from "./ModalEdit";
import {
   DataGrid,
   GridColDef,
   GridRowId,
   GridRowsProp,
   GridColumns,
   GridValueGetterParams,
   GridRenderCellParams,
   GridSortModel,
} from "@mui/x-data-grid";
import { objectsEqual } from "../../utils/utils";
import Grid from "@mui/material/Grid";
import { Stack } from "@mui/material";

const AgeGroups = () => {
   const [sortModel, setSortModel] = useState<GridSortModel>([
      {
         field: "min_age",
         sort: "asc",
      },
   ]);

   const {
      data: groupAges,
      error,
      isError,
      isLoading,
      isFetching,
      refetch,
   } = useQuery(["group-ages"], () => GET<IGroupAge[]>(`/base/group-ages`), {
      retry: false,
      refetchOnWindowFocus: false,
   });
   if (isLoading || !groupAges) return <ScreenLoader />;
   if (isError) return <div>Error! {JSON.stringify(error)}</div>;

   const currentlySelected = (model: GridSortModel) => {
      !objectsEqual(model, sortModel) && setSortModel(model);
   };

   return (
      <Grid
         container
         rowGap={2}
         style={{ overflowY: "scroll", height: "100%" }}
      >
         <Grid item xs={12}>
            <DataGrid
               autoHeight
               style={{ fontSize: 12 }}
               sortModel={sortModel}
               onSortModelChange={(model) => currentlySelected(model)}
               rows={groupAges}
               columns={generateColumns()}
               pageSize={20}
               rowsPerPageOptions={[20]}
            />
         </Grid>
      </Grid>
   );

   function generateColumns(): GridColDef[] {
      const columns: GridColumns = [
         {
            field: "edit",
            headerName: "Edit",
            width: 85,
            minWidth: 85,
            editable: false,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            renderCell: (params: GridRenderCellParams) => {
               return (
                  <Stack
                     direction="row"
                     justifyContent="center"
                     alignItems="center"
                     spacing={1}
                  >
                     <ModalEdit
                        ageGroupId={params.row.id}
                        ageGroupName={params.row.name}
                        ageGroupMin={params.row.min_age}
                        ageGroupMax={params.row.max_age}
                     />
                  </Stack>
               );
            },
         },
         {
            field: "group",
            headerName: "Group",
            minWidth: 150,
            editable: false,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.name;
            },
         },
         {
            field: "min_age",
            headerName: "Min Age",
            minWidth: 150,
            editable: false,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.min_age;
            },
         },
         {
            field: "max_age",
            headerName: "Max Age",
            minWidth: 150,
            editable: false,
            flex: 1,
            disableColumnMenu: true,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.max_age;
            },
         },
      ];
      return columns;
   }
};

export default AgeGroups;
