// React Query
import { useMutation, useQuery } from "react-query";

// Yup
import * as Yup from "yup";

// Formik
import { Form, Formik } from "formik";

// API
import { GET, POST } from "../../../../../services/api/api";

// Moment
import moment from "moment";

// Hot Toast
import { toast } from "react-hot-toast";

// Interfaces
import {
  ILearningPath,
  ILearningPaths,
} from "../../../../../interfaces/learningPaths.interface";

// Utils
import { sortAlphabetically } from "../../../../../utils/utils";

// Material UI
import {
  Alert,
  Autocomplete,
  Box,
  Divider,
  Grid,
  LinearProgress,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { DateTimePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DateAdapter from "@mui/lab/AdapterDateFns";

const GeneralDataSchema = Yup.object().shape({
  name: Yup.string()
    .min(5, "Muy pequeño!")
    .max(500, "Mucho texto!")
    .required("Requerido"),

  slug: Yup.string().required("Requerido"),
  youtube_video_id: Yup.string(),
  call_out: Yup.string(),
  description: Yup.string().min(5, "Muy pequeño!").max(500, "Mucho texto!"),
  seo_meta_description: Yup.string().min(5, "Muy pequeño!").max(255, "Mucho texto!"),

  published_at: Yup.date().nullable(true),
  archived_at: Yup.date()
    .nullable(true)
    .min(Yup.ref("published_at"), "ARCHIVADO NO puede ser antes que PUBLICADO"),

  next_learning_path: Yup.number(),
});

interface Props {
  refetch: () => {};
  learningPathId: number;
  setLearningPathId: any;
  isGeneralDataSent: boolean;
  setIsGeneralDataSent: any;
}

const GeneralData: React.FC<Props> = ({
  refetch,
  learningPathId,
  setLearningPathId,
  isGeneralDataSent,
  setIsGeneralDataSent,
}) => {
  const { data: learningPath, remove } = useQuery(
    ["learningPath-general-data", learningPathId],
    () => GET<ILearningPath>(`/learning_paths_backoffice/${learningPathId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!learningPathId && isGeneralDataSent,
    }
  );

  const { data: learningPaths, isLoading: isLoadingLearningPaths } = useQuery(
    ["learning-paths"],
    () => GET<ILearningPaths>(`/learning-paths/`),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const createLearningPathGeneralData = useMutation(
    (learningPath: any) => POST(`/learning_paths_backoffice/`, learningPath),
    {
      retry: false,
    }
  );

  if (!!learningPathId && isGeneralDataSent && !learningPath)
    return <LinearProgress />;
  if (isLoadingLearningPaths && !learningPaths) return <LinearProgress />;

  let initialValues: any = {
    name: "",
    slug: "",

    youtube_video_id: "",
    call_out: "",

    description: "",

    seo_title: "",
    seo_meta_description: "",

    published_at: null,
    archived_at: null,

    next_learning_path: -1,
  };

  if (isGeneralDataSent && learningPath) {
    initialValues = {
      name: learningPath.name,
      slug: learningPath.slug,

      youtube_video_id: learningPath.youtube_video_id,
      call_out: learningPath.call_out,

      description: learningPath.description,

      seo_title: learningPath.seo_title,
      seo_meta_description: learningPath.seo_meta_description,

      published_at:
        learningPath.published_at && moment(learningPath.published_at).toDate(),
      archived_at:
        learningPath.archived_at && moment(learningPath.archived_at).toDate(),

      next_learning_path: learningPath.next_learning_path,
    };
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={GeneralDataSchema}
      onSubmit={async (values) => {
        const data = {
          ...values,
          next_learning_path:
            values.next_learning_path > -1 ? values.next_learning_path : null,
        };

        await toast.promise(
          createLearningPathGeneralData.mutateAsync(data).then((res) => {
            setIsGeneralDataSent(true);
            setLearningPathId(res.data.id);
          }),
          {
            loading: "Cargando...",
            success: "Datos Generales creado",
            error: "Error",
          },
          {
            success: {
              icon: <AddCircleIcon color="primary" />,
            },
          }
        );

        remove();
        refetch();
      }}
    >
      {({ errors, touched, values, handleChange, setFieldValue }) => (
        <Form>
          <Grid container rowSpacing={2} alignItems="flex-start">
            {/* Main */}
            <Grid item container columnSpacing={2} rowSpacing={2} xs={12}>
              {/* Name */}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  fullWidth
                  id="name"
                  label="Nombre"
                  placeholder="Nombre"
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.name && touched.name && (
                  <Alert severity="error">{errors.name}</Alert>
                )}
              </Grid>

              {/* Slug */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="slug"
                  label="Slug"
                  placeholder="Slug"
                  variant="outlined"
                  value={values.slug}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.slug && touched.slug && (
                  <Alert severity="error">{errors.slug}</Alert>
                )}
              </Grid>

              {/* Youtube_video_id */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="youtube_video_id"
                  label="Youtube Video Id"
                  placeholder="Youtube Video Id"
                  variant="outlined"
                  value={values.youtube_video_id}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.youtube_video_id && touched.youtube_video_id && (
                  <Alert severity="error">{errors.youtube_video_id}</Alert>
                )}
              </Grid>

              {/* Call_out */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="call_out"
                  label="Call Out"
                  placeholder="Call Out"
                  variant="outlined"
                  value={values.call_out}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.call_out && touched.call_out && (
                  <Alert severity="error">{errors.call_out}</Alert>
                )}
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <TextareaAutosize
                  id="description"
                  aria-label="minimum height"
                  placeholder="Descripción"
                  minRows={10}
                  value={values.description}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                  style={{ width: "99%", minWidth: "99%", maxWidth: "99%" }}
                />
                {errors.description && touched.description && (
                  <Alert severity="error">{errors.description}</Alert>
                )}
              </Grid>

              {/* Seo_title */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="seo_title"
                  label="Seo Title"
                  placeholder="Seo Title"
                  variant="outlined"
                  value={values.seo_title}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.seo_title && touched.seo_title && (
                  <Alert severity="error">{errors.seo_title}</Alert>
                )}
              </Grid>

              {/* Seo_meta_description */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="seo_meta_description"
                  label="Seo Meta Description"
                  placeholder="Seo Meta Description"
                  variant="outlined"
                  value={values.seo_meta_description}
                  onChange={handleChange}
                  disabled={isGeneralDataSent}
                />
                {errors.seo_meta_description && touched.seo_meta_description && (
                  <Alert severity="error">{errors.seo_meta_description}</Alert>
                )}
              </Grid>

              {/* published_at | archived_at */}
              <Grid item container columnSpacing={2} rowSpacing={1} xs={12}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  {/* published_at */}
                  <Grid item xs={6}>
                    <DateTimePicker
                      label="Publicado"
                      inputFormat="yyyy-MM-dd"
                      value={values.published_at}
                      disableMaskedInput
                      ampm={false}
                      onChange={(value) => setFieldValue("published_at", value)}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                      disabled={isGeneralDataSent}
                    />
                    {errors.published_at && touched.published_at && (
                      <Alert severity="error">{errors.published_at}</Alert>
                    )}
                  </Grid>

                  {/* archived_at */}
                  <Grid item xs={6}>
                    <DateTimePicker
                      label="Archivado"
                      inputFormat="yyyy-MM-dd"
                      value={values.archived_at}
                      disableMaskedInput
                      minDate={values.published_at}
                      ampm={false}
                      onChange={(value) => setFieldValue("archived_at", value)}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                      disabled={isGeneralDataSent}
                    />
                    {errors.archived_at && touched.archived_at && (
                      <Alert severity="error">{errors.archived_at}</Alert>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {values.published_at ? (
                      <Alert variant="standard" severity="success">
                        El grupo esta publicado
                      </Alert>
                    ) : (
                      <Alert variant="standard" severity="warning">
                        El grupo esta privado
                      </Alert>
                    )}
                  </Grid>
                </LocalizationProvider>
              </Grid>

              {/* Next_learning_path */}
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  id="next_learning_path"
                  options={sortAlphabetically(learningPaths!.all, "name")}
                  autoHighlight
                  value={
                    learningPaths!.all.find(
                      (learningPath) => learningPath.id === values.next_learning_path
                    ) || null
                  }
                  onChange={(_e, value: any) => {
                    value
                      ? setFieldValue("next_learning_path", value.id)
                      : setFieldValue("next_learning_path", -1);
                  }}
                  getOptionLabel={(option) => `${option.name} (${option.id})`}
                  renderOption={(props, option) => (
                    <Box key={option.id} component="li" {...props}>
                      {option.name} ({option.id})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Next Learning Path"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                  disabled={isGeneralDataSent}
                />
                {errors.next_learning_path && touched.next_learning_path && (
                  <Alert severity="error">{errors.next_learning_path}</Alert>
                )}
              </Grid>
            </Grid>

            {/* Save */}
            <Grid item container rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={createLearningPathGeneralData.isLoading}
                  variant="contained"
                  type="submit"
                  disabled={isGeneralDataSent}
                  startIcon={<AddCircleIcon />}
                >
                  Crear
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default GeneralData;
