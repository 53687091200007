// Components
import SignInForm from "../../components/SignInForm/SignInForm";
import SignInLayout from "../../layouts/SignInLayout/SignInLayout";

function SignIn() {
  return (
    <SignInLayout>
      <SignInForm />
    </SignInLayout>
  );
}

export default SignIn;
