// Material UI
import { Chip, Grid, Stack } from "@mui/material";
import {
   DataGrid,
   GridColumns,
   GridValueGetterParams,
   GridColDef,
} from "@mui/x-data-grid";

// Interfaces
import { IRoom, IStudent } from "../../../../interfaces/group.interface";

// Components
import CustomLoadingOverlay from "../../../../components/CustomLoadingOverlay/CustomLoadingOverlay";
import UpdateStudentRoom from "./UpdateStudentRoom/UpdateStudentRoom";
import DeleteGroupStudent from "./DeleteGroupStudent/DeleteGroupStudent";
import ModalAddStudentToGroup from "./ModalAddStudentToGroup/ModalAddStudentToGroup";
import ModalGuardianDetails from "../../../Guardians/ModalGuardianDetails/ModalGuardianDetails";

interface Props {
   students: IStudent[];
   rooms: IRoom[];
   complement_students: IStudent[];
   groupId: number;
   refetch?: () => {};
   isFetching: boolean;
}

const Students: React.FC<Props> = ({
   students,
   rooms,
   complement_students,
   groupId,
   refetch,
   isFetching,
}) => {
   return (
      <Grid container rowGap={2}>
         {/* Create New Room */}
         <Grid item xs={12}>
            <ModalAddStudentToGroup
               complement_students={complement_students}
               groupId={groupId}
               /* refetch={refetch} */
            />
         </Grid>

         {/* Students */}
         <Grid item xs={12}>
            <DataGrid
               autoHeight
               style={{ fontSize: 12 }}
               loading={isFetching}
               rows={students}
               columns={generateColumnsByStudent()}
               pageSize={20}
               rowsPerPageOptions={[20]}
               checkboxSelection={false}
               disableSelectionOnClick
               components={{
                  LoadingOverlay: CustomLoadingOverlay,
               }}
            />
         </Grid>
      </Grid>
   );

   function generateColumnsByStudent(): GridColDef[] {
      const columns: GridColumns = [
         {
            field: "actions",
            headerName: "Acciones",
            minWidth: 100,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            editable: false,
            renderCell: (params: GridValueGetterParams) => {
               return (
                  <DeleteGroupStudent
                     groupId={groupId}
                     studentId={params.row.id}
                     /* refetch={refetch} */
                  />
               );
            },
         },
         {
            field: "full_name",
            headerName: "Nombre del estudiante",
            minWidth: 250,
            disableColumnMenu: true,
            editable: false,
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.full_name;
            },
         },
         {
            field: "guardian",
            headerName: "Tutor / Madre / Padre",
            minWidth: 500,
            editable: false,
            flex: 1,
            renderCell: (params: GridValueGetterParams) => {
               return (
                  <Stack
                     sx={{ width: "100%" }}
                     direction="row"
                     columnGap={2}
                     alignItems="center"
                     justifyContent="space-between"
                  >
                     <span>{params.row.guardian.full_name}</span>
                     <Chip
                        variant="outlined"
                        color="secondary"
                        label={params.row.guardian.email}
                     />
                     <ModalGuardianDetails
                        onlyIcon
                        guardianId={params.row.guardian.id}
                     />
                  </Stack>
               );
            },
         },
         {
            field: "age",
            headerName: "Edad",
            minWidth: 100,
            width: 100,
            editable: false,
            disableColumnMenu: true,
            align: "center",
            valueGetter: (params: GridValueGetterParams) => {
               return params.row.age;
            },
         },
         {
            field: "room",
            headerName: "Salón",
            minWidth: 450,
            editable: false,
            disableColumnMenu: true,
            renderCell: (params: GridValueGetterParams) => {
               return (
                  <UpdateStudentRoom
                     studentId={params.row.id}
                     rooms={rooms}
                     room={params.row.room}
                     groupId={groupId}
                     /* refetch={refetch} */
                  />
               );
            },
         },
      ];

      return columns;
   }
};

export default Students;
