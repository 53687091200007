import React from "react";

import "./tag.scss";

interface Props {
   children: any;
   className?: string;
   color?: string;
}

const Tag: React.FC<Props> = ({ children, className, color = "" }) => {
   return (
      <div className={`tag ${className}`} style={{ background: color }}>
         {children}
      </div>
   );
};

export default Tag;
