// Interfaces
import { currencies } from "../interfaces/currency.interface";

// Course //
export const initialValueByCurrencyCourse = (currency: currencies, prices: any) => {
  switch (currency) {
    case "PEN":
      return {
        // Show_as_free_course
        show_as_free_course: prices.show_as_free_course,

        // Standar amount
        amount: prices.amount_pen,

        // Discount ------------------------------------------
        has_discount: prices.has_discount,
        discount_percentage: prices.discount_percentage,

        // Standar amount + discount
        amount_after_discount: prices.amount_after_discount_pen,

        // Has_test_month
        has_test_month: prices.has_test_month,

        // Test_month_amount
        test_month_amount: prices.test_month_amount,

        // Test_month_duration
        test_month_duration: prices.test_month_duration,

        // Test_month_classes
        test_month_classes: prices.test_month_classes,
      };
    case "USD":
      return {
        // Show_as_free_course
        show_as_free_course: prices.show_as_free_course,

        // Standar amount
        amount: prices.amount_usd,

        // Discount ------------------------------------------
        has_discount: prices.has_discount,
        discount_percentage: prices.discount_percentage,

        // Standar amount + discount
        amount_after_discount: prices.amount_after_discount_usd,

        // Has_test_month
        has_test_month: prices.has_test_month,

        // Test_month_amount
        test_month_amount: prices.test_month_amount_usd,

        // Test_month_duration
        test_month_duration: prices.test_month_duration,

        // Test_month_classes
        test_month_classes: prices.test_month_classes,
      };
    case "MXN":
      return {
        // Show_as_free_course
        show_as_free_course: prices.show_as_free_course,

        // Standar amount
        amount: prices.amount_mxn,

        // Discount ------------------------------------------
        has_discount: prices.has_discount,
        discount_percentage: prices.discount_percentage,

        // Standar amount + discount
        amount_after_discount: prices.amount_after_discount_mxn,

        // Has_test_month
        has_test_month: prices.has_test_month,

        // Test_month_amount
        test_month_amount: prices.test_month_amount_mxn,

        // Test_month_duration
        test_month_duration: prices.test_month_duration,

        // Test_month_classes
        test_month_classes: prices.test_month_classes,
      };
    case "COP":
      return {
        // Show_as_free_course
        show_as_free_course: prices.show_as_free_course,

        // Standar amount
        amount: prices.amount_col,

        // Discount ------------------------------------------
        has_discount: prices.has_discount,
        discount_percentage: prices.discount_percentage,

        // Standar amount + discount
        amount_after_discount: prices.amount_after_discount_col,

        // Has_test_month
        has_test_month: prices.has_test_month,

        // Test_month_amount
        test_month_amount: prices.test_month_amount_col,

        // Test_month_duration
        test_month_duration: prices.test_month_duration,

        // Test_month_classes
        test_month_classes: prices.test_month_classes,
      };
    default:
      return {};
  }
};

export const submitByCurrencyCourse = (currency: currencies, values: any) => {
  switch (currency) {
    case "PEN":
      return {
        // Show_as_free_course
        show_as_free_course: values.show_as_free_course,

        // Standar amount
        amount_pen: values.amount,

        // Discount ------------------------------------------
        has_discount: values.has_discount,
        discount_percentage: values.discount_percentage,

        // Standar amount + discount
        amount_after_discount_pen: values.amount_after_discount,

        // Has_test_month
        has_test_month: values.has_test_month,

        // Test_month_amount
        test_month_amount: values.test_month_amount,

        // Test_month_duration
        test_month_duration: values.test_month_duration,

        // Test_month_classes
        test_month_classes: values.test_month_classes,
      };
    case "USD":
      return {
        // Show_as_free_course
        show_as_free_course: values.show_as_free_course,

        // Standar amount
        amount_usd: values.amount,

        // Discount ------------------------------------------
        has_discount: values.has_discount,
        discount_percentage: values.discount_percentage,

        // Standar amount + discount
        amount_after_discount_usd: values.amount_after_discount,

        // Has_test_month
        has_test_month: values.has_test_month,

        // Test_month_amount
        test_month_amount_usd: values.test_month_amount,

        // Test_month_duration
        test_month_duration: values.test_month_duration,

        // Test_month_classes
        test_month_classes: values.test_month_classes,
      };
    case "MXN":
      return {
        // Show_as_free_course
        show_as_free_course: values.show_as_free_course,

        // Standar amount
        amount_mxn: values.amount,

        // Discount ------------------------------------------
        has_discount: values.has_discount,
        discount_percentage: values.discount_percentage,

        // Standar amount + discount
        amount_after_discount_mxn: values.amount_after_discount,

        // Has_test_month
        has_test_month: values.has_test_month,

        // Test_month_amount
        test_month_amount_mxn: values.test_month_amount,

        // Test_month_duration
        test_month_duration: values.test_month_duration,

        // Test_month_classes
        test_month_classes: values.test_month_classes,
      };
    case "COP":
      return {
        // Show_as_free_course
        show_as_free_course: values.show_as_free_course,

        // Standar amount
        amount_col: values.amount,

        // Discount ------------------------------------------
        has_discount: values.has_discount,
        discount_percentage: values.discount_percentage,

        // Standar amount + discount
        amount_after_discount_col: values.amount_after_discount,

        // Has_test_month
        has_test_month: values.has_test_month,

        // Test_month_amount
        test_month_amount_col: values.test_month_amount,

        // Test_month_duration
        test_month_duration: values.test_month_duration,

        // Test_month_classes
        test_month_classes: values.test_month_classes,
      };
    default:
      return {};
  }
};

// Learning Path //
export const initialValueByCurrency = (currency: currencies, prices: any) => {
  switch (currency) {
    case "PEN":
      return {
        show_as_free_course: prices.show_as_free_course,
        available_to_purchase: prices.available_to_purchase,

        // Standar amount
        amount: prices.amount_pen,
        // Per month
        amount_per_month: prices.amount_per_month_pen,

        // Discount ------------------------------------------
        has_discount: prices.has_discount,
        discount_percentage: prices.discount_percentage,

        // Standar amount + discount
        amount_after_discount: prices.amount_after_discount_pen,
        // Per month + discount
        amount_after_discount_per_month: prices.amount_after_discount_per_month_pen,

        amount_full_course_by_course: prices.amount_full_course_by_course_pen,
      };
    case "USD":
      return {
        show_as_free_course: prices.show_as_free_course,
        available_to_purchase: prices.available_to_purchase,

        // Standar amount
        amount: prices.amount_usd,
        // Per month
        amount_per_month: prices.amount_per_month_usd,

        // Discount ------------------------------------------
        has_discount: prices.has_discount,
        discount_percentage: prices.discount_percentage,

        // Standar amount + discount
        amount_after_discount: prices.amount_after_discount_usd,
        // Per month + discount
        amount_after_discount_per_month: prices.amount_after_discount_per_month_usd,

        amount_full_course_by_course: prices.amount_full_course_by_course_usd,
      };
    case "MXN":
      return {
        show_as_free_course: prices.show_as_free_course,
        available_to_purchase: prices.available_to_purchase,

        // Standar amount
        amount: prices.amount_mxn,
        // Per month
        amount_per_month: prices.amount_per_month_mxn,

        // Discount ------------------------------------------
        has_discount: prices.has_discount,
        discount_percentage: prices.discount_percentage,

        // Standar amount + discount
        amount_after_discount: prices.amount_after_discount_mxn,
        // Per month + discount
        amount_after_discount_per_month: prices.amount_after_discount_per_month_mxn,

        amount_full_course_by_course: prices.amount_full_course_by_course_mxn,
      };
    case "COP":
      return {
        show_as_free_course: prices.show_as_free_course,
        available_to_purchase: prices.available_to_purchase,

        // Standar amount
        amount: prices.amount_col,
        // Per month
        amount_per_month: prices.amount_per_month_col,

        // Discount ------------------------------------------
        has_discount: prices.has_discount,
        discount_percentage: prices.discount_percentage,

        // Standar amount + discount
        amount_after_discount: prices.amount_after_discount_col,
        // Per month + discount
        amount_after_discount_per_month: prices.amount_after_discount_per_month_col,

        amount_full_course_by_course: prices.amount_full_course_by_course_col,
      };
    default:
      return {};
  }
};

export const submitByCurrency = (currency: currencies, values: any) => {
  switch (currency) {
    case "PEN":
      return {
        show_as_free_course: values.show_as_free_course,
        available_to_purchase: values.available_to_purchase,

        // Standar amount
        amount_pen: values.amount,
        // Per month
        amount_per_month_pen: values.amount_per_month,

        // Discount ------------------------------------------
        has_discount: values.has_discount,
        discount_percentage: values.discount_percentage,

        // Standar amount + discount
        amount_after_discount_pen: values.amount_after_discount,
        // Per month + discount
        amount_after_discount_per_month_pen: values.amount_after_discount_per_month,

        amount_full_course_by_course_pen: values.amount_full_course_by_course,
      };
    case "USD":
      return {
        show_as_free_course: values.show_as_free_course,
        available_to_purchase: values.available_to_purchase,

        // Standar amount
        amount_usd: values.amount,
        // Per month
        amount_per_month_usd: values.amount_per_month,

        // Discount ------------------------------------------
        has_discount: values.has_discount,
        discount_percentage: values.discount_percentage,

        // Standar amount + discount
        amount_after_discount_usd: values.amount_after_discount,
        // Per month + discount
        amount_after_discount_per_month_usd: values.amount_after_discount_per_month,

        amount_full_course_by_course_usd: values.amount_full_course_by_course,
      };
    case "MXN":
      return {
        show_as_free_course: values.show_as_free_course,
        available_to_purchase: values.available_to_purchase,

        // Standar amount
        amount_mxn: values.amount,
        // Per month
        amount_per_month_mxn: values.amount_per_month,

        // Discount ------------------------------------------
        has_discount: values.has_discount,
        discount_percentage: values.discount_percentage,

        // Standar amount + discount
        amount_after_discount_mxn: values.amount_after_discount,
        // Per month + discount
        amount_after_discount_per_month_mxn: values.amount_after_discount_per_month,

        amount_full_course_by_course_mxn: values.amount_full_course_by_course,
      };
    case "COP":
      return {
        show_as_free_course: values.show_as_free_course,
        available_to_purchase: values.available_to_purchaseamount_after_discount_per_month_col,

        // Standar amount
        amount_col: values.amount,
        // Per month
        amount_per_month_col: values.amount_per_month,

        // Discount ------------------------------------------
        has_discount: values.has_discount,
        discount_percentage: values.discount_percentage,

        // Standar amount + discount
        amount_after_discount_col: values.amount_after_discount,
        // Per month + discount
        amount_after_discount_per_month_col: values.amount_after_discount_per_month,

        amount_full_course_by_course_col: values.amount_full_course_by_course,
      };
    default:
      return {};
  }
};
