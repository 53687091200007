// React
import React, { SyntheticEvent, useEffect, useState } from "react";

// Router
import { useNavigate, useParams } from "react-router-dom";

// Moment
import moment from "moment";
import "moment/locale/es";

// Interfaces
import { IGroupRooms } from "../../../interfaces/group.interface";

// Redux
import {
   listRoomsByGroupId,
   selectStateRoomsByGroupId,
} from "../../../slices/roomSlice/roomsByGroupIdSlice";
import { AppDispatch, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";

// Material UI
import {
   Chip,
   Divider,
   Grid,
   IconButton,
   Stack,
   Tooltip,
   Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Components
import ScreenLoader from "../../../components/ScreenLoader/ScreenLoader";
import Rooms from "./Rooms/Rooms";
import Students from "./Students/Students";
import Paragraph from "../../../components/common/Paragraph/Paragraph";

interface Props { }

const GroupRooms: React.FC<Props> = () => {
   const navigate = useNavigate();
   const { groupId } = useParams();
   const dispatch: AppDispatch = useAppDispatch();
   const { rooms, isLoading } = useSelector(selectStateRoomsByGroupId);
   const [currentPosition, setCurrentPosition] = useState<string>("0");

   useEffect(() => {
      if (groupId) {
         dispatch(listRoomsByGroupId(Number(groupId)));
      }
   }, [groupId]);

   const handleChangePosition = (_event: SyntheticEvent, newValue: string) =>
      setCurrentPosition(newValue);

   if (isLoading || !rooms) return <ScreenLoader />;

   return (
      <Grid style={{ overflowY: "scroll", height: "100%" }}>
         <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            rowGap={2}
         >
            {/* Information */}
            <Grid item xs={12}>
               <Divider textAlign="left">
                  <Chip
                     label="Información del grupo"
                     variant="outlined"
                     color="primary"
                  />
               </Divider>
            </Grid>
            <Grid item container rowGap={1.5} xs={12}>
               {/* Name */}
               <Grid item xs={6}>
                  <Stack direction="row" spacing={2}>
                     <Typography variant="subtitle2" gutterBottom>
                        Nombre:
                     </Typography>
                     <Typography variant="body2" gutterBottom>
                        {rooms.name}
                     </Typography>
                  </Stack>
               </Grid>

               {/* Start Date */}
               <Grid item xs={6}>
                  <Stack direction="row" spacing={2}>
                     <Typography variant="subtitle2" gutterBottom>
                        Fecha de inicio:
                     </Typography>
                     <Typography variant="body2" gutterBottom>
                        {moment(rooms.start_date).format("YYYY MMMM D")}
                     </Typography>
                  </Stack>
               </Grid>

               {/* Time */}
               <Grid item xs={6}>
                  <Stack direction="row" spacing={2}>
                     <Typography variant="subtitle2" gutterBottom>
                        Horario:
                     </Typography>
                     <Typography variant="body2" gutterBottom>
                        {/* Monday */}
                        {rooms.time_monday &&
                           `Cada lunes, de ${moment(
                              new Date(`2002/08/01 ${rooms.time_monday}`)
                           ).format("HH:mm A")} a ${moment(
                              new Date(`2002/08/01 ${rooms.time_monday}`)
                           )
                              .add(rooms.duration_monday, "minutes")
                              .format("HH:mm A")}`}

                        {/* Tuesday */}
                        {rooms.time_tuesday &&
                           `Cada martes, de ${moment(
                              new Date(`2002/08/01 ${rooms.time_tuesday}`)
                           ).format("HH:mm A")} a ${moment(
                              new Date(`2002/08/01 ${rooms.time_tuesday}`)
                           )
                              .add(rooms.duration_tuesday, "minutes")
                              .format("HH:mm A")}`}

                        {/* Wednesday */}
                        {rooms.time_wednesday &&
                           `Cada miercoles, de ${moment(
                              new Date(`2002/08/01 ${rooms.time_wednesday}`)
                           ).format("HH:mm A")} a ${moment(
                              new Date(`2002/08/01 ${rooms.time_wednesday}`)
                           )
                              .add(rooms.duration_wednesday, "minutes")
                              .format("HH:mm A")}`}

                        {/* Thursday */}
                        {rooms.time_thursday &&
                           `Cada jueves, de ${moment(
                              new Date(`2002/08/01 ${rooms.time_thursday}`)
                           ).format("HH:mm A")} a ${moment(
                              new Date(`2002/08/01 ${rooms.time_thursday}`)
                           )
                              .add(rooms.duration_thursday, "minutes")
                              .format("HH:mm A")}`}

                        {/* Friday */}
                        {rooms.time_friday &&
                           `Cada viernes, de ${moment(
                              new Date(`2002/08/01 ${rooms.time_friday}`)
                           ).format("HH:mm A")} a ${moment(
                              new Date(`2002/08/01 ${rooms.time_friday}`)
                           )
                              .add(rooms.duration_friday, "minutes")
                              .format("HH:mm A")}`}

                        {/* Saturday */}
                        {rooms.time_saturday &&
                           `Cada sabado, de ${moment(
                              new Date(`2002/08/01 ${rooms.time_saturday}`)
                           ).format("HH:mm A")} a ${moment(
                              new Date(`2002/08/01 ${rooms.time_saturday}`)
                           )
                              .add(rooms.duration_saturday, "minutes")
                              .format("HH:mm A")}`}
                     </Typography>
                  </Stack>
               </Grid>

               {/* End Date */}
               <Grid item xs={6}>
                  <Stack direction="row" spacing={2}>
                     <Typography variant="subtitle2" gutterBottom>
                        Fin de clases:
                     </Typography>
                     <Typography variant="body2" gutterBottom>
                        {moment(rooms.end_date).format("YYYY MMMM D")}
                     </Typography>
                  </Stack>
               </Grid>

               {/* Course */}
               <Grid item xs={6}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                     <Typography variant="subtitle2">Curso:</Typography>

                     <Typography variant="body2" gutterBottom>
                        {rooms.course.name}
                     </Typography>

                     <Tooltip title="Ir a editar curso" arrow placement="right">
                        <IconButton
                           aria-label="more-info"
                           size="small"
                           color="primary"
                           onClick={() =>
                              navigate(
                                 `/dashboard/courses?id=${rooms.course.id}`
                              )
                           }
                        >
                           <VisibilityIcon />
                        </IconButton>
                     </Tooltip>
                  </Stack>
               </Grid>

               {/* Learning Path */}
               {rooms.course.first_learning_path && (
                  <Grid item xs={6}>
                     <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="subtitle2">Ruta:</Typography>

                        <Typography variant="body2" gutterBottom>
                           {rooms.course.name_of_first_learning_path}
                        </Typography>

                        <Tooltip
                           title="Ir a editar ruta"
                           arrow
                           placement="right"
                        >
                           <IconButton
                              aria-label="more-info"
                              size="small"
                              color="primary"
                              onClick={() =>
                                 navigate(
                                    `/dashboard/learning-paths?id=${rooms.course.first_learning_path}`
                                 )
                              }
                           >
                              <VisibilityIcon />
                           </IconButton>
                        </Tooltip>
                     </Stack>
                  </Grid>
               )}
            </Grid>

            {/* Helpers */}
            <Grid item xs={12}>
               <Divider textAlign="left">
                  <Chip label="Ayudas" variant="outlined" color="primary" />
               </Divider>
            </Grid>
            <Grid item container xs={12}>
               <Stack direction="row" spacing={2}>

                  < Paragraph
                     title="Copiar URL Asesor Comercial"
                     text={`${process.env.REACT_APP_DOMAIN}/enrollment-page/${rooms.id}?asesor=true`}
                     copyable
                     hideText
                     direction="right"
                  />
                  {rooms.course.is_first_course_of_learning_path && (
                     <Paragraph
                        title="Copiar URL Ruta Completa + Asesor Comercial"
                        text={`${process.env.REACT_APP_DOMAIN}/enrollment-page/${rooms.id}?ruta_completa=true&asesor=true`}
                        copyable
                        hideText
                        direction="right"
                        placement="bottom"
                     />
                  )}

               </Stack>
            </Grid>

            {/* Sections */}
            <Grid item container rowGap={1.5} xs={12}>
               <TabContext value={currentPosition}>
                  <Box
                     sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        width: "100%",
                     }}
                  >
                     <TabList
                        onChange={handleChangePosition}
                        aria-label="room actions"
                     >
                        <Tab label="Salones" value="0" />
                        <Tab label="Estudiantes" value="1" />
                     </TabList>
                  </Box>

                  <TabPanel value="0" sx={{ p: 0, width: "100%" }}>
                     <Rooms
                        rooms={rooms.rooms}
                        teachers={rooms.teachers}
                        groupId={rooms.id}
                        /* refetch={listRoomsForGroup} */
                        isFetching={!rooms}
                     />
                  </TabPanel>
                  <TabPanel value="1" sx={{ p: 0, width: "100%" }}>
                     <Students
                        students={rooms.students}
                        rooms={rooms.rooms}
                        complement_students={rooms.complement_students}
                        groupId={rooms.id}
                        /* refetch={listRoomsForGroup} */
                        isFetching={!rooms}
                     />
                  </TabPanel>
               </TabContext>
            </Grid>
         </Grid>
      </Grid>
   );
};

export default GroupRooms;
