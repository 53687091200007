// React
import React, { SyntheticEvent, useState } from "react";

// Material UI
import { Box } from "@material-ui/core";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

// Components
import GeneralData from "./GeneralData/GeneralData";
import TechnicalData from "./TechnicalData/TechnicalData";
import Prices from "./Prices/Prices";
import Multimedia from "./Multimedia/Multimedia";
import PathFaqs from "./PathFaqs/PathFaqs";
import Recommendations from "./Recommendations/Recommendations";

interface Props {
  learningPathId: number;
  refetch: () => {};
  onClose: () => void;
}

const EditLearningPath: React.FC<Props> = ({ learningPathId, refetch }) => {
  const [currentPosition, setCurrentPosition] = useState<string>("0");

  const handleChangePosition = (_event: SyntheticEvent, newValue: string) =>
    setCurrentPosition(newValue);

  return (
    <TabContext value={currentPosition}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <TabList onChange={handleChangePosition} aria-label="room actions">
          <Tab label="Datos Generales" value="0" />
          <Tab label="Datos Técnicos" value="1" />
          <Tab label="Precios" value="2" />
          <Tab label="Multimedia" value="3" />
          <Tab label="Path FAQS" value="4" />
          <Tab label="Recomendaciones" value="5" />
        </TabList>
      </Box>

      <TabPanel value="0" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <GeneralData refetch={refetch} learningPathId={learningPathId} />
      </TabPanel>
      <TabPanel value="1" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <TechnicalData refetch={refetch} learningPathId={learningPathId} />
      </TabPanel>
      <TabPanel value="2" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <Prices refetch={refetch} learningPathId={learningPathId} />
      </TabPanel>
      <TabPanel value="3" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <Multimedia refetch={refetch} learningPathId={learningPathId} />
      </TabPanel>
      <TabPanel value="4" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <PathFaqs refetch={refetch} learningPathId={learningPathId} />
      </TabPanel>
      <TabPanel value="5" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <Recommendations
          refetch={refetch}
          learningPathId={learningPathId}
          originType="learning_path"
        />
      </TabPanel>
    </TabContext>
  );
};

export default EditLearningPath;
