import { styled } from "@material-ui/core/styles";

export const Text = styled("span")(() => ({
  margin: "0 0 0 8px",
}));

export const Mailto = styled("a")(() => ({
  TextDecoder: "none",
  Color: "inherit",
}));
