// Material UI
import { LinearProgress } from "@mui/material";
import { GridOverlay } from "@mui/x-data-grid";

interface Props {}

const CustomLoadingOverlay: React.FC<Props> = () => {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};

export default CustomLoadingOverlay;
