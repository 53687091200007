// Axios
import axios, { AxiosResponse } from "axios";

// Helpers
import { getAccessCookie } from "../helpers/cookies.helpers";
import {
   IGroupDetail,
   IGroupDetailPag,
   IGroupRooms,
} from "../interfaces/group.interface";

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? GROUPS
export const getGroups = async (
   selectOption1?: any,
   selectOption2?: any,
   selectOption3?: any,
   selectOption4?: any,
   isSearch?: boolean,
   message?: string
) => {
   const response = await axios.get<IGroupDetailPag[]>(
      `${instanceBaseURL}/groups/?no-page${
         selectOption1?.name
            ? `&course__learning_paths__name=${selectOption1?.name}`
            : ""
      }${selectOption2?.name ? `&course__name=${selectOption2?.name}` : ""}${
         selectOption3?.id < 4 ? `&published_at=${selectOption3?.value}` : ""
      }${
         selectOption4?.id < 3
            ? `&course__business_type=${selectOption4?.name}`
            : ""
      }${isSearch ? `&search=${message}` : ""}`,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return response;
};

export const getGroupById = async (groupId: any) => {
   try {
      const response = await axios.get<IGroupDetail>(
         `${instanceBaseURL}/groups/${groupId}/`,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return response;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const postCreateGroup = async (newGroup: IGroupDetail) => {
   try {
      const response = await axios.post(
         `${instanceBaseURL}/groups/`,
         newGroup,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return response;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const patchEditGroup = async (groupId: any, group: IGroupDetail) => {
   try {
      const { data } = await axios.patch(
         `${instanceBaseURL}/groups/${groupId}/`,
         group,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};
