// React
import React, { useState } from "react";

// React Query
import { QueryObserver, useMutation, useQueryClient } from "react-query";

// Interface
import { ICoupon } from "../../../interfaces/coupon.interface";

// API
import { POST_MICRO } from "../../../services/api/api";

// Material UI
import { Grid, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddCircleIcon from "@mui/icons-material/AddCircle";


interface Props {
    couponProgramId: number;
}

const CreateCoupon: React.FC<Props> = ({
    couponProgramId,
}) => {
    const [coupon, setCoupon] = useState<ICoupon>({ code: "", program_id: 0 });

    const createCourse = useMutation((data: ICoupon) => POST_MICRO(`/coupon/`, data), {
        retry: false,
    });

    const queryClient = useQueryClient();
    const { refetch: refetchCoupon } = new QueryObserver(queryClient, {
        queryKey: "coupon-program",
    });

    const handleCreateCoupon = async () => {
        await createCourse.mutateAsync({ ...coupon, program_id: couponProgramId });

        refetchCoupon();
        setCoupon({ code: "", program_id: 0 });
    }

    return (
        <Grid item xs={12}>
            <Stack direction="row" spacing={1.5} justifyContent="space-between">
                <Stack
                    direction="column"
                    spacing={1.5}
                    sx={{ minWidth: "65%", maxWidth: "65%" }}
                >
                    <TextField
                        fullWidth
                        id="code"
                        label="Add code"
                        placeholder="Add code"
                        value={coupon.code}
                        onChange={(e) => {
                            setCoupon({
                                ...coupon,
                                code: e.target.value.toUpperCase(),
                            });
                        }}
                        variant="outlined"
                        size="small"
                        type="text"
                        onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                    />

                </Stack>

                <LoadingButton
                    sx={{ width: "50%" }}
                    variant="contained"
                    onClick={handleCreateCoupon}
                    startIcon={<AddCircleIcon />}
                >
                    Add
                </LoadingButton>
            </Stack>
        </Grid>
    );
};

export default CreateCoupon;