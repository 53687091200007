import React from "react";

interface Props {
  fill?: string;
  className?: string;
}

const IconLogo: React.FC<Props> = ({ className, fill = "#000000" }) => {
  return (
    <svg
      className={className}
      width="56"
      height="39"
      viewBox="0 0 56 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_705_6380)">
        <path
          d="M7.68451 6.77659C7.14525 7.52771 6.28243 8.02398 5.27132 8.02398C3.58615 8.02398 2.31889 6.64246 2.31889 4.91221C2.31889 3.19537 3.58615 1.78702 5.27132 1.78702C6.25547 1.78702 7.09132 2.26988 7.63058 2.96735L9.00569 1.98821C8.1968 0.861531 6.84865 0.164062 5.27132 0.164062C2.62897 0.164062 0.593262 2.29671 0.593262 4.91221C0.593262 7.51429 2.62897 9.64694 5.27132 9.64694C6.8891 9.64694 8.23725 8.90923 9.04613 7.76914L7.68451 6.77659Z"
          fill={fill}
        />
        <path
          d="M17.1127 1.8025C17.9755 1.8025 18.3664 2.59386 18.3664 3.2645C18.3664 3.94856 17.9755 4.69968 17.0587 4.69968H15.1983V1.8025H17.1127ZM17.8002 6.16167C19.4584 5.90682 20.1595 4.57896 20.1595 3.2645C20.1595 1.76226 19.2427 0.273438 17.1935 0.273438H13.4727V9.51488H15.1983V6.22873H15.9128L18.0968 9.51488H20.173L17.8002 6.16167Z"
          fill={fill}
        />
        <path
          d="M30.7811 9.51488H32.6011L29.1228 0.273438H27.2624L23.7842 9.51488H25.6177L26.467 7.27493H29.9317L30.7811 9.51488ZM28.1926 2.24513L29.3655 5.7727H27.0467L28.1926 2.24513Z"
          fill={fill}
        />
        <path
          d="M43.0844 6.77659C42.5452 7.52771 41.6824 8.02398 40.6712 8.02398C38.9861 8.02398 37.7188 6.64246 37.7188 4.91221C37.7188 3.19537 38.9861 1.78702 40.6712 1.78702C41.6554 1.78702 42.4912 2.26988 43.0305 2.96735L44.4056 1.98821C43.5967 0.861531 42.2486 0.164062 40.6712 0.164062C38.0289 0.164062 35.9932 2.29671 35.9932 4.91221C35.9932 7.51429 38.0289 9.64694 40.6712 9.64694C42.289 9.64694 43.6372 8.90923 44.4461 7.76914L43.0844 6.77659Z"
          fill={fill}
        />
        <path
          d="M51.5963 4.86063L55.1286 0.273438H53.1064L50.6122 3.64006V0.273438H48.873V9.51488H50.6122V6.0946L53.7131 9.51488H55.9645L51.5963 4.86063Z"
          fill={fill}
        />
        <path
          d="M0.377441 16.2881H2.99285V23.9602H4.73196V16.2881H7.34735V14.7188H0.377441V16.2881Z"
          fill={fill}
        />
        <path
          d="M17.2866 14.7188V18.5951H13.5657V14.7188H11.8535V23.9602H13.5657V20.1375H17.2866V23.9602H19.0257V14.7188H17.2866Z"
          fill={fill}
        />
        <path
          d="M24.312 23.9602H30.1899V22.4311H26.0242V20.1644H29.5563V18.6487H26.0242V16.2478H30.0551V14.7188H24.312V23.9602Z"
          fill={fill}
        />
        <path
          d="M7.68451 35.6672C7.14525 36.4183 6.28243 36.9146 5.27132 36.9146C3.58615 36.9146 2.31889 35.5331 2.31889 33.8028C2.31889 32.086 3.58615 30.6776 5.27132 30.6776C6.25547 30.6776 7.09132 31.1605 7.63058 31.858L9.00569 30.8788C8.1968 29.7522 6.84865 29.0547 5.27132 29.0547C2.62897 29.0547 0.593262 31.1873 0.593262 33.8028C0.593262 36.4049 2.62897 38.5376 5.27132 38.5376C6.8891 38.5376 8.23725 37.7999 9.04613 36.6598L7.68451 35.6672Z"
          fill={fill}
        />
        <path
          d="M14.6189 33.8028C14.6189 32.1262 15.9266 30.7447 17.6118 30.7447C19.27 30.7447 20.6047 32.1262 20.6047 33.8028C20.6047 35.4929 19.27 36.8476 17.6118 36.8476C15.9266 36.8476 14.6189 35.4929 14.6189 33.8028ZM12.9067 33.8028C12.9067 36.4049 14.9694 38.5376 17.6118 38.5376C20.2541 38.5376 22.3303 36.4049 22.3303 33.8028C22.3303 31.1873 20.2541 29.0547 17.6118 29.0547C14.9694 29.0547 12.9067 31.1873 12.9067 33.8028Z"
          fill={fill}
        />
        <path
          d="M28.7759 30.7334H30.6768C32.3216 30.7334 33.1439 32.1283 33.1439 33.8049C33.1439 35.4681 32.3216 36.8362 30.6768 36.8362H28.7759V30.7334ZM30.6768 38.4055C33.3327 38.4055 34.8696 36.4338 34.8696 33.8049C34.8696 31.176 33.3327 29.1641 30.6768 29.1641H27.0503V38.4055H30.6768Z"
          fill={fill}
        />
        <path
          d="M39.6401 38.4055H45.5181V36.8765H41.3523V34.6097H44.8844V33.094H41.3523V30.6931H45.3833V29.1641H39.6401V38.4055Z"
          fill={fill}
        />
        <path
          d="M49.9302 37.5194C49.9302 38.1095 50.402 38.5388 50.9817 38.5388C51.5749 38.5388 52.0604 38.1095 52.0604 37.5194C52.0604 36.9292 51.5749 36.5 50.9817 36.5C50.402 36.5 49.9302 36.9292 49.9302 37.5194Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_705_6380">
          <rect width="56" height="38.6909" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconLogo;
