import { useEffect, useMemo, useState } from "react";

// Services
import { getTeachersByAvailability } from "../../services/teachers";

// Components
import Table from "../../components/molecules/Table";
import DefaultLayout from "../../components/templates/DefaultLayout";
import TagModality from "../../components/molecules/TagModality";
import ButtonLineal from "../../components/molecules/ButtonLineal";
import ScreenLoader from "../../components/ScreenLoader/ScreenLoader";
import Typography from "../../components/atoms/Typography";
import ModalCreateTeacher from "../../views/Teachers/ModalCreateTeacher/ModalCreateTeacher";

import "./teacherAvailability.scss";

const TeacherAvailability = () => {
   const [teachers, setTeachers] = useState([]);

   useEffect(() => {
      listTeacher();
   }, []);

   const listTeacher = async () => {
      const response = await getTeachersByAvailability();
      if (response) setTeachers(response);
   };

   const columns = useMemo(
      () => [
         {
            Header: "ID",
            accessor: "id",
            widthColumn: 40,
            disableSortBy: true,
         },
         {
            Header: "Nombres",
            accessor: "first_name",
            minWidthColumn: 140,
            classColumn: "name-group",
            /*widthColumn: 320, */
            Cell: ({ cell }: any) => <>{cell.value}</>,
            /* hasTooltip: true,
            disableSortBy: true, */
         },
         {
            Header: "Apellidos",
            accessor: "last_name",
            classColumn: "name-teacher",
            widthColumn: 190,
            /* hasTooltip: true, */
            Cell: ({ cell }: any) => <>{cell.value}</>,
         },
         {
            Header: "Modalidad",
            accessor: "modality_CTC",
            hasSort: true,
            widthColumn: 100,
            align: "center",
            Cell: ({ cell }: any) => (
               <span style={{ textTransform: "capitalize" }}>
                  <TagModality>{cell.value}</TagModality>
               </span>
            ),
            disableSortBy: true,
            disableFilters: true,
         },
         {
            Header: "Correo",
            accessor: "email",
            widthColumn: 225,
            Cell: ({ cell }: any) => <>{cell.value}</>,
            disableSortBy: true,
            disableFilters: true,
         },
         {
            Header: "Discord",
            accessor: "discord",
            widthColumn: 190,
            align: "center",
            Cell: ({ cell }: any) => <>{cell.value}</>,
            disableSortBy: true,
            disableFilters: true,
         },
         {
            Header: "WhatsApp",
            accessor: "phone_number",
            widthColumn: 120,
            align: "center",
            Cell: ({ cell }: any) => <>{cell.value}</>,
            disableSortBy: true,
            disableFilters: true,
            //hideColumn: true,
         },
         {
            Header: "",
            accessor: "action",
            actionWidth: 58,
            align: "center",
            headerPagination: false,
            hasTooltip: false,
            tooltipValue: "Ir a Zoom",
            tooltipValue1: "Ir a Wiki",
            Cell: (props: any) => {
               return (
                  <>
                     <ButtonLineal
                        size="small"
                        to={`/dashboard/teachers/teacher-availability/teacher-profile/${props.row.original.id}`}
                     >
                        Editar
                     </ButtonLineal>
                  </>
               );
            },
            disableSortBy: true,
            disableFilters: true,
         },
      ],
      []
   );
   if (!teachers) return <ScreenLoader />;

   return (
      <DefaultLayout>
         {/* Create Teacher */}
         <ModalCreateTeacher refetch={listTeacher} />
         <Typography
            type="h2"
            variation="mobile"
            bold
            className="teacher-availability teacher-availability__title"
         >
            Profesores y su disponibilidad
         </Typography>
         <Typography
            type="paragraph2"
            variation="mobile"
            className="teacher-availability teacher-availability__subtitle"
         >
            Ingresa a Editar para cambiar la disponibilidad y más datos de cada
            profesor.
         </Typography>
         <Table data={teachers} columns={columns} />
      </DefaultLayout>
   );
};

export default TeacherAvailability;
