import React from "react";

interface Props {
    fill?: string;
    className?: string;
}

const IconInvoices: React.FC<Props> = ({ className, fill = "#555555" }) => {
    return (
        <svg
            className={className}
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 0.601562V20.6016H12.3077V19.0631H1.53846V2.14002H9.23077V6.75541H13.8462V8.29387H15.3846V5.67849L15.1538 5.44772L10.5385 0.832332L10.3077 0.601562H0ZM10.7692 3.21695L12.7692 5.21695H10.7692V3.21695ZM3.07692 8.29387V9.83233H12.3077V8.29387H3.07692ZM16.1538 9.83233V11.3708C14.8462 11.6016 13.8462 12.6785 13.8462 14.0631C13.8462 15.6016 15 16.7554 16.5385 16.7554H17.3077C17.9231 16.7554 18.4615 17.2939 18.4615 17.9093C18.4615 18.5246 17.9231 19.0631 17.3077 19.0631H14.6154V20.6016H16.1538V22.14H17.6923V20.6016C19 20.3708 20 19.2939 20 17.9093C20 16.3708 18.8462 15.2169 17.3077 15.2169H16.5385C15.9231 15.2169 15.3846 14.6785 15.3846 14.0631C15.3846 13.4477 15.9231 12.9093 16.5385 12.9093H19.2308V11.3708H17.6923V9.83233H16.1538ZM3.07692 12.14V13.6785H8.46154V12.14H3.07692ZM10 12.14V13.6785H12.3077V12.14H10ZM3.07692 15.2169V16.7554H8.46154V15.2169H3.07692ZM10 15.2169V16.7554H12.3077V15.2169H10Z"
                fill={fill}
            />
        </svg>
    )
};
export default IconInvoices;
