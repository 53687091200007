import React, { useEffect, useState } from "react";

// Components

import ButtonFilled from "../../molecules/ButtonFilled/ButtonFilled";
import Typography from "../../atoms/Typography";
import Select from "../../molecules/Select";
import Textbox from "../../molecules/Textbox";

import IconArrow from "../../atoms/icons/IconArrow";
import IconClose from "../../atoms/icons/IconClose";

// Axios
import axios from "axios";

// Helpers
import { getAccessCookie } from "../../../helpers/cookies.helpers";

import "./globalSearch.scss";

interface Props {
   message: string;
   setMessage: any;
   isSearch: boolean;
   setIsSearch: any;
   children?: any;
   selectOption1?: any;
   setSelectOption1?: any;
   selectOption2?: any;
   setSelectOption2?: any;
   selectOption3?: any;
   setSelectOption3?: any;
   selectOption4?: any;
   setSelectOption4?: any;
   data?: any;
}

const GlobalSearch: React.FC<Props> = ({
   message,
   setMessage,
   isSearch,
   setIsSearch,
   selectOption1,
   setSelectOption1,
   selectOption2,
   setSelectOption2,
   selectOption3,
   setSelectOption3,
   selectOption4,
   setSelectOption4,
   data,
}) => {
   let datos: any[] = data;

   let objState = [
      { id: 100, name: "Todos", value: null },
      { id: 1, name: "Público", value: false },
      { id: 2, name: "Privado", value: true },
   ];

   let objBT = [
      { id: 100, name: "Todos" },
      { id: 1, name: "B2C" },
      { id: 2, name: "B2B" },
   ];

   const [isOpenSelect1, setIsOpenSelect1] = useState(false);
   const [isOpenSelect2, setIsOpenSelect2] = useState(false);
   const [isOpenSelect3, setIsOpenSelect3] = useState(false);
   const [isOpenSelect4, setIsOpenSelect4] = useState(false);
   const [statusButton, setStatusButton] = useState("disabled");
   const [individualCourses, setIndividualCourses] = useState<any[]>([]);

   const onClickButton = () => {
      setIsSearch(!isSearch);
      if (message) {
         setStatusButton("clear");
      }
      if (statusButton === "clear") {
         setSelectOption1({});
         setSelectOption2({});
         setSelectOption3({});
         setSelectOption4({});
         setMessage("");
         setStatusButton("disabled");
         setIsOpenSelect1(false);
         setIsOpenSelect2(false);
         setIsOpenSelect3(false);
         setIsOpenSelect4(false);
      }
   };

   useEffect(() => {
      if (message) {
         setStatusButton("search");
      } else if (
         selectOption1.name ||
         selectOption2.name ||
         selectOption3.name ||
         selectOption4.name
      ) {
         setStatusButton("clear");
      }
   }, [
      selectOption1.name,
      selectOption2.name,
      selectOption3.name,
      selectOption4.name,
      message,
   ]);

   useEffect(() => {
      getIndividualCourses();
   }, []);

   const getIndividualCourses = async () => {
      try {
         const { data } = await axios.get<any[]>(
            `${process.env.REACT_APP_CTC_BACKEND_URL}/groups_for_ci/`,
            {
               headers: { Authorization: `Bearer ${getAccessCookie()}` },
            }
         );

         const renameItem = data.map((item) => {
            let groupitem = {
               id: item.id,
               name: item.course,
               learning_path: "Cursos Individuales",
            };
            return groupitem;
         });

         const filterIC = renameItem.filter(
            (item) => item.learning_path === "Cursos Individuales"
         );

         let hash: any = {};
         let filterUnique = filterIC.filter((o: any) =>
            hash[o.name] ? false : (hash[o.name] = true)
         );

         setIndividualCourses(filterUnique);
      } catch (error) {
         console.log("somethings went wrong!", error);
      }
   };

   const handleChange = (event: any) => {
      setMessage(event.target.value);
   };

   const optionSelection1 = (option: any): void => {
      setSelectOption1(option);
      setSelectOption2({});
      setSelectOption3({});
      setSelectOption4({});
   };
   const optionSelection2 = (option: any): void => {
      setSelectOption2(option);
   };
   const optionSelection3 = (option: any): void => {
      setSelectOption3(option);
   };
   const optionSelection4 = (option: any): void => {
      setSelectOption4(option);
   };

   return (
      <div className="global-search">
         <div className="global-search__detail-global-search">
            <section className="global-search__detail">
               <div className="global-search__programa global-search__default">
                  <Typography type="paragraph2" variation="mobile" bold>
                     Programa
                  </Typography>
                  <Select
                     onClickOutside={() => {
                        setIsOpenSelect1(false);
                     }}
                     setIsOpen={setIsOpenSelect1}
                     isOpen={isOpenSelect1}
                     size="default"
                     onclickDefaultValue={optionSelection1}
                     options={datos}
                     disabled={!datos}
                  >
                     {selectOption1.name ? selectOption1?.name : "Todos"}
                  </Select>
               </div>
               <div className="global-search__curso global-search__default">
                  <Typography type="paragraph2" variation="mobile" bold>
                     Curso
                  </Typography>
                  <Select
                     onClickOutside={() => {
                        setIsOpenSelect2(false);
                     }}
                     setIsOpen={setIsOpenSelect2}
                     isOpen={isOpenSelect2}
                     size="default"
                     onclickDefaultValue={optionSelection2}
                     options={
                        selectOption1.id !== 0
                           ? selectOption1.courses
                           : individualCourses
                     }
                     disabled={selectOption1?.id >= 0 ? false : true}
                  >
                     {selectOption2.name ? selectOption2?.name : "Todos"}
                  </Select>
               </div>
               <div className="global-search__estado global-search__small">
                  <Typography type="paragraph2" variation="mobile" bold>
                     Estado
                  </Typography>
                  <Select
                     onClickOutside={() => {
                        setIsOpenSelect3(false);
                     }}
                     setIsOpen={setIsOpenSelect3}
                     isOpen={isOpenSelect3}
                     size="small"
                     options={objState}
                     onclickDefaultValue={optionSelection3}
                  >
                     {selectOption3.name ? selectOption3?.name : "Todos"}
                  </Select>
               </div>
               <div className="global-search__business global-search__small">
                  <Typography type="paragraph2" variation="mobile" bold>
                     Business
                  </Typography>
                  <Select
                     onClickOutside={() => {
                        setIsOpenSelect4(false);
                     }}
                     setIsOpen={setIsOpenSelect4}
                     isOpen={isOpenSelect4}
                     size="small"
                     options={objBT}
                     onclickDefaultValue={optionSelection4}
                  >
                     {selectOption4.name ? selectOption4?.name : "Todos"}
                  </Select>
               </div>
               <div className="global-search__busqueda global-search__default">
                  <Typography type="paragraph2" variation="mobile" bold>
                     Búsqueda de ID o Grupo
                  </Typography>
                  <Textbox onChange={handleChange}>{message}</Textbox>
               </div>
               <div className="global-search__container-button">
                  <Typography type="paragraph2" variation="mobile" bold>
                     {" "}
                  </Typography>
                  <ButtonFilled
                     size="small"
                     className={`global-search__button global-search__button--${statusButton}`}
                     disabled={statusButton === "disabled" ? true : false}
                     onClick={onClickButton}
                     iconRight={
                        statusButton === "clear" ? (
                           <IconClose fill="#000000" />
                        ) : (
                           <IconArrow fill="#ffffff" />
                        )
                     }
                  >
                     {statusButton === "clear" ? "Borrar" : "Buscar"}
                  </ButtonFilled>
               </div>
            </section>
         </div>
      </div>
   );
};

export default GlobalSearch;
