// React Query
import { useMutation, useQuery } from "react-query";

// Yup
import * as Yup from "yup";

// Formik
import { Form, Formik } from "formik";

// API
import { GET, PATCHFILES } from "../../../../../services/api/api";

// Interfaces
import { IFullCourse } from "../../../../../interfaces/course.interface";

// Hot Toast
import { toast } from "react-hot-toast";

// Material UI
import {
  Alert,
  CardMedia,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const MultimediaSchema = Yup.object().shape({
  thumbnail: Yup.mixed(),
  cover: Yup.mixed(),
  final_project_cover: Yup.mixed(),

  jumbotron_cover: Yup.mixed(),
  jumbotron_cover_mobile: Yup.mixed(),

  curriculum: Yup.mixed(),
  instructive: Yup.mixed(),
});

interface Props {
  refetch: () => {};
  courseId: number;
  isMultimediaSent: boolean;
  setIsMultimediaSent: any;
}

const Multimedia: React.FC<Props> = ({
  refetch,
  courseId,
  isMultimediaSent,
  setIsMultimediaSent,
}) => {
  const { data: course, remove } = useQuery(
    ["course-multimedia", courseId],
    () => GET<IFullCourse>(`/courses_backoffice/${courseId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!courseId && isMultimediaSent,
    }
  );

  const createCourseMultimedia = useMutation(
    (courseMultimedia: any) =>
      PATCHFILES(`/courses_backoffice/media/${courseId}/`, courseMultimedia),
    {
      retry: false,
    }
  );

  if (!!courseId && isMultimediaSent && !course) return <LinearProgress />;

  let initialValues: any = {
    thumbnail: null,
    cover: null,
    final_project_cover: null,

    jumbotron_cover: null,
    jumbotron_cover_mobile: null,

    curriculum: null,
    instructive: null,
  };

  if (isMultimediaSent && course) {
    initialValues = {
      thumbnail: course.thumbnail,
      cover: course.cover,
      final_project_cover: course.final_project_cover,

      jumbotron_cover: course.jumbotron_cover,
      jumbotron_cover_mobile: course.jumbotron_cover_mobile,

      curriculum: course.curriculum,
      instructive: course.instructive,
    };
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={MultimediaSchema}
      onSubmit={async (values) => {
        var bodyFormData = new FormData();
        bodyFormData.append("thumbnail", values.thumbnail);
        bodyFormData.append("cover", values.cover);
        bodyFormData.append("final_project_cover", values.final_project_cover);
        bodyFormData.append("jumbotron_cover", values.jumbotron_cover);
        bodyFormData.append("jumbotron_cover_mobile", values.jumbotron_cover_mobile);
        bodyFormData.append("curriculum", values.curriculum);
        bodyFormData.append("instructive", values.instructive);

        await toast.promise(
          createCourseMultimedia
            .mutateAsync(bodyFormData)
            .then(() => setIsMultimediaSent(true)),
          {
            loading: "Cargando...",
            success: "Multimedia creado",
            error: "Error",
          },
          {
            success: {
              icon: <AddCircleIcon color="primary" />,
            },
          }
        );

        remove();
        refetch();
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <Grid container rowSpacing={2} alignItems="flex-start">
            {/* Main */}
            <Grid item container columnSpacing={10} rowSpacing={2} xs={12}>
              {/* Thumbnail */}
              <Grid item xs={6}>
                <Stack direction="column" rowGap={1.5}>
                  <Chip
                    label="Thumbnail"
                    variant="outlined"
                    color={values.thumbnail ? "primary" : "default"}
                  />
                  <Stack
                    direction="column"
                    rowGap={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <input
                      id="thumbnail"
                      name="thumbnail"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue("thumbnail", e.target.files[0]);
                      }}
                      disabled={isMultimediaSent}
                    />
                    {values.thumbnail && (
                      <CardMedia
                        component="img"
                        height="100"
                        width="100"
                        image={
                          typeof values.thumbnail === "object"
                            ? URL.createObjectURL(values.thumbnail)
                            : values.thumbnail
                        }
                        sx={{
                          objectFit: "contain",
                          borderRadius: "8px",
                        }}
                      />
                    )}
                    {errors.thumbnail && touched.thumbnail && (
                      <Alert severity="error">{errors.thumbnail}</Alert>
                    )}
                  </Stack>
                </Stack>
              </Grid>

              {/* Cover */}
              <Grid item xs={6}>
                <Stack direction="column" rowGap={1.5}>
                  <Chip
                    label="Cover"
                    variant="outlined"
                    color={values.cover ? "primary" : "default"}
                  />
                  <Stack
                    direction="column"
                    rowGap={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <input
                      id="cover"
                      name="cover"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue("cover", e.target.files[0]);
                      }}
                      disabled={isMultimediaSent}
                    />
                    {values.cover && (
                      <CardMedia
                        component="img"
                        height="100"
                        width="100"
                        image={
                          typeof values.cover === "object"
                            ? URL.createObjectURL(values.cover)
                            : values.cover
                        }
                        sx={{ objectFit: "contain", borderRadius: "8px" }}
                      />
                    )}
                    {errors.cover && touched.cover && (
                      <Alert severity="error">{errors.cover}</Alert>
                    )}
                  </Stack>
                </Stack>
              </Grid>

              {/* Final_project_cover */}
              <Grid item xs={12}>
                <Stack direction="column" rowGap={1.5}>
                  <Chip
                    label="Final Project Cover"
                    variant="outlined"
                    color={values.final_project_cover ? "primary" : "default"}
                  />
                  <Stack
                    direction="column"
                    rowGap={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <input
                      id="final_project_cover"
                      name="final_project_cover"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue("final_project_cover", e.target.files[0]);
                      }}
                      disabled={isMultimediaSent}
                    />
                    {values.final_project_cover && (
                      <CardMedia
                        component="img"
                        height="100"
                        width="100"
                        image={
                          typeof values.final_project_cover === "object"
                            ? URL.createObjectURL(values.final_project_cover)
                            : values.final_project_cover
                        }
                        sx={{ objectFit: "cover", borderRadius: "8px" }}
                      />
                    )}
                    {errors.final_project_cover && touched.final_project_cover && (
                      <Alert severity="error">{errors.final_project_cover}</Alert>
                    )}
                  </Stack>
                </Stack>
              </Grid>

              {/* Jumbotron_cover */}
              <Grid item xs={6}>
                <Stack direction="column" rowGap={1.5}>
                  <Chip
                    label="Jumbotron Cover"
                    variant="outlined"
                    color={values.jumbotron_cover ? "primary" : "default"}
                  />
                  <Stack
                    direction="column"
                    rowGap={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <input
                      id="jumbotron_cover"
                      name="jumbotron_cover"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue("jumbotron_cover", e.target.files[0]);
                      }}
                      disabled={isMultimediaSent}
                    />
                    {values.jumbotron_cover && (
                      <CardMedia
                        component="img"
                        height="100"
                        width="100"
                        image={
                          typeof values.jumbotron_cover === "object"
                            ? URL.createObjectURL(values.jumbotron_cover)
                            : values.jumbotron_cover
                        }
                        sx={{ objectFit: "contain", borderRadius: "8px" }}
                      />
                    )}
                    {errors.jumbotron_cover && touched.jumbotron_cover && (
                      <Alert severity="error">{errors.jumbotron_cover}</Alert>
                    )}
                  </Stack>
                </Stack>
              </Grid>

              {/* Jumbotron_cover_mobile */}
              <Grid item xs={6}>
                <Stack direction="column" rowGap={1.5}>
                  <Chip
                    label="Jumbotron Cover Mobile"
                    variant="outlined"
                    color={values.jumbotron_cover_mobile ? "primary" : "default"}
                  />
                  <Stack
                    direction="column"
                    rowGap={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <input
                      id="jumbotron_cover_mobile"
                      name="jumbotron_cover_mobile"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue("jumbotron_cover_mobile", e.target.files[0]);
                      }}
                      disabled={isMultimediaSent}
                    />
                    {values.jumbotron_cover_mobile && (
                      <CardMedia
                        component="img"
                        height="100"
                        width="100"
                        image={
                          typeof values.jumbotron_cover_mobile === "object"
                            ? URL.createObjectURL(values.jumbotron_cover_mobile)
                            : values.jumbotron_cover_mobile
                        }
                        sx={{ objectFit: "contain", borderRadius: "8px" }}
                      />
                    )}
                    {errors.jumbotron_cover_mobile &&
                      touched.jumbotron_cover_mobile && (
                        <Alert severity="error">
                          {errors.jumbotron_cover_mobile}
                        </Alert>
                      )}
                  </Stack>
                </Stack>
              </Grid>

              {/* Curriculum */}
              <Grid item xs={6}>
                <Stack direction="column" rowGap={1.5}>
                  <Chip
                    label="Curriculum"
                    variant="outlined"
                    color={values.curriculum ? "primary" : "default"}
                  />
                  <Stack
                    direction="column"
                    rowGap={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <input
                      id="curriculum"
                      name="curriculum"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue("curriculum", e.target.files[0]);
                      }}
                      disabled={isMultimediaSent}
                    />
                    {values.curriculum && (
                      <object
                        data={
                          typeof values.curriculum === "object"
                            ? URL.createObjectURL(values.curriculum)
                            : values.curriculum
                        }
                        type="application/pdf"
                        width="100%"
                        height="200px"
                        style={{ borderRadius: "8px" }}
                      >
                        <p>Alternative text - include a link</p>
                      </object>
                    )}
                    {errors.curriculum && touched.curriculum && (
                      <Alert severity="error">{errors.curriculum}</Alert>
                    )}
                  </Stack>
                </Stack>
              </Grid>

              {/* Instructive */}
              <Grid item xs={6}>
                <Stack direction="column" rowGap={1.5}>
                  <Chip
                    label="Instructive"
                    variant="outlined"
                    color={values.instructive ? "primary" : "default"}
                  />
                  <Stack
                    direction="column"
                    rowGap={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <input
                      id="instructive"
                      name="instructive"
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue("instructive", e.target.files[0]);
                      }}
                      disabled={isMultimediaSent}
                    />
                    {values.instructive && (
                      <object
                        data={
                          typeof values.instructive === "object"
                            ? URL.createObjectURL(values.instructive)
                            : values.instructive
                        }
                        type="application/pdf"
                        width="100%"
                        height="200px"
                        style={{ borderRadius: "8px" }}
                      >
                        <p>Alternative text - include a link</p>
                      </object>
                    )}
                    {errors.instructive && touched.instructive && (
                      <Alert severity="error">{errors.instructive}</Alert>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            {/* Save */}
            <Grid item container rowSpacing={2} xs={12}>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  loading={createCourseMultimedia.isLoading}
                  variant="contained"
                  type="submit"
                  disabled={isMultimediaSent}
                  startIcon={<AddCircleIcon />}
                >
                  Crear
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default Multimedia;
