import React from "react";

// Colors
import colors from "../../atoms/Colors";

// Components
import imgProfile from "../../../assets/img/imgProfile.png";
import Typography from "../../atoms/Typography";
import ButtonLineal from "../ButtonLineal";
import ButtonFilled from "../ButtonFilled";
import IconArrow from "../../atoms/icons/IconArrow";
import ScreenLoader from "../../ScreenLoader/ScreenLoader";

// Redux
import { AppDispatch, useAppDispatch } from "../../../store/store";
import { setTeacherToRoom } from "../../../slices/roomSlice/roomDetailByGroupSlice";

import "./dropdownDetailTeacher.scss";

interface Props {
   setDropdownComponent: any;
   setIsOpen: any;
   teacherInfo: any;
   onClickHandler: Function;
}

const DropdownDetailTeacher: React.FC<Props> = ({
   setDropdownComponent,
   setIsOpen,
   teacherInfo,
   onClickHandler,
}) => {
   const dispatch: AppDispatch = useAppDispatch();

   if (!teacherInfo) return <ScreenLoader fullWidth={false} />;

   const firstName = teacherInfo.first_name
      ? teacherInfo.first_name.split(" ")[0]
      : "";
   const lastName = teacherInfo.last_name
      ? teacherInfo.last_name.split(" ")[0]
      : "";

   return (
      <div>
         <section className="dropdown__detail-teacher">
            <div className="dropdown__container">
               <div className="dropdown__section-teacher">
                  <img
                     className="dropdown__image-teacher"
                     src={imgProfile}
                     alt="Imagen perfil profesor | CTC"
                  />
                  <Typography
                     type="paragraph2"
                     variation="mobile"
                     className="dropdown__name-teacher"
                  >
                     {`${firstName.toLowerCase()} ${lastName.toLowerCase()}`}
                  </Typography>
               </div>
               <section className="dropdown__extra-details">
                  <Typography type="small-12" variation="mobile">
                     Modalidad
                  </Typography>
                  <Typography
                     className="dropdown__modality"
                     type="small-12"
                     variation="mobile"
                     bold
                  >
                     {teacherInfo.modality_CTC}
                  </Typography>
                  <Typography type="small-12" variation="mobile">
                     Cursos actuales
                  </Typography>
                  <Typography type="small-12" variation="mobile" bold>
                     {teacherInfo.current_courses.courses
                        .map(
                           (course: any, idx: number) =>
                              course.group__course__name
                        )
                        .join(", ")}
                  </Typography>
               </section>
               <div>
                  <ButtonLineal
                     size="small"
                     className="dropdown__button-return"
                     onClick={() => setDropdownComponent("listTeacher")}
                     iconLeft={
                        <IconArrow
                           fill={colors.black}
                           className="dropdown__icon-return"
                        />
                     }
                  >
                     Regresar
                  </ButtonLineal>
               </div>
               <div className="dropdown__cta-choose">
                  <ButtonFilled
                     size="small"
                     onClick={() => {
                        setIsOpen(false);
                        setDropdownComponent("listTeacher");
                        if (teacherInfo) {
                           const teacherDetail = {
                              email: teacherInfo.email,
                              full_name: `${teacherInfo.first_name} ${teacherInfo.last_name}`,
                              id: teacherInfo.id,
                           };
                           onClickHandler(teacherDetail);
                           dispatch(setTeacherToRoom(teacherDetail));
                        }
                     }}
                  >
                     Elegir profesor
                  </ButtonFilled>
               </div>
            </div>
         </section>
      </div>
   );
};

export default DropdownDetailTeacher;
