// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

// Constants
import PERMISSIONS from "../../constants/permission.constants";

// Components
import NextClass from "../../components/organism/NextClass";
import RoomsLayout from "../../components/templates/RoomsLayout";
import ScreenLoader from "../../components/ScreenLoader/ScreenLoader";
import TableRoom from "../../components/organism/TableRoom";

function Rooms() {
   const user = useSelector((state: RootState) => state.auth.user);

   if (!user) return <ScreenLoader />;

   return (
      <RoomsLayout
         nextClass={
            !user?.permission[PERMISSIONS.roomListAll] ? (
               <NextClass user={user} />
            ) : null
         }
         table={<TableRoom user={user} />}
      />
   );
}

export default Rooms;
