import "./studentLayout.scss";

interface Props {
   table: any;
}

const StudentLayout: React.FC<Props> = ({ table }) => {
   return (
      <main className="student-layout">
         <div className="student-layout__table">{table}</div>
      </main>
   );
};

export default StudentLayout;
