import React, { useState } from "react";

import "./button.scss";

interface Props {
   children: any;
   onClick?: any;
   className?: string;
   size?: any;
   iconLeft?: any;
   iconRight?: any;
   disabled?: any;
   type?: any;
   fullWidth?: boolean | string | number;
   width?: string | number;
}

const Button: React.FC<Props> = ({
   children,
   onClick,
   size = "default",
   iconLeft,
   iconRight,
   className,
   disabled,
   type = "button",
   fullWidth = false,
   width,
   ...otherProps
}) => {
   let hasIcon = iconLeft || iconRight ? "icon" : "no-icon";
   return (
      <>
         <button
            disabled={disabled}
            className={`button button__${hasIcon} button__${hasIcon}--${size} ${
               className ? className : ""
            }`}
            {...otherProps}
            onClick={disabled ? "" : onClick}
            type={type}
            style={{
               width:
                  typeof fullWidth === "string" || typeof fullWidth === "number"
                     ? width
                     : fullWidth === true
                     ? "100%"
                     : "auto",
            }}
         >
            {iconLeft} {children} {iconRight}
         </button>
      </>
   );
};

export default Button;
