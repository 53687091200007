// React
import React, { SyntheticEvent, useState } from "react";

// Material UI
import { Box } from "@material-ui/core";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

// Components
import GeneralData from "./GeneralData/GeneralData";
import TechnicalData from "./TechnicalData/TechnicalData";
import Prices from "./Prices/Prices";
import Multimedia from "./Multimedia/Multimedia";
import PathFaqs from "./PathFaqs/PathFaqs";
import Recommendations from "./Recommendations/Recommendations";

interface Props {
  refetch: () => {};
  onClose: () => void;
}

const CreateLearningPath: React.FC<Props> = ({ refetch }) => {
  const [currentPosition, setCurrentPosition] = useState<string>("0");

  const [learningPathId, setLearningPathId] = useState<number>(0);

  const [isGeneralDataSent, setIsGeneralDataSent] = useState<boolean>(false);
  const [isTechnicalDataSent, setIsTechnicalDataSent] = useState<boolean>(false);
  const [isMultimediaSent, setIsMultimediaSent] = useState<boolean>(false);

  const handleChangePosition = (_event: SyntheticEvent, newValue: string) =>
    setCurrentPosition(newValue);

  return (
    <TabContext value={currentPosition}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <TabList onChange={handleChangePosition} aria-label="room actions">
          <Tab label="Datos Generales" value="0" />
          <Tab label="Datos Técnicos" value="1" disabled={!learningPathId} />
          <Tab label="Precios" value="2" disabled={!learningPathId} />
          <Tab label="Multimedia" value="3" disabled={!learningPathId} />
          <Tab label="Path Faqs" value="4" disabled={!learningPathId} />
          <Tab label="Recomendaciones" value="5" disabled={!learningPathId} />
        </TabList>
      </Box>

      <TabPanel value="0" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <GeneralData
          refetch={refetch}
          learningPathId={learningPathId}
          setLearningPathId={setLearningPathId}
          isGeneralDataSent={isGeneralDataSent}
          setIsGeneralDataSent={setIsGeneralDataSent}
        />
      </TabPanel>
      <TabPanel value="1" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <TechnicalData
          refetch={refetch}
          learningPathId={learningPathId}
          isTechnicalDataSent={isTechnicalDataSent}
          setIsTechnicalDataSent={setIsTechnicalDataSent}
        />
      </TabPanel>
      <TabPanel value="2" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <Prices refetch={refetch} learningPathId={learningPathId} />
      </TabPanel>
      <TabPanel value="3" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <Multimedia
          refetch={refetch}
          learningPathId={learningPathId}
          isMultimediaSent={isMultimediaSent}
          setIsMultimediaSent={setIsMultimediaSent}
        />
      </TabPanel>
      <TabPanel value="4" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <PathFaqs refetch={refetch} learningPathId={learningPathId} />
      </TabPanel>
      <TabPanel value="5" sx={{ p: 0, width: "100%", marginTop: "20px" }}>
        <Recommendations
          refetch={refetch}
          learningPathId={learningPathId}
          originType="learning_path"
        />
      </TabPanel>
    </TabContext>
  );
};

export default CreateLearningPath;
