// Axios
import axios, { AxiosResponse } from "axios";

// Helpers
import { getAccessCookie } from "../helpers/cookies.helpers";
import { IGroupDetailPag } from "../interfaces/group.interface";

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? LEARNINGS
export const getLearningsForGroups = async () => {
   const response = await axios.get<IGroupDetailPag[]>(
      `${instanceBaseURL}/learning_paths_backoffice_groups/`,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return response;
};
