import Tag from "../../atoms/TagStatus/Tag";

import "./tagModality.scss";

interface Props {
   children: any;
   className?: string;
   modality?: "locador";
}

const TagModality: React.FC<Props> = ({ children, className = "" }) => {
   return <Tag className={`tag-modality ${className}`}>{children}</Tag>;
};

export default TagModality;
