// Axios
import axios, { AxiosResponse } from "axios";

// Helpers
import { getAccessCookie } from "../helpers/cookies.helpers";
import { EmailAndPassword, IStudent } from "../interfaces/students.interface";

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? STUDENTS
export const getStudents = async () => {
   const { data } = await axios.get<IStudent[]>(
      `${instanceBaseURL}/students-backoffice/?no-page`,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return data;
};

export const getStudentForId = async (studentId: number) => {
   const { data } = await axios.get<IStudent>(
      `${instanceBaseURL}/students/${studentId}/`,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return data;
};

export const postAddStudentToGroup = async (groupId: number, selectedStudentId: number) => {
   try {
      const { data } = await axios.post(
         `${instanceBaseURL}/groups/${groupId}/students/${selectedStudentId}/`,
         {},
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const postUpdateStudentRoom= async (roomId: any, studentId: any) => {
   try {
      const { data } = await axios.post(
         `${instanceBaseURL}/rooms/${roomId}/students/${studentId}/`,
         {},
         {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};
//credenciales
export const patchUpdateStudent= async ({ email, password, password_google }: EmailAndPassword, studentId:any) => {
   try {
      const { data } = await axios.patch(
         `${instanceBaseURL}/students/${studentId}/`,
         { email, password, password_google },
         {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const patchEditStudent= async (student: any, studentId:any) => {
   try {
      const { data } = await axios.patch(
         `${instanceBaseURL}/students/${studentId}/`,
         student,
         {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};

export const removeStudentOfGroup = async (groupId: any, studentId: any) => {
   try {
      const { data } = await axios.delete(
         `${instanceBaseURL}/groups/${groupId}/students/${studentId}`,
         {
            headers: { Authorization: `Bearer ${getAccessCookie()}` },
         }
      );
      return data;
   } catch (e) {
      //handleInvalidToken({ redirect: '/' });
   }
};