// React
import { useState } from "react";

// Interfaces
import { Enrolment } from "../../../../../../interfaces/guardian.interface";

// Material UI
import { Dialog, DialogContent, IconButton, Tooltip } from "@mui/material";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

// Components
import CustomDialogTitle from "../../../../../../components/CustomDialogTitle/CustomDialogTitle";
import ViewEnrolments from "./ViewEnrolments/ViewEnrolments";

interface Props {
  enrolments: Enrolment[];
  guardianId: number;
  //refetch: () => {};
}

const ModalViewEnrolments: React.FC<Props> = ({ enrolments, guardianId }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      {/* Open Modal */}
      <Tooltip title="Inscripciones" arrow placement="left">
        <IconButton
          aria-label="more info"
          color="primary"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleOpenModal();
          }}
        >
          <VisibilityRoundedIcon />
        </IconButton>
      </Tooltip>

      {/* Modal View Enrolments */}
      <Dialog fullWidth maxWidth="lg" open={openModal} onClose={handleCloseModal}>
        <CustomDialogTitle onClose={handleCloseModal}>
          Inscripciones
        </CustomDialogTitle>
        <DialogContent dividers>
          <ViewEnrolments enrolments={enrolments} guardianId = {guardianId} /* refetch={refetch} */ />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalViewEnrolments;
