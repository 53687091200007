// React
import { useEffect, useState } from "react";

// Router
import Router from "./routes/routes";

// Redux
import { useSelector } from "react-redux";
import {
   getCurrentUser,
   logout,
   refreshToken,
   setToken,
} from "./slices/authSlice";
import { getAllRooms } from "./slices/roomSlice/roomsSlice";
import { getAllGuardians } from "./slices/guardianSlice/guardianSlice";
import { getAllStudents } from "./slices/studentSlice/studentSlice";
import { getAllGroups } from "./slices/groupSlice/groupsSlice";
import { AppDispatch, RootState, useAppDispatch } from "./store/store";
import { getAllEnrolments } from "./slices/enrolmentSlice/enrolmentsSlice";

// Constants
import PERMISSIONS from "./constants/permission.constants";

// Helpers
import { getAccessCookie, getRefreshCookie } from "./helpers/cookies.helpers";

// Hooks
import useFirstRender from "./hooks/useIsFirstRender";

function App() {
   const dispatch: AppDispatch = useAppDispatch();
   const user = useSelector((state: RootState) => state.auth.user);
   const rejected = useSelector((state: RootState) => state.auth.rejected);
   const lastRejected = useSelector(
      (state: RootState) => state.auth.lastRejected
   );

   const isFirstRender = useFirstRender();
   const accessCookie = getAccessCookie();
   const refreshCookie = getRefreshCookie();
   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
      !!accessCookie
   );
   
   useEffect(() => {
      if (user) {
         const userId = user?.permission[PERMISSIONS.roomListAll]
            ? 0
            : user?.id;

         dispatch(getAllRooms(userId));
         dispatch(getAllGuardians());
         dispatch(getAllStudents());
         dispatch(getAllEnrolments());
         dispatch(getAllGroups());
      }
   }, [dispatch, user]);

   useEffect(() => {
      rejected && refreshCookie && refresh(refreshCookie);
      // eslint-disable-next-line
   }, [rejected]);

   useEffect(() => {
      if (lastRejected) {
         dispatch(logout());
         setIsAuthenticated(false);
      }
      // eslint-disable-next-line
   }, [lastRejected]);

   useEffect(() => {
      !isFirstRender && setIsAuthenticated(!!user);
      // eslint-disable-next-line
   }, [user]);

   useEffect(() => {
      if (isAuthenticated) {
         dispatch(setToken(accessCookie));
         dispatch(getCurrentUser());
      }
      // eslint-disable-next-line
   }, []);

   const refresh = async (refresh: string) => {
      const result = await dispatch(refreshToken(refresh));

      if (refreshToken.fulfilled.match(result)) {
         const resultUser = await dispatch(getCurrentUser());
         if (getCurrentUser.fulfilled.match(resultUser)) {
            setIsAuthenticated(true);
         }
      }
   };

   return <Router isAuthenticated={isAuthenticated} />;
}

export default App;
