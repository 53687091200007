// React
import React, { useState } from "react";

// React Query
import { QueryObserver, useQueryClient, useMutation } from "react-query";

// API
import { PATCH_MICRO } from "../../services/api/api";

// Components
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay/CustomLoadingOverlay";

// Material UI
import {
    DataGrid,
    GridColDef,
    GridColumns,
    GridValueGetterParams,
    GridRenderCellParams,
    GridSortModel,
} from "@mui/x-data-grid";
import { Grid, Stack, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

// Interfaces
import { ICoupon } from "../../interfaces/coupon.interface";

// Utils
import { objectsEqual } from "../../utils/utils";

interface Props {
    coupons?: ICoupon[];
    couponProgramId: number;
    refetch: () => {};
}

const ListCoupon: React.FC<Props> = ({ coupons, refetch }) => {
    const queryClient = useQueryClient();
    const { refetch: refetchCoupon } = new QueryObserver(queryClient, {
        queryKey: "coupon-program",
    });

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: "id",
            sort: "desc",
        },
    ]);

    const patchCoupon = useMutation(
        (id: number) => PATCH_MICRO(`/coupon/${id}`)
    );

    const handleCancelCoupon = async (id: number) => {
        await patchCoupon.mutateAsync(id);
        refetch();
        refetchCoupon();
    };

    function generateColumnsByCoupons(): GridColDef[] {
        const columns: GridColumns = [
            {
                field: "id",
                headerName: "Id",
                width: 100,
                editable: false,
                valueGetter: (params: GridValueGetterParams) => {
                    return params.row.id;
                },
            },
            {
                field: "code",
                headerName: "Code",
                width: 400,
                editable: false,
                valueGetter: (params: GridValueGetterParams) => {
                    return params.row.code;
                },
            },
            {
                field: "state",
                headerName: "State",
                width: 200,
                editable: false,
                valueGetter: (params: GridValueGetterParams) => {
                    return params.row.state;
                },
            },
            {
                field: "delete",
                headerName: "Delete",
                width: 85,
                minWidth: 85,
                editable: false,
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                align: "center",
                headerAlign: "center",
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <IconButton
                                edge="end"
                                aria-label="comments"
                                onClick={() => handleCancelCoupon(params.row.id)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    );
                }
            },
        ];
        return columns;
    }

    const currentlySelected = (model: GridSortModel) => {
        !objectsEqual(model, sortModel) && setSortModel(model);
    };

    return (
        <Grid xs={12}>
            {
                coupons?.filter((coupon) => coupon.state == 'active').length ?
                    <DataGrid
                        autoHeight
                        style={{ fontSize: 12, height: 200 }}
                        sortModel={sortModel}
                        onSortModelChange={(model) => currentlySelected(model)}
                        rows={coupons?.filter((coupon) => coupon.state == 'active')}
                        columns={generateColumnsByCoupons()}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        components={{
                            LoadingOverlay: CustomLoadingOverlay,
                        }}
                    /> : null
            }
        </Grid>
    )
};

export default ListCoupon;