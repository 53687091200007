// React Query
import { useMutation } from "react-query";

// API
import { DELETE } from "../../../../services/api/api";

// Material UI
import { IconButton, ListItem, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  id: number;
  name: string;
  refetch: () => {};
  refetchRecommendation: () => {};
}

const CustomListItem: React.FC<Props> = ({
  id,
  name,
  refetch,
  refetchRecommendation,
}) => {
  const deleteRecommendation = useMutation(
    (id: number) => DELETE(`/recommended/backoffice/${id}/`),
    {
      retry: false,
    }
  );

  const handleDeleteTask = async (id: number) => {
    await deleteRecommendation.mutateAsync(id);

    refetch();
    refetchRecommendation();
  };

  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="comments"
          onClick={() => handleDeleteTask(id)}
        >
          <DeleteIcon />
        </IconButton>
      }
      dense
    >
      <ListItemText id={id.toString()} primary={name} />
    </ListItem>
  );
};

export default CustomListItem;
