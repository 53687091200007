// React
import { useMemo } from "react";

// Redux
import {
   selectStateRooms,
   updateRoomZoomLink,
} from "../../../slices/roomSlice/roomsSlice";
import { useSelector } from "react-redux";
import { AppDispatch, useAppDispatch } from "../../../store/store";

// Moment
import moment from "moment";
import "moment/locale/es";

// Hot Toast
import { toast } from "react-hot-toast";

// Constants
import PERMISSIONS from "../../../constants/permission.constants";

// Router
import { Link as LinkRouter } from "react-router-dom";

import ScreenLoader from "../../../components/ScreenLoader/ScreenLoader";
import Table from "../../../components/molecules/Table";
import Link from "../../../components/molecules/Link";
import TagStatusGroups from "../../../components/molecules/TagStatusGroups";
import FloatingMenu from "../../../components/molecules/FloatingMenu";
import IconZoomColor from "../../../components/atoms/icons/IconZoomColor";
import IconWiki from "../../../components/atoms/icons/IconWiki";
import ItemFloatingMenu from "../../molecules/ItemFloatingMenu";

import "./tableRoom.scss";

interface Props {
   user: any;
}

const TableRoom: React.FC<Props> = ({ user }) => {
   const dispatch: AppDispatch = useAppDispatch();
   const { rooms, isLoading } = useSelector(selectStateRooms);

   const handleRefreshMeetingLink = async (zoomId: any) => {
      await dispatch(updateRoomZoomLink(zoomId));
   };

   const columns = useMemo(
      () => [
         {
            Header: "ID",
            accessor: "id",
            minWidthColumn: 42,
            disableSortBy: true,
         },
         {
            Header: "Grupo",
            accessor: "group.name",
            classColumn: "name-group",
            widthColumn: 320,
            Cell: ({ cell }: any) => <>{cell.value}</>,
            hasTooltip: true,
            disableSortBy: true,
         },
         {
            Header: "Profesor",
            accessor: "teacher.full_name",
            classColumn: "name-teacher",
            widthColumn: 152,
            hasTooltip: true,
            Cell: ({ cell }: any) => <>{cell.value}</>,
         },
         {
            Header: "Día",
            accessor: "day_sessions",
            hasSort: true,
            widthColumn: 80,
            disableFilters: true,
            sortType: (rowA: any, rowB: any, id: any, desc: any) => {
               if (rowA.values[id][0] > rowB.values[id][0]) return 1;
               if (rowB.values[id][0] > rowA.values[id][0]) return -1;
               return 0;
            },
            Cell: ({ cell }: any) => <>{cell.value[0]}</>,
         },
         {
            Header: "Estado",
            accessor: "status",
            align: "center",
            Cell: ({ cell }: any) => (
               <TagStatusGroups status={cell.value}>
                  {cell.value}
               </TagStatusGroups>
            ),
            disableSortBy: true,
            disableFilters: true,
         },
         {
            Header: "Inicio",
            accessor: "group.start_date",
            align: "center",
            Cell: ({ cell }: any) => (
               <>{cell.value ? moment(cell.value).format("DD-MM-YY") : ""}</>
            ),
            disableFilters: true,
         },
         {
            Header: "Fin",
            accessor: "group.end_date",
            align: "center",
            Cell: ({ cell }: any) => (
               <>{cell.value ? moment(cell.value).format("DD-MM-YY") : ""}</>
            ),
            disableFilters: true,
         },
         {
            Header: "Estudiantes",
            accessor: "students_number",
            align: "center",
            disableSortBy: true,
            disableFilters: true,
            widthColumn: 81,
            //hideColumn: true,
         },
         {
            Header: "",
            accessor: "action",
            actionWidth: 160,
            hasTooltip: true,
            tooltipValue: "Ir a Zoom",
            tooltipValue1: "Ir a Wiki",
            Cell: (props: any) => {
               return (
                  <>
                     <Link
                        className="table-room__link table-room__link--hover-lineal"
                        to={`/dashboard/rooms/${props.row.original.id}`}
                     >
                        Ver salón
                     </Link>
                     {props.row.original.zoom_data?.zoom_id ? (
                        <div
                           className="table-guardian__container-tooltip"
                           onClick={() =>
                              handleRefreshMeetingLink(
                                 props.row.original.zoom_data.zoom_id
                              )
                           }
                        >
                           <IconZoomColor
                              className={`table-room__button table-room__button--enabled`}
                              fill="#237FE8"
                           />
                           <section className="table-guardian__tooltip table-guardian__tooltip--zoom">
                              {props.column.tooltipValue}
                           </section>
                        </div>
                     ) : (
                        <div className="table-guardian__container-tooltip">
                           <IconZoomColor
                              className={`table-room__button table-room__button--disabled`}
                              fill="#bcbcbc"
                           />
                           <section className="table-guardian__tooltip table-guardian__tooltip--zoom">
                              {props.column.tooltipValue}
                           </section>
                        </div>
                     )}
                     {props.row.original.course_wiki_url ? (
                        <a
                           className="table-guardian__container-tooltip"
                           href={props.row.original.course_wiki_url}
                           target="_blank"
                        >
                           <IconWiki
                              className={`table-room__button table-room__button--enabled`}
                              fill="#9C56E0"
                           />
                           <section className="table-guardian__tooltip table-guardian__tooltip--wiki">
                              {props.column.tooltipValue1}
                           </section>
                        </a>
                     ) : (
                        <div className="table-guardian__container-tooltip">
                           <IconWiki
                              className={`table-room__button table-room__button--disabled`}
                              fill="#bcbcbc"
                           />
                           <section className="table-guardian__tooltip table-guardian__tooltip--wiki">
                              {props.column.tooltipValue1}
                           </section>
                        </div>
                     )}
                     <FloatingMenu
                        items={[
                           <ItemFloatingMenu
                              onClick={() => {
                                 if (props.row.original.zoom_data.url_alumno) {
                                    toast.success("URL de Zoom copiado...");
                                    navigator.clipboard.writeText(
                                       props.row.original.zoom_data.url_alumno
                                    );
                                 } else toast.error("No existe URL de Zoom");
                              }}
                           >
                              Copiar URL de Zoom
                           </ItemFloatingMenu>,
                           <>
                              {user?.permission[PERMISSIONS.roomListAll] ? (
                                 <ItemFloatingMenu>
                                    <LinkRouter
                                       to={`/dashboard/groups/${props.row.original.group.id}/rooms`}
                                    >
                                       Ver Grupo
                                    </LinkRouter>
                                 </ItemFloatingMenu>
                              ) : (
                                 <ItemFloatingMenu disabled>
                                    Ver Grupo
                                 </ItemFloatingMenu>
                              )}
                           </>,
                           ,
                        ]}
                     />
                  </>
               );
            },
            headerPagination: true,
            disableSortBy: true,
            disableFilters: true,
         },
      ],
      []
   );
   if (isLoading) return <ScreenLoader />;

   return <Table data={rooms} columns={columns} />;
};

export default TableRoom;
