// React
import { useState } from "react";

// Formik
import { Formik, Form } from "formik";

// Material UI
import { LoadingButton } from "@mui/lab";
import { Input, Stack, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";

// Components
import Paragraph from "../../../../components/common/Paragraph/Paragraph";

// Constants
import PERMISSIONS from "../../../../constants/permission.constants";

// Redux
import {
   AppDispatch,
   RootState,
   useAppDispatch,
} from "../../../../store/store";
import { useSelector } from "react-redux";
import {
   getAllRooms,
   updateRoom,
} from "../../../../slices/roomSlice/roomsSlice";

interface Props {
   roomId: number;
   url_satisfaction_survey: string;
   refetch: () => {};
}

const EditUrlSatisfaction: React.FC<Props> = ({
   roomId,
   url_satisfaction_survey,
   refetch,
}) => {
   const [isEdit, setIsEdit] = useState<boolean>(false);
   const dispatch: AppDispatch = useAppDispatch();
   const user = useSelector((state: RootState) => state.auth.user);
   const { isCompleted } = useSelector((state: RootState) => state.rooms);

   const editRoom = async (room: any) => {
      try {
         await dispatch(updateRoom({ roomId, room }));
      } catch (error) {
         console.log(error);
      } finally {
         if (user) {
            const userId = user?.permission[PERMISSIONS.roomListAll]
               ? 0
               : user?.id;
            await dispatch(getAllRooms(userId));
         }
      }
   };

   const handleEdit = () => setIsEdit(true);
   const handleCancel = () => setIsEdit(false);

   const initialValues = {
      url_satisfaction_survey: url_satisfaction_survey,
   };

   return (
      <>
         {isEdit ? (
            <>
               <Formik
                  initialValues={initialValues}
                  onSubmit={async (values) => {
                     const data = {
                        url_satisfaction_survey:
                           values.url_satisfaction_survey || " ",
                     };
                     await editRoom(data);
                     refetch();
                     setIsEdit(false);
                  }}
               >
                  {({ values, handleChange }) => (
                     <Form>
                        <Stack direction="row" columnGap={1}>
                           <Input
                              id="url_satisfaction_survey"
                              value={values.url_satisfaction_survey}
                              onChange={handleChange}
                           />
                           <LoadingButton
                              color="primary"
                              type="submit"
                              /* loading={isCompleted ? false : true} */
                              loadingPosition="start"
                              startIcon={<SaveIcon />}
                              variant="contained"
                           >
                              Guardar
                           </LoadingButton>
                           <LoadingButton
                              color="error"
                              onClick={handleCancel}
                              loadingPosition="start"
                              startIcon={<CancelIcon />}
                              variant="contained"
                           >
                              Cancelar
                           </LoadingButton>
                        </Stack>
                     </Form>
                  )}
               </Formik>
            </>
         ) : (
            <>
               <Typography variant="body2" gutterBottom>
                  {url_satisfaction_survey}
               </Typography>
               <LoadingButton
                  color="secondary"
                  onClick={handleEdit}
                  loadingPosition="start"
                  startIcon={<EditIcon />}
                  variant="contained"
               >
                  Editar
               </LoadingButton>
               <Paragraph
                  text={url_satisfaction_survey}
                  copyable
                  hideText
                  direction="right"
               />
            </>
         )}
      </>
   );
};

export default EditUrlSatisfaction;
