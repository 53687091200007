import { styled } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import Alert from "@mui/material/Alert";

export const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  [theme.breakpoints.down(768)]: {},
}));

export const Title = styled("h1")(({ theme }) => ({
  margin: "0 0 3rem 0",
  textAlign: "center",
  fontSize: "calc(30px + (42 - 30) * ((100vw - 300px) / (1600 - 300)))",

  [theme.breakpoints.down(768)]: {
    width: "80%",
  },
}));

export const Image = styled("img")(({ theme }) => ({
  width: "35%",
  margin: "0 0 3rem 0",

  [theme.breakpoints.down(768)]: {
    width: "30%",
    margin: "0 0 2rem 0",
  },
}));

export const LoginFormTile = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down(768)]: {
    background: "#ffffff",
    borderRadius: "20px",
    padding: "2rem 0",
  },
}));

export const CustomTextField = styled(TextField)(() => ({
  margin: "0 0 1.5rem 0",
  width: "80%",
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  margin: "0 0 1.5rem 0",
  width: "80%",
  padding: ".6rem",

  [theme.breakpoints.down(768)]: {
    margin: "0",
  },
}));

export const CustomAlert = styled(Alert)(({ theme }) => ({
  margin: "-1rem 0 1rem 0",
  width: "75%",
}));
