// Redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Axios
import { AxiosError } from "axios";

// Interfaces
import { ICourse } from "../../interfaces/course.interface";

// Services
import { getCourses } from "../../services/courses";

export interface IAuthError {
   msg: string;
}

export interface ICoursesState {
   courses: ICourse[];
   isLoading: boolean;
   isCompleted: boolean;
   error: IAuthError | null;
   rejected: boolean;
}

const initialState: ICoursesState = {
   courses: [],
   isLoading: false,
   isCompleted: false,
   error: null,
   rejected: false,
};

export const getAllCourses = createAsyncThunk(
   "courses/getAllCourses",
   async (_, { getState, rejectWithValue }: any) => {
      try {
         /* const { auth: user } = getState() as RootState; */
         const response = await getCourses();

         return response.data;
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const coursesSlice = createSlice({
   name: "courses",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      /** getAllCourses */
      builder.addCase(getAllCourses.pending, (state, _) => {
         state.courses = initialState.courses;
         state.isLoading = true;
      });
      builder.addCase(getAllCourses.fulfilled, (state, action) => {
         state.courses = action.payload;
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(getAllCourses.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
   },
});

export const selectStateCourses = (state: any) => state.courses;
