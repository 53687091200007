// Redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Axios
import { AxiosError } from "axios";

// Interfaces
import { IGroupDetailPag } from "../../interfaces/group.interface";

// Services
import { getIndividualCourses } from "../../services/courses";

export interface IAuthError {
   msg: string;
}

export interface ICoursesState {
   courses: IGroupDetailPag[];
   isLoading: boolean;
   isCompleted: boolean;
   error: IAuthError | null;
   rejected: boolean;
}

const initialState: ICoursesState = {
   courses: [],
   isLoading: false,
   isCompleted: false,
   error: null,
   rejected: false,
};

export const getAllIndividualCourses = createAsyncThunk(
   "courses/getAllIndividualCourses",
   async (
      {
         selectOption2,
         selectOption3,
         selectOption4,
         isSearch,
         message,
      }: {
         selectOption2?: any;
         selectOption3?: any;
         selectOption4?: any;
         isSearch?: boolean;
         message?: string;
      } = {},
      { getState, rejectWithValue }: any
   ) => {
      try {
         /* const { auth: user } = getState() as RootState; */
         const response = await getIndividualCourses(
            selectOption2,
            selectOption3,
            selectOption4,
            isSearch,
            message
         );
         let filterData;
         if (response.status === 200) {
            filterData = response.data.filter(
               (item) => item.learning_path === "Cursos Individuales"
            );
         }
         return filterData;
      } catch (err) {
         if (err instanceof Error) {
            console.log(err.message);
         }
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const individualCoursesSlice = createSlice({
   name: "individualCourses",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      /** getAllIndividualCourses */
      builder.addCase(getAllIndividualCourses.pending, (state, _) => {
         state.courses = initialState.courses;
         state.isLoading = true;
      });
      builder.addCase(getAllIndividualCourses.fulfilled, (state, action) => {
         state.courses = action.payload; /* .sort((a: any, b: any) => {
            if (a.id < b.id) return 1;
            if (a.id > b.id) return -1;
            return 0;
         }); */
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(getAllIndividualCourses.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
   },
});

export const selectStateIndividualCourses = (state: any) =>
   state.individualCourses;
