// React
import { useEffect } from "react";

// Router
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

// Redux
import { setRoom } from "../../../slices/roomDetailSlice/roomDetailSlice";
import { AppDispatch, useAppDispatch } from "../../../store/store";

// React Query
import { useMutation, useQuery } from "react-query";

// API
import { GET, PATCH } from "../../../services/api/api";

// Hot Toast
import { toast } from "react-hot-toast";

// Utils
import { clearString } from "../../../utils/utils";

// Interfaces
import { IRoomDetail } from "../../../interfaces/room.interface";

// Material UI
import { LoadingButton } from "@mui/lab";
import VideocamRoundedIcon from "@material-ui/icons/VideocamRounded";
import { Grid, Paper, Stack, Typography } from "@material-ui/core";
import { IconButton, LinearProgress, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

// Components
import ScreenLoader from "../../../components/ScreenLoader/ScreenLoader";
import EditUrlSatisfaction from "./EditUrlSatisfaction/EditUrlSatisfaction";
import Students from "./Students/Students";
import Teachers from "./Teachers/Teachers";
import Paragraph from "../../../components/common/Paragraph/Paragraph";

function RoomDetail() {
   const dispatch: AppDispatch = useAppDispatch();
   const { roomId } = useParams();

   const {
      data: room,
      error,
      isError,
      isLoading,
      isFetching,
      refetch,
   } = useQuery(["room", roomId], () => GET<IRoomDetail>(`/rooms/${roomId}/`), {
      retry: false,
      refetchOnWindowFocus: false,
   });

   const refreshMeetingLink = useMutation(
      (zoom_meeting_id: string) =>
         PATCH(`/meeting/${zoom_meeting_id}/refresh-start/`, {
            zoom_meeting_id,
         }),
      { retry: false }
   );

   useEffect(() => {
      if (room) dispatch(setRoom(room));
      // eslint-disable-next-line
   }, [room]);

   if (isLoading || !room) return <ScreenLoader />;
   if (isError) return <div>Error! {JSON.stringify(error)}</div>;

   const handleRefreshMeetingLink = async () => {
      await toast.promise(
         refreshMeetingLink
            .mutateAsync(clearString(room.zoom_id))
            .then(({ data: { start_url } }) => {
               setTimeout(() => {
                  window.open(start_url, "_blank");
               }, 1000);
            }),
         {
            loading: "Cargando...",
            success: "Vamos a Zoom!",
            error: `Ha ocurrido un error. Inicie la clase con la cuenta <${room.licensed_user_email}>`,
         },
         {
            success: {
               icon: <InsertLinkIcon color="primary" />,
            },
         }
      );
   };

   return (
      <Grid style={{ overflowY: "scroll", height: "100%" }}>
         {isFetching && <LinearProgress />}
         <Grid container component={Paper} spacing={2} paddingBottom={2}>
            {/* teacher full_name */}
            <Grid item xs={6}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     Profesor:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     {room.teacher.full_name}
                  </Typography>
               </Stack>
            </Grid>

            {/* room name */}
            <Grid item xs={6}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     Room:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     {room.name}
                  </Typography>
               </Stack>
            </Grid>

            {/* group name */}
            <Grid item xs={6}>
               <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="subtitle2" gutterBottom>
                     Grupo:
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                     {room.group.name}
                  </Typography>

                  <Link
                     to={`/dashboard/groups/${room.group.id}/rooms`}
                     style={{ textDecoration: "none" }}
                  >
                     <Tooltip title="Ir al grupo" arrow placement="right">
                        <IconButton color="primary">
                           <RemoveRedEyeIcon />
                        </IconButton>
                     </Tooltip>
                  </Link>
               </Stack>
            </Grid>

            {/* start_date */}
            <Grid item xs={6}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     Fecha de inicio:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     {room.group.start_date}
                  </Typography>
               </Stack>
            </Grid>

            {/* end_date */}
            <Grid item xs={6}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     Fecha de fin:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     {room.group.end_date}
                  </Typography>
               </Stack>
            </Grid>

            {/* zoom_id */}
            <Grid item xs={6}>
               <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="subtitle2" gutterBottom>
                     ID de Zoom:
                  </Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                     <Typography variant="body2" gutterBottom>
                        {room.zoom_id}
                     </Typography>

                     {!!room.password && (
                        <LoadingButton
                           onClick={handleRefreshMeetingLink}
                           loadingPosition="start"
                           startIcon={
                              (refreshMeetingLink.isError && <ErrorIcon />) ||
                              (refreshMeetingLink.isSuccess && (
                                 <CheckIcon />
                              )) || <VideocamRoundedIcon />
                           }
                           loading={refreshMeetingLink.isLoading}
                           color={
                              (refreshMeetingLink.isError && "error") ||
                              (refreshMeetingLink.isSuccess && "success") ||
                              "primary"
                           }
                           size="small"
                           variant="contained"
                        >
                           Ir a Zoom
                        </LoadingButton>
                     )}
                  </Stack>
               </Stack>
            </Grid>

            {/* password */}
            <Grid item xs={6}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     Contraseña meet:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     {room.password}
                  </Typography>
               </Stack>
            </Grid>

            {/* licensed_user_email */}
            <Grid item xs={6}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     Licencia:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     {room.licensed_user_email}
                  </Typography>
               </Stack>
            </Grid>

            {/* licensed_user_password */}
            <Grid item xs={6}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     Contraseña de licencia:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     {room.licensed_user_password}
                  </Typography>
               </Stack>
            </Grid>

            {/* zoom_url */}
            <Grid item xs={6}>
               <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     URL del estudiante:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     {room.zoom_url}
                  </Typography>
               </Stack>
            </Grid>

            {/* google_drive_url_share */}
            <Grid item xs={6}>
               <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="subtitle2" gutterBottom>
                     Drive con grabaciones:
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                     <Paragraph
                        text={room.google_drive_url_share}
                        copyable
                        direction="right"
                     />
                  </Typography>
               </Stack>
            </Grid>

            {/* url_satisfaction_survey */}
            <Grid item xs={12}>
               <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="subtitle2" gutterBottom>
                     URL de satisfacción:
                  </Typography>
                  <EditUrlSatisfaction
                     roomId={room.id}
                     url_satisfaction_survey={room.url_satisfaction_survey}
                     refetch={refetch}
                  />
               </Stack>
            </Grid>
         </Grid>

         <Students refetch={refetch} room={room} />
         <Teachers />
      </Grid>
   );
}

export default RoomDetail;
