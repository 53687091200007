// React Query
import { useQuery } from "react-query";

// API
import { GET } from "../../../../../services/api/api";

// Interfaces
import { IFullCourse } from "../../../../../interfaces/course.interface";

// Material UI
import { Grid, LinearProgress } from "@mui/material";

// Components
import CourseFaqs from "../../../../../components/Courses/CourseFaqs/CourseFaqs";

interface Props {
  refetch: () => {};
  courseId: number;
}

const PathFaqs: React.FC<Props> = ({ refetch, courseId }) => {
  const {
    data: course,
    error: errorCourse,
    isError: isErrorCourse,
    isLoading: isLoadingCourse,
    refetch: refetchCourse,
  } = useQuery(
    ["course-faqs", courseId],
    () => GET<IFullCourse>(`/courses_backoffice/${courseId}/`),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoadingCourse || !course) return <LinearProgress />;
  if (isErrorCourse) return <div>Error! {JSON.stringify(errorCourse)}</div>;

  return (
    <Grid container rowSpacing={2} alignItems="flex-start">
      <CourseFaqs
        faqs={course.faqs}
        courseId={courseId}
        refetch={refetch}
        refetchCourse={refetchCourse}
      />
    </Grid>
  );
};

export default PathFaqs;
