// Material UI
import { Chip, Grid, Stack } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paragraph from "../common/Paragraph/Paragraph";

interface Props {
  children?: React.ReactNode;
  onClose?: () => void;
  options?: {
    id?: number | string;
    copyUrl?: boolean;
  };
}

const CustomDialogTitle: React.FC<Props> = ({
  children,
  onClose,
  options,
  ...other
}) => {
  const url = window.location.href;

  return (
    <DialogTitle sx={{ m: 0, pl: 4, pr: 7, py: 2 }} {...other}>
      <Grid container alignItems="center" rowSpacing={{ xs: 1.5 }}>
        {/* Title */}
        <Grid item xs={12} sm>
          {children}
        </Grid>

        {/* Options */}
        {options && (
          <Grid item xs={12} sm="auto">
            <Stack direction="row" spacing={1}>
              {options.id && <Chip label={"ID: " + options.id} />}
              {options.copyUrl && (
                <Paragraph
                  title="Copiar URL"
                  text={url}
                  copyable
                  hideText
                  direction="right"
                  placement="top"
                />
              )}
            </Stack>
          </Grid>
        )}
      </Grid>

      {/* Button CLOSE */}
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export default CustomDialogTitle;
