// React
import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

// React Query
import { useMutation } from "react-query";

// API
import { POST } from "../../../services/api/api";

// Material UI
import {
   Chip,
   Divider,
   Grid,
   IconButton,
   Stack,
   Alert,
   TextField,
   Typography,
   CardMedia,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import EditIcon from "@mui/icons-material/Edit";
import UpdateIcon from "@mui/icons-material/Update";
import AddCircleIcon from "@mui/icons-material/AddCircle";

interface Props {
   infoSessions: InfoSession;
   courseId: number;
   refetch: any;
   refetchCourse: any;
}

interface InfoSession {
   id?: number;
   number_of_sessions: number;
   session_detail?: any;
   session_number: string;
   habilities: any;
   projects: any;
   course?: number;
}

const CourseInfoSession: React.FC<Props> = ({
   infoSessions,
   courseId,
   refetch,
   refetchCourse,
}) => {
   const [hability, setHability] = useState<Partial<{ description: string }>>({
      description: "",
   });
   const [project, setProject] = useState<
      Partial<{ description?: any; multimedia?: any }>
   >({ description: "", multimedia: null });
   const [idSession, setIdSession] = useState<number | null>(null);
   const [currentSession, setCurrentSession] = useState(null);
   const [imageToEdit, setImageToEdit] = useState<
      Partial<{ id?: any; multimedia?: any; name?: string | null }>
   >({
      id: null,
      multimedia: null,
      name: null,
   });
   const [alert, setAlert] = useState<Partial<{ type?: any; message?: any }>>({
      type: "",
      message: "",
   });

   const createCourseSessionInfo = useMutation(
      (infoSession: InfoSession) => POST(`/sessions/`, infoSession),
      {
         retry: false,
      }
   );

   const handleCreateSessionInfo = async () => {
      if (
         infoSessions.session_detail.session.length <
            infoSessions.number_of_sessions &&
         hability.description &&
         project.description
      ) {
         await createCourseSessionInfo.mutateAsync({
            habilities: [hability],
            projects: [{ description: project.description }],
            course: courseId,
            session_number: (
               infoSessions.session_detail.session.length + 1
            ).toString(),
            number_of_sessions: infoSessions.number_of_sessions,
         });

         refetch();
         refetchCourse();
         setHability({ description: "" });
         setProject({ ...project, description: "" });
         setAlert({ type: "success", message: "Se creó correctamente" });
      } else {
         if (
            infoSessions.session_detail.session.length >=
            infoSessions.number_of_sessions
         ) {
            setAlert({
               type: "error",
               message: `No se pudo agregar la información, el limite de sesiones es ${infoSessions.number_of_sessions}`,
            });
         } else {
            setAlert({
               type: "error",
               message: "No se pudo agregar la información",
            });
         }
      }
   };

   const handleEditProjectImage = async (id: number) => {
      const data = new FormData();
      data.append("multimedia", project.multimedia);
      await axios
         .patch(
            `${process.env.REACT_APP_CTC_BACKEND_URL}/session/project/media/${id}/`,
            data,
            {
               headers: {
                  Authorization: `Bearer ${Cookies.get("ctctknrfrsh")}`,
               },
            }
         )
         .then((res) => {
            refetch();
            refetchCourse();
            setIdSession(null);
            setAlert({
               type: "success",
               message: "Se actualizó la imagen correctamente",
            });
         })
         .catch((e) => {
            setAlert({
               type: "error",
               message: "No se pudo actualizar la imagen",
            });
         });
   };

   const handleEditSessionInfo = async (id: number) => {
      if (currentSession) {
         const data = {
            course: infoSessions.id,
            session_number: currentSession,
            habilities: [hability],
            projects: [{ description: project.description }],
         };

         await axios
            .patch(
               `${process.env.REACT_APP_CTC_BACKEND_URL}/sessions/${id}/`,
               data,
               {
                  headers: {
                     Authorization: `Bearer ${Cookies.get("ctctknrfrsh")}`,
                  },
               }
            )
            .then((res) => {
               refetch();
               refetchCourse();
               setIdSession(null);
               setImageToEdit({ name: null, multimedia: null, id: null });
               setHability({ description: "" });
               setProject({ description: "", multimedia: null });
               setAlert({
                  type: "success",
                  message: "Se actualizó correctamente",
               });
            })
            .catch((e) => {
               setAlert({
                  type: "error",
                  message: "No se pudo actualizar la información",
               });
            });
      }
   };

   const handleFileChange = (event: any) => {
      event.preventDefault();
      let input = event.target;
      if (input.files && input.files[0]) {
         setProject({
            ...project,
            multimedia: input.files[0],
         });
         setImageToEdit({ ...imageToEdit, name: input.files[0].name });
      }
   };

   return (
      <form>
         <Grid
            container
            rowSpacing={1}
            columnSpacing={1}
            sx={{
               padding: 3,
            }}
         >
            {/* Main */}
            {infoSessions &&
               infoSessions.session_detail &&
               infoSessions.session_detail.session.length > 0 &&
               infoSessions.session_detail.session
                  .sort((a: any, b: any) => a.session_number - b.session_number)
                  .map((item: any, idx: any) => (
                     <Grid item xs={12} key={idx}>
                        <Grid container rowSpacing={1}>
                           <Grid item xs={12}>
                              <Grid
                                 container
                                 rowSpacing={1}
                                 columnSpacing={3}
                                 direction="row"
                                 justifyContent="space-between"
                                 alignItems="center"
                              >
                                 <Grid item>
                                    <Typography variant="subtitle2">
                                       {`Sesión ${item.session_number}`}
                                    </Typography>
                                 </Grid>
                                 <Grid item>
                                    <Stack direction="row" columnGap={0}>
                                       <IconButton
                                          aria-label="edit"
                                          onClick={() => {
                                             setProject({
                                                description:
                                                   item?.projects?.description,
                                             });
                                             setHability({
                                                description:
                                                   item?.habilities
                                                      ?.description,
                                             });
                                             setIdSession(item.id);
                                             setCurrentSession(
                                                item.session_number
                                             );
                                             setImageToEdit({
                                                id: item.projects?.id,
                                                multimedia:
                                                   item.projects?.multimedia,
                                             });
                                          }}
                                          color="secondary"
                                       >
                                          <EditIcon />
                                       </IconButton>
                                       {/* 
														<IconButton
															aria-label="remove"
															// onClick={() => handleRemoveSessionInfo(infoSession)}
															color="error"
														>
															<DeleteIcon />
														</IconButton> 
													*/}
                                    </Stack>
                                 </Grid>
                              </Grid>
                              <Divider />
                           </Grid>
                           <Grid item xs={12} md={8}>
                              <Typography
                                 variant="body2"
                                 sx={{
                                    textDecoration: "underline",
                                 }}
                              >
                                 Habilidad
                              </Typography>
                              <Typography variant="body2">
                                 {item?.habilities?.description}
                              </Typography>
                              <br />
                              <Typography
                                 variant="body2"
                                 sx={{
                                    textDecoration: "underline",
                                 }}
                              >
                                 Proyecto
                              </Typography>
                              <Typography variant="body2">
                                 {item?.projects?.description}
                              </Typography>
                           </Grid>
                           <Grid item xs={12} md={4}>
                              <Stack direction="column" rowGap={1.5}>
                                 <Chip
                                    label="Imagen Proyecto"
                                    variant="outlined"
                                    color={
                                       project.multimedia
                                          ? "primary"
                                          : "default"
                                    }
                                 />
                                 <Stack
                                    direction="column"
                                    rowGap={2}
                                    alignItems="center"
                                    justifyContent="space-between"
                                 >
                                    {item?.projects?.multimedia ? (
                                       <CardMedia
                                          component="img"
                                          height="100"
                                          width="100"
                                          image={item?.projects?.multimedia}
                                          sx={{
                                             objectFit: "contain",
                                             borderRadius: "8px",
                                          }}
                                       />
                                    ) : null}
                                 </Stack>
                              </Stack>
                           </Grid>
                        </Grid>
                     </Grid>
                  ))}

            {alert.message && (
               <Grid item xs={12}>
                  <Alert severity={alert.type}>{alert.message}</Alert>
               </Grid>
            )}
            <Grid item xs={12}>
               <Typography variant="subtitle2">
                  {idSession
                     ? "Actualizar información de la sesión"
                     : "Agregar información de la sesión"}
               </Typography>
            </Grid>

            {/* Btn Add ToDo */}
            <Grid item xs={12} md={8}>
               <Grid container rowSpacing={1}>
                  <Grid item xs={12}>
                     <TextField
                        fullWidth
                        id="question"
                        label="Habilidad"
                        placeholder="Habilidad"
                        value={hability.description}
                        onChange={(e) => {
                           setHability({
                              ...hability,
                              description: e.target.value,
                           });
                        }}
                        variant="outlined"
                        size="small"
                        type="text"
                        onKeyPress={(e) =>
                           e.key === "Enter" && e.preventDefault()
                        }
                        multiline
                        rows={3}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <TextField
                        fullWidth
                        id="answer"
                        label="Proyecto"
                        placeholder="Proyecto"
                        minRows={4}
                        value={project.description}
                        onChange={(e) =>
                           setProject({
                              ...project,
                              description: e.target.value,
                           })
                        }
                        style={{
                           width: "100%",
                           minWidth: "100%",
                           maxWidth: "100%",
                        }}
                        onKeyPress={(e) =>
                           e.key === "Enter" && e.preventDefault()
                        }
                        multiline
                        rows={3}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <LoadingButton
                        variant="contained"
                        onClick={
                           idSession
                              ? () => handleEditSessionInfo(idSession)
                              : () => handleCreateSessionInfo()
                        }
                        startIcon={
                           idSession ? <UpdateIcon /> : <AddCircleIcon />
                        }
                        color={idSession ? "secondary" : "primary"}
                        sx={{
                           width: "100%",
                           minWidth: "100%",
                           maxWidth: "100%",
                        }}
                     >
                        {idSession ? "Editar" : "Agregar"}
                     </LoadingButton>
                  </Grid>
               </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
               <Stack direction="column" rowGap={1}>
                  <Chip
                     label="Cambiar Imagen Proyecto"
                     variant="outlined"
                     color={project.multimedia ? "primary" : "default"}
                  />
                  <Stack
                     direction="column"
                     rowGap={2}
                     alignItems="center"
                     justifyContent="space-between"
                     sx={{ height: 148 }}
                  >
                     <input
                        id="image-project"
                        name="image-project"
                        type="file"
                        onChange={handleFileChange}
                        disabled={idSession ? false : true}
                     />
                     {project.multimedia ? (
                        <>
                           <CardMedia
                              component="img"
                              height="100"
                              width="100"
                              image={
                                 typeof project.multimedia === "object"
                                    ? URL.createObjectURL(project.multimedia)
                                    : project.multimedia
                              }
                              sx={{ objectFit: "contain", borderRadius: "8px" }}
                           />
                        </>
                     ) : imageToEdit.multimedia ? (
                        <>
                           <CardMedia
                              component="img"
                              height="100"
                              width="100"
                              image={
                                 typeof imageToEdit.multimedia === "object"
                                    ? URL.createObjectURL(
                                         imageToEdit.multimedia
                                      )
                                    : imageToEdit.multimedia
                              }
                              sx={{ objectFit: "contain", borderRadius: "8px" }}
                           />
                        </>
                     ) : null}
                  </Stack>
                  <LoadingButton
                     disabled={
                        idSession !== null && imageToEdit.name && imageToEdit.id
                           ? false
                           : true
                     }
                     sx={{ width: "100%", marginTop: 0.9 }}
                     variant="contained"
                     onClick={() =>
                        handleEditProjectImage(
                           imageToEdit.id ? imageToEdit.id : null
                        )
                     }
                  >
                     Actualizar Imagen
                  </LoadingButton>
               </Stack>
            </Grid>
         </Grid>
      </form>
   );
};

export default CourseInfoSession;
