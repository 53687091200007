import React, { useState } from "react";
import {
   useTable,
   useSortBy,
   useFilters,
   useGlobalFilter,
   usePagination,
} from "react-table";

import { matchSorter } from "match-sorter";

import FooterPagination from "../FooterPagination";

import IconSort from "../../atoms/icons/IconSort";
import IconSearch from "../../atoms/icons/IconSearch";
import IconResetFilter from "../../atoms/icons/IconResetFilter";
import IconArrowPagination from "../../atoms/icons/IconArrowPagination";

import "./table.scss";

interface Props {
   data: any;
   columns: any;
}

function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
   return matchSorter(rows, filterValue, {
      keys: [(row: any) => row.values[id]],
   });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

const Table: React.FC<Props> = ({ data, columns }) => {
   const [isVisible, setIsVisible] = useState("");
   const filterTypes = React.useMemo(
      () => ({
         // Add a new fuzzyTextFilterFn filter type.
         fuzzyText: fuzzyTextFilterFn,
         text: (rows: any, id: any, filterValue: any) => {
            return rows.filter((row: any) => {
               const rowValue = row.values[id];
               return rowValue !== undefined
                  ? String(rowValue)
                       .toLowerCase()
                       .startsWith(String(filterValue).toLowerCase())
                  : true;
            });
         },
      }),
      []
   );

   // Define a default UI for filtering
   function DefaultColumnFilter({ column }: any) {
      //const count = column.preFilteredRows.length;

      return (
         <section className="container-filter">
            <input
               className="container-filter__input"
               value={column.filterValue || ""}
               onKeyPress={(e) => {
                  if (e.key === "Enter") {
                     setIsVisible("");
                  }
               }}
               onChange={(e) => {
                  column.setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
               }}
               placeholder={`Buscar por ${
                  column.Header.toLowerCase ? column.Header.toLowerCase() : ""
               }`}
            />
            <div
               className="container-filter__button-close"
               onClick={() => setIsVisible("")}
            >
               <IconResetFilter />
            </div>
         </section>
      );
   }

   const defaultColumn = React.useMemo(
      () => ({
         // Let's set up our default Filter UI
         Filter: DefaultColumnFilter,
      }),
      []
   );
   const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
   } = useTable(
      {
         columns,
         data,
         defaultColumn, // Be sure to pass the defaultColumn option
         filterTypes,
         initialState: { pageIndex: 0, pageSize: 20 },
      },
      useFilters, // useFilters!
      useGlobalFilter, // useGlobalFilter!
      useSortBy,
      usePagination
   );

   const fromPage = pageIndex === 0 ? pageIndex + 1 : pageSize * pageIndex + 1;
   const endPage = rows.length % pageSize;
   const totalPage = Math.floor(rows.length / pageSize) + 1;
   const toPage =
      pageIndex + 1 === totalPage
         ? endPage < pageSize
            ? fromPage + endPage - 1
            : fromPage + pageSize - 1
         : fromPage + pageSize - 1;
   const totalRows = rows ? rows.length : 0;
   const paginate = (page: number) => gotoPage(page);
   const columns_len = columns.length;
   const [toggleTopPagination, setToggleTopPagination] = useState(false);

   return (
      <div className={`table ${totalPage > 1 ? "" : "table--padding"}`}>
         <table className="table__container" {...getTableProps()}>
            <thead>
               {headerGroups.map((headerGroup: any, index: number) => (
                  <tr
                     key={index}
                     className="table__head-row"
                     {...headerGroup.getHeaderGroupProps()}
                  >
                     {headerGroup.headers.map((column: any, index: number) => {
                        if (column.hideColumn) {
                           return;
                        }
                        return (
                           <th
                              key={index}
                              className={`table__th ${
                                 column.isSorted
                                    ? "table__th--sorted"
                                    : "table__th--notsorted"
                              }`}
                              style={{
                                 width:
                                    column.id == "action"
                                       ? column.actionWidth
                                       : column.widthColumn
                                       ? column.widthColumn
                                       : "auto",
                              }}
                           >
                              <div
                                 className={`table__content-th ${
                                    column.align === "center"
                                       ? "table__content-th--center"
                                       : column.align === "right"
                                       ? "table__content-th--right"
                                       : "table__content-th--left"
                                 }`}
                              >
                                 {column.headerPagination ? (
                                    <div className="table__pagination-header">
                                       <div
                                          onClick={() => {
                                             previousPage();
                                             setToggleTopPagination(true);
                                          }}
                                          className={`table__icon ${
                                             pageIndex > 0
                                                ? "table__icon--hover-color"
                                                : "table__icon--disabled"
                                          }`}
                                       >
                                          <IconArrowPagination
                                             className="table__icon-left"
                                             fill={`${
                                                pageIndex > 0
                                                   ? "#313131"
                                                   : "#BCBCBC"
                                             }`}
                                          />
                                       </div>
                                       <span className="table__number-page">
                                          {pageIndex + 1}
                                       </span>
                                       <div
                                          onClick={() => {
                                             nextPage();
                                             setToggleTopPagination(true);
                                          }}
                                          className={`table__icon ${
                                             pageIndex === totalPage - 1
                                                ? "table__icon--disabled"
                                                : "table__icon--hover-color"
                                          }`}
                                       >
                                          <IconArrowPagination
                                             className="table__icon-right"
                                             fill={`${
                                                pageIndex === totalPage - 1
                                                   ? "#BCBCBC"
                                                   : "#313131"
                                             }`}
                                          />
                                       </div>
                                    </div>
                                 ) : (
                                    <>{column.render("Header")}</>
                                 )}
                                 {!column.disableFilters ||
                                 !column.disableSortBy ? (
                                    <section className="table__column-actions">
                                       {column.canSort ? (
                                          <div
                                             className="table__button-sort"
                                             {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                             )}
                                          >
                                             {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                   <IconSort
                                                      top="#313131"
                                                      bottom="#BCBCBC"
                                                   />
                                                ) : (
                                                   <IconSort
                                                      top="#BCBCBC"
                                                      bottom="#313131"
                                                   />
                                                )
                                             ) : (
                                                <IconSort
                                                   top="#313131"
                                                   bottom="#313131"
                                                />
                                             )}
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                       {column.canFilter ? (
                                          <div className="table__input-filter">
                                             {column.filterValue ? (
                                                <button
                                                   className="table__button-filter"
                                                   onClick={() =>
                                                      column.setFilter(
                                                         undefined
                                                      )
                                                   }
                                                >
                                                   <IconResetFilter />
                                                </button>
                                             ) : (
                                                <button
                                                   className="table__button-filter"
                                                   onClick={() =>
                                                      setIsVisible(column.id)
                                                   }
                                                >
                                                   <IconSearch />
                                                </button>
                                             )}
                                             {column.id === isVisible ? (
                                                <>{column.render("Filter")}</>
                                             ) : (
                                                ""
                                             )}
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                    </section>
                                 ) : (
                                    ""
                                 )}
                              </div>
                           </th>
                        );
                     })}
                  </tr>
               ))}
            </thead>
            {rows.length > 0 && (
               <tbody className="table__tbody" {...getTableBodyProps()}>
                  {page.map((row: any, index: number) => {
                     prepareRow(row);
                     return (
                        <>
                           <tr
                              key={index}
                              className="table__body-row"
                              {...row.getRowProps()}
                           >
                              {row.cells.map((cell: any, index: number) => {
                                 if (cell.column.hideColumn) {
                                    return;
                                 }
                                 return (
                                    <td
                                       key={index}
                                       className={`table__td ${
                                          cell.column.isSorted
                                             ? "table__td--sorted"
                                             : ""
                                       } ${
                                          cell.column.filterValue
                                             ? "table__td--filtered"
                                             : ""
                                       } ${
                                          cell.column.classColumn
                                             ? `table__td--${cell.column.classColumn}`
                                             : ""
                                       } `}
                                       style={{
                                          width:
                                             cell.column.id == "action"
                                                ? `${cell.column.actionWidth}px !important`
                                                : cell.column.widthColumn
                                                ? cell.column.widthColumn
                                                : "auto",
                                          minWidth: cell.column.minWidthColumn
                                             ? cell.column.minWidthColumn
                                             : cell.column.widthColumn
                                             ? cell.column.widthColumn
                                             : "",
                                          maxWidth:
                                             cell.column.id == "action"
                                                ? `${cell.column.actionWidth}px !important`
                                                : "",
                                       }}
                                       {...cell.getCellProps()}
                                    >
                                       {cell.value !== undefined && (
                                          <>
                                             {cell.column.hasTooltip && (
                                                <span className="table__tooltip">
                                                   {cell.column.tooltipValue
                                                      ? cell.column.tooltipValue
                                                      : cell.value}
                                                </span>
                                             )}
                                          </>
                                       )}
                                       <div
                                          className={`table__content-td ${
                                             cell.column.align === "center"
                                                ? "table__content-td--center"
                                                : cell.column.align === "right"
                                                ? "table__content-td--right"
                                                : "table__content-td--left"
                                          } ${
                                             cell.column.id === "action" &&
                                             "table__content-td--action"
                                          }`}
                                          /* style={{
															maxWidth: "max-content",
															minWidth:
																cell.column.id == "action"
																	? "auto"
																	: "",
														}} */
                                       >
                                          <span
                                             className={
                                                cell.column.id === "action"
                                                   ? "table__value-actions"
                                                   : "table__value"
                                             }
                                          >
                                             <span>{cell.render("Cell")}</span>
                                          </span>
                                       </div>
                                    </td>
                                 );
                              })}
                           </tr>
                        </>
                     );
                  })}
                  {page.length <= 1 && (
                     <tr className="table__body-row">
                        <td
                           colSpan={headerGroups[0].headers.length}
                           className="table__td"
                        >
                           <div className="table__caption">Hola</div>
                        </td>
                     </tr>
                  )}
               </tbody>
            )}
            {totalPage > 1 ? (
               <tfoot className="table__footer">
                  <tr>
                     <td colSpan={columns_len}>
                        <div className="table__footer-pagination">
                           <FooterPagination
                              toggleTopPagination={toggleTopPagination}
                              setToggleTopPagination={setToggleTopPagination}
                              pageIndex={pageIndex}
                              previousPage={previousPage}
                              nextPage={nextPage}
                              fromPage={fromPage}
                              toPage={toPage}
                              totalPage={totalPage}
                              totalRows={totalRows}
                              paginate={paginate}
                           />
                        </div>
                     </td>
                  </tr>
               </tfoot>
            ) : (
               ""
            )}
         </table>
      </div>
   );
};

/* function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row: any) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}
filterGreaterThan.autoRemove = (val: any) => typeof val !== "number"; */

export default Table;
