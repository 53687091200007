// Axios
import axios, { AxiosResponse } from "axios";

// Helpers
import { getAccessCookie } from "../helpers/cookies.helpers";
import { ICourse } from "../interfaces/course.interface";
import { IGroupDetailPag } from "../interfaces/group.interface";

const instanceBaseURL = process.env.REACT_APP_CTC_BACKEND_URL;

//? COURSES
export const getIndividualCourses = async (
   selectOption2?: any,
   selectOption3?: any,
   selectOption4?: any,
   isSearch?: boolean,
   message?: string
) => {
   const response = await axios.get<IGroupDetailPag[]>(
      `${instanceBaseURL}/groups/?no-page${
         selectOption2.name ? `&course__name=${selectOption2.name}` : ""
      }${selectOption3.id < 3 ? `&published_at=${selectOption3.value}` : ""}${
         selectOption4.id < 3
            ? `&course__business_type=${selectOption4.name}`
            : ""
      }${isSearch ? `&search=${message}` : ""}`,
      {
         headers: { Authorization: `Bearer ${getAccessCookie()}` },
      }
   );
   return response;
};

export const getCourses = async () => {
   const response = await axios.get<ICourse[]>(`${instanceBaseURL}/courses/`, {
      headers: { Authorization: `Bearer ${getAccessCookie()}` },
   });
   return response;
};
