// Yup
import * as Yup from "yup";

// Formik
import { Form, Formik } from "formik";

// Interfaces
import {
   Invoice,
   UpdateInvoice,
} from "../../../../../../../interfaces/guardian.interface";

//Redux
import { AppDispatch, useAppDispatch } from "../../../../../../../store/store";
import {
   getGuardianDetails,
   selectStateGuardianDetails,
   updateGuardianInvoice,
} from "../../../../../../../slices/guardianSlice/guardianInvoicesSlice";
import { useSelector } from "react-redux";

// Material UI
import {
   Alert,
   Divider,
   FormControl,
   Grid,
   InputLabel,
   MenuItem,
   Select,
   TextField,
} from "@mui/material";
import { LoadingButton, DateTimePicker, LocalizationProvider } from "@mui/lab";
import UpdateIcon from "@mui/icons-material/Update";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import bgLocale from "date-fns/locale/bg";
import moment from "moment";

interface Props {
   guardianId: number;
   invoiceId: number;
   invoice: Invoice;
   //refetch: () => {};
   onClose: () => void;
}

const UpdateInvoiceSchema = Yup.object().shape({
   is_verified: Yup.boolean().required("Requerido"),
   amount: Yup.number().required("Requerido"),
   source: Yup.string().required("Requerido"),
   invoiced_at: Yup.date().required("Requerido"),
});

const EditInvoice: React.FC<Props> = ({
   guardianId,
   invoiceId,
   invoice,
   onClose,
}) => {
   const dispatch: AppDispatch = useAppDispatch();
   const { isLoadingUpdateInvoice } = useSelector(selectStateGuardianDetails);

   const initialValues: UpdateInvoice = {
      is_verified: invoice.is_verified,
      source: invoice.source,
      amount: invoice.amount,
      currency: invoice.currency,
      payment_gateway: invoice.payment_gateway,
      invoiced_at: invoice.invoiced_at,
      payment_at: invoice.payment_at,
   };

   return (
      <Formik
         initialValues={initialValues}
         validationSchema={UpdateInvoiceSchema}
         onSubmit={async (values) => {
            await dispatch(updateGuardianInvoice({ invoiceId, values }));
            await dispatch(getGuardianDetails(guardianId));
            onClose();
         }}
      >
         {({ errors, touched, values, handleChange, setFieldValue }: any) => (
            <Form>
               <Grid container rowGap={2}>
                  <Grid item container columnSpacing={2} xs={12}>
                     <Grid item xs={12}>
                        <LocalizationProvider
                           dateAdapter={AdapterDateFns}
                           adapterLocale={bgLocale}
                        >
                           <DateTimePicker
                              label="Fecha Facturación"
                              inputFormat="dd/MM/yyyy (p)"
                              value={values.invoiced_at}
                              onChange={(value) =>
                                 setFieldValue("invoiced_at", value)
                              }
                              renderInput={(params) => (
                                 <TextField fullWidth {...params} />
                              )}
                           />
                           {errors.invoiced_at && touched.invoiced_at && (
                              <Alert severity="error">
                                 {errors.invoiced_at}
                              </Alert>
                           )}
                        </LocalizationProvider>
                     </Grid>
                  </Grid>
                  <Grid item container columnSpacing={2} xs={12}>
                     <Grid item xs={6}>
                        <LocalizationProvider
                           dateAdapter={AdapterDateFns}
                           adapterLocale={bgLocale}
                        >
                           <DateTimePicker
                              label="Fecha Pago"
                              inputFormat="dd/MM/yyyy (p)"
                              value={values.payment_at}
                              onChange={(value) =>
                                 setFieldValue("payment_at", value)
                              }
                              renderInput={(params) => (
                                 <TextField fullWidth {...params} />
                              )}
                           />
                        </LocalizationProvider>
                     </Grid>
                     <Grid item xs={6}>
                        <FormControl fullWidth>
                           <InputLabel id="source">Fuente de pago</InputLabel>
                           <Select
                              autoFocus
                              id="source"
                              labelId="source"
                              value={values.source}
                              label="Fuente de pago"
                              placeholder="Fuente de pago"
                              onChange={(e) =>
                                 setFieldValue("source", e.target.value)
                              }
                           >
                              <MenuItem value={"undefined"}>Undefined</MenuItem>
                              <MenuItem value={"payment_web"}>Web</MenuItem>
                              <MenuItem value={"payment_inscription"}>
                                 Inscripción Interna
                              </MenuItem>
                              <MenuItem value={"backoffice_form"}>BO</MenuItem>
                              <MenuItem value={"batch_load"}>
                                 Carga Lote
                              </MenuItem>
                           </Select>
                        </FormControl>
                        {errors.source && touched.source && (
                           <Alert severity="error">{errors.source}</Alert>
                        )}
                     </Grid>
                  </Grid>
                  {/* Basic Data */}
                  <Grid item container columnSpacing={2} xs={12}>
                     {/* Is_verified */}
                     <Grid item xs={6}>
                        <FormControl fullWidth>
                           <InputLabel id="is_verified">
                              Pago verificado
                           </InputLabel>
                           <Select
                              autoFocus
                              id="is_verified"
                              labelId="is_verified"
                              value={values.is_verified ? 0 : 1}
                              label="Pago verificado"
                              placeholder="Pago verificado"
                              onChange={(e) =>
                                 setFieldValue(
                                    "is_verified",
                                    e.target.value === 0
                                 )
                              }
                           >
                              <MenuItem value={0}>Verificado</MenuItem>
                              <MenuItem value={1}>Sin confirmar</MenuItem>
                           </Select>
                        </FormControl>
                        {errors.is_verified && touched.is_verified && (
                           <Alert severity="error">{errors.is_verified}</Alert>
                        )}
                     </Grid>
                     <Grid item xs={6}>
                        <FormControl fullWidth>
                           <InputLabel id="payment_gateway">
                              Método de pago
                           </InputLabel>
                           <Select
                              autoFocus
                              id="payment_gateway"
                              labelId="Método de pago"
                              value={values.payment_gateway}
                              label="Método de pago"
                              placeholder="Método de pago"
                              onChange={(e) =>
                                 setFieldValue(
                                    "payment_gateway",
                                    e.target.value
                                 )
                              }
                           >
                              <MenuItem value={"dlocal"}>dlocal</MenuItem>
                              <MenuItem value={"stripe"}>stripe</MenuItem>
                              <MenuItem value={"niubiz"}>niubiz</MenuItem>
                           </Select>
                        </FormControl>
                        {errors.payment_gateway && touched.payment_gateway && (
                           <Alert severity="error">
                              {errors.payment_gateway}
                           </Alert>
                        )}
                     </Grid>
                  </Grid>

                  <Grid item container columnSpacing={2} xs={12}>
                     <Grid item xs={6}>
                        <FormControl fullWidth>
                           <TextField
                              fullWidth
                              id="amount"
                              label="Monto"
                              variant="outlined"
                              type="number"
                              value={values.amount}
                              onChange={handleChange}
                           />
                        </FormControl>
                        {errors.amount && touched.amount && (
                           <Alert severity="error">{errors.amount}</Alert>
                        )}
                     </Grid>
                     {/* Source */}
                     <Grid item xs={6}>
                        <FormControl fullWidth>
                           <InputLabel id="currency">Divisa</InputLabel>
                           <Select
                              autoFocus
                              id="currency"
                              labelId="Divisa"
                              value={values.currency}
                              label="Divisa"
                              placeholder="Divisa"
                              onChange={(e) =>
                                 setFieldValue("currency", e.target.value)
                              }
                           >
                              <MenuItem value={"PEN"}>PEN</MenuItem>
                              <MenuItem value={"USD"}>USD</MenuItem>
                              <MenuItem value={"MXN"}>MXN</MenuItem>
                              <MenuItem value={"COP"}>COP</MenuItem>
                           </Select>
                        </FormControl>
                        {errors.currency && touched.currency && (
                           <Alert severity="error">{errors.currency}</Alert>
                        )}
                     </Grid>
                  </Grid>

                  {/* Edit Invoice */}
                  <Grid item container rowSpacing={2} xs={12}>
                     <Grid item xs={12}>
                        <Divider />
                     </Grid>

                     <Grid item xs={12}>
                        <LoadingButton
                           fullWidth
                           loading={
                              isLoadingUpdateInvoice /* updateInvoice.isLoading */
                           }
                           variant="contained"
                           type="submit"
                           startIcon={<UpdateIcon />}
                        >
                           Editar
                        </LoadingButton>
                     </Grid>
                  </Grid>
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default EditInvoice;
