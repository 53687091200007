import React, { useEffect, useState, useRef, ChangeEvent } from "react";

// Components
import Typography from "../../atoms/Typography";
import Switch from "../../molecules/Switch";
import IconArrowCollapse from "../../atoms/icons/IconArrowCollapse";
import IconAdd from "../../atoms/icons/IconAdd";
import IconDelete from "../../atoms/icons/IconDelete";

//helpers
import { renderDay } from "../../../helpers/day.helpers";
import {
   defHourRange,
   hourFormatter,
   hourFormatterSecond,
   hourFromRangeBetween,
   hourToRangeBetween,
   inputHourRange,
   valHourTo,
   valOtherRangesTime,
} from "../../../helpers/hour.helpers";

import colors from "../../atoms/Colors";

import "./scheduleAvailability.scss";

interface Props {
   isActive?: any;
   setIsActive?: any;
   children: string;
   setSchedule?: any;
   setIsError?: any;
   schedule?: any;
   defaultValues?: Array<any>;
}

const ScheduleAvailability: React.FC<Props> = ({
   isActive,
   setIsActive,
   children,
   setSchedule,
   setIsError,
   schedule,
   defaultValues,
}) => {
   /* const ref = useRef<HTMLInputElement>(null); */
   const [isOpen, setIsOpen] = useState(false);
   const [addNewHour, setAddNewHour] = useState(0);
   const [deleteHour1, setDeleteHour1] = useState(false);
   const [deleteHour2, setDeleteHour2] = useState(false);

   const [hourOf, setHourOf] = useState("00:00");
   const [hourTo, setHourTo] = useState("00:00");
   const [errorOf, setErrorOf] = useState(false);
   const [errorTo, setErrorTo] = useState(false);

   const [hourOf1, setHourOf1] = useState("00:00");
   const [hourTo1, setHourTo1] = useState("00:00");
   const [errorOf1, setErrorOf1] = useState(false);
   const [errorTo1, setErrorTo1] = useState(false);

   const [hourOf2, setHourOf2] = useState("00:00");
   const [hourTo2, setHourTo2] = useState("00:00");
   const [errorOf2, setErrorOf2] = useState(false);
   const [errorTo2, setErrorTo2] = useState(false);

   useEffect(() => {
      if (isActive) {
         handleSchedule();
      }
   }, [
      isActive,
      setIsActive,
      hourOf,
      setHourOf,
      hourTo,
      setHourTo,
      hourOf1,
      setHourOf1,
      hourTo1,
      setHourTo1,
      hourOf2,
      setHourOf2,
      hourTo2,
      setHourTo2,
      addNewHour,
      setAddNewHour,
   ]);

   useEffect(() => {
      if (defaultValues) {
         if (
            defaultValues[0] &&
            defaultValues[0].start_time &&
            defaultValues[0].end_time
         ) {
            setIsActive(true);
            /* setIsOpen(true); */
            setHourOf(defaultValues[0]?.start_time);
            setHourTo(defaultValues[0]?.end_time);
            if (
               defaultValues[1] &&
               defaultValues[1].start_time &&
               defaultValues[1].end_time
            ) {
               setHourOf1(defaultValues[1].start_time);
               setHourTo1(defaultValues[1].end_time);
               if (
                  defaultValues[2] &&
                  defaultValues[2].start_time &&
                  defaultValues[2].end_time
               ) {
                  setHourOf2(defaultValues[2].start_time);
                  setHourTo2(defaultValues[2].end_time);
               }
            }
         }
      }
   }, []);

   useEffect(() => {
      if (isActive) {
         if (hourOf === "00:00" && hourTo === "00:00") {
            setErrorOf(true);
            setErrorTo(true);
            setIsError(true);
         } else if (hourOf === "00:00") {
            setErrorOf(true);
            setIsError(true);
         } else if (hourTo === "00:00") {
            setErrorTo(true);
         } else if (hourOf1 === "00:00" && hourTo1 === "00:00") {
            setErrorOf1(true);
            setErrorTo1(true);
         } else if (hourOf1 === "00:00") {
            setErrorOf1(true);
         } else if (hourTo1 === "00:00") {
            setErrorTo1(true);
         } else if (hourOf2 === "00:00" && hourTo2 === "00:00") {
            setErrorOf2(true);
            setErrorTo2(true);
         } else if (hourOf2 === "00:00") {
            setErrorOf2(true);
         } else if (hourTo2 === "00:00") {
            setErrorTo2(true);
         }
      } else {
         setIsError(false);
      }
   }, [isActive, errorOf, errorTo, errorOf1, errorTo1, errorOf2, errorTo2]);

   const handleAddNewSchedule = async () => {
      switch (addNewHour) {
         case 0:
            if (!errorOf && !errorTo) {
               setAddNewHour(addNewHour + 1);
            }
            return hourOf;
         case 1:
            if (!errorOf && !errorTo) {
               if (!errorOf1 && !errorTo1) {
                  setAddNewHour(addNewHour + 1);
               }
            }
            return hourOf1;
         case 2:
            if (!errorOf && !errorTo) {
               if (!errorOf1 && !errorTo1) {
                  if (!errorOf2 && !errorTo2) {
                     setAddNewHour(2);
                  }
               }
            }
            return hourOf2;
      }
   };

   const handleSchedule = async () => {
      let modelData: any = [];
      if (isActive) {
         switch (addNewHour) {
            case 0:
               if (hourOf !== "00:00" && hourTo !== "00:00") {
                  modelData.push({
                     day: renderDay(children),
                     available: isActive,
                     start_time: hourFormatterSecond(hourOf),
                     end_time: hourFormatterSecond(hourTo),
                  });
               }
               setSchedule(modelData);
               return hourOf;
            case 1:
               if (hourOf1 !== "00:00" && hourTo1 !== "00:00") {
                  modelData.push(
                     {
                        day: renderDay(children),
                        available: isActive,
                        start_time: hourFormatterSecond(hourOf),
                        end_time: hourFormatterSecond(hourTo),
                     },
                     {
                        day: renderDay(children),
                        available: isActive,
                        start_time: hourFormatterSecond(hourOf1),
                        end_time: hourFormatterSecond(hourTo1),
                     }
                  );
               } else {
                  modelData.push({
                     day: renderDay(children),
                     available: isActive,
                     start_time: hourFormatterSecond(hourOf),
                     end_time: hourFormatterSecond(hourTo),
                  });
               }
               setSchedule(modelData);
               return hourOf1;
            case 2:
               if (hourOf2 !== "00:00" && hourTo2 !== "00:00") {
                  modelData.push(
                     {
                        day: renderDay(children),
                        available: isActive,
                        start_time: hourFormatterSecond(hourOf),
                        end_time: hourFormatterSecond(hourTo),
                     },
                     {
                        day: renderDay(children),
                        available: isActive,
                        start_time: hourFormatterSecond(hourOf1),
                        end_time: hourFormatterSecond(hourTo1),
                     },
                     {
                        day: renderDay(children),
                        available: isActive,
                        start_time: hourFormatterSecond(hourOf2),
                        end_time: hourFormatterSecond(hourTo2),
                     }
                  );
               } else {
                  modelData.push(
                     {
                        day: renderDay(children),
                        available: isActive,
                        start_time: hourFormatterSecond(hourOf),
                        end_time: hourFormatterSecond(hourTo),
                     },
                     {
                        day: renderDay(children),
                        available: isActive,
                        start_time: hourFormatterSecond(hourOf1),
                        end_time: hourFormatterSecond(hourTo1),
                     }
                  );
               }
               setSchedule(modelData);
               return hourOf2;
         }
      }
   };

   const setHidden = async (section: any) => {
      switch (section) {
         case 1:
            setDeleteHour1(true);
            setAddNewHour(addNewHour - 1);
            setHourOf1("00:00");
            setHourTo1("00:00");
            return deleteHour1;
         case 2:
            setDeleteHour2(true);
            setAddNewHour(addNewHour - 1);
            setHourOf2("00:00");
            setHourTo2("00:00");
            return deleteHour2;
      }
   };

   const handleBlurOf = (event: ChangeEvent<HTMLInputElement>) => {
      if (defHourRange(event.target.value)) {
         setErrorOf(true);
      } else {
         setErrorOf(false);
      }
   };

   const handleBlurTo = (event: ChangeEvent<HTMLInputElement>) => {
      if (defHourRange(event.target.value)) {
         setErrorTo(true);
      } else if (valHourTo(event.target.value, hourOf)) {
         setErrorTo(true);
      } else {
         setErrorTo(false);
         setHourTo(hourTo);
      }
   };

   const handleBlurOf1 = (event: ChangeEvent<HTMLInputElement>) => {
      if (defHourRange(event.target.value)) {
         setErrorOf1(true);
      } else if (hourFromRangeBetween(event.target.value, hourOf, hourTo)) {
         setErrorOf1(true);
      } else {
         setErrorOf1(false);
      }
   };

   const handleBlurTo1 = (event: ChangeEvent<HTMLInputElement>) => {
      if (defHourRange(event.target.value)) {
         setErrorTo1(true);
      } else if (hourToRangeBetween(event.target.value, hourOf, hourTo)) {
         setErrorTo1(true);
      } else if (valHourTo(event.target.value, hourOf1)) {
         setErrorTo1(true);
      } else if (
         valOtherRangesTime(hourOf, hourTo, hourOf1, event.target.value)
      ) {
         setErrorTo1(true);
      } else {
         setErrorTo1(false);
      }
   };

   const handleBlurOf2 = (event: ChangeEvent<HTMLInputElement>) => {
      if (defHourRange(event.target.value)) {
         setErrorOf2(true);
      } else if (hourFromRangeBetween(event.target.value, hourOf1, hourTo1)) {
         setErrorOf2(true);
      } else if (hourFromRangeBetween(event.target.value, hourOf, hourTo)) {
         setErrorOf2(true);
      } else {
         setErrorOf2(false);
      }
   };

   const handleBlurTo2 = (event: ChangeEvent<HTMLInputElement>) => {
      if (defHourRange(event.target.value)) {
         setErrorTo2(true);
      } else if (hourToRangeBetween(event.target.value, hourOf, hourTo)) {
         setErrorTo2(true);
      } else if (hourToRangeBetween(event.target.value, hourOf1, hourTo1)) {
         setErrorTo2(true);
      } else if (valHourTo(event.target.value, hourOf2)) {
         setErrorTo2(true);
      } else if (
         valOtherRangesTime(hourOf, hourTo, hourOf2, event.target.value)
      ) {
         setErrorTo2(true);
      } else if (
         valOtherRangesTime(hourOf1, hourTo1, hourOf2, event.target.value)
      ) {
         setErrorTo2(true);
      } else {
         setErrorTo2(false);
      }
   };

   const handleChangeOf = (event: ChangeEvent<HTMLInputElement>) => {
      setHourOf(event.target.value);
   };
   const handleChangeOf1 = (event: ChangeEvent<HTMLInputElement>) => {
      setHourOf1(event.target.value);
   };
   const handleChangeOf2 = (event: ChangeEvent<HTMLInputElement>) => {
      setHourOf2(event.target.value);
   };

   const handleChangeTo = (event: ChangeEvent<HTMLInputElement>) => {
      setHourTo(event.target.value);
   };
   const handleChangeTo1 = (event: ChangeEvent<HTMLInputElement>) => {
      setHourTo1(event.target.value);
   };
   const handleChangeTo2 = (event: ChangeEvent<HTMLInputElement>) => {
      setHourTo2(event.target.value);
   };

   const handleChangeSwitch = () => {
      setIsActive(!isActive);
   };

   return (
      <div
         className={`
            schedule-availability 
            ${
               isOpen
                  ? "schedule-availability__open"
                  : "schedule-availability__close"
            }
            ${
               isActive
                  ? "schedule-availability__isActive"
                  : "schedule-availability__isInactive"
            }
        `}
      >
         <section className="schedule-availability__content">
            <div className="schedule-availability__content--day">
               <Typography
                  type="paragraph2"
                  variation="mobile"
                  className="schedule-availability__content--day"
               >
                  {children}
               </Typography>
            </div>
            <div
               className={`schedule-availability__content--icon schedule-availability__divider`}
               onClick={
                  isActive ? () => setIsOpen(!isOpen) : () => setIsOpen(false)
               }
            >
               <IconArrowCollapse
                  fill={isActive ? colors.black : colors.gris05}
                  className={`
                        ${
                           isActive
                              ? "schedule-availability__content--icon-collapse"
                              : "schedule-availability__content--icon-collapse--disabled"
                        }
                        schedule-availability__content--icon-collapse--${
                           isOpen ? "open" : "close"
                        }`}
               />
            </div>
            <div
               className="schedule-availability__content--switch"
               style={{ border: "1px solid green" }}
            >
               <Switch
                  isActive={isActive}
                  setIsActive={setIsActive}
                  textLeft={isActive ? "Activo" : "Inactivo"}
                  name={children}
                  onClick={() => handleChangeSwitch()}
               ></Switch>
            </div>
         </section>
         <section
            className={`schedule-availability__sub-content schedule-availability__sub-content--${
               isActive && isOpen ? "open" : "hidden"
            }`}
         >
            {isOpen ? (
               <div className="schedule-availability__sub-content--container">
                  <section className="schedule-availability__sub-content--item">
                     <div className="schedule-availability__sub-content--times">
                        <Typography type="paragraph2" variation="mobile">
                           De:
                        </Typography>
                        <input
                           className={`schedule-availability__sub-content--input schedule-availability__input-alert${
                              errorOf ? "--error" : "--success"
                           }`}
                           type="time"
                           name="time-de"
                           onChange={handleChangeOf}
                           onBlur={handleBlurOf}
                           list="listalimitestiempo1"
                           value={hourOf}
                           defaultValue={hourOf}
                           required
                        />
                        <datalist id="listalimitestiempo1">
                           {inputHourRange.map((hour: string) => (
                              <option value={hour} key={hour}></option>
                           ))}
                        </datalist>
                        <Typography type="paragraph2" variation="mobile">
                           a
                        </Typography>
                        <input
                           className={`schedule-availability__sub-content--input schedule-availability__input-alert${
                              errorTo ? "--error" : "--success"
                           }`}
                           type="time"
                           name="time-de"
                           onChange={handleChangeTo}
                           onBlur={handleBlurTo}
                           list="listalimitestiempo2"
                           defaultValue={hourTo}
                           value={hourTo}
                           pattern="[9-20]{2}:[0-9]{2}"
                           required
                        />
                        <datalist
                           className="schedule-availability__list-schedule"
                           id="listalimitestiempo2"
                        >
                           {inputHourRange.map((hour: string) => (
                              <option value={hour} key={hour}></option>
                           ))}
                        </datalist>
                     </div>
                     <div
                        className="schedule-availability__icon-add"
                        onClick={() => handleAddNewSchedule()}
                     >
                        <IconAdd />
                     </div>
                  </section>
                  {addNewHour > 0 ? (
                     <section className="schedule-availability__sub-content--item">
                        <div className="schedule-availability__sub-content--times">
                           <Typography type="paragraph2" variation="mobile">
                              De:
                           </Typography>
                           <input
                              className={`schedule-availability__sub-content--input schedule-availability__input-alert${
                                 errorOf1 ? "--error" : "--success"
                              }`}
                              type="time"
                              name="time-de"
                              onChange={handleChangeOf1}
                              onBlur={handleBlurOf1}
                              list="listalimitestiempo3"
                              defaultValue={hourFormatterSecond(hourOf1)}
                              value={hourFormatterSecond(hourOf1)}
                              required
                           />
                           <datalist id="listalimitestiempo3">
                              {inputHourRange.map((hour: string) => (
                                 <option value={hour} key={hour}></option>
                              ))}
                           </datalist>
                           <Typography type="paragraph2" variation="mobile">
                              a
                           </Typography>
                           <input
                              className={`schedule-availability__sub-content--input schedule-availability__input-alert${
                                 errorTo1 ? "--error" : "--success"
                              }`}
                              type="time"
                              name="time-de"
                              onChange={handleChangeTo1}
                              onBlur={handleBlurTo1}
                              defaultValue={hourTo1}
                              value={hourTo1}
                              list="listalimitestiempo4"
                              required
                           />
                           <datalist id="listalimitestiempo4">
                              {inputHourRange.map((hour: string) => (
                                 <option value={hour} key={hour}></option>
                              ))}
                           </datalist>
                        </div>
                        <div
                           className={`
                                        schedule-availability__sub-content--iconDelete 
                                        schedule-availability__sub-content--${
                                           deleteHour1 ? "hidden" : ""
                                        }`}
                           onClick={() => setHidden(1)}
                        >
                           <IconDelete />
                        </div>
                     </section>
                  ) : (
                     <div></div>
                  )}
                  {addNewHour > 1 ? (
                     <section className="schedule-availability__sub-content--item">
                        <div className="schedule-availability__sub-content--times">
                           <Typography type="paragraph2" variation="mobile">
                              De:
                           </Typography>
                           <input
                              className={`schedule-availability__sub-content--input schedule-availability__input-alert${
                                 errorOf2 ? "--error" : "--success"
                              }`}
                              type="time"
                              name="time-de"
                              onChange={handleChangeOf2}
                              onBlur={handleBlurOf2}
                              defaultValue={hourOf2}
                              value={hourOf2}
                              list="listalimitestiempo5"
                              required
                           />
                           <datalist id="listalimitestiempo5">
                              {inputHourRange.map((hour: string) => (
                                 <option value={hour} key={hour}></option>
                              ))}
                           </datalist>
                           <Typography type="paragraph2" variation="mobile">
                              a
                           </Typography>
                           <input
                              className={`schedule-availability__sub-content--input schedule-availability__input-alert${
                                 errorTo2 ? "--error" : "--success"
                              }`}
                              type="time"
                              name="time-de"
                              onChange={handleChangeTo2}
                              onBlur={handleBlurTo2}
                              defaultValue={hourTo2}
                              value={hourTo2}
                              list="listalimitestiempo6"
                              required
                           />
                           <datalist id="listalimitestiempo6">
                              {inputHourRange.map((hour: string) => (
                                 <option value={hour} key={hour}></option>
                              ))}
                           </datalist>
                        </div>
                        <div
                           className={`
                                        schedule-availability__sub-content--iconDelete 
                                        schedule-availability__sub-content--${
                                           deleteHour2 ? "hidden" : ""
                                        }`}
                           onClick={() => setHidden(2)}
                        >
                           <IconDelete />
                        </div>
                     </section>
                  ) : (
                     <div></div>
                  )}
               </div>
            ) : (
               <div></div>
            )}
         </section>
      </div>
   );
};

export default ScheduleAvailability;
