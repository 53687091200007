// React
import { useEffect, useMemo, useState } from "react";

// Moment
import moment from "moment";
import "moment/locale/es";

// Hot Toast
import { toast } from "react-hot-toast";

// Redux
import {
   getAllGroups,
   selectStateGroups,
} from "../../../slices/groupSlice/groupsSlice";
import {
   getAllIndividualCourses,
   selectStateIndividualCourses,
} from "../../../slices/courseSlice/individualCoursesSlice";
import { useSelector } from "react-redux";
import { AppDispatch, useAppDispatch } from "../../../store/store";

// Components
import ScreenLoader from "../../../components/ScreenLoader/ScreenLoader";
import Table from "../../../components/molecules/Table";
import Link from "../../../components/molecules/Link";
import FloatingMenu from "../../../components/molecules/FloatingMenu";
import ItemFloatingMenu from "../../../components/molecules/ItemFloatingMenu";

import IconOn from "../../atoms/icons/IconOn";
import IconOff from "../../atoms/icons/IconOff";
import IconPage from "../../atoms/icons/IconPage";
import Modal from "../../../components/Modal/Modal";

import EditGroup from "../../../views/Groups/ModalEditGroup/EditGroup/EditGroup";
import DuplicateGroup from "../../../views/Groups/ModalDuplicateGroup/DuplicateGroup/DuplicateGroup";
import CreateGroup from "../../../views/Groups/ModalCreateGroup/CreateGroup/CreateGroup";

import "./tableGroups.scss";

interface Props {
   message: string;
   isSearch: boolean;
   selectOption1?: any;
   selectOption2?: any;
   selectOption3?: any;
   selectOption4?: any;
   openModalCreate?: any;
   setOpenModalCreate?: any;
}

const TableGroups: React.FC<Props> = ({
   message,
   isSearch,
   selectOption1,
   selectOption2,
   selectOption3,
   selectOption4,
   openModalCreate,
   setOpenModalCreate,
}) => {
   const dispatch: AppDispatch = useAppDispatch();
   const infoGroups = useSelector(selectStateGroups);
   const infoIndividualCourses = useSelector(selectStateIndividualCourses);
   const [openModalEdit, setOpenModalEdit] = useState(false);
   const [openModalDuplicate, setOpenModalDuplicate] = useState(false);
   const [idGroup, setIdGroup] = useState<number | null>(null);

   useEffect(() => {
      if (
         selectOption1 ||
         selectOption2 ||
         selectOption3 ||
         selectOption4 ||
         isSearch ||
         message
      ) {
         if (selectOption1.id === 0) {
            dispatch(
               getAllIndividualCourses({
                  selectOption2,
                  selectOption3,
                  selectOption4,
                  isSearch,
                  message,
               })
            );
         } else {
            dispatch(
               getAllGroups({
                  selectOption1,
                  selectOption2,
                  selectOption3,
                  selectOption4,
                  isSearch,
                  message,
               })
            );
         }
      }
   }, [selectOption1, selectOption2, selectOption3, selectOption4, isSearch]);

   const columns = useMemo(
      () => [
         {
            Header: "ID",
            accessor: "id",
            widthColumn: 57,
            disableSortBy: true,
            disableFilters: true,
         },
         {
            Header: "Grupo",
            accessor: "name",
            classColumn: "name-group",
            hasTooltip: true,
            widthColumn: 258,
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ cell }: any) => <span>{cell.value}</span>,
         },
         {
            Header: "Programa",
            classColumn: "name-program",
            accessor: "learning_path",
            hasTooltip: true,
            widthColumn: 220,
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ cell }: any) => <span>{cell.value}</span>,
         },
         {
            Header: "Curso",
            /* classColumn: "name-course", */
            hasTooltip: true,
            accessor: "course",
            widthColumn: 210,
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ cell }: any) => <span>{cell.value}</span>,
         },
         {
            Header: "Estado",
            accessor: "published_at",
            align: "center",
            minWidthColumn: 58,
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ cell }: any) => (
               <span>{cell.value ? <IconOn /> : <IconOff />}</span>
            ),
         },
         {
            Header: "Inicio",
            accessor: "start_date",
            minWidthColumn: 78,
            align: "center",
            disableFilters: true,
            Cell: ({ cell }: any) => (
               <>{cell.value ? moment(cell.value).format("DD-MM-YY") : ""}</>
            ),
         },
         {
            Header: "Estudiantes",
            accessor: "number_of_students",
            minWidthColumn: 107,
            align: "center",
            disableFilters: true,
            Cell: ({ cell }: any) => <span>{cell.value}</span>,
         },
         {
            Header: "",
            accessor: "action",
            headerPagination: true,
            actionWidth: 167,
            disableSortBy: true,
            disableFilters: true,
            hasTooltip: true,
            tooltipValue: "copiar url",
            Cell: (props: any) => (
               <>
                  <Link
                     className="table-group__link table-group__link--hover-lineal"
                     to={`/dashboard/groups/${props.row.values.id}/rooms`}
                  >
                     Ver grupo
                  </Link>
                  <div
                     className="table-group__container-tooltip"
                     onClick={() => {
                        if (props.row.values.id) {
                           toast.success("URL de Enrolment copiado...");
                           navigator.clipboard.writeText(
                              `https://www.crackthecode.la/enrollment-page/${props.row.values.id}`
                           );
                        } else toast.error("No existe URL del Enrolment");
                     }}
                  >
                     <IconPage
                        className={`table-group__button table-group__button--enabled`}
                     />
                     <section className="table-group__tooltip">
                        {props.column.tooltipValue}
                     </section>
                  </div>
                  <FloatingMenu
                     items={[
                        <ItemFloatingMenu
                           onClick={() => {
                              setOpenModalEdit(true);
                              setIdGroup(props.row.values.id);
                           }}
                        >
                           Editar
                        </ItemFloatingMenu>,
                        <ItemFloatingMenu
                           onClick={() => {
                              if (props.row.values.id) {
                                 toast.success("URL de Enrolment copiado...");
                                 navigator.clipboard.writeText(
                                    `https://www.crackthecode.la/enrollment-page/${props.row.values.id}`
                                 );
                              } else toast.error("No existe URL del Enrolment");
                           }}
                        >
                           Copiar URL
                        </ItemFloatingMenu>,
                        <ItemFloatingMenu
                           onClick={() => {
                              if (props.row.values.id) {
                                 toast.success(
                                    "URL de Enrolment Learning Path copiado..."
                                 );
                                 navigator.clipboard.writeText(
                                    `https://www.crackthecode.la/enrollment-page/${props.row.values.id}?ruta_completa=true`
                                 );
                              } else
                                 toast.error(
                                    "No existe URL del Enrolment Learning Path"
                                 );
                           }}
                        >
                           Copiar URL de Ruta
                        </ItemFloatingMenu>,
                        <ItemFloatingMenu
                           onClick={() => {
                              setOpenModalDuplicate(true);
                              setIdGroup(props.row.values.id);
                           }}
                        >
                           Duplicar
                        </ItemFloatingMenu>,
                     ]}
                  />
               </>
            ),
         },
      ],
      []
   );

   if (
      selectOption1.id === 0
         ? infoIndividualCourses.isLoading
         : infoGroups.isLoading
   )
      return <ScreenLoader />;

   return (
      <>
         <Table
            data={
               selectOption1.id === 0
                  ? infoIndividualCourses.courses
                  : infoGroups.groups
            }
            columns={columns}
         />
         <Modal
            title="Crear Grupo"
            fullWidth
            maxWidth="md"
            openModal={openModalCreate}
            handleCloseModal={() => setOpenModalCreate(false)}
         >
            <CreateGroup onClose={() => setOpenModalCreate(false)} />
         </Modal>
         {idGroup ? (
            <>
               <Modal
                  id={idGroup}
                  title="Editar Grupo"
                  fullWidth
                  maxWidth="md"
                  openModal={openModalEdit}
                  handleCloseModal={() => setOpenModalEdit(false)}
               >
                  <EditGroup
                     groupId={idGroup}
                     onClose={() => setOpenModalEdit(false)}
                  />
               </Modal>
               <Modal
                  id={idGroup}
                  title="Duplicar Grupo"
                  fullWidth
                  maxWidth="md"
                  openModal={openModalDuplicate}
                  handleCloseModal={() => setOpenModalDuplicate(false)}
               >
                  <DuplicateGroup
                     groupId={idGroup}
                     onClose={() => setOpenModalDuplicate(false)}
                  />
               </Modal>
            </>
         ) : (
            ""
         )}
      </>
   );
};

export default TableGroups;
