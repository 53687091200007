//Redux
import { AppDispatch, useAppDispatch } from "../../../../../../../../store/store";
import { cancelEnrollStudent } from "../../../../../../../../slices/enrolmentSlice/enrolmentsSlice";
import { getGuardianDetails } from "../../../../../../../../slices/guardianSlice/guardianInvoicesSlice";

// Material UI
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmDialog } from "../../../../../../../../components/ConfirmDialog/ConfirmDialog";

interface Props {
   enrollmentId: number;
   guardianId: number;
   //refetch: () => {};
}

const CancelEnrollment: React.FC<Props> = ({ enrollmentId, guardianId }) => {
   const dispatch: AppDispatch = useAppDispatch();

   const handleCancelEnrollment = async () => {
      try {
         await dispatch(cancelEnrollStudent(enrollmentId));
      } catch (error) {
         console.log(error);
      } finally {
         await dispatch(getGuardianDetails(guardianId));
      }
   };

   return (
      <Tooltip title="Cancelar" arrow placement="left">
         <IconButton
            aria-label="Cancel"
            color="error"
            size="small"
            onClick={(e) => {
               e.stopPropagation();
               confirmDialog(
                  `¿Realmente quieres cancelar la inscripción con ID:${enrollmentId}?`,
                  () => handleCancelEnrollment()
               );
            }}
         >
            <DeleteIcon />
         </IconButton>
      </Tooltip>
   );
};

export default CancelEnrollment;
