// React Query
import { useQuery } from "react-query";

// API
import { GET } from "../../../../services/api/api";

// Moment
import moment from "moment";

// Interfaces
import { ISlider } from "../../../../interfaces/slider.interface";

// Material UI
import Grid from "@mui/material/Grid";
import {
  CardMedia,
  Chip,
  Divider,
  Switch,
  TextField,
  Stack,
  LinearProgress,
  Alert,
} from "@mui/material";
import DateAdapter from "@mui/lab/AdapterDateFns";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";

interface Props {
  sliderId: number;
}

const DetailsSlider: React.FC<Props> = ({ sliderId }) => {
  const {
    data: slider,
    error: errorSlider,
    isError: isErrorSlider,
    isLoading: isLoadingSlider,
  } = useQuery(
    ["slider", sliderId.toString()],
    () => GET<ISlider>(`/sliders/${sliderId}/`),
    {
      retry: false,
    }
  );

  if (isLoadingSlider || !slider) return <LinearProgress />;
  if (isErrorSlider) return <div>Error! {JSON.stringify(errorSlider)}</div>;

  return (
    <Grid item container rowGap={2} xs={12}>
      {/* Details */}
      <Grid item container rowGap={2} xs={12}>
        {/* Header */}
        <Grid item container columnSpacing={2} rowGap={2} xs={12}>
          {/* Title */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="title"
              type="text"
              label="Título"
              variant="standard"
              value={slider.title}
              disabled={true}
            />
          </Grid>

          {/* Subtitle */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="subtitle"
              type="text"
              label="Subtítulo"
              variant="standard"
              value={slider.subtitle}
              disabled={true}
            />
          </Grid>

          {/* Page */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="page"
              type="text"
              disabled
              label="Pagina"
              variant="standard"
              value={slider.page}
            />
          </Grid>

          {/* Order_in_page */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="order_in_page"
              type="text"
              disabled
              label="Orden de slider"
              variant="standard"
              value={slider.order_in_page}
            />
          </Grid>

          {/* published_at | archived_at */}
          <Grid item container columnSpacing={2} xs={12}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              {/* published_at */}
              <Grid item xs={6}>
                <DateTimePicker
                  label="Publicado"
                  inputFormat="yyyy-MM-dd"
                  value={slider.published_at && moment(slider.published_at).toDate()}
                  disableMaskedInput
                  ampm={false}
                  onChange={(value: any) => console.log(value)}
                  disabled
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>

              {/* archived_at */}
              <Grid item xs={6}>
                <DateTimePicker
                  label="Archivado"
                  inputFormat="yyyy-MM-dd"
                  value={slider.archived_at && moment(slider.archived_at).toDate()}
                  disableMaskedInput
                  minDate={
                    slider.published_at && moment(slider.published_at).toDate()
                  }
                  ampm={false}
                  onChange={(value: any) => console.log(value)}
                  disabled
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            {slider.is_published ? (
              <Alert variant="standard" severity="success">
                El grupo esta publicado
              </Alert>
            ) : (
              <Alert variant="standard" severity="warning">
                El grupo esta privado
              </Alert>
            )}
          </Grid>
        </Grid>

        {/* Primary Button || Secondary Button */}
        <Grid item container columnSpacing={2} xs={12}>
          {/* Primary Button */}
          <Grid item container rowGap={2} xs={6}>
            {/* Header */}
            <Grid item xs={12}>
              <Divider>
                <Chip variant="outlined" label="PRIMARY BUTTON" />
              </Divider>
            </Grid>

            {/* Is_primary_button_enabled */}
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Switch
                  checked={slider.is_primary_button_enabled}
                  color="success"
                  disabled={true}
                />
                {slider.is_primary_button_enabled ? (
                  <Chip
                    icon={<VisibilityIcon />}
                    color="success"
                    label="Boton Visible"
                  />
                ) : (
                  <Chip
                    icon={<VisibilityOffIcon />}
                    color="warning"
                    label="Boton Oculto"
                  />
                )}
              </Stack>
            </Grid>

            {/* Primary_button_text */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="primary_button_text"
                type="text"
                label="Texto"
                variant="standard"
                value={slider.primary_button_text}
                disabled={true}
              />
            </Grid>

            {/* Primary_button_url */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="primary_button_url"
                type="url"
                label="URL"
                variant="standard"
                value={slider.primary_button_url}
                disabled={true}
              />
            </Grid>

            {/* Primary_button_background_color */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="primary_button_background_color"
                type="color"
                label="Color del boton"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={slider.primary_button_background_color}
                disabled={true}
              />
            </Grid>

            {/* Primary_button_text_color */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="primary_button_text_color"
                type="color"
                label="Color del texto"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={slider.primary_button_text_color}
                disabled={true}
              />
            </Grid>
          </Grid>

          {/* Secondary Button */}
          <Grid item container rowGap={2} xs={6}>
            {/* Header */}
            <Grid item xs={12}>
              <Divider>
                <Chip variant="outlined" label="SECONDARY BUTTON" />
              </Divider>
            </Grid>

            {/* Is_secondary_button_enabled */}
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Switch
                  checked={slider.is_secondary_button_enabled}
                  color="success"
                  disabled={true}
                />
                {slider.is_secondary_button_enabled ? (
                  <Chip
                    icon={<VisibilityIcon />}
                    color="success"
                    label="Boton Visible"
                  />
                ) : (
                  <Chip
                    icon={<VisibilityOffIcon />}
                    color="warning"
                    label="Boton Oculto"
                  />
                )}
              </Stack>
            </Grid>

            {/* Secondary_button_text */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="secondary_button_text"
                type="text"
                label="Texto"
                variant="standard"
                value={slider.secondary_button_text}
                disabled={true}
              />
            </Grid>

            {/* Secondary_button_url */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="secondary_button_url"
                type="url"
                label="URL"
                variant="standard"
                value={slider.secondary_button_url}
                disabled={true}
              />
            </Grid>

            {/* Secondary_button_background_color */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="secondary_button_background_color"
                type="color"
                label="Color del boton"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={slider.secondary_button_background_color}
                disabled={true}
              />
            </Grid>

            {/* Secondary_button_text_color */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="secondary_button_text_color"
                type="color"
                label="Color del texto"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={slider.secondary_button_text_color}
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Upload Images */}
      <Grid item container rowGap={2} xs={12}>
        {/* Header */}
        <Grid item xs={12}>
          <Divider textAlign="center">
            <Chip
              label="IMAGENES"
              variant="outlined"
              color="primary"
              icon={<ViewCarouselIcon />}
            />
          </Divider>
        </Grid>

        {/* Container */}
        <Grid item container columnSpacing={2} rowGap={2} xs={12}>
          {/* Cover Desktop */}
          <Grid item xs={6}>
            <Chip
              sx={{ width: "100%" }}
              label="Cover Desktop"
              variant="outlined"
              icon={<DesktopWindowsIcon />}
            />
          </Grid>

          {/* Cover Mobile */}
          <Grid item xs={6}>
            <Chip
              sx={{ width: "100%" }}
              label="Cover Mobile"
              variant="outlined"
              icon={<PhoneIphoneIcon />}
            />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          {slider.cover && (
            <CardMedia
              component="img"
              height="140"
              image={slider.cover}
              sx={{ objectFit: "contain" }}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          {slider.cover_mobile && (
            <CardMedia
              component="img"
              height="140"
              image={slider.cover_mobile}
              sx={{ objectFit: "contain" }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsSlider;
