// React
import { useEffect, useState } from "react";

// Moment
import moment from "moment";

// Hot Toast
import { toast } from "react-hot-toast";
import colors from "../../atoms/Colors";

// Interfaces
import {
   IAttendance,
   IDateSession,
   IRoomDetail,
   IStudentRecord,
} from "../../../interfaces/room.interface";

// Material UI
import {
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   FormControl,
   MenuItem,
   Paper,
   Select,
   SelectChangeEvent,
   Stack,
   Typography,
   IconButton,
   Tooltip,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
   patchAttendanceMutation,
   postCreateUniqueAttendance,
} from "../../../services/attendance";
import AttendanceIndicator from "../../common/AttendanceIndicator/AttendanceIndicator";

import "./tableAttendanceStudent.scss";

interface Props {
   roomInfo: IRoomDetail;
   refetch: () => {};
   setOpenModal: any;
   setValueDateId: any;
   setValueDate: any;
}

const TableAttendanceStudent: React.FC<Props> = ({
   roomInfo,
   refetch,
   setOpenModal,
   setValueDateId,
   setValueDate,
}) => {
   const [currentDateSession, setCurrentDateSession] = useState<IDateSession[]>(
      []
   );

   const [currentStudentsRecords, setCurrentStudentRecords] = useState<
      IStudentRecord[]
   >([]);

   useEffect(() => {
      setCurrentStudentRecords(roomInfo ? roomInfo?.students_records : []);
      setCurrentDateSession(roomInfo ? roomInfo?.date_session : []);

      // eslint-disable-next-line
   }, [roomInfo]);

   const [isLoadingAttendanceUnique, setIsLoadingAttendanceUnique] =
      useState(false);
   const [isLoadingAttendanceMutation, setIsLoadingAttendanceMutation] =
      useState(false);

   const createUniqueAttendance = async (attendance: any) => {
      try {
         setIsLoadingAttendanceUnique(true);
         const data = await postCreateUniqueAttendance(attendance);
         if (data) {
            toast.success("Asistencia tomada correctamente!");
         } else {
            toast.error("Ha ocurrido un error al tomar la asistencia.");
         }
         setIsLoadingAttendanceUnique(false);
      } catch (error) {
         setIsLoadingAttendanceUnique(false);
         console.log("somethings went wrong!", error);
         toast.error("Ha ocurrido un error al tomar la asistencia.");
      }
   };

   const updateAttendanceMutation = async (attendance: any) => {
      try {
         setIsLoadingAttendanceMutation(true);
         const data = await patchAttendanceMutation(attendance);
         if (data) {
            toast.success("Asistencia actualizada correctamente!");
         } else {
            toast.error("Ha ocurrido un error al actualizar la asistencia.");
         }
         setIsLoadingAttendanceMutation(false);
      } catch (error) {
         setIsLoadingAttendanceMutation(false);
         console.log("somethings went wrong!", error);
         toast.error("Ha ocurrido un error al actualizar la asistencia.");
      }
   };

   const handleSubmitUniqueAttendance = async (
      event: any,
      attendance: any,
      student: any
   ) => {
      const newAttendance = {
         number_of_session: attendance.number_of_session,
         status: event.target.value,
         object_id: student,
         room: attendance.room,
      };

      await createUniqueAttendance({ ...newAttendance });
      refetch();
   };

   const handleUpdateStateAttendance = async (event: any, attendance: any) => {
      await updateAttendanceMutation({
         id: attendance.id,
         status: event.target.value,
      });
      refetch();
   };

   return (
      <>
         <TableContainer
            component={Paper}
            sx={{
               maxHeight: 700,
               boxShadow: "none",
               "&::-webkit-scrollbar": {
                  height: 12,
               },
               "&::-webkit-scrollbar-track": {
                  backgroundColor: colors.gris02,
               },
               "&::-webkit-scrollbar-thumb": {
                  backgroundColor: colors.gris03,
                  borderRadius: 2,
               },
            }}
         >
            <Table
               stickyHeader
               sx={{ minWidth: 700 }}
               aria-label="customized table"
            >
               {/* Header */}
               <TableHead>
                  <TableRow>
                     <TableCell
                        component="th"
                        scope="row"
                        sx={{
                           color: colors.gris05,
                           position: "sticky",
                           left: 0,
                           zIndex: 11,
                           background: "#fff",
                           minWidth: 120,
                           maxWidth: 120,
                           width: 120,
                           borderBottom: "none",
                        }}
                     ></TableCell>
                     <TableCell
                        component="th"
                        scope="row"
                        sx={{
                           position: "sticky",
                           left: 152,
                           zIndex: 10,
                           background: "#fff",
                           minWidth: 40,
                           maxWidth: 40,
                           width: 40,
                           borderBottom: "none",
                        }}
                     ></TableCell>
                     <TableCell
                        component="th"
                        scope="row"
                        sx={{
                           position: "sticky",
                           left: 224,
                           zIndex: 9,
                           background: "#fff",
                           minWidth: 220,
                           maxWidth: 220,
                           width: 220,
                           borderBottom: "none",
                        }}
                     ></TableCell>
                     {currentDateSession?.map((row: IDateSession) => (
                        <TableCell
                           key={row?.datetime}
                           component="td"
                           scope="row"
                           sx={{
                              color: "#757575",
                              minWidth: 102,
                              width: 102,
                              p: "12px 4px",
                              borderBottom: "none",
                           }}
                        >
                           <Stack
                              /* direction="column" */
                              columnGap={1.5}
                              alignItems="center"
                              sx={{
                                 display: "flex",
                                 flexDirection: "row",
                                 alignItems: "center",
                                 fontFamily: "Sofia Pro",
                                 fontStyle: "normal",
                                 fontWeight: 600,
                                 fontSize: 12,
                                 justifyContent: "center",
                              }}
                           >
                              {moment(row?.datetime).format("D MMM YYYY")}
                              <Tooltip
                                 title="Editar fecha."
                                 arrow
                                 placement="top"
                              >
                                 <IconButton
                                    onClick={() => {
                                       setOpenModal(true);
                                       setValueDate(
                                          moment(row?.datetime).format(
                                             "YYYY-MM-DD"
                                          )
                                       );
                                       setValueDateId(row?.id);
                                    }}
                                    component="span"
                                    size="small"
                                    sx={{ color: colors.gris03 }}
                                 >
                                    <EditIcon
                                       sx={{
                                          width: 16,
                                          height: 16,
                                       }}
                                    />
                                 </IconButton>
                              </Tooltip>
                           </Stack>
                        </TableCell>
                     ))}
                  </TableRow>
                  <TableRow>
                     <TableCell
                        component="th"
                        scope="row"
                        sx={{
                           position: "sticky",
                           left: 0,
                           top: 57,
                           zIndex: 8,
                           background: "#fff",
                           minWidth: 120,
                           maxWidth: 120,
                           width: 120,
                           fontFamily: "Sofia Pro",
                           fontStyle: "normal",
                           fontWeight: 700,
                           fontSize: 12,
                           textAlign: "center",
                           borderBottom: "none",
                        }}
                     >
                        Estado
                     </TableCell>
                     <TableCell
                        component="th"
                        scope="row"
                        sx={{
                           /* position: "sticky", */
                           left: 152,
                           top: 57,
                           zIndex: 7,
                           minWidth: 40,
                           maxWidth: 40,
                           width: 40,
                           background: "#fff",
                           fontFamily: "Sofia Pro",
                           fontStyle: "normal",
                           fontWeight: 700,
                           fontSize: 12,
                           textAlign: "center",
                           borderBottom: "none",
                        }}
                     >
                        #
                     </TableCell>
                     <TableCell
                        component="th"
                        scope="row"
                        sx={{
                           position: "sticky",
                           left: 224,
                           top: 57,
                           zIndex: 6,
                           background: "#fff",
                           minWidth: 250,
                           maxWidth: 250,
                           width: 250,
                           fontFamily: "Sofia Pro",
                           fontStyle: "normal",
                           fontWeight: 700,
                           fontSize: 12,
                           borderBottom: "none",
                        }}
                     >
                        Estudiante
                     </TableCell>
                     {roomInfo?.date_session.map((session, index) => {
                        return (
                           <TableCell
                              key={session.session_number}
                              component="th"
                              scope="row"
                              sx={{
                                 position: "sticky",
                                 top: 57,
                                 fontFamily: "Sofia Pro",
                                 fontStyle: "normal",
                                 fontWeight: 700,
                                 fontSize: 12,
                                 textAlign: "center",
                                 p: "0px 4px",
                                 borderBottom: "none",
                              }}
                           >
                              Sesión {session.session_number}
                           </TableCell>
                        );
                     })}
                  </TableRow>
               </TableHead>

               {/* Body */}
               <TableBody>
                  {currentStudentsRecords?.map(
                     (row: IStudentRecord, index_row: number) => (
                        <TableRow key={row.student.full_name}>
                           <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                 position: "sticky",
                                 left: 0,
                                 zIndex: 5,
                                 background: colors.white,
                              }}
                           >
                              <Typography
                                 sx={{
                                    color:
                                       row?.enrolment_state === "done"
                                          ? colors.statusAttendedText
                                          : row?.enrolment_state === "risk"
                                          ? colors.statusDelayText
                                          : colors.statusAbsenceText,
                                    background:
                                       row?.enrolment_state === "done"
                                          ? colors.statusAttended
                                          : row?.enrolment_state === "risk"
                                          ? colors.statusDelay
                                          : colors.statusAbsence,
                                    fontFamily: "Sofia Pro",
                                    position: "sticky",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    borderRadius: 1,
                                    p: "8px 8px 4px",
                                    fontSize: 14,
                                    gap: 4,
                                    textAlign: "center",
                                 }}
                              >
                                 {row?.enrolment_state === "done"
                                    ? "Activo"
                                    : row?.enrolment_state === "risk"
                                    ? "Riesgo"
                                    : "Abandono"}
                              </Typography>
                           </TableCell>
                           <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                 position: "sticky",
                                 left: 152,
                                 zIndex: 4,
                                 background: "#fff",
                                 fontFamily: "Sofia Pro",
                                 fontStyle: "normal",
                                 fontWeight: 400,
                                 fontSize: 14,
                                 textAlign: "center",
                              }}
                           >
                              {index_row + 1}
                           </TableCell>
                           {/* Student */}
                           <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                 position: "sticky",
                                 left: 224,
                                 zIndex: 3,
                                 background: "#fff",
                              }}
                           >
                              <Stack
                                 direction="row"
                                 columnGap={1.5}
                                 alignItems="center"
                              >
                                 <div
                                    style={{
                                       color:
                                          row?.enrolment_state === "abandoned"
                                             ? colors.gris03
                                             : colors.gris05,
                                       width: 250,
                                       whiteSpace: "nowrap",
                                       textOverflow: "ellipsis",
                                       overflow: "hidden",
                                       fontFamily: "Sofia Pro",
                                       fontStyle: "normal",
                                       fontWeight: 400,
                                       fontSize: 14,
                                       position: "sticky",
                                    }}
                                 >
                                    {row.student.full_name}
                                 </div>
                              </Stack>
                           </TableCell>

                           {/* Attendances */}
                           {row?.attendances?.map(
                              (attendance: IAttendance, index: number) => (
                                 <TableCell
                                    component="th"
                                    scope="row"
                                    key={index}
                                    sx={{
                                       p: "0px 4px",
                                       textAlign: "center",
                                    }}
                                 >
                                    <FormControl
                                       sx={{ m: 1 }}
                                       variant="standard"
                                    >
                                       <Select
                                          labelId="demo-customized-select-label"
                                          id="demo-customized-select"
                                          value={attendance.status}
                                          defaultValue={0}
                                          /* disabled = {row?.enrolment_state == "abandoned" ? true : false} */
                                          MenuProps={{
                                             anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: 50,
                                             },
                                          }}
                                          sx={{
                                             ".MuiSelect-icon ": {
                                                fill:
                                                   attendance.status == 0
                                                      ? colors.gris03
                                                      : attendance.status == 1
                                                      ? colors.statusAttendedText
                                                      : attendance.status == 2
                                                      ? colors.statusDelayText
                                                      : attendance.status == 3
                                                      ? colors.statusAbsenceText
                                                      : attendance.status == 4
                                                      ? colors.statusJustifiedText
                                                      : colors.gris03,
                                             },
                                          }}
                                          IconComponent={KeyboardArrowDownIcon}
                                          className={`table-attendance-student table-attendance-student__select 
                                             ${
                                                attendance.status == 0
                                                   ? "table-attendance-student__disable"
                                                   : attendance.status == 1
                                                   ? "table-attendance-student__attended"
                                                   : attendance.status == 2
                                                   ? "table-attendance-student__delay"
                                                   : attendance.status == 3
                                                   ? "table-attendance-student__absence"
                                                   : attendance.status == 4
                                                   ? "table-attendance-student__justified"
                                                   : "table-attendance-student__disable"
                                             }
                                          `}
                                          onChange={(
                                             event: SelectChangeEvent<number>
                                          ) => {
                                             if (attendance.id !== null) {
                                                handleUpdateStateAttendance(
                                                   event,
                                                   attendance
                                                );
                                             } else {
                                                handleSubmitUniqueAttendance(
                                                   event,
                                                   attendance,
                                                   row.student.id
                                                );
                                             }
                                          }}
                                       >
                                          <MenuItem
                                             value={0}
                                             style={{ display: "none" }}
                                          >
                                             <em>-</em>
                                          </MenuItem>
                                          <MenuItem
                                             value={1}
                                             sx={{ minWidth: 105 }}
                                          >
                                             <AttendanceIndicator
                                                message="A"
                                                status="attended"
                                             />
                                          </MenuItem>
                                          <hr />
                                          <MenuItem value={2}>
                                             <AttendanceIndicator
                                                message="T"
                                                status="delay"
                                             />
                                          </MenuItem>
                                          <hr />
                                          <MenuItem value={3}>
                                             <AttendanceIndicator
                                                message="F"
                                                status="absence"
                                             />
                                          </MenuItem>
                                          <hr />
                                          <MenuItem value={4}>
                                             <AttendanceIndicator
                                                message="J"
                                                status="justified"
                                             />
                                          </MenuItem>
                                       </Select>
                                    </FormControl>
                                 </TableCell>
                              )
                           )}
                        </TableRow>
                     )
                  )}
               </TableBody>
            </Table>
         </TableContainer>
      </>
   );
};

export default TableAttendanceStudent;
