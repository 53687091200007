import React from "react";

interface Props {
   fill?: string;
   className?: string;
}

const IconDiscord: React.FC<Props> = ({ className, fill = "#5865F2" }) => {
   return (
      <svg
         className={className}
         width="23"
         height="16"
         viewBox="0 0 23 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M19.2146 1.34003C17.727 0.705799 16.1533 0.255152 14.535 0C14.3334 0.33208 14.0979 0.778729 13.9355 1.13406C12.1907 0.894994 10.4619 0.894994 8.74915 1.13406C8.58682 0.77881 8.34599 0.33208 8.14255 0C6.52267 0.255269 4.94759 0.707064 3.45932 1.34334C0.497778 5.42139 -0.305074 9.39804 0.0963078 13.3184C2.0606 14.655 3.96419 15.467 5.83572 15.9984C6.30083 15.4155 6.71199 14.7985 7.06494 14.1536C6.39298 13.9205 5.74519 13.6332 5.12927 13.2952C5.29135 13.1857 5.44962 13.0716 5.60384 12.9531C9.33607 14.5438 13.3914 14.5438 17.0791 12.9531C17.234 13.0708 17.3922 13.1849 17.5536 13.2952C16.9367 13.6341 16.2877 13.9219 15.6144 14.1553C15.9693 14.8028 16.3797 15.4205 16.8436 16C18.7169 15.4687 20.6223 14.6567 22.5865 13.3184C23.0576 8.77376 21.782 4.83353 19.2146 1.33995V1.34003ZM7.57344 10.9075C6.45303 10.9075 5.53419 9.95433 5.53419 8.79371C5.53419 7.63308 6.43345 6.67834 7.57344 6.67834C8.71353 6.67834 9.63228 7.63139 9.61269 8.79371C9.61447 9.95433 8.71353 10.9075 7.57344 10.9075ZM15.1094 10.9075C13.989 10.9075 13.0702 9.95433 13.0702 8.79371C13.0702 7.63308 13.9694 6.67834 15.1094 6.67834C16.2495 6.67834 17.1682 7.63139 17.1487 8.79371C17.1487 9.95433 16.2495 10.9075 15.1094 10.9075V10.9075Z"
            fill={fill}
         />
      </svg>
   );
};

export default IconDiscord;
