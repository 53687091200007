// React
import { memo, useState } from "react";

// Moment
import moment from "moment";
import "moment/locale/es";

// Utils
import { objectsEqual } from "../../../../../utils/utils";

// Interfaces
import { Student } from "../../../../../interfaces/guardian.interface";

// Material UI
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridValueGetterParams,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";

// Components
import CustomLoadingOverlay from "../../../../../components/CustomLoadingOverlay/CustomLoadingOverlay";
import Link from "../../../../../components/molecules/Link";
import IconArrow from "../../../../../components/atoms/icons/IconArrow";

interface Props {
  students: Student[];
  isFetching: boolean;
}

const DataGridStudents: React.FC<Props> = ({ students, isFetching }) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "id",
      sort: "desc",
    },
  ]);

  function currentlySelected(model: GridSortModel) {
    if (!objectsEqual(model, sortModel)) setSortModel(model);
  }

  return (
    <DataGrid
      autoHeight
      style={{ fontSize: 12 }}
      loading={isFetching}
      sortModel={sortModel}
      onSortModelChange={(model) => currentlySelected(model)}
      rows={students}
      columns={generateColumnsByStudents()}
      pageSize={20}
      rowsPerPageOptions={[20]}
      components={{
        LoadingOverlay: CustomLoadingOverlay,
      }}
    />
  );
};

function generateColumnsByStudents(): GridColDef[] {
  const columns: GridColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.id;
      },
    },
    {
      field: "full_name",
      headerName: "Nombre",
      minWidth: 200,
      flex: 1,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return `${params.row.first_name} ${params.row.last_name}`;
      },
    },
    {
      field: "birthdate",
      headerName: "Cumpleaños",
      minWidth: 130,
      disableColumnMenu: true,
      editable: false,
      type: "dateTime",
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.birthdate && moment(params.row.birthdate);
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          params.row.birthdate && moment(params.row.birthdate).format("YYYY MMM D")
        );
      },
    },
    {
      field: "school_name",
      headerName: "Colegio",
      minWidth: 150,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.school_name;
      },
    },
    {
      field: "gender",
      headerName: "Género",
      minWidth: 100,
      disableColumnMenu: true,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.gender;
      },
    },
    {
      field: "device_experience",
      headerName: "Experiencia tecnológica",
      minWidth: 200,
      disableColumnMenu: true,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.device_experience ? "Si 🧑‍💻" : "No 🥲";
      },
    },
    {
      field: "operative_system",
      headerName: "Sistema operativo",
      minWidth: 160,
      disableColumnMenu: true,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.operative_system;
      },
    },
    {
      field: "accion",
      headerName: "Acción",
      minWidth: 160,
      disableColumnMenu: true,
      editable: false,
      renderCell: (params: GridValueGetterParams) => {
        return (
          <>
            <div
              className="table-student-rooms__button table-student-rooms__button--hover-lineal"
            >
              <Link
                  className="table-student-rooms__link-student"
                  to={`/dashboard/students?id=${params.row.id}`}
                  iconRight={<IconArrow fill="#000000" />}
              >
                  Ver estudiante
              </Link>
            </div>
          </>
        );
      },
    },
  ];

  return columns;
}

export default memo(DataGridStudents);
