// React
import { useState } from "react";

// Hot Toast
import { toast } from "react-hot-toast";

// Styles
import { Mailto, Text } from "./styles";

// Material UI
import { IconButton, Tooltip } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import EmailIcon from "@mui/icons-material/Email";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface Props {
  title?: string;
  copyable?: boolean;
  mailable?: {
    email: string;
  };
  hideable?: boolean;
  hideText?: boolean;
  direction?: "left" | "right";
  text: string;
  placement?: "top" | "right" | "bottom" | "left";
}

function Paragraph({
  title = "Copiar",
  copyable = false,
  mailable,
  hideable = false,
  hideText,
  direction,
  text,
  placement = "right",
}: Props) {
  const [copied, setCopied] = useState(false);
  const [hided, setHided] = useState(true);

  const handleCopyable = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);

    toast(`${text.slice(0, 20)} Copiado!`, {
      icon: "👏",
    });

    setTimeout(() => setCopied(false), 1200);
  };
  const handleHideable = () => setHided(!hided);

  return (
    <>
      {/* Copyable */}
      {copyable &&
        (direction === "left" ||
          (direction === undefined && (
            <Tooltip
              title={`${copied ? "Copiado" : "Copiar"}`}
              arrow
              placement="left"
            >
              <IconButton
                onClick={() => handleCopyable(text)}
                component="span"
                size="small"
                color="primary"
              >
                {copied ? <CheckIcon /> : <ContentCopyIcon />}
              </IconButton>
            </Tooltip>
          )))}

      {/* Mailable */}
      {mailable && (
        <Mailto href={`mailto:${mailable.email}`}>
          <Tooltip title="Mailto" arrow placement="top">
            <IconButton component="span" size="small" color="primary">
              <EmailIcon />
            </IconButton>
          </Tooltip>
        </Mailto>
      )}

      {/* Hideable */}
      {hideable && (
        <Tooltip title={`${hided ? "Mostrar" : "Ocultar"}`} arrow placement="top">
          <IconButton
            onClick={() => handleHideable()}
            component="span"
            size="small"
            color="primary"
          >
            {hided ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      )}

      {/* Text */}
      {hideable ? (
        <Text>{hided ? "**********" : text}</Text>
      ) : !hideText ? (
        <Text>{text}</Text>
      ) : null}

      {copyable && direction === "right" && (
        <Tooltip title={`${copied ? "Copiado" : title}`} arrow placement={placement}>
          <IconButton
            onClick={() => handleCopyable(text)}
            component="span"
            size="small"
            color="primary"
          >
            {copied ? <CheckIcon /> : <ContentCopyIcon />}
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}

export default Paragraph;
