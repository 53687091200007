import React from "react";

interface Props {
  fill?: string;
  className?: string;
}

const IconArrow: React.FC<Props> = ({ className, fill = "#313131" }) => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6895 8.75121H12.6879L9.93377 5.49236C9.80499 5.33972 9.74303 5.14294 9.76153 4.94529C9.78002 4.74765 9.87746 4.56534 10.0324 4.43847C10.1873 4.3116 10.3871 4.25057 10.5877 4.26879C10.7884 4.28701 10.9734 4.383 11.1022 4.53564L14.8958 9.02029C14.9213 9.05596 14.9442 9.09343 14.9641 9.1324C14.9641 9.16978 14.9641 9.1922 15.0172 9.22957C15.0516 9.31527 15.0696 9.4065 15.0703 9.49865C15.0696 9.5908 15.0516 9.68203 15.0172 9.76773C15.0172 9.8051 15.0172 9.82752 14.9641 9.8649C14.9442 9.90387 14.9213 9.94134 14.8958 9.97701L11.1022 14.4617C11.0309 14.546 10.9415 14.6139 10.8406 14.6604C10.7396 14.7069 10.6295 14.7309 10.518 14.7307C10.3407 14.7311 10.1689 14.6703 10.0324 14.5588C9.95558 14.4961 9.89207 14.419 9.84552 14.3321C9.79897 14.2451 9.7703 14.1499 9.76113 14.052C9.75197 13.9541 9.76251 13.8554 9.79213 13.7615C9.82175 13.6677 9.86989 13.5805 9.93377 13.5049L12.6879 10.2461H3.6895C3.48827 10.2461 3.29529 10.1673 3.153 10.0272C3.01071 9.887 2.93078 9.69688 2.93078 9.49865C2.93078 9.30042 3.01071 9.1103 3.153 8.97013C3.29529 8.82996 3.48827 8.75121 3.6895 8.75121Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconArrow;
