// React
import { useState } from "react";

// Router
import { useSearchParams } from "react-router-dom";

// Helpers
import { addParams, deleteParams } from "../../../helpers/router.helpers";

// Material UI
import { IconButton } from "@material-ui/core";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

// Components
import GuardianDetails from "./GuardianDetails/GuardianDetails";
import Modal from "../../../components/Modal/Modal";
import OpenModal from "../../../components/Modal/OpenModal/OpenModal";

interface Props {
   guardianId: number;
   onlyIcon?: boolean;
   autoOpen?: boolean;
}

const ModalGuardianDetails: React.FC<Props> = ({
   guardianId,
   onlyIcon,
   autoOpen = false,
}) => {
   const [params, setParams] = useSearchParams();
   const [openModal, setOpenModal] = useState(autoOpen);

   const handleOpenModal = () => {
      addParams(params, setParams, "id", guardianId.toString());
      setOpenModal(true);
   };
   const handleCloseModal = () => {
      deleteParams(params, setParams, "id");
      setOpenModal(false);
   };

   return (
      <>
         {/* Open Modal */}
         {!autoOpen && (
            <>
               {onlyIcon ? (
                  <IconButton color="primary" onClick={handleOpenModal}>
                     <RemoveRedEyeIcon />
                  </IconButton>
               ) : (
                  <OpenModal
                     option="more-info"
                     placement="left"
                     handleOpenModal={handleOpenModal}
                  />
               )}
            </>
         )}

         {/* Modal Guardian Details */}
         <Modal
            id={guardianId}
            title="Tutor / Madre / Padre"
            copyUrl
            fullWidth
            maxWidth="xl"
            openModal={openModal}
            handleCloseModal={handleCloseModal}
         >
            <GuardianDetails guardianId={guardianId} />
         </Modal>
      </>
   );
};

export default ModalGuardianDetails;
