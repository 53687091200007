// React
import React from "react";

// Material UI
import { Chip, Stack, Switch } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface Props {
   typeAll: "all_path" | "all_course";
   valueAll: boolean;
   handleChangeTypeAll: (typeAll: any, value: boolean) => void;
}

const CouponAll: React.FC<Props> = ({
   valueAll,
   typeAll,
   handleChangeTypeAll,
}) => {
   return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
         <Switch
            checked={valueAll}
            color="success"
            onChange={(e) => handleChangeTypeAll(typeAll, e.target.checked)}
         />
         {!valueAll ? (
            <Chip
               icon={<VisibilityOffIcon />}
               color="success"
               label={`Aplicar a ${
                  typeAll == "all_course"
                     ? "todos los cursos"
                     : "todas las rutas"
               }?`}
            />
         ) : null}
      </Stack>
   );
};

export default CouponAll;
