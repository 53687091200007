// React
import { SyntheticEvent, useState } from "react";

// Material UI
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// Components
import AttendanceTeacher from "./AttendanceTeacher/AttendanceTeacher";

function Teachers() {
  const [currentPosition, setCurrentPosition] = useState<string>("0");

  const handleChangePosition = (_event: SyntheticEvent, newValue: string) =>
    setCurrentPosition(newValue);

  return (
    <Box sx={{ width: "100%" }}>
      <Tab label="Profesores" disabled />
      <TabContext value={currentPosition}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangePosition} aria-label="room actions">
            <Tab label="Asistencias" value="0" />
          </TabList>
        </Box>

        <TabPanel value="0">
          <AttendanceTeacher />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default Teachers;
