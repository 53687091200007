// Redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Hot Toast
import { toast } from "react-hot-toast";

// Axios
import { AxiosError } from "axios";

// Interfaces
import { IGroupRooms } from "../../interfaces/group.interface";

// Services
import { getRoomsByGroupId } from "../../services/rooms";
import { postUpdateStudentRoom } from "../../services/students";

export interface IAuthError {
   msg: string;
}

export interface IGroupState {
   rooms: IGroupRooms | null;
   isLoading: boolean;
   isCompleted: boolean;
   error: IAuthError | null;
   rejected: boolean;
}

const initialState: IGroupState = {
   rooms: null,
   isLoading: false,
   isCompleted: false,
   error: null,
   rejected: false,
};

export const listRoomsByGroupId = createAsyncThunk(
   "rooms/listRoomsByGroupId",
   async (groupId: number, { getState, rejectWithValue }: any) => {
      try {
         const response = await getRoomsByGroupId(groupId);
         return response?.data;
      } catch (err) {
         if (err instanceof Error) {
            console.log(err.message);
         }
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const addStudentToRoom = createAsyncThunk(
   "rooms/addStudentToRoom",
   async (
      {roomId, studentId}: any,
      { getState, rejectWithValue, dispatch, ...others }: any
   ) => {
      try {
         const response = await postUpdateStudentRoom(roomId, studentId);
         if (response) {
            toast.success("Alumno agregado al salón correctamente!");
         } else {
            toast.error("Ha ocurrido un error al cambiar al estudiante del salón.");
         }
         return response;
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const roomsByGroupIdSlice = createSlice({
   name: "roomsByGroupId",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      /** listRoomsByGroupId */
      builder.addCase(listRoomsByGroupId.pending, (state, _) => {
         state.rooms = initialState.rooms;
         state.isLoading = true;
      });
      builder.addCase(listRoomsByGroupId.fulfilled, (state, action) => {
         state.rooms = action.payload;
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(listRoomsByGroupId.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
      /** addStudentToRoom */
      builder.addCase(addStudentToRoom.pending, (state, _) => {
         state.isLoading = true;
      });
      builder.addCase(addStudentToRoom.fulfilled, (state, action) => {
         //state?.students?.push(action.payload);
         state.isLoading = false;
         state.error = null;
      });
      builder.addCase(addStudentToRoom.rejected, (state, _) => {
         state.isLoading = initialState.isLoading;
         state.rejected = true;
      });
   },
});

export const selectStateRoomsByGroupId = (state: any) => state.roomsByGroupId;
