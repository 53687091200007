import React, { useState } from "react";

import "./footerPagination.scss";
import IconArrowPagination from "../../atoms/icons/IconArrowPagination";

interface Props {
    children?: any;
    pageIndex?: any;
    previousPage?: any;
    nextPage?: any;
    fromPage?:any;
    toPage?:any;
    totalRows?:any;
    totalPage?:any;
    toggleTopPagination?:boolean;
    setToggleTopPagination?:any;
    paginate:Function;
}

const FooterPagination: React.FC<Props> = ({
    children,
    pageIndex,
    previousPage,
    nextPage,
    fromPage,
    toPage,
    totalRows,
    totalPage,
    toggleTopPagination,
    setToggleTopPagination,
    paginate,
  }) => {

    const [message, setMessage] = useState(1);
    const [beforeMessage, setBeforeMessage] = useState(1);

    const updateNumber = (value: number) : void => {
        if ( 0 < value && value < totalPage + 1){
            setMessage(value);
        }
    };

    const handleChange = (event: any) => {
        setToggleTopPagination(false);
        setMessage(event.target.value);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            if(message > 0){
                paginate(message - 1);
                event.target.blur();
            }
        }
    }

    const handleFocus = (e: any) => {
        setBeforeMessage(e.target.value);
    };

    const handleBlur = (event: any) => {
        if (event.target.value === '') {
            setMessage(beforeMessage);
        } else {
            paginate(message - 1);
        }
    };

    return (
        <div className="footer-pagination">
            <div className="footer-pagination__icon-container">
                <div onClick={() => { updateNumber(pageIndex); previousPage();}} className={`footer-pagination__icon ${pageIndex > 0 ? "footer-pagination__icon--hover-color" : "footer-pagination__icon--disabled" }`}>
                    <IconArrowPagination className="footer-pagination__icon-left" fill={`${pageIndex > 0 ? "#313131" : "#BCBCBC" }`}/>
                </div>

                <span className="footer-pagination__number-page">
                    <input 
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        className="footer-pagination__textbox" 
                        type="number" 
                        min={1}
                        max={totalPage}
                        value={toggleTopPagination ? pageIndex + 1 : message}
                        name="page"
                    />
                    <p className="footer-pagination__text-number">/</p>
                    <p className="footer-pagination__text-number">{totalPage}</p>
                </span>

                <div onClick={() => {updateNumber(pageIndex + 2); nextPage();}} className={`footer-pagination__icon ${pageIndex === totalPage - 1 ? "footer-pagination__icon--disabled" : "footer-pagination__icon--hover-color" }`}>
                    <IconArrowPagination className="footer-pagination__icon-right" fill={`${pageIndex === totalPage - 1 ? "#BCBCBC" : "#313131" }`}/>
                </div>
            </div>
            <div className="footer-pagination__text">
                {`Resultados: ${fromPage} - ${toPage} de ${totalRows}`}
            </div>
        </div>
    );
  };
  
  export default FooterPagination;