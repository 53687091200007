// React
import { useState } from "react";

// Interfaces
import { IStudent } from "../../../../../../interfaces/room.interface";

// Components
import EditStudent from "./EditStudent/EditStudent";
import OpenModal from "../../../../../../components/Modal/OpenModal/OpenModal";
import Modal from "../../../../../../components/Modal/Modal";

interface Props {
  studentId: number;
  student: IStudent;
}

const ModalEditStudent: React.FC<Props> = ({ studentId, student }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      {/* Open Modal */}
      <OpenModal option="edit" placement="left" handleOpenModal={handleOpenModal} />

      {/* Modal Edit Student */}
      <Modal
        id={studentId}
        title="Editar Estudiante"
        fullWidth
        maxWidth="xs"
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      >
        <EditStudent
          studentId={studentId}
          student={student}
          onClose={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default ModalEditStudent;
