// React Query
import { QueryObserver, useMutation, useQueryClient } from "react-query";

// Yup
import * as Yup from "yup";

// Formik
import { Formik, Form } from "formik";

// API
import { POST_MICRO } from "../../../services/api/api";

// Hot Toast
import { toast } from "react-hot-toast";

// Utils
import { sortAlphabetically } from "../../../utils/utils";

// Material UI
import Grid from "@mui/material/Grid";
import { LoadingButton } from "@mui/lab";
import { Alert, Autocomplete, Box, TextField } from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";

const SignupSchema = Yup.object().shape({
    model_id: Yup.number()
        .test("Is positive?", "Seleccione un curso", (value) => value! > 0)
        .required("Requerido"),
});

interface Props {
    srcData: any[];
    programId: number;
    viewType: "course" | "learning_path";
    refetch: () => {};
}

const CreateRule: React.FC<Props> = ({
    srcData,
    programId,
    viewType,
    refetch,
}) => {

    const queryClient = useQueryClient();
    const { refetch: refetchRule } = new QueryObserver(queryClient, {
        queryKey: "coupon-program",
    });

    const createRule = useMutation(
        (rule: any) => POST_MICRO(`/rule/${viewType}`, rule),
        {
            retry: false,
        }
    );

    const initialValues = {
        model_id: -1,
        program_id: programId,
    };

    const searcher = (
        values: any,
        setFieldValue: any
    ) => {
        return (
            <Autocomplete
                fullWidth
                id="model_id"
                options={sortAlphabetically(srcData, "name")}
                autoHighlight
                value={
                    srcData.find((data) => data.id === values.model_id) || null
                }
                onChange={(_e, value: any) => {
                    value
                        ? setFieldValue("model_id", value.id)
                        : setFieldValue("model_id", -1);
                }}
                getOptionLabel={(option) => `${option.name} (${option.id})`}
                renderOption={(props, option) => (
                    <Box key={option.id} component="li" {...props}>
                        [{option.id}] {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={viewType === 'course' ? "Course" : "Learning Path"}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "off",
                        }}
                    />
                )}
            />
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={async (values, { resetForm }) => {
                const data = {
                    model_id: values.model_id,
                    program_id: values.program_id,
                };

                await toast.promise(
                    createRule.mutateAsync(data!),
                    {
                        loading: "Cargando...",
                        success: "Regla añadida",
                        error: "Error",
                    },
                    {
                        success: {
                            icon: <UpdateIcon color="primary" />,
                        },
                    }
                );

                refetchRule();
                resetForm();
                refetch();
            }}
        >
            {({ errors, touched, values, setFieldValue }) => (
                <Form>
                    <Grid item container columnSpacing={1} xs={12}>
                        <Grid item xs={8}>
                            {searcher(values, setFieldValue)}

                            {errors.model_id && touched.model_id && (
                                <Alert severity="error">{errors.model_id}</Alert>
                            )}
                        </Grid>

                        <Grid item xs={4}>
                            <LoadingButton
                                fullWidth
                                loading={createRule.isLoading}
                                variant="contained"
                                type="submit"
                                startIcon={<UpdateIcon />}
                                sx={{ height: "100%" }}
                            >
                                Add
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default CreateRule;