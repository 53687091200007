// Router
import { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../molecules/Sidebar";

import "./dashboardLayout.scss";

const DashboardLayout = () => {
  const [isOpen, setIsOpen] = useState(true);

  let state = isOpen ? "open" : "closed";

  return (
    <main className="dashboard-layout">
      <div className="dashboard-layout__sidebar">
        <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <div className={`dashboard-layout__content-${state}`}>
        <Outlet />
      </div>
    </main>
  );
};

export default DashboardLayout;
