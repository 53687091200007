import React, { useEffect, useRef, useState } from "react";

import "./selectOptions.scss";

interface Props {
   size: any;
   isScroll?: boolean;
   options: any;
   className?: string;
}

const SelectOptions: React.FC<Props> = ({
   options,
   size,
   isScroll,
   className,
}) => {
   return (
      <>
         <div
            className={`select-options__modal select-options__modal--${size} ${
               isScroll ? "select-options__modal--isScroll" : ""
            }  ${className ? className : ""}`}
         >
            <>
               {options && options.length > 0 ? (
                  <ul className={"select-options__modal-content"}>
                     {options?.map((option: any) => option)}
                  </ul>
               ) : (
                  <ul className="select-options__sin-contenido">
                     Se encontraron 0 coincidencias
                  </ul>
               )}
            </>
         </div>
      </>
   );
};

export default SelectOptions;
