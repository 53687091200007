import React from "react";
import Button from "../Button";

import "./buttonFilled.scss";

interface Props {
   children: any;
   onClick?: any;
   className?: string;
   size?: any;
   iconLeft?: any;
   iconRight?: any;
   hasHoverColor?: boolean;
   disabled?: boolean;
   type?: any;
   fullWidth?: boolean | string | number;
   width?: string | number;
}

const ButtonFilled: React.FC<Props> = ({
   children,
   onClick,
   className,
   hasHoverColor,
   disabled,
   type = "button",
   width,
   fullWidth,
   ...otherProps
}) => {
   let hoverType = hasHoverColor ? "hover-color" : "hover-no-color";

   return (
      <>
         <Button
            {...otherProps}
            disabled={disabled}
            className={`button-filled button-filled--${hoverType} ${
               disabled ? "button-filled__disabled" : ""
            } ${className ? className : ""}`}
            onClick={onClick}
            type={type}
            fullWidth={fullWidth}
            width={width}
         >
            {children}
         </Button>
      </>
   );
};

export default ButtonFilled;
