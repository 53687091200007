// React
import { useState } from "react";

// Router
import { Link } from "react-router-dom";

// Moment
import moment from "moment";
import "moment/locale/es";

// Utils
import { objectsEqual } from "../../../../../../../utils/utils";

// Interfaces
import { Enrolment } from "../../../../../../../interfaces/guardian.interface";

// Material UI
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridValueGetterParams,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { IconButton, Stack } from "@material-ui/core";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

// Components
import CancelEnrolment from "./CancelEnrolment/CancelEnrolment";

interface Props {
  enrolments: Enrolment[];
  guardianId:number;
  //refetch: () => {};
}

const ViewEnrolments: React.FC<Props> = ({ enrolments, guardianId }) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "created_at",
      sort: "desc",
    },
  ]);

  function currentlySelected(model: GridSortModel) {
    if (!objectsEqual(model, sortModel)) setSortModel(model);
  }

  return (
    <DataGrid
      autoHeight
      style={{ fontSize: 12 }}
      sortModel={sortModel}
      onSortModelChange={(model) => currentlySelected(model)}
      rows={enrolments}
      columns={generateColumnsByEnrolments()}
      pageSize={20}
      rowsPerPageOptions={[20]}
    />
  );

  function generateColumnsByEnrolments(): GridColDef[] {
    const columns: GridColumns = [
      {
        field: "actions",
        headerName: "Acciones",
        minWidth: 100,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        align: "center",
        headerAlign: "center",
        editable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              direction="row"
              columnGap={1}
              alignItems="center"
              justifyContent="center"
            >
              <CancelEnrolment enrollmentId={params.row.id} guardianId = {guardianId} /* refetch={refetch} */ />
            </Stack>
          );
        },
      },
      {
        field: "id",
        headerName: "ID",
        width: 100,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.id;
        },
      },
      {
        field: "full_name",
        headerName: "Nombre del estudiante",
        minWidth: 210,
        flex: 1,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return (
            params.row.student ?
              `${params.row.student.first_name} ${params.row.student.last_name}`
              :
              ''
          )
        },
      },
      {
        field: "course",
        headerName: "Curso",
        minWidth: 220,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.course.name;
        },
      },
      {
        field: "group",
        headerName: "Grupo",
        minWidth: 500,
        disableColumnMenu: true,
        editable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.group && params.row.group.name;
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              columnGap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              {console.log(params)}
              <span>{params.row.group && params.row.group.name}</span>
              {params.row.group && (
                <Link
                  to={`/dashboard/groups/${params.row.group.id}/rooms`}
                  style={{ textDecoration: "none" }}
                >
                  <IconButton color="primary">
                    <RemoveRedEyeIcon />
                  </IconButton>
                </Link>
              )}
            </Stack>
          );
        },
      },
      {
        field: "created_at",
        headerName: "Fecha de inscripción",
        minWidth: 180,
        disableColumnMenu: true,
        editable: false,
        type: "dateTime",
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.created_at && moment(params.row.created_at);
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            params.row.created_at &&
            moment(params.row.created_at).format("YYYY MMM D")
          );
        },
      },
    ];

    return columns;
  }
};

export default ViewEnrolments;
