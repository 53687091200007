// Yup
import * as Yup from "yup";

// Formik
import { Formik, Form } from "formik";

// Utils
import { sortAlphabetically } from "../../../../../../utils/utils";

// Interfaces
import { ITeacher } from "../../../../../../interfaces/group.interface";

// Redux
import {
   AppDispatch,
   RootState,
   useAppDispatch,
} from "../../../../../../store/store";
import { useSelector } from "react-redux";
import {
   getAllRooms,
   updateRoom,
} from "../../../../../../slices/roomSlice/roomsSlice";

// Constants
import PERMISSIONS from "../../../../../../constants/permission.constants";

// Material UI
import {
   Alert,
   Autocomplete,
   Box,
   Divider,
   Grid,
   TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import UpdateIcon from "@mui/icons-material/Update";
import { listRoomsByGroupId } from "../../../../../../slices/roomSlice/roomsByGroupIdSlice";

const SignupSchema = Yup.object().shape({
   name: Yup.string().min(2, "Muy corto!").required("Requerido"),
   topic: Yup.string().min(2, "Muy corto!").required("Requerido"),
   teacher: Yup.number().min(0, "Elija requerido!").required("Requerido"),
});

interface Props {
   groupId: number;
   roomId: number;
   roomName: string;
   roomTopic: string;
   roomTeacher: number;
   teachers: ITeacher[];
   refetch?: () => {};
   onClose: () => void;
}

const EditRoom: React.FC<Props> = ({
   groupId,
   roomId,
   roomName,
   roomTopic,
   roomTeacher,
   teachers,
   refetch,
   onClose,
}) => {
   const dispatch: AppDispatch = useAppDispatch();
   const user = useSelector((state: RootState) => state.auth.user);
   const { isCompleted } = useSelector((state: RootState) => state.rooms);

   const funcUpdateRoom = async (room: any) => {
      try {
         await dispatch(updateRoom({ roomId, room }));
      } catch (error) {
         console.log(error);
      } finally {
         if (user) {
            const userId = user?.permission[PERMISSIONS.roomListAll]
               ? 0
               : user?.id;
            await dispatch(getAllRooms(userId));
         }
      }
   };

   const initialValues = {
      name: roomName,
      topic: roomTopic,
      teacher: roomTeacher,
      group: groupId,
   };

   return (
      <Formik
         initialValues={initialValues}
         validationSchema={SignupSchema}
         onSubmit={async (values) => {
            await funcUpdateRoom({
               name: values.name.trim(),
               teacher: values.teacher,
            });
            dispatch(listRoomsByGroupId(Number(groupId)));
            /* refetch(); */
            onClose();
         }}
      >
         {({ errors, touched, values, handleChange, setFieldValue }) => (
            <Form>
               <Grid container rowGap={2}>
                  {/* Basic Data */}
                  <Grid item container columnSpacing={2} rowGap={2}>
                     {/* Name */}
                     <Grid item xs={6}>
                        <TextField
                           autoFocus
                           fullWidth
                           id="name"
                           label="Nombre del salón"
                           variant="outlined"
                           placeholder="Construye con Roblox"
                           value={values.name}
                           onChange={handleChange}
                        />
                        {errors.name && touched.name && (
                           <Alert severity="error">{errors.name}</Alert>
                        )}
                     </Grid>

                     {/* Topic */}
                     <Grid item xs={6}>
                        <TextField
                           fullWidth
                           id="topic"
                           label="Asunto"
                           variant="outlined"
                           placeholder="Profesor John Titor"
                           value={values.topic}
                           onChange={handleChange}
                           disabled
                        />
                        {errors.topic && touched.topic && (
                           <Alert severity="error">{errors.topic}</Alert>
                        )}
                     </Grid>

                     {/* Teacher */}
                     <Grid item xs={12}>
                        <Autocomplete
                           fullWidth
                           id="teacher"
                           options={sortAlphabetically(teachers, "full_name")}
                           autoHighlight
                           value={
                              teachers.find(
                                 (teacher) => teacher.id === values.teacher
                              ) || null
                           }
                           onChange={(_e, value: ITeacher) => {
                              value
                                 ? setFieldValue("teacher", value.id)
                                 : setFieldValue("teacher", -1);
                           }}
                           getOptionLabel={(option) => `${option.full_name}`}
                           renderOption={(props, option) => (
                              <Box key={option.id} component="li" {...props}>
                                 {option.full_name}
                              </Box>
                           )}
                           renderInput={(params) => (
                              <TextField
                                 {...params}
                                 label="Profesor"
                                 inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                 }}
                              />
                           )}
                        />
                        {errors.teacher && touched.teacher && (
                           <Alert severity="error">{errors.teacher}</Alert>
                        )}
                     </Grid>
                  </Grid>

                  {/* Edit Room */}
                  <Grid item container rowSpacing={2} xs={12}>
                     <Grid item xs={12}>
                        <Divider />
                     </Grid>

                     <Grid item xs={12}>
                        <LoadingButton
                           fullWidth
                           /* loading={isCompleted ? false : true} */
                           variant="contained"
                           type="submit"
                           startIcon={<UpdateIcon />}
                        >
                           Editar
                        </LoadingButton>
                     </Grid>
                  </Grid>
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default EditRoom;
