// Formik
import { Formik, Form } from "formik";

//Redux
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../../store/store";
import { selectStateStudentForId } from "../../../../slices/studentSlice/studentByIdSlice";

// Material UI
import Grid from "@mui/material/Grid";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import {
   Alert,
   Autocomplete,
   FormControl,
   InputLabel,
   LinearProgress,
   MenuItem,
   Select,
   TextField,
} from "@mui/material";
import { Box } from "@material-ui/system";
import IconArrow from "../../../../components/atoms/icons/IconArrow";
import TableStudentRooms from "../../../../components/organism/TableStudentRooms";

// components
import Link from "../../../../components/molecules/Link";
import Typography from "../../../../components/atoms/Typography";
import colors from "../../../../components/atoms/Colors";

interface Props {
   studentId: number;
}

const DetailsStudent: React.FC<Props> = ({ studentId }) => {
   
   const { guardians, isLoading } = useSelector((state: RootState) => state.guardians);
   const { student, isLoadingEditStudent } = useSelector(selectStateStudentForId);
   const dispatch: AppDispatch = useAppDispatch();

   if (isLoadingEditStudent || isLoading || !student || !guardians)
      return <LinearProgress />;

   const initialValues = {
      first_name: student.first_name,
      last_name: student.last_name,
      birthdate: student.birthdate,
      school_name: student.school_name,
      gender: student.gender,
      device_experience: student.device_experience,
      operative_system: student.operative_system,
      email: student.email,
      password: student.password,
      password_google: student.password_google,
      guardian: student.guardian,
      user_mdl_id: student.user_mdl_id,
   };

   return (
      <Formik initialValues={initialValues} onSubmit={async (values) => {}}>
         {({ errors, touched, values, handleChange, setFieldValue }) => (
            <Form>
               <Grid container rowGap={2}>
                  {/* Details */}
                  <Grid item container columnSpacing={2} rowGap={3} xs={12}>
                     {/* User_mdl_id */}
                     <Grid item xs={2}>
                        <TextField
                           disabled
                           fullWidth
                           id="user_mdl_id"
                           label="ID moodle"
                           variant="outlined"
                           value={values.user_mdl_id}
                           onChange={handleChange}
                        />
                        {errors.user_mdl_id && touched.user_mdl_id && (
                           <Alert severity="error">{errors.user_mdl_id}</Alert>
                        )}
                     </Grid>

                     {/* First_name */}
                     <Grid item xs={4}>
                        <TextField
                           disabled
                           fullWidth
                           id="first_name"
                           label="Nombre"
                           variant="outlined"
                           value={values.first_name}
                           onChange={handleChange}
                        />
                        {errors.first_name && touched.first_name && (
                           <Alert severity="error">{errors.first_name}</Alert>
                        )}
                     </Grid>

                     {/* Last_name */}
                     <Grid item xs={6}>
                        <TextField
                           disabled
                           fullWidth
                           id="last_name"
                           label="Apellido"
                           variant="outlined"
                           value={values.last_name}
                           onChange={handleChange}
                        />
                        {errors.last_name && touched.last_name && (
                           <Alert severity="error">{errors.last_name}</Alert>
                        )}
                     </Grid>

                     {/* School_name */}
                     <Grid item xs={12}>
                        <TextField
                           disabled
                           fullWidth
                           id="school_name"
                           label="Nombre del colegio"
                           variant="outlined"
                           value={values.school_name}
                           onChange={handleChange}
                        />
                        {errors.school_name && touched.school_name && (
                           <Alert severity="error">{errors.school_name}</Alert>
                        )}
                     </Grid>

                     {/* Birthdate */}
                     <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                           <DatePicker
                              disabled
                              label="Cumpleaños"
                              inputFormat="yyyy-MM-dd"
                              value={values.birthdate}
                              disableMaskedInput
                              onChange={(value) =>
                                 setFieldValue("birthdate", value)
                              }
                              renderInput={(params) => (
                                 <TextField fullWidth {...params} />
                              )}
                           />
                           {errors.birthdate && touched.birthdate && (
                              <Alert severity="error">{errors.birthdate}</Alert>
                           )}
                        </LocalizationProvider>
                     </Grid>

                     {/* Gender */}
                     <Grid item xs={3}>
                        <FormControl fullWidth>
                           <InputLabel id="gender">Género</InputLabel>
                           <Select
                              disabled
                              labelId="gender"
                              id="gender"
                              label="Género"
                              value={values.gender}
                              onChange={(value) =>
                                 setFieldValue("gender", value.target.value)
                              }
                           >
                              <MenuItem value="male">Male</MenuItem>
                              <MenuItem value="female">Female</MenuItem>
                              <MenuItem value="unspecified">
                                 Unspecified
                              </MenuItem>
                           </Select>
                           {errors.gender && touched.gender && (
                              <Alert severity="error">{errors.gender}</Alert>
                           )}
                        </FormControl>
                     </Grid>

                     {/* Device_experience */}
                     <Grid item xs={4}>
                        <FormControl fullWidth>
                           <InputLabel id="device_experience">
                              Experiencia tecnológica
                           </InputLabel>
                           <Select
                              disabled
                              labelId="device_experience"
                              id="device_experience"
                              label="Experiencia tecnológica"
                              value={values.device_experience}
                              onChange={(value) =>
                                 setFieldValue(
                                    "device_experience",
                                    value.target.value
                                 )
                              }
                           >
                              <MenuItem value="notexperience">
                                 notexperience
                              </MenuItem>
                              <MenuItem value="basic">basic</MenuItem>
                              <MenuItem value="intermediate">
                                 intermediate
                              </MenuItem>
                              <MenuItem value="advance">advance</MenuItem>
                           </Select>
                           {errors.device_experience &&
                              touched.device_experience && (
                                 <Alert severity="error">
                                    {errors.device_experience}
                                 </Alert>
                              )}
                        </FormControl>
                     </Grid>

                     {/* Operative_system */}
                     <Grid item xs={2}>
                        <FormControl fullWidth>
                           <InputLabel id="operative_system">
                              Sistema operativo
                           </InputLabel>
                           <Select
                              disabled
                              labelId="operative_system"
                              id="operative_system"
                              label="Experiencia tecnologica"
                              value={values.operative_system}
                              onChange={(value) =>
                                 setFieldValue(
                                    "operative_system",
                                    value.target.value
                                 )
                              }
                           >
                              <MenuItem value="none">none</MenuItem>
                              <MenuItem value="windows">windows</MenuItem>
                              <MenuItem value="macos">macos</MenuItem>
                              <MenuItem value="linux">linux</MenuItem>
                              <MenuItem value="other">other</MenuItem>
                           </Select>
                           {errors.operative_system &&
                              touched.operative_system && (
                                 <Alert severity="error">
                                    {errors.operative_system}
                                 </Alert>
                              )}
                        </FormControl>
                     </Grid>

                     {/* Email */}
                     <Grid item xs={12}>
                        <TextField
                           disabled
                           fullWidth
                           id="email"
                           label="Correo"
                           variant="outlined"
                           value={values.email}
                           onChange={handleChange}
                        />
                        {errors.email && touched.email && (
                           <Alert severity="error">{errors.email}</Alert>
                        )}
                     </Grid>

                     {/* Password */}
                     <Grid item xs={6}>
                        <TextField
                           disabled
                           fullWidth
                           id="password"
                           label="Contraseña"
                           variant="outlined"
                           value={values.password}
                           onChange={handleChange}
                        />
                        {errors.password && touched.password && (
                           <Alert severity="error">{errors.password}</Alert>
                        )}
                     </Grid>

                     {/* Password Google */}
                     <Grid item xs={6}>
                        <TextField
                           disabled
                           fullWidth
                           id="password_google"
                           label="Contraseña Google"
                           variant="outlined"
                           value={values.password_google}
                           onChange={handleChange}
                        />
                        {errors.password_google && touched.password_google && (
                           <Alert severity="error">
                              {errors.password_google}
                           </Alert>
                        )}
                     </Grid>

                     {/* Guardian */}
                     <Grid item xs={10}>
                        <Autocomplete
                           disabled
                           fullWidth
                           id="guardian"
                           options={guardians}
                           autoHighlight
                           defaultValue={guardians.find(
                              (guardian) => guardian.id === student.guardian.id
                           )}
                           onChange={(_e, value: any) =>
                              setFieldValue("guardian", value)
                           }
                           getOptionLabel={(option) =>
                              `${option.first_name} ${option.last_name} (${option.id})`
                           }
                           renderOption={(props, option) => (
                              <Box key={option.id} component="li" {...props}>
                                 {option.first_name} {option.last_name} (
                                 {option.id})
                              </Box>
                           )}
                           renderInput={(params) => (
                              <TextField
                                 {...params}
                                 label="Tutor / Madre / Padre"
                                 inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                 }}
                              />
                           )}
                        />
                        {errors.guardian && touched.guardian && (
                           <Alert severity="error">{errors.guardian}</Alert>
                        )}
                     </Grid>
                     <Grid item xs={2}>
                        <div className="table-student-rooms__container">
                           <Link
                              //hoverColor = {true}
                              className="table-student-rooms__link table-student-rooms__link--hover-lineal"
                              iconRight={<IconArrow fill={colors.white} />}
                              to={`/dashboard/guardians?id=${student.guardian.id}`}
                           >
                              Ver Tutor
                           </Link>
                        </div>
                     </Grid>
                     <Grid item xs={12}>
                        <Typography 
                           className="table-student-rooms__title"
                           type="paragraph" 
                           variation="paragraph2" bold
                        >
                           Salones
                        </Typography>
                        <TableStudentRooms />
                     </Grid>
                  </Grid>
               </Grid>
            </Form>
         )}
      </Formik>
   );
};

export default DetailsStudent;
