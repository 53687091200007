import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
   IGuardian,
   IGuardianDetails,
   UpdateInvoice,
} from "../../interfaces/guardian.interface";
import {
   getGuardianInvoices,
   patchEditGuardianInvoice,
} from "../../services/guardians";

export interface IAuthError {
   msg: string;
}

export interface IGuardianDetailsState {
   guardians: IGuardian[];
   guardian: IGuardianDetails | {};
   isLoadingGuardianDetails: boolean;
   isLoadingUpdateInvoice: boolean;
   isCompleted: boolean;
   error: IAuthError | null;
   rejected: boolean;
}

const initialState: IGuardianDetailsState = {
   guardians: [],
   guardian: {},
   isLoadingGuardianDetails: false,
   isLoadingUpdateInvoice: false,
   isCompleted: false,
   error: null,
   rejected: false,
};

export const getGuardianDetails = createAsyncThunk(
   "guardiansInvoices/getGuardianDetails",
   async (guardianId: number, { rejectWithValue }: any) => {
      try {
         /* const { auth: user } = getState() as RootState; */
         const data = await getGuardianInvoices(guardianId);
         return data;
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const updateGuardianInvoice = createAsyncThunk(
   "guardiansInvoices/updateGuardianInvoice",
   async ({ invoiceId, values }: any, { rejectWithValue }: any) => {
      try {
         const data = await patchEditGuardianInvoice(invoiceId, values);
         return { id: data.id, ...values };
      } catch (err) {
         let error: AxiosError = err as AxiosError;
         return rejectWithValue(error.response?.data);
      }
   }
);

export const guardiansInvoicesSlice = createSlice({
   name: "guardiansInvoices",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      /** getOnlyGuardianForId */
      builder.addCase(getGuardianDetails.pending, (state, _) => {
         state.guardian = initialState.guardian;
         state.isLoadingGuardianDetails = true;
      });
      builder.addCase(getGuardianDetails.fulfilled, (state, action) => {
         state.guardian = action.payload;
         state.isLoadingGuardianDetails = false;
         state.error = null;
      });
      builder.addCase(getGuardianDetails.rejected, (state, _) => {
         /* state.rooms = initialState.rooms; */
         state.isLoadingGuardianDetails = initialState.isLoadingGuardianDetails;
         state.rejected = true;
      });
      /** updateGuardianInvoice */
      builder.addCase(updateGuardianInvoice.pending, (state, _) => {
         state.isLoadingUpdateInvoice = true;
      });
      builder.addCase(updateGuardianInvoice.fulfilled, (state, action) => {
         if (!action.payload) {
            console.log(
               "Update could not complete, dont exist ID Guardian",
               action.payload
            );
            return;
         }
         const payload = action.payload;
         const guardianState = { ...state.guardian } as IGuardianDetails;
         const guardianFiltered = guardianState.invoices.filter(
            (invoice: any) => invoice.id !== payload.id
         );
         const guardianUpdated = guardianState.invoices.find((invoice: any) => {
            if (invoice.id === payload.id) {
               return {
                  ...invoice,
                  amount: payload.amount,
                  currency: payload.currency,
                  invoiced_at: payload.invoiced_at,
                  is_verified: payload.is_verified,
                  payment_at: payload.payment_at,
                  payment_gateway: payload.payment_gateway,
                  source: payload.source,
               };
            }
         });
         const newGuardianState = {
            ...guardianState,
            invoices: [...guardianFiltered, guardianUpdated],
         };
         state.guardian = newGuardianState;
         state.isLoadingUpdateInvoice = false;
         state.isCompleted = true;
         state.error = null;
      });
      builder.addCase(updateGuardianInvoice.rejected, (state, _) => {
         state.isLoadingUpdateInvoice = initialState.isLoadingUpdateInvoice;
         state.rejected = true;
      });
   },
});

export const selectStateGuardianDetails = (state: any) =>
   state.guardiansInvoices;
